import React, { useState, useEffect, useRef } from "react"
import IotDevicesConfig from "./iotDevicesConfig"
import * as Features from "@features"
import defaultListConfig from "./fixture"
import { useListPageState } from "@state"
import { stubData } from "@fixture"
import { getTrainingProductsApi } from "./api"
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import {
  setlocationOfSmartDeviceConfig,
  selectedSDCLocation,
} from "../../reduxSagas/admin/actions"
import { ConfirmationDialog } from "@features"
import * as Adm from "@adm"
import { Button, Dialog, IconButton, Tooltip } from "@material-ui/core"
import HierarchyMapping from "../masterData/HierarchyMapping"
import _ from "lodash"
import { withStyles } from "@material-ui/core/styles"
import { Grid, Paper, TextField, Card } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import SearchIcon from "@material-ui/icons/Search"
import MaintenancePage from "../smartDeviceConfig/MaintenancePage"
import HeirarchyTableColumns from "./fixture/HeirarchyTableColumns"
import SyncIcon from "@material-ui/icons/Sync"
import LastSync from "./LastSync"
import { useTranslation } from "react-i18next"

const StyledDialog = withStyles((theme) => ({
  paper: {
    minHeight: "90vh !important",
    maxHeight: "120vh !important",
    minWidth: "170vh !important",
    maxWidth: "190vh !important",
    padding: "0px",
  },
}))(Dialog)

const useStyles = makeStyles((theme) => ({
  paperStyles: {
    padding: "14px",
    marginBottom: 25,
  },
  textFeildStylesWithSearch: {
    width: "100%",
    height: "38px",
    "& div": {
      borderRadius: "5px 0px 0px 5px !important",
    },
  },
  textFeildStyles: {
    width: "100%",
    height: "38px",
    "& div": {
      borderRadius: "5px 0px 0px 5px !important",
    },
  },
  iconStyles: {
    color: "white",
    marginTop: 8,
    marginLeft: 5,
    cursor: "pointer",
  },
  inputEndorsementStyles: {
    color: "blue",
  },
  cardstyle: {
    height: "100%",
    margin: "auto",
    flexDirection: "column",
  },
}))

const TrainingProducts = ({
  locationListInitial,
  locationListDatas,
  locationId,
  setlocationId,
  locationObjectData = [],
  setLocationObjectData,
  isWriteAllowed = false,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const locationDat = locationListDatas
  const [dropDownList, setDropDownList] = useState([
    "Warehouse",
    "Scrap",
    "Select Location",
  ])
  const [stores1, setStores1] = React.useState([])
  const [selectedLocationList, setSelectedLocationList] = useState([])
  const [isDedupeOpen, setDedupeOpen] = useState(false)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [createClicked, setCreateClicked] = useState(false)
  const [reDirect, setReDirect] = useState(false)
  const [locationType, setLocationType] = useState("")
  const [re_render, setRerender] = useState(false)
  const history = useHistory()
  const countries = stubData.data.country.data
  const pageData = stubData.data.country
  const dataState = {
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [],
    take: pageData.next.limit,
    skip: 0,
  }

  const turnstileGlobalState =
    useListPageState({ dataState, defaultListConfig }) || {}
  const {
    loading,
    alert,
    alertMessage,
    alertType,
    columnData,
    setAlert,
    setLoading,
    setAlertMessage,
    setAlertType,
    setColumnData,
    rowData,
    items,
    setItems,
    setRowData,
    gridState,
    selection,
    setSelection,
    setGridState,
  } = turnstileGlobalState

  const tableLocationAttributes = {
    tableGlobalState: turnstileGlobalState,
    tabs: ["Training Products"],
    tabWindows: [{ src: "Default" }],
    title: "Training Products",
    deleteMultiple: true,
    detailPageRoute: `/master-data/products/training-products/details`,
    reDirectPath: `${t("/master-data/products/training-products/details")}/`,
    editVariable: "_id",
  }
  const urlGridState =
    history?.location?.state?.state?.urlGridState || window?.windowGridState

  useEffect(() => {
    if (urlGridState === undefined) {
      setGridState(gridState)
      window["redirectPage"] = false
    }
  }, [])

  useEffect(() => {
    if (!window?.redirectPage) {
      if (window !== undefined) {
        window["windowGridState"] = gridState
      }
      getTrainingProductsApi(turnstileGlobalState, locationId)
    }
  }, [gridState, locationId])

  useEffect(() => {
    if (urlGridState !== undefined && window?.redirectPage) {
      setGridState(urlGridState)
      window["redirectPage"] = false
      setRerender(true)
    }
  }, [urlGridState])

  useEffect(() => {
    setCreateClicked(false)
    return () => {
      if (!history.location.pathname.includes(t("training-products"))) {
        setlocationId("", "FS_SDCLocId")
        setLocationObjectData([], "FS_SDCLocObj")
      }
    }
  }, [])

  useEffect(() => {
    const newSelection = { ...selection }
    newSelection.selectedItems.length = 0
    newSelection.selectedLocationRowItems.length = 0
    newSelection.selectAll = false
    newSelection.store_ids.length = 0
    setSelection(newSelection)
  }, [locationId])

  useEffect(() => {
    const newDropDownList = [...dropDownList]
    if (locationId.length !== 0) {
      !newDropDownList.includes(locationId) && newDropDownList.push(locationId)
      setlocationId(locationId, "FS_SDCLocId")
    } else {
      if (
        locationListDatas &&
        locationListDatas.status === "success" &&
        locationListDatas.data &&
        locationListDatas.data.docs &&
        locationListDatas.data.docs.length > 0
      ) {
        !newDropDownList.includes(locationListDatas.data.docs[0].locationId) &&
          newDropDownList.push(locationListDatas.data.docs[0].locationId)
        setlocationId(locationListDatas.data.docs[0].locationId, "FS_SDCLocId")
      }
    }
  }, [locationListDatas])

  const handleDedupeModal = (val) => {
    if (val === "Select Location") {
      setDedupeOpen(true)
    } else {
      setlocationId(val, "FS_SDCLocId")
    }
  }
  const handleDedupeClose = () => {
    setDedupeOpen(false)
  }

  const onDialogSubmit = (selectedItems) => {
    if (selectedItems.length === 1) {
      const newDropDownList = [...dropDownList]
      let selectedIds = selectedItems.map((x) => _.get(x, "locationId"))
      setlocationId(selectedIds[0], "FS_SDCLocId")
      setLocationObjectData(selectedItems, "FS_SDCLocObj")
      newDropDownList.pop()
      newDropDownList.push(selectedIds[0])
      setStores1(selectedIds)
      setLocationType(selectedItems[0].locationType?.code)
      setSelectedLocationList(selectedItems)
      setDedupeOpen(false)
      ;(locationId === null || locationId.toString().length === 0) &&
        createClicked &&
        history.push(t(`/master-data/products/training/create`))
    } else {
      setAlertType("error")
      setAlert(true)
      setAlertMessage("Please choose only one Location")
    }
  }
  const onCloseHandlerToMakeDefaultValues = () => {
    setAlertType("")
    setAlert(false)
    setAlertMessage("")
  }

  const handleReset = () => {
    setlocationId("", "FS_SDCLocId")
    setLocationObjectData([], "FS_SDCLocObj")
  }

  return (
    <>
      <Adm.BackdropOverlay open={loading} />
      {
        <IotDevicesConfig
          urlGridState={window?.windowGridState}
          setCreateClicked={setCreateClicked}
          setDedupeOpen={setDedupeOpen}
          locationId={locationId}
          breadCrumbTitle={["Master Data", "Products", "Training Products"]}
          path={t(`/master-data/products/training-products/create`)}
          disableField={
            locationObjectData &&
            locationObjectData[0] &&
            locationObjectData[0].locationType?.code === "Decommission"
              ? true
              : false
          }
          createButtonEnabled={isWriteAllowed}
        />
      }
      <Paper elevation={3} className={classes.paperStyles}>
        <Grid direction="row" container className="cls-trainingproducts">
          <Grid item xs={4} sm={5} md={5} lg={4} xl={4}>
            <div style={{ display: "flex" }}>
              <TextField
                className={`${classes.textFeildStyles} makeStyles-textFeildStyles-support`}
                id="outlined-basic"
                label={t("Location Number")}
                variant="outlined"
                size="small"
                disabled
                value={locationId}
              />
              <div
                onClick={() => setDedupeOpen(true)}
                style={{
                  backgroundColor: "var(--primaryColor)",
                  cursor: "pointer",
                  height: 38,
                  width: 34,
                  borderRadius: "0px 5px 5px 0px",
                }}
              >
                <SearchIcon className={classes.iconStyles} />
              </div>
            </div>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            {locationId && (
              <Button
                style={{
                  marginLeft: "40px",
                  lineHeight: "2.1",
                  borderColor: "var(--primaryColor)",
                  color: "var(--primaryColor)",
                }}
                variant="outlined"
                color="primary"
                size="medium"
                onClick={handleReset}
              >
                {t("Clear")}
              </Button>
            )}
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <LastSync
              handleRefresh={() => {
                getTrainingProductsApi(turnstileGlobalState, locationId)
              }}
            />
          </Grid>
        </Grid>
      </Paper>

      <Features.CustomTableUI
        isWriteAllowed={isWriteAllowed}
        isLookup={locationId === "Scrap" ? true : false}
        {...tableLocationAttributes}
        handleDedupeModal={handleDedupeModal}
        locationId={locationId}
        dropDownList={dropDownList}
        deleteMultiple={true}
        ShowCheckBox={false}
      />
      <StyledDialog
        open={isDedupeOpen}
        onClose={handleDedupeClose}
        aria-labelledby="form-dialog-title"
        maxWidth="xl"
        style={{
          position: "fixed",
          zIndex: 1300,
        }}
      >
        <HierarchyMapping
          showLocationTypeFilters={true}
          lookupType="location"
          isLookUp
          handleCloseLookUp={handleDedupeClose}
          handleLookUpPrimaryAction={onDialogSubmit}
          isWriteAllowed={isWriteAllowed}
          hideCheckBoxAll={true}
          selectedItems={locationObjectData}
          ShowCheckBox={false}
          sdcFilter={[
            {
              field: "pos_search",
              operator: "eq",
              value: true,
            },
          ]}
          columns={HeirarchyTableColumns}
          isKCApi={true}
        />
      </StyledDialog>
      {alert ? (
        <Adm.SnackBar
          makeValuesToDefault={true}
          onCloseHandlerToMakeDefaultValues={onCloseHandlerToMakeDefaultValues}
          open={true}
          message={alertMessage}
          type={alertType}
        />
      ) : null}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    locationListDatas: state.adminReducer.locationListData,
    locationId: state.adminReducer.smartDeviceConfig.FS_SDCLocId,
    locationObjectData: state.adminReducer.locationObjectData.FS_SDCLocObj,
  }
}
const mapDispatchToProps = () => {
  return {
    setlocationId: setlocationOfSmartDeviceConfig,
    setLocationObjectData: selectedSDCLocation,
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(TrainingProducts)
