import React, { useEffect, useState } from "react"
import { Grid, TablePagination } from "@material-ui/core"
import ProductAdjustMapping from "./ProductAdjustMapping"
import { useTranslation } from "react-i18next"

export const ProductAdjustMappingList = (props) => {
  const {
    getPackageRawData = [],
    defaultList = [],
    isStorage = true,
    onUpdateEachItemSubmit = () => "",
    identifier = "",
  } = props
  const rowsPerPageOptions = [5, 10, 20]
  const { t } = useTranslation()
  const [getPage, setPage] = useState(0)
  const [getRowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0])

  let page = getPage
  let rowsPerPage = getRowsPerPage
  let totalCount = defaultList?.length || 100
  let filterData =
    defaultList.length > 0
      ? defaultList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : []

  const renderPagination = () => (
    <TablePagination
      id="id-renderPagination-kit"
      labelRowsPerPage={`${t("Rows per page")}:`}
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={totalCount}
      page={page}
      rowsPerPage={rowsPerPage}
      onChangePage={(event, newPage) => {
        setPage(newPage)
      }}
      onChangeRowsPerPage={(event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
      }}
    />
  )

  console.log(filterData, "filterData")
  return (
    <Grid>
      {renderPagination()}
      {filterData.map((product, index) => {
        let IncrementValue =
          product?.selected === undefined
            ? product?.selected
              ? "Increment"
              : "Decrement"
            : "Increment"

        let isIncrement = IncrementValue.includes("Increment")
        let isDecrement = IncrementValue.includes("Decrement")

        let quantity_value = product?.quantity || "0"
        let inventory_on_hand_value = product?.inventory_on_hand
        let projected_inventory_Value =
          quantity_value === "0"
            ? "0"
            : isIncrement
            ? `${Number(inventory_on_hand_value) + Number(quantity_value)}`
            : `${Number(inventory_on_hand_value) - Number(quantity_value)}`
        let editData = {
          ...product,
          storeUnit: identifier,
          inventoryOnHand: product?.inventory_on_hand,
        }

        return (
          <ProductAdjustMapping
            getPackageRawData={getPackageRawData}
            productIndex={index}
            viewPage="create"
            isStorage={isStorage}
            onUpdateSubmit={(e) => {
              let event = e
              event["values"]["item_id"] = product?.item_id
              onUpdateEachItemSubmit(event)
            }}
            editData={editData}
          />
        )
      })}
      {renderPagination()}
    </Grid>
  )
}
