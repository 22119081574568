import React, { useEffect, useState, useRef, useContext } from "react"
import {
  SwipeableDrawer,
  Grid,
  Typography,
  CardHeader,
  Card,
  FormControlLabel,
  Checkbox,
  Slider,
  IconButton,
  Box,
  Button,
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { augmentConfig, postObject } from "./Fixture"
import { CloseIcon, Save } from "@iconsGallery"
import AccordianPanel from "../../../components/AdmKit/AccordianPanel/AccordianPanel"
import _, { cloneDeep } from "lodash"
import { CardBody } from "@progress/kendo-react-layout"
import { CheckBox } from "@material-ui/icons"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { renderAugmentDetails, renderChildAugmentDetails } from "./renderProps"
import { letterSpacing } from "@material-ui/system"
import * as ServiceAPI from "@services"
import * as constantsAPI from "@constants"
import { API_AUGMENTATION_URL } from "../../../utils/url"
import * as Adm from "@adm"
import { JSUtils, GlobalAppContext } from "@utils"
import { useTranslation } from "react-i18next"
import { useStyles } from "./styles"
import PreviewImage from "./PreviewImage"
import { GeneratePostObject } from "./utils"
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite"

const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}
const { headerAxios } = ServiceAPI

const START_AUGMENTATION = `${API_AUGMENTATION_URL}${API_END_POINTS.augmentation}`

export default function AugmentationFilter(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    openAug,
    setOpenAug,
    rowData,
    param,
    locationId,
    getProductDetails,
    handleSetLivedata,
    setSelection,
    selectionState,
    loading,
    setLoading,
  } = props

  const [augmentConfigList, setAugmentConfigList] = useState(augmentConfig)
  const [augPostObj, setAugPostObj] = useState(postObject)
  const [enableSave, setEnableSave] = useState(true)
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [showSnackBarType, setShowSnackBarType] = useState("success")
  const [snackBarMessage, setSnackBarMessage] = useState("")
  const [data, setData] = useState([])
  const GlobalAppStates = useContext(GlobalAppContext)
  let wsTelemtryTrainingList = useRef(null)

  useEffect(() => {
    if (rowData?.data.length > 0) {
      let copyAugPostObj = cloneDeep(augPostObj)
      let tempFileIds = []
      rowData?.data?.map((item) => {
        if (item.selected) {
          tempFileIds.push(item.image_id)
        }
      })
      copyAugPostObj.file_ids = tempFileIds
      copyAugPostObj.trianing_product_id = param
      setAugPostObj(copyAugPostObj)
    }
  }, [rowData])

  const handleChange = (mainData, subData, childData, updateValue) => {
    setEnableSave(false)
    let copyAugmentConfigList = cloneDeep(augmentConfigList)
    setAugPostObj(
      GeneratePostObject(augPostObj, subData, childData, updateValue)
    )

    const index = copyAugmentConfigList.findIndex(
      (x) => x.name === mainData.name
    )
    const index2 = copyAugmentConfigList[index].child.findIndex(
      (x) => x.name === subData.name
    )
    if (childData !== "") {
      const index3 = copyAugmentConfigList[index].child[index2].child.findIndex(
        (x) => x.name === childData.name
      )
      copyAugmentConfigList[index].child[index2].child[index3][
        "value"
      ] = updateValue
    } else {
      copyAugmentConfigList[index].child[index2]["value"] = updateValue
    }

    setAugmentConfigList(copyAugmentConfigList)
  }

  const handleReset = () => {
    setAugmentConfigList(augmentConfig)
    setAugPostObj(postObject)
    setSnackBarMessage("")
    setEnableSave(true)
    setShowSnackBar(false)
  }

  let WSSURL = process.env.REACT_APP_BASE_DOMAIN_URL.replace(
    "https://",
    "wss://"
  )
  const handleSubmit = async () => {
    setShowSnackBar(false)
    setLoading(true)
    let getTenantId = localStorage.getItem("tenantId")
    let SOCKET_URL = `${WSSURL}/api/augmentationws/augmentation/status/ws`
    let initialData = {
      tenantId: getTenantId || "",
      product_id: param,
    }

    headerAxios
      .post(START_AUGMENTATION, augPostObj, {
        headers: {
          locationId: locationId,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false)
          setShowSnackBarType("success")
          setShowSnackBar(true)
          setSnackBarMessage(
            JSUtils.formatMessageCode(
              res?.data,
              t,
              t("Augmentation completed successfully")
            )
          )
          getProductDetails(param)
          handleReset()
          setOpenAug(false)
        } else {
          setLoading(false)
          setShowSnackBarType("error")
          setShowSnackBar(true)
          setSnackBarMessage(JSUtils.formatMessageCode(res?.response?.data, t))
          setOpenAug(true)
        }
      })
      .catch(() => {
        setLoading(false)
        setShowSnackBarType("error")
        setShowSnackBar(true)
        setSnackBarMessage(
          JSUtils.formatMessageCode({}, t, "Internal Server Error")
        )
        setOpenAug(true)
      })

    setSelection(selectionState)
  }

  const handleAccordianChange = (name) => {
    const copyAugmentConfigList = augmentConfigList.map((item) => {
      return {
        ...item,
        isOpen: item.name === name ? !item.isOpen : false,
      }
    })
    setAugmentConfigList(copyAugmentConfigList)
  }

  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer
          open={props.openAug}
          anchor={"right"}
          onClose={() => {
            props.setOpenAug(false)
          }}
        >
          <Adm.SnackBar
            open={showSnackBar}
            message={snackBarMessage}
            type={showSnackBarType}
          />
          <Card className="cls-Augmentation">
            <CardHeader
              title={t("AUGMENTATION")}
              action={
                <>
                  <Button
                    style={{
                      marginLeft: "10px",
                    }}
                    variant={"contained"}
                    disabled={enableSave}
                    onClick={() => {
                      handleReset()
                    }}
                  >
                    {t("RESET")}
                  </Button>
                  <Button
                    variant={"contained"}
                    endIcon={<PlayCircleFilledWhiteIcon />}
                    disabled={enableSave}
                    onClick={() => handleSubmit()}
                    style={{
                      marginLeft: "10px",
                      background: enableSave ? "" : "#02a627",
                      color: enableSave ? "" : "#ffffff",
                    }}
                  >
                    {t("START AUGMENTATION")}
                  </Button>
                </>
              }
            />
          </Card>
          <Grid container className={classes.cls_aug_grid}>
            <Grid item xs={7} className={classes.cls_img_item_grid}>
              <PreviewImage filter={augmentConfigList} />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={4} className={classes.cls_main_aug_grid}>
              {augmentConfigList.map((item) => (
                <div
                  style={{ marginBottom: "2px" }}
                  className="cls-Augmentation"
                >
                  <AccordianPanel
                    isOpen={item.isOpen}
                    label={t(item.name)}
                    isEditMode={false}
                    handleExpandClick={() => {
                      handleAccordianChange(item.name)
                    }}
                    renderContainer={() => {
                      return (
                        <div style={{ marginBottom: "20px" }}>
                          {item.child.map((subItem) => {
                            return (
                              <div
                                style={{ padding: "20px 20px 0px 20px" }}
                                className="cls-Augmentation"
                              >
                                {renderAugmentDetails({
                                  item,
                                  subItem,
                                  handleChange,
                                  classes,
                                  t,
                                })}
                                <div>
                                  {subItem?.child?.map((childItem) => {
                                    return renderChildAugmentDetails({
                                      item,
                                      subItem,
                                      childItem,
                                      handleChange,
                                      classes,
                                      t,
                                    })
                                  })}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      )
                    }}
                  />
                </div>
              ))}
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  )
}
