import React, { useEffect, useState } from "react"
import { useStyles } from "./styles"

const getTransformModalFx = (filter = []) => {
  let fx = {}
  const [_x, _color, _clarity] = filter
  const { child: _colorChild = [] } = _color
  const { child: _clarityChild = [] } = _clarity

  filter.map((itm, index) => {
    const { child = [] } = itm
    child.map((childItm) => {
      const { child: subChild = [] } = childItm
      subChild.map((subChildItm) => {
        let keyName = subChildItm.name.toLowerCase().replaceAll(" ", "_")
        let valueName = subChildItm.value
        fx[keyName] = valueName
      })
    })
  })
  _colorChild.map((subChildItm) => {
    let keyName = subChildItm.name.toLowerCase().replaceAll(" ", "_")
    let valueName = subChildItm.value
    fx[keyName] = valueName
  })
  _clarityChild.map((subChildItm) => {
    let keyName = subChildItm.name.toLowerCase().replaceAll(" ", "_")
    let valueName = subChildItm.value
    fx[keyName] = valueName
  })

  return fx
}
function PreviewImage(props) {
  const { filter: filterList = [] } = props
  const classes = useStyles()

  let transform = ""
  let filter = ""
  let fxKit = getTransformModalFx(filterList)

  Object.keys(fxKit).forEach(function (key, index) {
    if ("brightness" === key) {
      filter = `${filter} brightness(${fxKit[key]})`
    }
    if ("hue" === key) {
      filter = `${filter} hue-rotate(${fxKit[key]}turn)`
    }
    if ("saturation" === key) {
      filter = `${filter} saturate(${fxKit[key]})`
    }
    if ("contrast" === key) {
      filter = `${filter} contrast(${fxKit[key]})`
    }
    if ("grayscale" === key && fxKit[key] === true) {
      filter = `${filter} grayscale(100%)`
    }
    if ("vertical_flip" === key && fxKit[key] === true) {
      transform = `${transform} rotateX(-180deg)`
    }
    if ("horizontal_flip" === key && fxKit[key] === true) {
      transform = `${transform} rotateY(-180deg)`
    }
    if ("rotation(180°)" === key && fxKit[key] === true) {
      transform = `${transform} rotate(${fxKit[key] === true ? "180" : "0"}deg)`
    }
    if ("sharpen" === key) {
      filter = `${filter} contrast(${100 + fxKit[key]}%) brightness(${
        100 + fxKit[key] / 2
      }%)`
    }
  })
  let styles = {
    transform,
    filter,
  }

  return (
    <div>
      <img
        style={styles}
        className={classes.cls_img_aug}
        alt="augmentimage"
        id="augmentimage"
        src="/static/img/AugmentImage.jpg"
      />
    </div>
  )
}

export { PreviewImage }
export default PreviewImage
