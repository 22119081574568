import {
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core"
import * as IconsGallery from "@iconsGallery"
import { createFilterOptions } from "@material-ui/lab/Autocomplete"
import { Autocomplete } from "@material-ui/lab"
import HelpIcon from "@material-ui/icons/Help"
import * as Adm from "@adm"
import * as Yup from "yup"
import React, { useState } from "react"
import { Formik } from "formik"
import _, { values } from "lodash"
import * as constantsAPI from "@constants"
import * as ServiceAPI from "@services"
import { CloseRounded } from "@material-ui/icons"
import styled from "styled-components"
import { useStyles, ProDialog, StyledAutocomplete } from "./style"
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

export const renderProductCard = (innerProductProps) => {
  const {
    product = {},
    onRemove = () => "",
    editImageURL = "",
    editProductName = "",
    editItemTitle = "",
    editModel = false,
    t,
  } = innerProductProps

  var defaultImage =
    product?.item_images?.length > 0
      ? _.find(product?.item_images, { default: true })
      : product?.imageURL
  const {
    _id = "",
    item_id = editItemTitle || "",
    title = editProductName || "",
    imageUrl = editImageURL?.length > 0
      ? editImageURL
      : "/static/img/noproducts.png",
    stockCode = "-",
    storeUnit = "",
  } = product

  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginTop: 0,
        height: "100%",
      }}
    >
      <Grid
        style={{
          marginTop: 4,
          width: "100%",
          height: "100%",
          minHeight: 110,
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
        }}
      >
        {title && title.length > 0 ? (
          <>
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid
                style={{
                  width: 180,
                  height: 180,
                  maxWidth: 180,
                  maxHeight: 180,
                  minWidth: 180,
                  minHeight: 180,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "inset 0px 0px 12px #08080852",
                  borderRadius: 4,
                  border: "1px solid #cccccc",
                }}
              >
                <img
                  src={defaultImage?.url || defaultImage || imageUrl}
                  alt={title}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxWidth: 160,
                    maxHeight: 160,
                    minWidth: 160,
                    minHeight: 160,
                  }}
                />
              </Grid>
              <Grid
                style={{
                  marginTop: 20,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "fit-content",
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                <Grid
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    wordWrap: "break-word",
                    margin: 0,
                    display: "flex",
                    fontSize: 14,
                    width: "100%",
                    color: "#656565",
                  }}
                >
                  <span style={{ width: 100 }}>
                    <span className="cls-plain-text-ui-kit">
                      {`${t("Item Id")}:`}
                    </span>
                  </span>
                  <span
                    style={{
                      fontWeight: "bold",
                      maxWidth: "calc(100% - 55px)",
                    }}
                  >
                    <span className="cls-plain-text-ui-kit">
                      {` ${item_id}`}
                    </span>
                  </span>
                </Grid>
                <Grid
                  style={{
                    wordWrap: "break-word",
                    margin: 0,
                    display: "flex",
                    fontSize: 14,
                    width: "100%",
                    color: "#656565",
                  }}
                >
                  <span style={{ width: 100 }}>
                    <span className="cls-plain-text-ui-kit">
                      {`${t("SKU")}:`}
                    </span>
                  </span>
                  <span
                    style={{
                      fontWeight: "bold",
                      maxWidth: "calc(100% - 55px)",
                    }}
                  >
                    <span className="cls-plain-text-ui-kit">
                      {` ${stockCode}`}
                    </span>
                  </span>
                </Grid>
                <Grid
                  style={{
                    wordWrap: "break-word",
                    margin: 0,
                    display: "flex",
                    fontSize: 14,
                    width: "100%",
                    color: "#656565",
                  }}
                >
                  <span style={{ width: 100 }}>
                    <span className="cls-plain-text-ui-kit">
                      {`${t("Location")}:`}
                    </span>
                  </span>
                  <span
                    style={{
                      fontWeight: "bold",
                      maxWidth: "calc(100% - 55px)",
                    }}
                  >
                    <span className="cls-plain-text-ui-kit">
                      {` ${storeUnit}`}
                    </span>
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid> {`${t("Product Not Added")}`}</Grid>
        )}
      </Grid>
    </Grid>
  )
}

export const renderStar = () => <span style={{ color: "red" }}>*</span>
export const renderValue = (value = "") => (
  <Grid style={{ color: "#000000", fontSize: 20, fontWeight: "bold" }}>
    <span className="cls-plain-text-ui-kit">
      {value.length > 0 ? value : "-"}
    </span>
  </Grid>
)

export const renderFieldTitle = (props) => {
  const { title = "xxx", renderChild = () => "" } = props
  return (
    <Grid>
      <Typography variant="h5">
        <span className="cls-plain-text-ui-kit">{title}</span>
        {renderStar()}
        {renderChild()}
      </Typography>
    </Grid>
  )
}

export const renderModalHeader = (props) => {
  const { title = "", renderChild = () => "" } = props
  return (
    <Grid
      id="scroll-dialog-title"
      style={{
        background: "#EFF2FF",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: 50,
        padding: 4,
        border: "1px solid #C6C6C6",
        borderRadius: "10px 10px 0px 0px",
      }}
    >
      <Grid item xs={8}>
        <Typography variant="h4" style={{ fontSize: 20, marginLeft: 10 }}>
          <span className="cls-plain-text-ui-kit">{title}</span>
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        container
        justify="flex-end"
        style={{ fontSize: 20, marginRight: 10 }}
      >
        {renderChild()}
      </Grid>
    </Grid>
  )
}

export const renderFieldInventoryOnHand = (props) => {
  const {
    getIsEdit,
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleChange,
    onGlobalUpdate,
    t,
  } = props
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: t("Inventory On Hand"),

        renderChild: () => {
          return (
            <>
              {editModel && (
                <Tooltip
                  title={t(
                    "Update the Inventory on hand solely through the Adjustments module to ensure the accurate tracking of Inventory"
                  )}
                  arrow
                  placement="top"
                >
                  <HelpIcon style={{ margin: "0 0 -5px 5px" }} />
                </Tooltip>
              )}
            </>
          )
        },
      })}
      <Grid>
        {getIsEdit ? (
          <TextField
            type="text"
            name="inventoryOnHand"
            disabled={true}
            className={classes.textInput}
            placeholder={t("Enter Available on Hand")}
            value={values?.inventoryOnHand}
            error={Boolean(touched.inventoryOnHand && errors.inventoryOnHand)}
            helperText={touched.inventoryOnHand && t(errors.inventoryOnHand)}
            onBlur={handleBlur}
            onKeyDown={(e) => handleWhiteSpace(e)}
            onKeyPress={(e) => handleKeyPress(e, "text")}
            onChange={(e) => {
              handleChange(e)
              // onGlobalUpdate()
            }}
            variant="outlined"
            size="small"
          />
        ) : (
          renderValue(values?.inventoryOnHand)
        )}
      </Grid>
    </Grid>
  )
}

export const renderFieldProjectedInventory = (props) => {
  const {
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleChange,
    onGlobalUpdate,
    getIsEdit,
    t,
  } = props
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: t("Projected Inventory"),
      })}
      <Grid>
        {getIsEdit ? (
          <TextField
            type="text"
            name="projected_inventory"
            disabled={true}
            className={classes.textInput}
            placeholder={t("Enter Available on Hand")}
            value={values?.projected_inventory?.toString()}
            error={Boolean(
              touched.projected_inventory && errors.projected_inventory
            )}
            helperText={
              touched.projected_inventory && t(errors.projected_inventory)
            }
            onBlur={handleBlur}
            onKeyDown={(e) => handleWhiteSpace(e)}
            onKeyPress={(e) => handleKeyPress(e, "text")}
            onChange={(e) => {
              handleChange(e)
              // onGlobalUpdate()
            }}
            variant="outlined"
            size="small"
          />
        ) : (
          renderValue(values?.projected_inventory)
        )}
      </Grid>
    </Grid>
  )
}

// export const renderFieldTaxRate = (props) => {
//   const {
//     editModel,
//     classes,
//     values,
//     touched,
//     errors,
//     handleBlur,
//     handleWhiteSpace,
//     handleKeyPress,
//     handleChange,
//     onGlobalUpdate,
//     isCheckTax = false,
//     getTaxPrice,
//     getPrepopuplate,
//     getIsEdit,
//   } = props
//   return (
//     <React.Fragment>
//       {isCheckTax && (
//         <Grid className={`cls-field-form-kit`}>
//           {renderFieldTitle({
//             title: "Tax Rate (%)",
//           })}

//           <Grid>
//             {getIsEdit ? (
//               <TextField
//                 type="text"
//                 className={classes.textInput}
//                 name="taxRate"
//                 placeholder="Enter Tax Rate"
//                 value={values.taxRate}
//                 error={Boolean(touched.taxRate && errors.taxRate)}
//                 helperText={touched.taxRate && errors.taxRate}
//                 onBlur={handleBlur}
//                 onKeyDown={(e) => handleWhiteSpace(e)}
//                 onKeyPress={(e) => handleKeyPress(e, "text")}
//                 onChange={(e) => {
//                   handleChange(e)
//                   getTaxPrice(e, "taxRate")
//                   // onGlobalUpdate()
//                 }}
//                 variant="outlined"
//                 size="small"
//               />
//             ) : (
//               renderValue(values.taxRate)
//             )}
//           </Grid>
//         </Grid>
//       )}
//     </React.Fragment>
//   )
// }

// export const renderFieldTaxValue = (props) => {
//   const {
//     editModel,
//     classes,
//     values,
//     touched,
//     errors,
//     handleBlur,
//     handleWhiteSpace,
//     handleKeyPress,
//     handleChange,
//     onGlobalUpdate,
//     isCheckTax,
//     getIsEdit,
//   } = props
//   return (
//     <React.Fragment>
//       {isCheckTax && (
//         <Grid className={`cls-field-form-kit`}>
//           {renderFieldTitle({
//             title: "Tax Value",
//           })}

//           <Grid>
//             {getIsEdit ? (
//               <TextField
//                 type="text"
//                 name="taxValue"
//                 className={classes.disabledTextInput}
//                 disabled
//                 value={values?.taxValue}
//                 variant="outlined"
//                 size="small"
//               />
//             ) : (
//               renderValue(values?.taxValue)
//             )}
//           </Grid>
//         </Grid>
//       )}
//     </React.Fragment>
//   )
// }

export const renderActionButton = (props) => {
  const {
    resetForm,
    handleSubmit,
    editModel,
    getIsEdit = false,
    setIsEdit = () => "",
  } = props
  const savebuttonAttrs = {
    variant: "contained",
    type: "submit",
    color: "primary",
  }

  const editbuttonAttrs = {
    variant: "contained",
    type: "cancel",
    color: "primary",
  }

  const cancelbuttonAttrs = {
    variant: "contained",
    type: "cancel",
    color: "primary",
  }

  return (
    <Grid>
      <Grid>
        {getIsEdit ? (
          <>
            <IconButton
              onClick={() => {
                resetForm()
                setIsEdit(false)
              }}
            >
              <IconsGallery.Slash style={{ color: "red" }} />
            </IconButton>
            <IconButton
              onClick={() => {
                handleSubmit()
              }}
            >
              <IconsGallery.CheckCircleIcon
                style={{
                  color: "#1dbe1d",
                  fontSize: 26,
                }}
              />
            </IconButton>
          </>
        ) : (
          <IconButton
            onClick={() => {
              setIsEdit(true)
            }}
          >
            <IconsGallery.EditIcon
              style={{
                color: "#000",
                fontSize: 26,
              }}
            />
          </IconButton>
        )}
      </Grid>
    </Grid>
  )
}

export const renderFieldPackageType = (props) => {
  const {
    editModel,
    classes,
    values,
    touched,
    errors,
    handlePackageType,
    handleScroll,
    getIsEdit,
    getPackageRawData = [],
    t,
  } = props

  const { adjustment_type = "" } = values || {}

  let isIncrement = adjustment_type.includes("Increment")
  let lcValue = values?.package_type
  let packageList = getPackageRawData.map((item) => item.packagetype_name)
  let lcPackageList = isIncrement ? packageList : ["empty"]

  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: "Select Package Type",
      })}
      <Grid>
        {getIsEdit ? (
          <StyledAutocomplete
            noOptionsText={t("No options")}
            clearText={t("Clear")}
            closeText={t("Close")}
            openText={t("Open")}
            id="id-pmaj-dropdown-menu-kit"
            options={lcPackageList}
            onChange={() => handlePackageType}
            disableClearable={true}
            value={lcValue}
            disabled={isIncrement ? (editModel ? true : false) : true}
            style={editModel ? { backgroundColor: "#f6f6f6" } : {}}
            ListboxProps={{
              onScroll: (e) => handleScroll(e),
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  name={"package_type"}
                  variant="outlined"
                  className={`${classes.autoCompleteHeight} makeStyles-autoCompleteHeight-support`}
                  placeholder="Select Package Type"
                  error={Boolean(touched.package_type && errors.package_type)}
                  helperText={touched.package_type && errors.package_type}
                />
              )
            }}
          />
        ) : (
          renderValue(lcValue)
        )}
      </Grid>
    </Grid>
  )
}
export const renderFieldAdjustmentReason = (props) => {
  const {
    editModel,
    classes,
    values,
    touched,
    errors,
    handleAdjustmentReason,
    productOpen,
    handleScroll,
    getIsEdit,
    t,
  } = props
  const { adjustment_type = "" } = values || {}
  let isIncrement = adjustment_type.includes("Increment")
  let lcPackageList = isIncrement
    ? ["Restock"]
    : ["Expired", "Damaged", "Shrink"]
  let lcValue = values?.adjustment_reason
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: t("Select Adjustment Reason"),
      })}
      <Grid>
        {getIsEdit ? (
          <StyledAutocomplete
            noOptionsText={t("No options")}
            clearText={t("Clear")}
            closeText={t("Close")}
            openText={t("Open")}
            id="id-pmaj-dropdown-menu-kit"
            options={lcPackageList}
            onChange={handleAdjustmentReason}
            // disableClearable={true}
            value={lcValue}
            disabled={isIncrement ? true : editModel ? true : false}
            style={editModel ? { backgroundColor: "#f6f6f6" } : {}}
            ListboxProps={{
              onScroll: (e) => handleScroll(e),
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  variant="outlined"
                  className={`${classes.autoCompleteHeight} makeStyles-autoCompleteHeight-support`}
                  placeholder={t("Select Adjustment Reason")}
                  error={Boolean(
                    touched.adjustment_reason && errors.adjustment_reason
                  )}
                  helperText={
                    touched.adjustment_reason && t(errors.adjustment_reason)
                  }
                />
              )
            }}
          />
        ) : (
          renderValue(lcValue)
        )}
      </Grid>
    </Grid>
  )
}

export const renderFieldAdjustmentType = (props) => {
  const {
    editModel,
    classes,
    values,
    touched,
    errors,
    handleAdjustmentType,
    handleAdjustmentReason,
    handleScroll,
    getIsEdit,
    setFieldValue,
    isStorage = false,
    t,
  } = props
  let lcPackageList = ["Increment", "Decrement"]

  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: t("Select Adjustment Type"),
      })}
      <Grid>
        {getIsEdit ? (
          <StyledAutocomplete
            noOptionsText={t("No options")}
            clearText={t("Clear")}
            closeText={t("Close")}
            openText={t("Open")}
            id="id-pmaj-dropdown-menu-kit"
            options={lcPackageList}
            onChange={(e, val) => {
              handleAdjustmentType(e, val, () => {
                let adjustment_type_kit = values?.adjustment_type
                let isIncrement = val.includes("Increment")
                let inventory_on_hand_kit = values?.inventoryOnHand
                let value = values?.quantity
                let isNaNKit = isNaN(value)
                let valueKit = isNaNKit
                  ? 0
                  : isIncrement
                  ? Number(inventory_on_hand_kit) + Number(value)
                  : Number(inventory_on_hand_kit) - Number(value)
                setFieldValue(
                  "projected_inventory",
                  value.length > 0 ? (value === "0" ? 0 : valueKit) : 0
                )
                let valueSample = []
                if (valueSample.length > 0) {
                  valueSample.forEach((sample) => {
                    setFieldValue(sample?.key, sample?.value)
                  })
                }
                handleAdjustmentReason(
                  e,
                  val.includes("Decrement") ? "Expired" : "Restock"
                )
              })
            }}
            disableClearable={true}
            value={values?.adjustment_type}
            disabled={editModel ? true : false}
            style={editModel ? { backgroundColor: "#f6f6f6" } : {}}
            ListboxProps={{
              onScroll: (e) => handleScroll(e),
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  variant="outlined"
                  className={`${classes.autoCompleteHeight} makeStyles-autoCompleteHeight-support`}
                  placeholder={t("Select Adjustment Type")}
                  error={Boolean(
                    touched.adjustment_type && errors.adjustment_type
                  )}
                  helperText={
                    touched.adjustment_type && t(errors.adjustment_type)
                  }
                />
              )
            }}
          />
        ) : (
          renderValue(values?.adjustment_type)
        )}
      </Grid>
    </Grid>
  )
}

export const renderFieldReorderQuantity = (props) => {
  const {
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleChange,
    onGlobalUpdate,
    getIsEdit,
    setFieldValue,
    t,
  } = props
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: t("Quantity"),
      })}

      <Grid>
        {getIsEdit ? (
          <TextField
            type="text"
            className={classes.textInput}
            name="quantity"
            placeholder={t("Enter Quantity")}
            value={values.quantity}
            error={Boolean(touched.quantity && errors.quantity)}
            helperText={touched.quantity && t(errors.quantity)}
            onBlur={handleBlur}
            onKeyDown={(e) => handleWhiteSpace(e)}
            onKeyPress={(e) => handleKeyPress(e, "text")}
            disabled={values.adjustment_type === ""}
            onChange={(e) => {
              let adjustment_type_kit = values?.adjustment_type
              let isIncrement = adjustment_type_kit.includes("Increment")
              let inventory_on_hand_kit = values?.inventoryOnHand
              let value = e.currentTarget.value
              let isNaNKit = isNaN(value)
              let valueKit = isNaNKit
                ? 0
                : isIncrement
                ? Number(inventory_on_hand_kit) + Number(value)
                : Number(inventory_on_hand_kit) - Number(value)
              setFieldValue(
                "projected_inventory",
                value.length > 0 ? (value === "0" ? 0 : valueKit) : 0
              )
              handleChange(e)
            }}
            variant="outlined"
            size="small"
          />
        ) : (
          renderValue(values.quantity)
        )}
      </Grid>
    </Grid>
  )
}

export const renderFieldPackageReceived = (props) => {
  const {
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleChange,
    onGlobalUpdate,
    getIsEdit,
  } = props
  let isDecrement =
    values?.adjustment_type !== undefined
      ? values?.adjustment_type.includes("Decrement")
      : false
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: "Package Received",
      })}

      <Grid>
        {getIsEdit ? (
          <TextField
            disabled={isDecrement}
            type="text"
            className={classes.textInput}
            name="package_received"
            placeholder="Enter Package Received"
            value={values.package_received}
            error={Boolean(touched.package_received && errors.package_received)}
            helperText={touched.package_received && errors.package_received}
            onBlur={handleBlur}
            onKeyDown={(e) => handleWhiteSpace(e)}
            onKeyPress={(e) => handleKeyPress(e, "text")}
            onChange={(e) => {
              handleChange(e)
              // onGlobalUpdate()
            }}
            variant="outlined"
            size="small"
          />
        ) : (
          renderValue(values.package_received)
        )}
      </Grid>
    </Grid>
  )
}

export const renderFieldOrderQuantity = (props) => {
  const {
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleChange,
    onGlobalUpdate,
    getIsEdit,
    setFieldValue,
  } = props
  let isDecrement =
    values?.adjustment_type !== undefined
      ? values?.adjustment_type.includes("Decrement")
      : false
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: "Order Quantity",
      })}

      <Grid>
        {getIsEdit ? (
          <TextField
            disabled={isDecrement}
            type="text"
            className={classes.textInput}
            name="order_quantity"
            placeholder="Enter Package Received"
            value={values.order_quantity}
            error={Boolean(touched.order_quantity && errors.order_quantity)}
            helperText={touched.order_quantity && errors.order_quantity}
            onBlur={handleBlur}
            onKeyDown={(e) => handleWhiteSpace(e)}
            onKeyPress={(e) => handleKeyPress(e, "text")}
            onChange={(e) => {
              handleChange(e)
              setFieldValue("order_received", e?.target?.value)
              if (values?.unit_price !== "") {
                let invnCalc = e?.target?.value * values?.unit_price
                setFieldValue("total_price", invnCalc.toString())
              }
              // onGlobalUpdate()
            }}
            variant="outlined"
            size="small"
          />
        ) : (
          renderValue(values.order_quantity)
        )}
      </Grid>
    </Grid>
  )
}

export const renderFieldOrderReceived = (props) => {
  const {
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleChange,
    onGlobalUpdate,
    getIsEdit,
    setFieldValue,
  } = props
  let isDecrement =
    values?.adjustment_type !== undefined
      ? values?.adjustment_type.includes("Decrement")
      : false
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: "Order Received",
      })}

      <Grid>
        {getIsEdit ? (
          <TextField
            disabled={isDecrement}
            type="text"
            className={classes.textInput}
            name="order_received"
            placeholder="Enter Order Received"
            value={values.order_received}
            error={Boolean(touched.order_received && errors.order_received)}
            helperText={touched.order_received && errors.order_received}
            onBlur={handleBlur}
            onKeyDown={(e) => handleWhiteSpace(e)}
            onKeyPress={(e) => handleKeyPress(e, "text")}
            onChange={(e) => {
              handleChange(e)
              if (values?.unit_price !== "") {
                let invnCalc = values?.unit_price * e?.target?.value
                setFieldValue("total_price", invnCalc.toString())
              }
              // onGlobalUpdate()
            }}
            variant="outlined"
            size="small"
          />
        ) : (
          renderValue(values.order_received)
        )}
      </Grid>
    </Grid>
  )
}

export const renderFieldUnitPrice = (props) => {
  const {
    getIsEdit,
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleChange,
    onGlobalUpdate,
    setFieldValue,
  } = props
  let isDecrement =
    values?.adjustment_type !== undefined
      ? values?.adjustment_type.includes("Decrement")
      : false
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: "Unit Price",
      })}

      <Grid>
        {getIsEdit ? (
          <TextField
            disabled={isDecrement}
            type="text"
            className={classes.textInput}
            name="unit_price"
            placeholder="Enter Unit Price"
            value={values.unit_price}
            error={Boolean(touched.unit_price && errors.unit_price)}
            helperText={touched.unit_price && errors.unit_price}
            onBlur={handleBlur}
            onKeyDown={(e) => handleWhiteSpace(e)}
            onKeyPress={(e) => handleKeyPress(e, "text")}
            onChange={(e) => {
              handleChange(e)
              if (values?.order_received !== "") {
                let invnCalc = e?.target?.value * values?.order_received
                setFieldValue("total_price", invnCalc.toString())
              }
              // onGlobalUpdate()
            }}
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{`$`}</InputAdornment>
              ),
            }}
          />
        ) : (
          renderValue(values.unit_price)
        )}
      </Grid>
    </Grid>
  )
}

export const renderFieldTotalPrice = (props) => {
  const {
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleChange,
    onGlobalUpdate,
    getIsEdit,
  } = props
  let isDecrement =
    values?.adjustment_type !== undefined
      ? values?.adjustment_type.includes("Decrement")
      : false
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: "Total Price",
      })}

      <Grid>
        {getIsEdit ? (
          <TextField
            disabled={true}
            //disabled={isDecrement}
            type="text"
            className={classes.textInput}
            name="total_price"
            placeholder="Enter Total Price"
            value={values.total_price}
            error={Boolean(touched.total_price && errors.total_price)}
            helperText={touched.total_price && errors.total_price}
            onBlur={handleBlur}
            onKeyDown={(e) => handleWhiteSpace(e)}
            onKeyPress={(e) => handleKeyPress(e, "text")}
            onChange={(e) => {
              handleChange(e)
              // onGlobalUpdate()
            }}
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{`$`}</InputAdornment>
              ),
            }}
          />
        ) : (
          renderValue(values.total_price)
        )}
      </Grid>
    </Grid>
  )
}

export const renderFieldBatchNumber = (props) => {
  const {
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleChange,
    onGlobalUpdate,
    getIsEdit,
  } = props
  let isDecrement =
    values?.adjustment_type !== undefined
      ? values?.adjustment_type.includes("Decrement")
      : false
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: "Batch Number",
      })}

      <Grid>
        {getIsEdit ? (
          <TextField
            disabled={isDecrement}
            type="text"
            className={classes.textInput}
            name="batch_number"
            placeholder="Enter Batch Number"
            value={values.batch_number}
            error={Boolean(touched.batch_number && errors.batch_number)}
            helperText={touched.batch_number && errors.batch_number}
            onBlur={handleBlur}
            onKeyDown={(e) => handleWhiteSpace(e)}
            onKeyPress={(e) => handleKeyPress(e, "text")}
            onChange={(e) => {
              handleChange(e)
              // onGlobalUpdate()
            }}
            variant="outlined"
            size="small"
          />
        ) : (
          renderValue(values.batch_number)
        )}
      </Grid>
    </Grid>
  )
}
export const renderFieldExpiryDate = (props) => {
  const {
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleDate,
    handleChange,
    onGlobalUpdate,
    getIsEdit,
  } = props
  let isDecrement =
    values?.adjustment_type !== undefined
      ? values?.adjustment_type.includes("Decrement")
      : false
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: "Expiry Date",
      })}

      <Grid>
        {getIsEdit ? (
          <TextField
            disabled={isDecrement}
            type="date"
            className={classes.textInput}
            name="expiry_date"
            placeholder="Enter Expiry Date"
            value={values.expiry_date}
            error={Boolean(touched.expiry_date && errors.expiry_date)}
            helperText={touched.expiry_date && errors.expiry_date}
            onBlur={handleBlur}
            onKeyDown={(e) => handleWhiteSpace(e)}
            onKeyPress={(e) => handleKeyPress(e, "text")}
            onChange={(e) => {
              handleChange(e)
              onGlobalUpdate()
            }}
            variant="outlined"
            size="small"
          />
        ) : (
          renderValue(values.expiry_date)
        )}
      </Grid>
    </Grid>
  )
}
