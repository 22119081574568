import * as Yup from "yup"
import _, { values } from "lodash"
import {
  renderFieldInventoryOnHand,
  renderFieldTaxRate,
  renderFieldTaxValue,
  renderFieldPackageType,
  renderFieldReorderQuantity,
  renderFieldPackageReceived,
  renderFieldOrderQuantity,
  renderFieldOrderReceived,
  renderFieldUnitPrice,
  renderFieldTotalPrice,
  renderFieldExpiryDate,
  renderFieldBatchNumber,
  renderFieldAdjustmentReason,
  renderFieldAdjustmentType,
  renderFieldProjectedInventory,
} from "./renderProps"
import * as Utils from "@utils"

export const doDataInitialValues = (
  editData,
  isStorage = false,
  viewPage = "edit"
) => {
  let commonData = {
    projected_inventory: _.get(editData, "projected_inventory", "") || "",
    inventoryOnHand: _.get(editData, "inventoryOnHand", "") || "",
    _id: _.get(editData, "_id", "") || "",
  }

  /**
   * @LOCATION_STORE_DATA
   */
  let storeData = {
    adjustment_type: _.get(editData, "adjustment_type", "") || "",
    adjustment_reason: _.get(editData, "adjustment_reason", "") || "",
    quantity: _.get(editData, "quantity", "") || "",
  }

  const config = {
    ...commonData,
    ...storeData,
  }

  return config
}

export const doValidationSchema = (
  props,
  isStorage = false,
  viewPage = "edit"
) => {
  const { isTax = false } = props

  let commonSchema = {
    inventoryOnHand: Yup.string()
      .required("Enter Available on Hand")
      .trim("White spaces cannot be allowed")
      .strict(true)
      .matches(
        Utils.JSUtils.getYupValidationCheck(/^(\d*\.{0,1}\d{0,2}$)/, true),
        `Please enter Valid Format`
      ),
    projected_inventory: Yup.string().required("Auto Projected inventory"),
  }

  let storeSchema = {
    // ...taxSchema,
    adjustment_type: Yup.string().required("Select Adjustment Type"),

    quantity: Yup.string()
      .trim("White spaces cannot be allowed")
      .strict(true)
      .required("Enter Quantity")
      .matches(
        Utils.JSUtils.getYupValidationCheck(/^(\d*\.{0,1}\d{0,2}$)/, true),
        `Please enter Valid Format`
      ),
    adjustment_reason: Yup.string().required("Select Adjustment Reason"),
  }

  let formSchema = storeSchema

  const config = {
    ...commonSchema,
    ...formSchema,
  }

  return config
}

export const doRenderList = (
  isStorage = false,
  viewPage = "",
  packageList = []
) => {
  let store = [
    renderFieldInventoryOnHand,
    renderFieldAdjustmentType,
    renderFieldReorderQuantity,
    renderFieldAdjustmentReason,
    // renderFieldTaxRate,
    // renderFieldTaxValue,
    renderFieldProjectedInventory,
  ]

  return [...store]
}
