import React, { useEffect, useRef, useState } from "react"
import {
  makeStyles,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@material-ui/core"
import _ from "lodash"
import SearchComponentSwitch from "./SearchComponentSwitch"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingInline: theme.spacing(1),
    "& .MuiOutlinedInput-marginDense": {
      height: 36,
    },
  },
  applyBtnContainer: {
    paddingInline: theme.spacing(0),
  },
  formControl: {
    '& .MuiFormLabel-root.Mui-focused': {
      color: "var(--primaryColor)",
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: "var(--primaryColor)",
      }
    }
  }
}))

const SearchArea = (props) => {
  const { t } = useTranslation()
  const { handleFetchMethod = () => { } } = props
  const classes = useStyles()
  const [searchBy, setSearchBy] = useState("")
  const [searchObj, setSearchObj] = useState(_.get(props, "params.filter", {}))
  const [searchByOptions, setSearchByOptions] = useState(
    props?.searchByOptionsAll
  )
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setSearchBy("")
    setSearchObj({})
  }, [props?.location?.key])

  useEffect(() => {
    if (!_.isEmpty(_.get(props, "params.searchBy", ""))) {
      setSearchObj({
        [_.get(props, "params.searchBy", "")]: _.get(
          props,
          "params.searchTerm",
          ""
        ),
      })
      setSearchBy(_.get(props, "params.searchBy", ""))
    }
  }, [props])

  const handleFilterApply = async () => {
    if (_.isFunction(_.get(props, "setParams"))) {
      await props?.setParams({
        ..._.get(props, "params", {}),
        searchBy,
        page: 1,
        limit: _.get(props, "params.limit", "10"),
        searchTerm: _.get(searchObj, searchBy, ""),
      })
      await handleFetchMethod("date_filter", true)
    }
  }

  const handleClear = async () => {
    setSearchBy("")
    setSearchObj({})
    await props?.setParams({
      page: 1,
      limit: 10,
      searchTerm: "",
      searchBy: "",
      sort: "",
    })
    await handleFetchMethod("date_filter", true)
  }

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      className={classes.root}
    >
      <Grid
        item
        xs={10}
        sm={6}
        md={3}
        lg={3}
        xl={3}
        style={{ padding: "0px 8px" }}
      >
        <FormControl variant="outlined" margin="dense" fullWidth className={classes.formControl}>
          <InputLabel id="search-by-select-label">{t('Filter By')}</InputLabel>
          <Select
            labelId="search-by-select-label"
            id="search-by-select"
            value={searchBy}
            onChange={(e) => {
              console.log(e, "search-by-select")
              setSearchBy(e.target.value)
              setSearchObj({ [e.target.value]: "" })
              if (_.isEmpty(e.target.value)) {
                props?.setParams({
                  ..._.get(props, "params", {}),
                  searchBy: "",
                  page: 1,
                  limit: 10,
                  filter: [],
                  searchTerm: "",
                })
              }
            }}
            label={t("Filter By")}
          >
            <MenuItem value="" disabled>
              <em>{t('Select')}</em>
            </MenuItem>
            {searchByOptions.map((option, idx) => (
              <MenuItem
                key={`search_by_option_${idx}`}
                value={_.get(option, "id", "")}
              >
                {t(_.get(option, "label", ""))}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {!_.isEmpty(searchBy) && (
        <>
          <SearchComponentSwitch
            label={_.get(
              _.find(searchByOptions, { id: searchBy }),
              "label",
              ""
            )}
            InputLabelProps={_.get(
              _.find(searchByOptions, { id: searchBy }),
              "labelProps",
              {}
            )}
            isLocationLookup={_.get(
              _.find(searchByOptions, { id: searchBy }),
              "isLocationLookup",
              ""
            )}
            searchObj={searchObj}
            setSearchObj={setSearchObj}
            searchObjKey={searchBy}
            searchBy={_.get(
              _.find(searchByOptions, { id: searchBy }),
              "component",
              ""
            )}
            searchByOptions={searchByOptions}
          />
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            lg={1}
            xl={1}
            className={classes.applyBtnContainer}
            style={{ margin: "0px 8px" }}
            alignItems="center"
          >
            <FormControl margin="dense" variant="outlined" fullWidth>
              <Button
                variant="outlined"
                color="primary"
                size="medium"
                style={{ color: "var(--primaryColor)", border: "1px solid var(--primaryColor)" }}
                onClick={() => handleClear()}
              >
                {t('Reset')}
              </Button>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            lg={1}
            xl={1}
            className={classes.applyBtnContainer}
            alignItems="center"
          >
            <FormControl margin="dense" variant="outlined" fullWidth>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                style={{ backgroundColor: "var(--primaryColor)", border: "1px solid var(--primaryColor)" }}
                onClick={() => handleFilterApply()}
              >
                {t('Apply')}
              </Button>
            </FormControl>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export { SearchArea }
export default SearchArea
