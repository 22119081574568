import React, { useEffect, useState } from "react"
import { Checkbox } from "@material-ui/core"

function CheckBox(props) {
  let { onCheck = () => "", name = "", checkedValue = false } = props || {}
  const [checked, setChecked] = React.useState(checkedValue)
  const handleChange = (event) => {
    setChecked(event.target.checked)
    onCheck(!checked, event.target.name)
  }

  useEffect(() => {
    setChecked(checkedValue)
  }, [checkedValue])

  const checkAttr = {
    checked: checked,
    onChange: handleChange,
    color: "primary",
    name: name,
    inputProps: { "aria-label": "secondary checkbox" },
    style: { color: "var(--primaryColor)" }
  }
  return (
    <div>
      <Checkbox {...checkAttr} />
    </div>
  )
}
export { CheckBox }
export default CheckBox