/* eslint-disable import/no-anonymous-default-export */

import * as ServiceAPI from "@services"
import * as constantsAPI from "@constants"
import axios from "axios"
import _ from "lodash"
import { API_CL_URL, API_MD_URL, API_PG_URL } from "../../utils/url"
import moment from "moment"
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}
const { headerAxios } = ServiceAPI
const authHeaderAxios = axios.create()

const GET_LOCATIONS = `${API_MD_URL}/api/stores/getlocations`
const GET_TRAINING_STATUS = `${API_CL_URL}/api/training/getTrainingStatus`
const GET_MODEL_TRAINING_LIST_HTIL = `${API_CL_URL}/api/training/getTrainingDataListHitl`
const CREATE_MODEL_TRAINING = `${API_CL_URL}/api/training/createTrainingDataSet`
const STOP_MODEL_TRAINING = `${API_CL_URL}/api/training/updateTrainingDataSetStatus`
const DELETE_MODEL_TRAINING = `${API_CL_URL}/api/training/deleteTrainingDataSet`
const VIEW_MODEL_TRAINING_DETAILS = `${API_CL_URL}/api/training/getTrainingData`
const GET_LIST_DATASETS_HTILs = `${API_PG_URL}/api/training/getTrainingDataListHitl`
const GET_LIST_DATASETS_HTIL = `${API_PG_URL}/api/masterdata/getListDataset`
const DELETE_DATASET = `${API_PG_URL}/api/masterdata/deleteDataset`
const GET_LIST_IMAGES = `${API_PG_URL}/api/masterdata/getListImages`
const DELETE_LIST_IMAGES = `${API_PG_URL}/api/masterdata/deleteImages`
const GET_TRAINING_PRODUCTS = `${API_PG_URL}/api/masterdata/getTrainingProducts`
const GET_LIST_DATASETS_SEG_HTILs = `${API_CL_URL}/api/training/getSegmentationTrainingProductDetails`
const GET_POLYGON_DETAILS = `${API_CL_URL}/api/training/getIndividualProductFiles`
const SAVE_POLYGON_DETAILS = `${API_CL_URL}/api/training/updateIndividualProductFiles`
const GET_CLASSIFICATION_PRODUCT_COUNT = `${API_CL_URL}/api/training/getClassificationProductCount`
const START_TRAINING_CLASSIFICATION = `${API_CL_URL}/api/training/createTrainingDataSet`
const UPDATE_TRAINING_PRODUCT = `${API_CL_URL}/api/training/updateTraningProduct`
const TRAINING_TRIGGER_ML_PIPELINE = `${API_CL_URL}/api/training/triggerMlPipeline`

export default {
  getLocations: async (page = 1, locationId = "") => {
    let filterValues = []
    locationId !== "" &&
      filterValues.push({
        field: "locationId",
        operator: "contains",
        value: locationId,
      })
    let body = {
      page: page,
      limit: 10,
      filter: filterValues,
      sort: {},
    }
    let response = await headerAxios.post(GET_LOCATIONS, body)
    return response
  },
  getTrainingStatus: async () => {
    let response = await headerAxios.get(GET_TRAINING_STATUS)
    return response
  },
  getTrainingListHtil: async (body, tenantId) => {
    let response = await headerAxios.post(GET_MODEL_TRAINING_LIST_HTIL, body, {
      headers: {
        "X-Tenant-ID": tenantId !== "all" ? tenantId : undefined,
      },
    })
    return response
  },
  createModelTraining: async (body, locId) => {
    let response = await headerAxios.post(CREATE_MODEL_TRAINING, body, {
      headers: {
        locationId: locId,
      },
    })
    return response
  },
  stopModelTraining: async (body, id) => {
    let response = await headerAxios.post(STOP_MODEL_TRAINING, body, {
      headers: {
        trainingId: id,
      },
    })
    return response
  },
  deleteModelTraining: async (id, tenantId) => {
    let response = await headerAxios.delete(DELETE_MODEL_TRAINING, {
      headers: {
        trainingId: id,
        "X-Tenant-ID": tenantId,
      },
    })
    return response
  },
  fetchModelTrainingDetails: async (id) => {
    let response = await headerAxios.get(VIEW_MODEL_TRAINING_DETAILS, {
      headers: {
        trainingId: id,
      },
    })
    return response
  },
  fetchListDataSetHtil: async (body, tenantId) => {
    let response = await headerAxios.post(GET_LIST_DATASETS_HTIL, body, {
      headers: {
        "X-Tenant-ID": tenantId,
      },
    })
    return response
  },
  fetchListDataSetClassification: async (body, tenantId) => {
    let response = await headerAxios.post(GET_LIST_DATASETS_HTIL, body, {
      headers: {
        "X-Tenant-ID": tenantId !== "all" ? tenantId : undefined,
      },
    })
    return response
  },
  fetchListDataSetSegmentation: async (body, trainingId, tenantId) => {
    let response = await headerAxios.post(
      `${GET_LIST_DATASETS_SEG_HTILs}/${trainingId}`,
      body,
      {
        headers: {
          "X-Tenant-ID": tenantId,
        },
      }
    )
    return response
  },
  deleteDatasetbyId: async (id) => {
    let response = await headerAxios.delete(`${DELETE_DATASET}/${id}`)
    return response
  },
  getListImagesByProductId: async (body, id) => {
    let response = await headerAxios.post(`${GET_LIST_IMAGES}/${id}`, body)
    return response
  },
  deleteImagesByProductId: async (body, id) => {
    let response = await headerAxios.delete(`${DELETE_LIST_IMAGES}/${id}`, {
      data: body,
    })
    return response
  },
  getTrainingProducts: async (id) => {
    let response = await headerAxios.get(`${GET_TRAINING_PRODUCTS}/${id}`)
    return response
  },
  fetchPolygonDetails: (fileId) => {
    return headerAxios.get(`${GET_POLYGON_DETAILS}/${fileId}`)
  },
  savePolygon: (body, fileId) => {
    let postObj = {}
    postObj = body.reduce((acc, item, index) => {
      acc[item.layerName] = item.points.map((point) => ({
        x: point[0],
        y: point[1],
      }))
      return acc
    }, {})
    const postObject = {
      isAnnotated: true,
      annotation_type: "polygon",
      annotation: { ...postObj },
      isAnnoatedEdited: true,
    }
    return headerAxios.post(`${SAVE_POLYGON_DETAILS}/${fileId}`, postObject)
  },
  getClassificationProductCount: async (tenantId, trainingId) => {
    let response = await headerAxios.get(
      `${GET_CLASSIFICATION_PRODUCT_COUNT}`,
      {
        headers: {
          "X-Tenant-ID": tenantId,
          trainingId: trainingId,
        },
      }
    )
    return response
  },
  startClassificationProductTraining: async (tenantId, locationId, data) => {
    let response = await headerAxios.post(
      `${START_TRAINING_CLASSIFICATION}`,
      data,
      {
        headers: {
          "X-Tenant-ID": tenantId,
          locationId: locationId,
        },
      }
    )
    return response
  },
  acceptNDiscardProductTraining: async (tenantId, productId, body) => {
    let response = await headerAxios.post(
      `${UPDATE_TRAINING_PRODUCT}/${productId}`,
      body,
      {
        headers: {
          "X-Tenant-ID": tenantId,
        },
      }
    )
    return response
  },

  TrainTriggerMLPipeline: async (tenantId, body) => {
    let response = await headerAxios.post(
      `${TRAINING_TRIGGER_ML_PIPELINE}/${body.training_id}`,
      {},
      {
        headers: {
          "X-Tenant-ID": tenantId,
          pipelineType: body.pipelineType,
          locationId: body.locationId,
        },
      }
    )
    return response
  },
}
