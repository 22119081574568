import * as Yup from "yup"
import * as Utils from "@utils"

export const formInput = {
  formValues: {
    fullname: "",
    addressline1: "",
    addressline2: "",
    addressline3: "",
    addressline4: "",
    country: "",
    city: "",
    state: "",
    zipcode5: "",
  },
  validationSchema: {
    fullname: Yup.string()
      .required(`Full Name is required`)
      .matches(
        Utils.JSUtils.getYupValidationCheck(/^[aA-zZ\s]+$/),
        `Only alphabets are allowed for this  key `
      ),
    addressline1: Yup.string().required(`Address Line 1 is required`),
    addressline2: Yup.string().required(`Address Line 2 is required`),
    addressline3: Yup.string().required(`Address Line 3 is required`),
    addressline4: Yup.string().required(`Address Line 4 is required`),
    country: Yup.string().required(`Country is required`),
    state: Yup.string().required(`State is required`),
    city: Yup.string().required(`City is required`),
    zipcode5: Yup.string().required(`Zip Code 5 is required`),
  },
}
