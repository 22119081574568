import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  collapse: {
    backgroundColor: theme.sidebar.collapseBackground,
  },

  chevronRighticon: {
    height: "24px",
    display: "grid",
    placeItems: "center",
    width: "24px",
    borderRadius: "100%",
    cursor: "pointer",
    position: "relative",
    top: "52px",
    left: "54px",
    // right: "-10px",
    zIndex: "9999",
    transition: "1s ease-in-out",
    // backgroundColor: "#F9F9FC",
    backgroundColor: "#db1383",
    fill: "white",
    boxShadow: "0px 0px 4px 0px #0000001C",
  },
  chevronLefticon: {
    height: "24px",
    display: "grid",
    placeItems: "center",
    width: "24px",
    cursor: "pointer",
    borderRadius: "100%",
    position: "relative",
    top: "52px",
    transition: "1s ease-in-out",
    // right: "-10px",
    left: "236px",
    zIndex: "1299",
    backgroundColor: "#df86bb",
    fill: "white",
    boxShadow: "0px 0px 4px 0px #0000001C",
  },
  tooltip: {
    // backgroundColor: '#1B2430',
    backgroundColor: "#140316",
    color: "#4A4A4A",
    width: "230px",
    borderRadius: "8px",
    marginLeft: "22px",
  },
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
  },
}))
