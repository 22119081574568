import React, { useState, useEffect } from "react"
import {
  Card,
  List,
  ListItem,
  IconButton,
  TextField,
  Button,
  ListItemText,
  Grid,
} from "@material-ui/core"
import { Formik } from "formik"
import * as Yup from "yup"
import { makeStyles } from "@material-ui/core/styles"
import { AddCircleOutlineIcon, CloseIcon } from "@iconsGallery"
import { useDispatch, useSelector } from "react-redux"
import { addLocationAttributes } from "@reduxHelper"
import _ from "lodash"
import * as Utils from "@utils"
import * as Adm from "@adm"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles({
  dividerBottom: { borderBottom: "0.1em solid rgba(0, 0, 0, 0.1)" },
  listPropertyHeader: {
    display: "flex",
    width: "100%",
    backgroundColor: "#f9f7fc !important",
  },
  listProperty: { display: "flex", width: "100%" },
  listItem: {
    border: "0.1em solid rgba(0, 0, 0, 0.1)",
    height: "50px",
    alignItems: "center",
    padding: "10px",
    "& .MuiTypography-body1": {
      textOverflow: "ellipsis",
      flexWrap: "nowrap",
      overflow: "hidden",
    },
  },
  listItemKey: {
    border: "0.1em solid rgba(0, 0, 0, 0.1)",
    height: "50px",
    alignItems: "center",
    padding: "10px",
    borderRight: "0",
    "& .MuiTypography-body1": {
      textOverflow: "ellipsis",
      flexWrap: "nowrap",
      overflow: "hidden",
    },
  },
  verticalDivider: { flex: 2 },
  textFiled1: {
    width: "100%",
    marginRight: "20px",
    marginLeft: "0px",
    flex: 1,
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
  },
  textFiled2: {
    width: "100%",
    marginRight: "20px",
    marginLeft: "0px",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
  },
})
function LocationAttributeContent(props) {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const reduxData = useSelector((state) => state.addLocationReducer)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")

  const [data, setData] = useState(
    _.get(reduxData, "locationAttributes") !== null
      ? reduxData.locationAttributes
      : _.get(props, "data", [])
  )
  useEffect(() => {
    setData(_.get(props, "data", []))
  }, [props.data])

  useEffect(() => {
    if (_.get(data, "locationAttributes") !== null) {
      setData(data)
    }
  }, [data])

  const handleKeyPress = (e, type) => {
    if (type === "number") {
      if (e.which === 32) {
        e.preventDefault()
      }
    } else {
      if (e.which === 32 && e.target.value === "") {
        e.preventDefault()
      }
    }
  }
  const deleteData = (id) => {
    let newItem = data.filter((_item) => {
      return _item.id !== id
    })
    setData(newItem)
    dispatch(addLocationAttributes(newItem))
  }

  return (
    <Card style={{ width: "100%" }}>
      {props.edit ? (
        <div
          className={`${classes.listProperty} makeStyles-listProperty-support`}
        >
          <div>
            <List>
              {data.map((header) => (
                <ListItem
                  className={classes.listItem}
                  style={{
                    paddingTop: "0px !important",
                    paddingBottom: "0px !important",
                  }}
                >
                  <ListItemText primary={header.id} />
                </ListItem>
              ))}
            </List>
          </div>
          <div>
            <List>
              {data.map((header) => (
                <ListItem className={classes.listItem}>
                  <ListItemText primary={header.value} />
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      ) : (
        <div>
          <Formik
            initialValues={{
              id: "",
              value: "",
            }}
            validationSchema={Yup.object().shape({
              id: Yup.string()
                .required(`Enter Name`)
                .min(1, "Name can be of min-1 max-50 characters")
                .max(50, "Name can be of min-1 max-50 characters")
                .strict(true),
              value: Yup.string()
                .required(`Enter Value`)
                .min(1, "Value can be of min-1 max-50 characters")
                .max(50, "Value can be of min-1 max-50 characters")
                .strict(true),
            })}
            onSubmit={(values, { resetForm }) => {
              setAlert(false)
              const adapter = {
                id: values.id,
                value: values.value,
              }
              const DataKit = [...data, adapter]
              const isDuplicate = Utils.JSUtils.isDuplicateObject(DataKit, "id")
              if (isDuplicate) {
                setAlertType("error")
                setAlertMessage(t("Duplicate Attribute-Value Pairs"))
                setAlert(true)
              } else {
                setData([...data, values])
                dispatch(addLocationAttributes([...data, values]))
                resetForm()
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <form
                noValidate
                onSubmit={handleSubmit}
                style={{ margin: "20px", marginLeft: "0", width: "100%" }}
              >
                {errors.submit && " "}
                <Grid
                  container
                  direction="row"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={10}
                  xl={10}
                  spacing={2}
                  className="cls-locationattribute"
                >
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <TextField
                      type="text"
                      name="id"
                      placeholder={t("Name")}
                      autoComplete="off"
                      onKeyPress={(e) => handleKeyPress(e, "text")}
                      value={values.id}
                      error={Boolean(touched.id && errors.id)}
                      helperText={touched.id && t(errors.id)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      size="small"
                      className={`${classes.textFiled1} makeStyles-textFiled-support`}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <TextField
                      type="text"
                      name="value"
                      placeholder={t("Value")}
                      autoComplete="off"
                      onKeyPress={(e) => handleKeyPress(e, "text")}
                      variant="outlined"
                      value={values.value}
                      error={Boolean(touched.value && errors.value)}
                      helperText={touched.value && t(errors.value)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size="small"
                      className={`${classes.textFiled2} makeStyles-textFiled-support`}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ background: "var(--primaryColor)" }}
                      type="submit"
                      startIcon={<AddCircleOutlineIcon />}
                    >
                      {t("Add")}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
          <Grid
            container
            direction="row"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            spacing={2}
            className="cls-locationattribute-table"
          >
            <Grid
              item
              container
              direction="row"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              {data.length > 0 && (
                <div className={classes.listPropertyHeader}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <div className={classes.verticalDivider}>
                      <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <ListItem className={classes.listItemKey}>
                          <ListItemText primary={t("Name")} />
                        </ListItem>
                      </List>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <div style={{ flex: 2 }}>
                      <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <ListItem
                          className={classes.listItem}
                          style={{ borderTop: 0 }}
                          alignItems="flex-start"
                        >
                          <ListItemText primary={t("Value")} />
                        </ListItem>
                      </List>
                    </div>
                  </Grid>
                </div>
              )}
            </Grid>
            <Grid
              item
              container
              direction="row"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <div
                className={`${classes.listProperty} makeStyles-listProperty-support`}
              >
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <div className={classes.verticalDivider}>
                    <List
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                    >
                      {data.map((item) => (
                        <ListItem
                          style={{ borderTop: 0 }}
                          className={classes.listItemKey}
                        >
                          <ListItemText primary={item.id} />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <div style={{ flex: 2 }}>
                    <List
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                    >
                      {data.map((item) => (
                        <>
                          <ListItem
                            style={{ borderTop: 0 }}
                            className={classes.listItem}
                            alignItems="flex-start"
                          >
                            <ListItemText primary={item.value} />
                            <IconButton
                              onClick={() => {
                                deleteData(item.id)
                              }}
                              style={{ padding: "5px" }}
                            >
                              <CloseIcon style={{ fontSize: "14px" }} />
                            </IconButton>
                          </ListItem>
                        </>
                      ))}
                    </List>
                  </div>
                </Grid>
              </div>
            </Grid>
          </Grid>
          {alert ? (
            <Adm.SnackBar open={true} message={alertMessage} type={alertType} />
          ) : null}
        </div>
      )}
    </Card>
  )
}
export { LocationAttributeContent }
export default LocationAttributeContent
