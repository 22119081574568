import {
  Box,
  colors,
  Container,
  Dialog,
  Divider,
  makeStyles,
  Tab,
  Tabs,
  Card,
  CardHeader,
  CardContent,
  Button,
  Chip,
  Grid,
  Typography,
  Menu,
  FormControlLabel,
  FormControl,
  FormGroup,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  TableHead,
  TableContainer,
  Table,
  TextField,
  Popover,
  ListItem,
} from "@material-ui/core"
import { green, red } from "@material-ui/core/colors"

import { default as MuiDialogContent } from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import { withStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import React, { useState } from "react"
import * as Features from "@features"
import { MoreVertical } from "react-feather"
import styled from "styled-components/macro"
import _ from "lodash"
import * as IconsGallery from "@iconsGallery"
import {
  Loop as LoopIcon,
  FilterList as FilterListIcon,
} from "@material-ui/icons"
import * as StyledDom from "./styles"
import * as Adm from "@adm"
import { DashboardMaterialDataGrid } from "../../../components/MaterialDataGrid/DashboardMaterialDataGrid"

function StoreListTableView(props) {
  const {
    title = "",
    rows = [],
    columnData = [],
    style = {},
    isLoading = false,
    handleRowClick = () => "",
    mainStateDashboard,
    tabIndex,
    tenantId,
  } = props || {}
  const classes = StyledDom.useStyles()

  const {
    anchorEl,
    setAnchorEl,
    InputData,
    setInputData,
    rowData,
    setRowData,
    items,
    setItems,
    gridState,
    setGridState,
    tempGridState,
    setTempGridState,
    pageData,
    setPageData,
  } = mainStateDashboard || {}

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  async function pageChange(skip_items, take_items) {
    const newGridState = { ...gridState }
    newGridState.dataState.skip = skip_items
    newGridState.dataState.take = take_items
    await setGridState(newGridState)
  }

  React.useEffect(() => {
    if (!_.isEqual(rowData, rows)) {
      setRowData(rows)
    }
  }, [rows])

  const handleChangePage = () => {
    console.log("handleChangePage")
  }
  const handleChangeRowsPerPage = () => {
    console.log("handleChangePage")
  }

  const tablePaginationProps = {
    pageNumber: mainStateDashboard.pageNumber,
    setPageNumber: mainStateDashboard.setPageNumber,
    rowsPerPage: mainStateDashboard.rowsPerPage,
    setRowsPerPage: mainStateDashboard.setRowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    totalRecords: rows.length,
    totalPages: 1,
  }

  const itemChange = (event) => {
    const data = rowData.data.map((item) =>
      item._id === event.dataItem._id
        ? { ...item, [event.field]: event.value }
        : item
    )
    setRowData({
      ...rowData,
      data: data,
    })
  }

  const setDefaultValue = (event, props) => {
    const newRowDataState = { ...rowData }
    newRowDataState["defaultID"] = props.dataItem._id
    newRowDataState.data.map((item) => {
      if (item._id === props.dataItem._id) {
        item[props.field] = !props.dataItem[props.field]
      }
      return item
    })
    setRowData(newRowDataState)
  }
  const selectionState = {
    selectedItems: [],
    selectAll: false,
  }
  const [selection, setSelection] = useState(selectionState)

  const headerSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked
    const newRowDataState = { ...rowData }
    const newSelectionState = { ...selection }
    let newSelectedItems = selection.selectedItems
    newRowDataState.data.map((item) => {
      item.selected = checked
      if (checked === true) {
        if (!newSelectionState.selectedItems.includes(item._id)) {
          newSelectedItems.push(item._id)
        }
      } else {
        newSelectedItems = newSelectedItems.filter(function (item1) {
          return item1 !== item._id
        })
      }
      return item
    })
    newSelectionState.selectedItems = newSelectedItems
    setRowData(newRowDataState)
    setSelection(newSelectionState)
  }

  const selectionChange = (event) => {
    console.log("dropDownFilterChange>>>", event)
    const newRowDataState = { ...rowData }
    const newSelectionState = { ...selection }

    if (newSelectionState.selectedItems.includes(event.dataItem._id)) {
      const newSelectedItems = newSelectionState.selectedItems.filter(function (
        item
      ) {
        return item !== event.dataItem._id
      })
      newSelectionState.selectedItems = newSelectedItems
      setSelection(newSelectionState)
    } else {
      newSelectionState.selectedItems.push(event.dataItem._id)
      setSelection(newSelectionState)
    }
    newRowDataState.data.map((item) => {
      if (item._id === event.dataItem._id) {
        item.selected = !event.dataItem.selected
      }
      return item
    })
    setRowData(newRowDataState)
  }

  const onDiscard = (props) => {}

  const onEdit = (props) => {}

  const onDelete = async (props) => {}

  const onSave = async (props) => {}

  const switchHandler = (event, props) => {}

  const reDirectTo = (row) => {
    console.log("reDirectTo>>>>", row)
  }

  return (
    <Card className={classes.cardStyles} style={style}>
      <Grid container>
        <Grid
          item
          md={5}
          style={{ padding: "10px", paddingLeft: 16, paddingTop: 16 }}
        >
          <Typography
            variant="body"
            style={{
              color: "#000000",
              fontWeight: "500",
              fontSize: 16,
            }}
          >
            <span className="cls-storelisttable-heading">{title}</span>
          </Typography>
        </Grid>
      </Grid>

      <CardContent style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
        <DashboardMaterialDataGrid
          itemChange={(event) => itemChange(event)}
          isInlineEditAllowed={true}
          setDefaultValue={(event, props) => setDefaultValue(event, props)}
          headerSelectionChange={(event) => headerSelectionChange(event)}
          selectionChange={(event) => selectionChange(event)}
          onDiscard={(props) => onDiscard(props)}
          onEdit={(props) => onEdit(props)}
          onDelete={(props) => onDelete(props)}
          onSave={(props) => onSave(props)}
          switchHandler={(event, props) => switchHandler(event, props)}
          setGridState={setGridState}
          gridState={gridState}
          setRowData={setRowData}
          pageData={pageData}
          actionMenu={true}
          reDirectButton={true}
          reDirectTo={reDirectTo}
          number_of_items={items}
          pageChangeEvent={pageChange}
          rowData={rowData}
          columnData={columnData}
          gridNoRecordsText="No records found"
          setAnchorEl={setAnchorEl}
          anchorEl={anchorEl}
          InputData={InputData}
          setInputData={setInputData}
          id={id}
          open={open}
          handleRowClick={handleRowClick}
          mainStateDashboard={mainStateDashboard}
          tabIndex={tabIndex}
          tenantId={tenantId}
          isLoading={isLoading}
          ShowCheckBox={false}
        />
      </CardContent>
    </Card>
  )
}

export default StoreListTableView
export { StoreListTableView }
