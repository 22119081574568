import {
  Layout,
  List,
  Monitor,
  Users,
  FileText,
  Package,
  Briefcase,
  Cpu,
  Eye,
  FrictionlessIcon,
  AutonomousStoreIcon,
  PlanogramIcon,
  SupportIcon,
  TransactionIcon,
  EqualizerOutlined,
  Settings,
  LogViewerIcon,
  AlertIconKit,
  AccessibilityIcon,
} from "@iconsGallery"
import trainingProducts from "../pages/trainingProducts"
import UserService from "../services/UserService"
import PageComponent from "./PageComponentLibrary"

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  readAccessPath: "Dashboard Data.Read",
  icon: <Layout />,
  component: PageComponent.SassDashboard,
  children: null,
}

// content

const contentRoutes = {
  id: "Content",
  path: "/content",
  readAccessPath: "Content Data.Read",
  writeAccessPath: "Content Data.Write",
  icon: <FileText />,
  childArray: [
    { readAccessPath: "Content Data.Read" },
    { readAccessPath: "Content Data.Product Category - Read" },
  ],
  children: [
    {
      path: "/content/countries",
      name: "Countries",
      component: PageComponent.Countries,
      readAccessPath: "Content Data.Read",
      writeAccessPath: "Content Data.Write",
    },
    {
      path: "/content/currencies",
      name: "Currencies",
      component: PageComponent.Currencies,
      readAccessPath: "Content Data.Read",
      writeAccessPath: "Content Data.Write",
    },
    {
      path: "/content/gender",
      name: "Gender",
      component: PageComponent.Gender,
      readAccessPath: "Content Data.Read",
      writeAccessPath: "Content Data.Write",
    },
    {
      path: "/content/languages",
      name: "Languages",
      component: PageComponent.Languages,
      readAccessPath: "Content Data.Read",
      writeAccessPath: "Content Data.Write",
    },
    {
      path: "/content/units-of-measure",
      name: "Units of Measure",
      component: PageComponent.UnitsOfMeasure,
      readAccessPath: "Content Data.Read",
      writeAccessPath: "Content Data.Write",
    },
    {
      path: "/content/barcode-types",
      name: "Barcode Types",
      component: PageComponent.BarcodeTypes,
      readAccessPath: "Content Data.Read",
      writeAccessPath: "Content Data.Write",
    },
    {
      path: "/content/tax",
      name: "Tax Code",
      component: PageComponent.Tax,
      readAccessPath: "Content Data.Read",
      writeAccessPath: "Content Data.Write",
    },
    {
      path: "/content/productCategory",
      name: "Product Category",
      component: PageComponent.ProductCategory,
     /*  readAccessPath: "Content Data.Read",
      writeAccessPath: "Content Data.Write", */
      readAccessPath: "Content Data.Product Category - Read",
      writeAccessPath: "Content Data.Product Category - Write",
    },
  ],
  component: null,
}

const translateMasterLangSync = {
  path: "/content/languages/translateMaster",
  readAccessPath: "Content Data.Read",
  writeAccessPath: "Content Data.Read",
  component: PageComponent.LanguageSync,
}

const translateBundleViewLangSync = {
  path: "/content/languages/translateBundle",
  readAccessPath: "Content Data.Read",
  writeAccessPath: "Content Data.Read",
  component: PageComponent.LanguageSync,
}

// masterData

const masterDataRoutes = {
  id: "Master Data",
  path: "/master-data",
  readAccessPath: "",
  icon: <List />,
  childArray: [
    { readAccessPath: "Master Data.Locations - Read" },
    { readAccessPath: "Master Data.Products - Read" },
    { readAccessPath: "Master Data.Inventory - Read" },
  ],
  children: [
    {
      path: "/master-data/locations",
      name: "Locations",
      icon: "",
      id: "Locations",
      readAccessPath: "Master Data.Locations - Read",
      writeAccessPath: "Master Data.Locations - Write",
      component: PageComponent.Locations,
      skipChildArray: true,
      childArray: [
        { readAccessPath: "Master Data.Locations - Read" },
        { readAccessPath: "Master Data.Locations Group - Read" },
        { readAccessPath: "Master Data.Locations Hierarchy - Read" },
        { readAccessPath: "Master Data.Locations Hierarchy Mapping - Read" },
      ],
      children: [
        {
          path: "/master-data/locations/groups",
          name: "Groups",
          readAccessPath: "Master Data.Locations Group - Read",
          writeAccessPath: "Master Data.Locations Group - Write",
          component: PageComponent.masterDataLocationGroups,
        },
        {
          path: "/master-data/locations/hierarchy",
          name: "Hierarchy",
          readAccessPath: "Master Data.Locations Hierarchy - Read",
          writeAccessPath: "Master Data.Locations Hierarchy - Write",
          component: PageComponent.masterDataLocationHierarchy,
        },
        {
          path: "/master-data/locations/hierarchyMapping",
          name: "Hierarchy Mapping",
          readAccessPath: "Master Data.Locations Hierarchy Mapping - Read",
          writeAccessPath: "Master Data.Locations Hierarchy Mapping - Write",
          component: PageComponent.masterDataLocationMapping,
        },
      ],
    },
    {
      path: "/master-data/products",
      name: "Products",
      icon: "",
      id: "Products",
      readAccessPath: "Master Data.Products - Read",
      writeAccessPath: "Master Data.Products - Write",
      component: PageComponent.Products,
      skipChildArray: true,
      childArray: [
        { readAccessPath: "Master Data.Products - Read" },
        { readAccessPath: "Master Data.Product Groups - Read" },
        { readAccessPath: "Master Data.Products Category - Read" },
        { readAccessPath: "Master Data.Product Mapping - Read" },
        { readAccessPath: "Master Data.Product Attributes - Read" },
        { readAccessPath: "Master Data.Product Training Products - Read" },
      ],
      children: [
        {
          path: "/master-data/products/groups",
          name: "Product Groups",
          readAccessPath: "Master Data.Product Groups - Read",
          writeAccessPath: "Master Data.Product Groups - Write",
          component: PageComponent.masterDataproductsGroups,
        },
        //commented Side Nav Product Category
        /* {
          path: "/master-data/products/category",
          name: "Product Category",
          readAccessPath: "Master Data.Products Category - Read",
          writeAccessPath: "Master Data.Products Category - Write",
          component: PageComponent.masterDataproductsHierarchy,
        }, */ 

        // {
        //   path: "/master-data/products/productMapping",
        //   name: "Product Mapping",
        //   readAccessPath: "Master Data.Product Mapping - Read",
        //   writeAccessPath: "Master Data.Product Mapping - Write",
        //   component: PageComponent.masterDataproductsMapping,
        // },
        {
          path: "/master-data/products/attribute",
          name: "Attributes",
          readAccessPath: "Master Data.Product Attributes - Read",
          writeAccessPath: "Master Data.Product Attributes - Write",
          component: PageComponent.masterDataproductsAttribute,
        },
        {
          name: "Training Products",
          path: "/master-data/products/training-products",
          readAccessPath: "Master Data.Product Training Products - Read",
          component: PageComponent.TenantTrainingProducts,
        },
      ],
    },
    {
      path: "/master-data/inventory",
      name: "Inventory",
      icon: "",
      id: "Inventory",
      readAccessPath: "Master Data.Inventory - Read",
      writeAccessPath: "Master Data.Inventory - Write",
      component: PageComponent.Inventory,
      skipChildArray: true,
      childArray: [
        { readAccessPath: "Master Data.Inventory - Read" },
        { readAccessPath: "Master Data.Inventory Adjustment - Read" },
        { readAccessPath: "Master Data.Inventory Count - Read" },
        { readAccessPath: "Master Data.Inventory snapshot report - Read" },
        { readAccessPath: "Master Data.Inventory audit report - Read" },
      ],
      children: [
        {
          path: "/master-data/inventory/adjustment",
          name: "Adjustments",
          readAccessPath: "Master Data.Inventory Adjustment - Read",
          writeAccessPath: "Master Data.Inventory Adjustment - Write",
          component: PageComponent.InventoryAdjustment,
        },
        {
          path: "/master-data/inventory/counts",
          name: "Counts",
          readAccessPath: "Master Data.Inventory Count - Read",
          writeAccessPath: "Master Data.Inventory Count - Write",
          component: PageComponent.InventoryCounts,
        },
        {
          path: "/master-data/inventory/snapshotReport",
          name: "Snapshot Report",
          readAccessPath: "Master Data.Inventory snapshot report - Read",
          component: PageComponent.InventorySnapshotReports,
        },
        {
          path: "/master-data/inventory/auditReport",
          name: "Audit Report",
          readAccessPath: "Master Data.Inventory audit report - Read",
          component: PageComponent.InventoryAuditReports,
        },
      ],
    },
  ],
  component: null,
}

// frictionless store
const InventoryAdjustment = {
  path:
    "/master-data/inventory/adjustment/productAdjustment/:locationId/:locationName",
  readAccessPath: "Master Data.Inventory Adjustment - Read",
  writeAccessPath: "Master Data.Inventory Adjustment - Read",
  component: PageComponent.InventoryAdjustmentEdit,
}

const productValidationImagesView = {
  path: "/master-data/products/training-products/productValidation/view",
  readAccessPath: "Master Data.Product Training Products - Read",
  writeAccessPath: "Master Data.Product Training Products - Write",
  component: PageComponent.productValidationImagesView,
}

const htilProductValidationImagesView = {
  path: "/hitl/main/productValidation/view",
  readAccessPath: "Continuous Learning.HITL - Read",
  writeAccessPath: "Continuous Learning.HITL - Write",
  component: PageComponent.productValidationImagesView,
}

const ModelTrainingCreate = {
  path: "/hitl/main/modelTraining/create",
  // path: "/master-data/products/training-products/modelTraining/create",
  readAccessPath: "Continuous Learning.HITL - Read",
  writeAccessPath: "Continuous Learning.HITL - Write",
  component: PageComponent.ModelTrainingCreate,
}

const ModelTrainingView = {
  // path: "/master-data/products/training-products/modelTraining/view/:trainingName",
  path: "/hitl/main/modelTraining/view",

  readAccessPath: "Continuous Learning.HITL - Read",
  writeAccessPath: "Continuous Learning.HITL - Read",
  component: PageComponent.ModelTrainingCreate,
}

// const HitlClassifcationView = {
//   // path: "/master-data/products/training-products/modelTraining/view/:trainingName",
//   path: "/hitl/modelTraining/view",

//   readAccessPath: "Master Data.Product Training Products - Read",
//   writeAccessPath: "Master Data.Product Training Products - Write",
//   component: PageComponent.ModelTrainingCreate,
// }

const AdjustInventoryView = {
  id: "Adjustment Details",
  path: "/master-data/inventory/adjustment/view/:locationId/:locationName",
  readAccessPath: "Master Data.Inventory Adjustment - Read",
  writeAccessPath: "Master Data.Inventory Adjustment - Read",
  component: PageComponent.AdjustInventoryView,
}

const FrictionlessStoreRevampRoutes = {
  id: "Frictionless Store",
  path: "/frictionless-store",
  readAccessPath: "",
  icon: <FrictionlessIcon />,
  childArray: [
    { readAccessPath: "Frictionless Store.Device Config POS - Read" },
    { readAccessPath: "Frictionless Store.Planogram - Read" },
  ],
  children: [
    {
      id: "Device Config",
      name: "Device Config",
      icon: "",
      readAccessPath: "",
      component: null,
      redirect: false,
      path: "/frictionless-store/dc",
      childArray: [
        { readAccessPath: "Frictionless Store.Device Config POS - Read" },
      ],
      children: [
        {
          path: "/frictionless-store/dc/pos",
          name: "POS",
          readAccessPath: "Frictionless Store.Device Config POS - Read",
          writeAccessPath: "Frictionless Store.Device Config POS - Write",
          component: PageComponent.ConfigurePos,
        },
        {
          path: "/frictionless-store/dc/model-updates",
          name: "Model Updates",
          component: PageComponent.ModalConfig,
          readAccessPath:
            "Frictionless Store.Device Config ModelUpdates - Read",
          writeAccessPath:
            "Frictionless Store.Device Config ModelUpdates - Write",
        },
      ],
    },
    {
      path: "/frictionless-store/planogram",
      id: "Planogram",
      name: "Planogram",
      component: PageComponent.SpacePlanningList,
      readAccessPath: "Frictionless Store.Planogram - Read",
      icon: <PlanogramIcon />,
    },
  ],
}

// Modals
const ModalConfigRoutes = {
  path: "/frictionless-store/dc/model-updates/:storeName",
  readAccessPath: "Frictionless Store.Device Config ModelUpdates - Read",
  writeAccessPath: "Frictionless Store.Device Config ModelUpdates - Write",
  component: PageComponent.ModalConfigDetails,
}

// transaction

const transactionRoutes = {
  id: "Transaction Browser",
  path: "/transactions",
  name: "Transaction Browser",
  readAccessPath: "Transaction Browser.Read",
  writeAccessPath: "Transaction Browser.Write",
  icon: <TransactionIcon />,
  component: PageComponent.Transactions,
}

const EditOrderRoutes = {
  id: "Edit Order",
  path: "/transactions/:txnId",
  icon: <Monitor />,
  readAccessPath: "Transaction Browser.Read",
  writeAccessPath: "Transaction Browser.Write",
  component: PageComponent.EditOrderDetails,
  children: null,
}

// livemonitoring

const liveMonitoringRoutes = {
  id: "Monitoring",
  path: "/livemonitoring",
  readAccessPath: "",
  icon: <EqualizerOutlined />,
  component: null,
  childArray: [
    { readAccessPath: "Monitoring.Devices - Read" },
    { readAccessPath: "Monitoring.SaaS - Read" },
  ],
  children: [
    {
      id: "Devices",
      name: "Devices",
      readAccessPath: "Monitoring.Devices - Read",
      icon: "",
      component: PageComponent.Devices,
      redirect: false,
      path: "/livemonitoring/devices",
    },
    {
      id: "Store",
      name: "Devices",
      readAccessPath: "Monitoring.SaaS - Read",
      icon: "",
      component: PageComponent.Stores,
      redirect: false,
      path: "/livemonitoring/tenants",
    },
  ],
}

const livemonitoringDevicesSaas = {
  id: "Store",
  name: "Devices",
  readAccessPath: "Monitoring.SaaS - Read",
  icon: "",
  component: PageComponent.Stores,
  redirect: false,
  path: "/livemonitoring/tenants",
}

const liveMonitoringProductDetailedScreenRoutes = {
  path: "/livemonitoring/products/product-detailed-page/itemId=:id",
  readAccessPath: "Monitoring.Devices - Read",
  component: PageComponent.EditLiveMonitoringProduct,
}

const liveMonitoringDevicesDetals = {
  path: "/livemonitoring/devices/:posId/details",
  readAccessPath: "Monitoring.Devices - Read",
  component: PageComponent.MonitoringDevices,
}

const liveMonitoringDeviceDetailedScreenRoutes = {
  path: "/livemonitoring/devices/detailed-page/itemId=:id",
  readAccessPath: "Monitoring.Devices - Read",
  component: PageComponent.EditLiveMonitoringDevices,
}

const liveMonitoringStoreDevicesDetails = {
  path: "/livemonitoring/tenants/:storeName/devices/:posId/details",
  readAccessPath: "Monitoring.SaaS - Read",
  component: PageComponent.MonitoringDevices,
}

const liveMonitoringDevices = {
  path: "/livemonitoring/tenants/:storeName/devices",
  readAccessPath: "Monitoring.SaaS - Read",
  component: PageComponent.Devices,
}

// Alerts

const alertRoutes = {
  id: "Alerts",
  path: "/alert",
  icon: <AlertIconKit />,
  childArray: [
    { readAccessPath: "Alerts.New Alert - Read" },
    { readAccessPath: "Alerts.Configuration - Read" },
    { readAccessPath: "Alerts.Monitor - Read" },
  ],
  children: [
    {
      path: "/alert/newAlert",
      name: "New Alert",
      component: PageComponent.NewAlert,
      readAccessPath: "Alerts.New Alert - Read",
      writeAccessPath: "Alerts.New Alert - Write",
    },
    {
      path: "/alert/configuration",
      name: "Configuration",
      component: PageComponent.Alerts,
      readAccessPath: "Alerts.Configuration - Read",
      writeAccessPath: "Alerts.Configuration - Write",
    },
    {
      path: "/alert/monitor",
      name: "Monitor",
      component: PageComponent.Alerts,
      readAccessPath: "Alerts.Monitor - Read",
      writeAccessPath: "Alerts.Monitor - Write",
    },
  ],
  component: null,
}

const alertconfigurationRoutes = {
  path: "/alert/configuration/:tenantId",
  name: "Configuration",
  component: PageComponent.Configuration,
  readAccessPath: "Alerts.Configuration - Read",
  writeAccessPath: "Alerts.Configuration - Write",
}

const alertmonitorRoutes = {
  path: "/alert/monitor/:tenantId/:severity?/:locationId?",
  name: "Monitor",
  component: PageComponent.Monitor,
  readAccessPath: "Alerts.Monitor - Read",
  writeAccessPath: "Alerts.Monitor - Write",
}

// Reports

const reportsRoutes = {
  id: "Reports",
  path: "/reports",
  icon: <Monitor />,
  childArray: [
    { readAccessPath: "Reports.Detailed Transaction Report - Read" },
    { readAccessPath: "Reports.Sales Summary Report - Read" },
    { readAccessPath: "Reports.Scheduled Report - Read" },
  ],
  children: [
    {
      path: "/reports/detailedTransactionReport",
      name: "Detailed Transaction Report",
      component: PageComponent.DetailedTransactionReport,
      readAccessPath: "Reports.Detailed Transaction Report - Read",
    },
    {
      path: "/reports/salesSummaryReport",
      name: "Sales Summary Report",
      component: PageComponent.SalesSummaryReport,
      readAccessPath: "Reports.Sales Summary Report - Read",
    },
    {
      path: "/reports/scheduledReports",
      name: "Report Scheduler",
      readAccessPath: "Reports.Scheduled Report - Read",
      writeAccessPath: "Reports.Scheduled Report - Write",
      component: PageComponent.scheduledReports,
    },
  ],
  component: null,
}

const scheduledReportsCreate = {
  id: "Scheduler Report Create",
  path: "/reports/scheduledReports/create",
  name: "Scheduler Report Create",
  component: PageComponent.scheduledReportsCrud,
  readAccessPath: "Reports.Scheduled Report - Read",
  writeAccessPath: "Reports.Scheduled Report - Write",
  children: null,
}

const scheduledReportsEdit = {
  id: "Scheduler Report Edit",
  path: "/reports/scheduledReports/edit",
  name: "Scheduler Report Edit",
  component: PageComponent.scheduledReportsCrud,
  readAccessPath: "Reports.Scheduled Report - Read",
  writeAccessPath: "Reports.Scheduled Report - Write",
  children: null,
}

const scheduledReportsCopy = {
  id: "Scheduler Report Copy",
  path: "/reports/scheduledReports/copy",
  name: "Scheduler Report Edit",
  component: PageComponent.scheduledReportsCrud,
  readAccessPath: "Reports.Scheduled Report - Read",
  writeAccessPath: "Reports.Scheduled Report - Write",
  children: null,
}

// User Management

const userRoutes = {
  id: "User Management",
  path: "/user-management",
  name: "User Management",
  icon: <Users />,
  childArray: [
    { readAccessPath: "User Management.Role Permission - Read" },
    { readAccessPath: "User Management.Users - Read" },
    { readAccessPath: "User Management.Tenant Permissions - Read" },
  ],
  children: [
    {
      path: "/user-management/roles",
      name: "Roles & Permissions",
      readAccessPath: "User Management.Role Permission - Read",
      writeAccessPath: "User Management.Role Permission - Write",
      component: PageComponent.Permission,
    },
    {
      path: "/user-management/users",
      name: "Users",
      readAccessPath: "User Management.Users - Read",
      writeAccessPath: "User Management.Users - Write",
      component: PageComponent.User,
    },
    {
      path: "/user-management/rolePermission",
      name: "Tenant permissions",
      readAccessPath: "User Management.Tenant Permissions - Read",
      writeAccessPath: "User Management.Tenant Permissions - Write",
      component: PageComponent.RolePermission,
      // menuHide: true,
    },
  ],
  component: null,
}

// Tenant

const tenantRoutes = {
  id: "Tenant",
  path: "/tenant",
  readAccessPath: "User Management.Tenant - Read",
  writeAccessPath: "User Management.Tenant - Write",
  icon: <Package />,
  component: PageComponent.Tenant,
  children: null,
}

const hitlRoutes = {
  id: "HITL",
  path: "/hitl",
  readAccessPath: "Continuous Learning.HITL - Read",
  writeAccessPath: "Continuous Learning.HITL - Write",
  icon: <AccessibilityIcon />,
  component: PageComponent.Hitl,
  children: null,
}

const hitlTenentRoutes = {
  path: "/hitl/main",
  readAccessPath: "Continuous Learning.HITL - Read",
  writeAccessPath: "Continuous Learning.HITL - Write",
  icon: <AccessibilityIcon />,
  component: PageComponent.HitlTenant,
  children: null,
}

const hiltDetailsRoutes = {
  path: "/hitl/main/view",
  readAccessPath: "Continuous Learning.HITL - Read",
  writeAccessPath: "Continuous Learning.HITL - Write",
  icon: <AccessibilityIcon />,
  component: PageComponent.ClassificationCreate,
  children: [
    {
      path: "/hitl/main/view/classification",
      readAccessPath: "Continuous Learning.HITL - Read",
      writeAccessPath: "Continuous Learning.HITL - Write",
      component: PageComponent.hitlClassificationUImagesView,
    },
    {
      path: "/hitl/main/view/segmentation",
      readAccessPath: "Continuous Learning.HITL - Read",
      writeAccessPath: "Continuous Learning.HITL - Write",
      component: PageComponent.hitlSegmentationUImagesView,
    },
  ],
}
const masterDataLocationDetailedScreenRoutes = {
  path: "/master-data/locations/location-detailed-page/storeId=:id",
  readAccessPath: "Master Data.Locations - Read",
  writeAccessPath: "Master Data.Locations - Write",
  component: PageComponent.LocationDetailedPage,
}
const masterDataLocationGroupDetailedScreenRoutes = {
  path:
    "/master-data/locations/groups/location-group-detailed-page/groupId=:id",
  component: PageComponent.LocationGroupDetailedPage,
  readAccessPath: "Master Data.Locations Group - Read",
  writeAccessPath: "Master Data.Locations Group - Write",
}

const masterDataProductGroupDetailedScreenRoutes = {
  path: "/master-data/products/groups/product-group-detailed-page/groupID=:id",
  readAccessPath: "Master Data.Product Groups - Read",
  writeAccessPath: "Master Data.Product Groups - Write",
  component: PageComponent.ProductGroupDetailedPage,
}

const masterDataCustomerDetailedScreenRoutes = {
  path: "/master-data/customers/customer-detailed-page/customerId=:id",
  readAccessPath: "Master Data.Customer - Read",
  writeAccessPath: "Master Data.Customer - Write",
  component: PageComponent.CustomerDetailedPage,
}

// Product Detailed Page
const masterDataProductDetailedScreenRoutes = {
  path: "/master-data/products/product-detailed-page/itemId=:id",
  readAccessPath: "Master Data.Products - Read",
  writeAccessPath: "Master Data.Products - Write",
  component: PageComponent.EditProduct,
}

const masterDataTrainingProductDetailedScreenRoutes = {
  path: "/master-data/products/training-products-detailed",
  readAccessPath: "Master Data.Products - Read",
  component: PageComponent.DetailedTrainedProduct,
}

const masterDataAddNewAddressScreenRoutes = {
  path: "/master-data/customers/add-new-address",
  component: PageComponent.AddNewAddress,
}

const masterDataPriceDetailedScreenRoutes = {
  path: "/master-data/price/price-detailed-page/priceId=:id",
  component: PageComponent.PriceDetailedPage,
}

const masterDataLocationGroups = {
  path: "/master-data/locations/groups",
  component: PageComponent.Groups,
  readAccessPath: "Master Data.Locations Group - Read",
  writeAccessPath: "Master Data.Locations Group - Write",
}

const masterDataLocationHierarchy = {
  path: "/master-data/locations/hierarchy",
  component: PageComponent.Hierarchy,
  readAccessPath: "Master Data.Locations Hierarchy - Read",
  writeAccessPath: "Master Data.Locations Hierarchy - Write",
}

const masterDataLocationMapping = {
  path: "/master-data/locations/hierarchyMapping",
  component: PageComponent.HierarchyMapping,
  readAccessPath: "Master Data.Locations Hierarchy Mapping - Read",
  writeAccessPath: "Master Data.Locations Hierarchy Mapping - Write",
}

const masterDataPosDetailedScreenRoutes = {
  path: "/frictionless-store/sdc/pos/pos-detailed-page/:locationId/:posId",
  component: PageComponent.PosDetailedScreen,
  readAccessPath: "Frictionless Store.Device Config POS - Read",
  writeAccessPath: "Frictionless Store.Device Config POS - Write",
}

const PosDetailedScreenRoutesRevamp = {
  path:
    "/frictionless-store/dc/pos/:storeName/pos-detailed-page/:locationId/:posId",
  component: PageComponent.PosDetailedScreen,
  readAccessPath: "Frictionless Store.Device Config POS - Read",
  writeAccessPath: "Frictionless Store.Device Config POS - Write",
}

const masterDataproductsGroups = {
  path: "/master-data/products/groups",
  component: PageComponent.ProductsGroups,
  readAccessPath: "Master Data.Product Groups - Read",
  writeAccessPath: "Master Data.Product Groups - Write",
}

const masterDataproductsHierarchy = {
  path: "/master-data/products/category",
  readAccessPath: "Master Data.Products Category - Read",
  writeAccessPath: "Master Data.Products Category - Write",
  component: PageComponent.ProductDataHierarchy,
}

const masterDataproductsMapping = {
  path: "/master-data/products/productMapping",
  component: PageComponent.HierarchyMapping,
  readAccessPath: "Master Data.Product Mapping - Read",
  writeAccessPath: "Master Data.Product Mapping - Write",
}

const masterDataproductsAttribute = {
  path: "/master-data/products/attribute",
  component: PageComponent.ProductAttribute,
  readAccessPath: "Master Data.Product Attributes - Read",
  writeAccessPath: "Master Data.Product Attributes - Write",
}

const TrainingProductsRoutes = {
  id: "Training Products",
  path: "/master-data/products/training-products",
  readAccessPath: "Master Data.Product Training Products - Read",
  writeAccessPath: "Master Data.Product Training Products - Write",
  component: PageComponent.TrainingProducts,
  children: null,
}

const logViewerRoutes = {
  id: "Log Viewer",
  path: "/logviewer",
  name: "Log Viewer",
  icon: <LogViewerIcon />,
  childArray: [{ readAccessPath: "Log Viewer.Audit Log - Read" }],
  children: [
    {
      path: "/logviewer/auditLog",
      name: "Audit Logs",
      readAccessPath: "Log Viewer.Audit Log - Read",
      component: PageComponent.AuditLog,
    },
  ],
  component: null,
}

const rolesPermissionRoutes = {
  id: "Role Permission Details",
  path: "/user-management/roles/rolesPermissionDetails/:tenantId?/:tenantName?",
  readAccessPath: "User Management.Role Permission - Read",
  writeAccessPath: "User Management.Role Permission - Write",
  icon: <Monitor />,
  component: PageComponent.RolePermissionTenantDetails,
  children: null,
}

const rolePermissionRoutes = {
  id: "Role Permission Details",
  path:
    "/user-management/rolePermission/rolesPermissionDetails/:tenantId?/:tenantName?",
  readAccessPath: "User Management.Role Permission - Read",
  writeAccessPath: "User Management.Role Permission - Write",
  icon: <Monitor />,
  component: PageComponent.RolePermissionDetails,
  children: null,
}

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: PageComponent.SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: PageComponent.SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: PageComponent.ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: PageComponent.Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: PageComponent.Page500,
    },
    {
      path: "/auth/forgetpassword",
      name: "forget password",
      component: PageComponent.ForgetPassword,
    },
    {
      path: "/password/resetpassword",
      name: "reset password",
      component: PageComponent.ForgetPassword,
    },
    {
      path: "/auth/403",
      name: "403 Page",
      component: PageComponent.Page403,
    },
  ],
  component: null,
}

const profileUpdateRoutes = {
  id: "Profile Update",
  path: "/profileUpdate/:userID",
  component: PageComponent.ProfileUpdate,
  readAccessPath: "Dashboard Data.Read",
  children: null,
}

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  icon: <Monitor />,
  component: PageComponent.SignIn,
  children: null,
}
const forgetPassword = {
  id: "Forget password page",
  path: "/forgetpassword",
  icon: null,
  component: PageComponent.ForgetPassword,
  children: null,
}

const proflieRoutes = {
  id: "Edit Profile",
  path: "/edit-profile",
  icon: <Monitor />,
  component: PageComponent.EditProfile,
  children: null,
}

// SETTINGS
const settingsRoutes = {
  id: "Settings",
  path: "/settings",
  icon: <Settings />,
  childArray: [
    { readAccessPath: "Settings.Product Catalog Configuration - Read" },
    { readAccessPath: "Settings.Avalara Configuration - Read" },
    { readAccessPath: "Settings.Tax Configuration - Read" },
    { readAccessPath: "Settings.Payment Gateway Config - Read" },
    { readAccessPath: "Settings.Receipt Print Template - Read" },
    { readAccessPath: "Settings.PMS Config - Read" },
    { readAccessPath: "Settings.Receipt Config - Read" },
  ],
  children: [
    {
      path: "/settings/receiptprinttemplate",
      name: "Receipt Print Template",
      readAccessPath: "Settings.Receipt Config - Read",
      writeAccessPath: "Settings.Receipt Config - Write",
      component: PageComponent.settingsReceiptPrintTemplate,
    },
    {
      path: "/settings/productcatalog",
      name: "Product Catalog",
      readAccessPath: "Settings.Product Catalog Configuration - Read",
      writeAccessPath: "Settings.Product Catalog Configuration - Write",
      component: PageComponent.ProductCatalog,
    },
    {
      path: "/settings/avalaraconfig",
      name: "Avalara Configuration",
      readAccessPath: "Settings.Avalara Configuration - Read",
      writeAccessPath: "Settings.Avalara Configuration - Write",
      component: PageComponent.AvalaraConfig,
    },
    {
      path: "/settings/connectorConfig",
      name: "Connector Config",
      readAccessPath: "Settings.Tax Configuration - Read",
      writeAccessPath: "Settings.Tax Configuration - Write",
      component: PageComponent.TaxConfig,
    },
    {
      path: "/settings/paymentGatewayTerminal",
      name: "Payment Gateway Config",
      readAccessPath: "Settings.Payment Gateway Config - Read",
      writeAccessPath: "Settings.Payment Gateway Config - Write",
      component: PageComponent.settingPaymentGatewayTerminal,
    },
    {
      path: "/settings/pmsConfig",
      name: "PMS Config",
      readAccessPath: "Settings.PMS Config - Read",
      writeAccessPath: "Settings.PMS Config - Write",
      component: PageComponent.settingPmsConfig,
    },
  ],
  component: null,
}

const receiptPrintTemplateEdit = {
  path: "/settings/receiptprinttemplate/edit",
  name: "Receipt Config Edit",
  readAccessPath: "Settings.Receipt Config - Read",
  writeAccessPath: "Settings.Receipt Config - Write",
  component: PageComponent.settingsReceiptPrintEdit,
}

const settingPmsConfigCreate = {
  path: "/settings/pmsConfig/create",
  name: "PMS Config",
  readAccessPath: "Settings.PMS Config - Read",
  writeAccessPath: "Settings.PMS Config - Write",
  component: PageComponent.settingPmsCreateEditConfig,
}

const settingPmsConfigEdit = {
  path: "/settings/pmsConfig/edit",
  name: "PMS Config Edit",
  readAccessPath: "Settings.PMS Config - Read",
  writeAccessPath: "Settings.PMS Config - Write",
  component: PageComponent.settingPmsCreateEditConfig,
}

const productcatalogcreate = {
  id: "Productcatalog Create ",
  path: "/settings/productcatalog/create",
  name: "Product Catalog",
  component: PageComponent.ProductCatalogAdd,
  readAccessPath: "Settings.Product Catalog Configuration - Read",
  writeAccessPath: "Settings.Product Catalog Configuration - Write",
  children: null,
}

const productcatalogEdit = {
  id: "Productcatalog Edit ",
  path: "/settings/productcatalog/edit",
  name: "Product Catalog",
  component: PageComponent.ProductCatalogEdit,
  readAccessPath: "Settings.Product Catalog Configuration - Read",
  writeAccessPath: "Settings.Product Catalog Configuration - Write",
  children: null,
}

const taxConfigcreate = {
  id: "Productcatalog Create ",
  path: "/settings/connectorConfig/create",
  name: "Connector Config",
  component: PageComponent.TaxConfigAdd,
  readAccessPath: "Settings.Tax Configuration - Read",
  writeAccessPath: "Settings.Tax Configuration - Write",
  children: null,
}

const taxconfigEdit = {
  id: "Productcatalog Edit ",
  path: "/settings/connectorConfig/edit",
  name: "Connector Config",
  component: PageComponent.TaxConfigEdit,
  readAccessPath: "Settings.Tax Configuration - Read",
  writeAccessPath: "Settings.Tax Configuration - Write",
  children: null,
}

const avalaraConfigCreate = {
  id: "AvalaraConfig Create ",
  path: "/settings/avalaraconfig/create",
  name: "Avalara Config",
  component: PageComponent.AvalaraConfigAdd,
  readAccessPath: "Settings.Avalara Configuration - Read",
  writeAccessPath: "Settings.Avalara Configuration - Write",
  children: null,
}

const avalaraConfigEdit = {
  id: "AvalaraConfig Edit ",
  path: "/settings/avalaraconfig/edit/:id",
  name: "Avalara Config",
  component: PageComponent.AvalaraConfigEdit,
  readAccessPath: "Settings.Avalara Configuration - Read",
  writeAccessPath: "Settings.Avalara Configuration - Write",
  children: null,
}
const helpRoutes = {
  id: "Help",
  path: "/help",
  icon: <Monitor />,
  component: PageComponent.Help,
  children: null,
}
const loginRoutes = {
  id: "Login",
  path: "/help",
  component: PageComponent.Login,
  children: null,
}

const supportRoutes = {
  id: "Help",
  path: "/support",
  icon: <Monitor />,
  component: PageComponent.Support,
  children: null,
}

const privacyRoutes = {
  id: "Help",
  path: "/privacy",
  icon: <Monitor />,
  component: PageComponent.Privacy,
  children: null,
}

const termsOfServiceRoutes = {
  id: "Help",
  path: "/terms-of-service",
  icon: <Monitor />,
  component: PageComponent.TermsOfService,
  children: null,
}

const createLocationRoutes = {
  id: "Create Location",
  path: "/master-data/locations/create",
  icon: <Monitor />,
  readAccessPath: "Master Data.Locations - Read",
  writeAccessPath: "Master Data.Locations - Write",
  component: PageComponent.CreateLocation,
  children: null,
}

const mainPosRoutes = {
  id: "Main Pos",
  path: "/frictionless-store/dc/pos/:storeName",
  icon: <Monitor />,
  readAccessPath: "Frictionless Store.Device Config POS - Read",
  writeAccessPath: "Frictionless Store.Device Config POS - Write",
  component: PageComponent.MainPos,
}

const createPosRoutes = {
  id: "Create Pos",
  path: "/frictionless-store/sdc/pos/create/:LocationNum",
  icon: <Monitor />,
  readAccessPath: "Frictionless Store.Device Config POS - Read",
  writeAccessPath: "Frictionless Store.Device Config POS - Write",
  component: PageComponent.CreateNewPos,
}

const createPosRevampRoutes = {
  id: "Create Pos",
  path: "/frictionless-store/dc/pos/:storeName/create/:LocationNum",
  icon: <Monitor />,
  readAccessPath: "Frictionless Store.Device Config POS - Read",
  writeAccessPath: "Frictionless Store.Device Config POS - Write",
  component: PageComponent.CreateNewPos,
}

const createCustomerRoutes = {
  id: "Create Customer",
  path: "/master-data/customers/create",
  icon: <Monitor />,
  component: PageComponent.CreateCustomer,
  children: null,
}

// Product Create
const createProductRoutes = {
  id: "Create Product",
  path: "/master-data/products/create",
  readAccessPath: "Master Data.Products - Read",
  writeAccessPath: "Master Data.Products - Write",
  icon: <Monitor />,
  component: PageComponent.CreateProduct,
  children: null,
}

const editProductRoutes = {
  id: "Edit Product",
  path: "/master-data/products/editProduct/:storeID/:upc",
  icon: <Monitor />,
  component: PageComponent.EditProduct,
  children: null,
}

// const createProductGroup = {
//   id: "Create Product Group",
//   path: "/master-data/products/createProductGroup/:groupID/:groupName",
//   icon: <Monitor />,
//   component: PageComponent.CreateProductGroup,
//   children: null,
// }

const GroupLocationsListRoutes = {
  id: "Group Locations List",
  path: "/master-data/locations/groups/create",
  icon: <Monitor />,
  readAccessPath: "Master Data.Locations Group - Read",
  writeAccessPath: "Master Data.Locations Group - Write",
  component: PageComponent.GroupLocationsList,
  children: null,
}

const CreateTrainingProductsRoutes = {
  id: "createTrainingProducts",
  path: "/master-data/products/training-products/create",
  readAccessPath: "Master Data.Product Training Products - Read",
  writeAccessPath: "Master Data.Product Training Products - Write",
  icon: <Monitor />,
  component: PageComponent.TrainingProductsCreate,
  children: null,
}

const EditTrianingRoutes = {
  id: "editTrainingProducts",
  path: "/master-data/products/training-products/details/:barcode",
  readAccessPath: "Master Data.Product Training Products - Read",
  writeAccessPath: "Master Data.Product Training Products - Write",
  icon: <Monitor />,
  component: PageComponent.TrainingProductsEdit,
  children: null,
}

//product Group Create
const GroupProductCreateRoutes = {
  id: "Group Locations List",
  path: "/master-data/products/groups/create",
  icon: <Monitor />,
  readAccessPath: "Master Data.Product Groups - Read",
  writeAccessPath: "Master Data.Product Groups - Write",
  component: PageComponent.ProductGroupCreate,
  children: null,
}

const statusRoutes = {
  id: "Status",
  path: "/status",
  icon: <Layout />,
  component: PageComponent.Status,
  children: null,
}

const CreateSmartShelf = {
  id: "createSmartShelf",
  path: "/autonomous-store/sdc/smartShelf/create/:LocationNum",
  readAccessPath:
    "Autonomous Store.Smart Device Configuration - Digit Shelves - Read",
  writeAccessPath:
    "Autonomous Store.Smart Device Configuration - Digit Shelves - Write",
  icon: <Monitor />,
  component: PageComponent.CreateSmartShelfDetails,
  children: null,
}

const CreateCamera = {
  id: "createCamera",
  path: "/autonomous-store/sdc/camera/create/:LocationNum",
  readAccessPath: "Autonomous Store.Smart Device Configuration - Camera - Read",
  writeAccessPath:
    "Autonomous Store.Smart Device Configuration - Camera - Write",
  icon: <Monitor />,
  component: PageComponent.CreateCameraDetails,
  children: null,
}

const maintainPlanoGram = {
  path: "/autonomous-store/planogram/maintainPlanogram/:locationID",
  readAccessPath:
    "Autonomous Store.Planogram - Maintenance and Management - Read",
  name: "Maintain Planogram",
  icon: <Monitor />,
  component: PageComponent.SpacePlanning,
  children: null,
}

const createDeviceFS = {
  id: "createDeviceFS",
  path: "/frictionless-store/sdc/device-management/create/:LocationNum",
  readAccessPath:
    "Frictionless Store.Smart Device Configuration - Device Management - Read",
  writeAccessPath:
    "Frictionless Store.Smart Device Configuration - Device Management - Write",
  icon: <Monitor />,
  component: PageComponent.CreateDeviceMgmtPage,
  children: null,
}

const editDeviceFS = {
  id: "editDeviceFS",
  path:
    "/frictionless-store/sdc/device-management/device-details/:LocationNum/:deviceId",
  readAccessPath:
    "Frictionless Store.Smart Device Configuration - Device Management - Read",
  writeAccessPath:
    "Frictionless Store.Smart Device Configuration - Device Management - Write",
  icon: <Monitor />,
  component: PageComponent.EditDeviceMgmtPage,
  children: null,
}

const CreateFSEdgeDeviceRoutes = {
  id: "createEdgeDevice",
  path: "/frictionless-store/sdc/edgeDevice/create/:LocationNum/:posId",
  readAccessPath: "Frictionless Store.Device Config Edge Device - Read",
  writeAccessPath: "Frictionless Store.Device Config Edge Device - Write",
  icon: <Monitor />,
  component: PageComponent.CreateFSEdgeDeviceDetails,
  children: null,
}

const EditFSEdgeDeviceRoutes = {
  id: "editEdgeDevice",
  path:
    "/frictionless-store/sdc/edgeDevice/edgeDeviceDetails/:LocationNum/:edgeDeviceId",
  readAccessPath: "Frictionless Store.Device Config Edge Device - Read",
  writeAccessPath: "Frictionless Store.Device Config Edge Device - Write",
  icon: <Monitor />,
  component: PageComponent.EditFSEdgeDeviceDetails,
  children: null,
}

const CreateTerminalRoutes = {
  id: "createTerminalScreen",
  path: "/frictionless-store/sdc/terminalScreen/create/:LocationNum/:posId",
  readAccessPath: "Frictionless Store.Device Config Terminal Screen - Read",
  writeAccessPath: "Frictionless Store.Device Config Terminal Screen - Write",
  icon: <Monitor />,
  component: PageComponent.CreateFSTerminalScreenDetails,
  children: null,
}

const EditTerminalRoutes = {
  id: "editTerminalScreen",
  path:
    "/frictionless-store/sdc/terminalScreen/details/:LocationNum/:terminalScreenId",
  readAccessPath: "Frictionless Store.Device Config Terminal Screen - Read",
  writeAccessPath: "Frictionless Store.Device Config Terminal Screen - Write",
  icon: <Monitor />,
  component: PageComponent.EditFSTerminalScreenDetails,
  children: null,
}

const CreateFSPrinterRoutes = {
  id: "createPrinter",
  path: "/frictionless-store/sdc/printer/create/:LocationNum/:posId",
  readAccessPath: "Frictionless Store.Device Config Printer - Read",
  writeAccessPath: "Frictionless Store.Device Config Printer - Write",
  icon: <Monitor />,
  component: PageComponent.CreateFSPrinterDetails,
  children: null,
}

const EditFSPrinterRoutes = {
  id: "editPrinter",
  path:
    "/frictionless-store/sdc/printer/printerDetails/:LocationNum/:printerId",
  readAccessPath: "Frictionless Store.Device Config Printer - Read",
  writeAccessPath: "Frictionless Store.Device Config Printer - Write",
  icon: <Monitor />,
  component: PageComponent.EditFSPrinterDetails,
  children: null,
}

const CreateFSScannerRoutes = {
  id: "createScanner",
  path: "/frictionless-store/sdc/scanner/create/:LocationNum/:posId",
  readAccessPath: "Frictionless Store.Device Config Scanner - Read",
  writeAccessPath: "Frictionless Store.Device Config Scanner - Write",
  icon: <Monitor />,
  component: PageComponent.CreateFSScannerDetails,
  children: null,
}

const EditFSScannerRoutes = {
  id: "editScanner",
  path:
    "/frictionless-store/sdc/scanner/scannerDetails/:LocationNum/:scannerId",
  readAccessPath: "Frictionless Store.Device Config Scanner - Read",
  writeAccessPath: "Frictionless Store.Device Config Scanner - Write",
  icon: <Monitor />,
  component: PageComponent.EditFSScannerDetails,
  children: null,
}

const maintainFrictionlessStore = {
  path:
    "/frictionless-store/planogram/:storeName/maintainFrictionlessStore/:locationID",
  readAccessPath: "Frictionless Store.Planogram - Read",
  name: "Maintain Frictionless Store",
  icon: <Monitor />,
  component: PageComponent.FrictionlessStore,
  children: null,
}

const frictionlessPlanogramListStore = {
  path: "/frictionless-store/planogram/:storeName",
  readAccessPath: "Frictionless Store.Planogram - Read",
  component: PageComponent.PlanogramList,
  children: null,
}

const createUserRoutes = {
  id: "Create User",
  path: "/user-management/users/create",
  readAccessPath: "User Management.Users - Read",
  writeAccessPath: "User Management.Users - Write",
  icon: <Monitor />,
  component: PageComponent.CreateEditUser,
  children: null,
}

const editUserRoutes = {
  id: "Edit User",
  path: "/user-management/users/view/:userID",
  readAccessPath: "User Management.Users - Read",
  writeAccessPath: "User Management.Users - Write",
  icon: <Monitor />,
  component: PageComponent.CreateEditUser,
  children: null,
}

const createTenantRoutes = {
  id: "Create Tenant",
  path: "/tenant/create",
  readAccessPath: "User Management.Tenant - Read",
  writeAccessPath: "User Management.Tenant - Write",
  icon: <Monitor />,
  component: PageComponent.TenantCreateEdit,
  children: null,
}

const editTenantRoutes = {
  id: "Edit Tenant",
  path: "/tenant/view/:TenantID",
  readAccessPath: "User Management.Tenant - Read",
  writeAccessPath: "User Management.Tenant - Write",
  icon: <Monitor />,
  component: PageComponent.TenantCreateEdit,
  children: null,
}

const dashboardLayout = [
  dashboardsRoutes,
  contentRoutes,
  masterDataRoutes,
  TrainingProductsRoutes,
  liveMonitoringRoutes,
  userRoutes,
  proflieRoutes,
  helpRoutes,
  loginRoutes,
  logViewerRoutes,
  settingsRoutes,
  privacyRoutes,
  supportRoutes,
  termsOfServiceRoutes,
  createLocationRoutes,
  createPosRoutes,
  mainPosRoutes,
  rolePermissionRoutes,
  rolesPermissionRoutes,
  livemonitoringDevicesSaas,
  liveMonitoringProductDetailedScreenRoutes,
  liveMonitoringDeviceDetailedScreenRoutes,
  liveMonitoringDevicesDetals,
  liveMonitoringDevices,
  liveMonitoringStoreDevicesDetails,
  masterDataLocationDetailedScreenRoutes,
  masterDataLocationGroupDetailedScreenRoutes,
  masterDataCustomerDetailedScreenRoutes,
  masterDataProductDetailedScreenRoutes,
  masterDataTrainingProductDetailedScreenRoutes,
  masterDataProductGroupDetailedScreenRoutes,
  masterDataPriceDetailedScreenRoutes,
  masterDataAddNewAddressScreenRoutes,
  statusRoutes,
  createCustomerRoutes,
  createProductRoutes,
  editProductRoutes,
  GroupLocationsListRoutes,
  GroupProductCreateRoutes,
  // createProductGroup,
  EditOrderRoutes,
  CreateSmartShelf,
  CreateCamera,
  masterDataproductsGroups,
  masterDataproductsHierarchy,
  masterDataproductsMapping,
  masterDataLocationGroups,
  masterDataLocationHierarchy,
  masterDataLocationMapping,
  masterDataPosDetailedScreenRoutes,
  masterDataproductsAttribute,
  maintainPlanoGram,
  maintainFrictionlessStore,
  tenantRoutes,
  hitlRoutes,
  CreateFSEdgeDeviceRoutes,
  EditFSEdgeDeviceRoutes,
  CreateFSPrinterRoutes,
  EditFSPrinterRoutes,
  CreateFSScannerRoutes,
  EditFSScannerRoutes,
  createUserRoutes,
  editUserRoutes,
  createTenantRoutes,
  editTenantRoutes,
  createDeviceFS,
  editDeviceFS,
  profileUpdateRoutes,
  CreateTrainingProductsRoutes,
  EditTrianingRoutes,
  CreateTerminalRoutes,
  EditTerminalRoutes,
  productcatalogEdit,
  taxConfigcreate,
  taxconfigEdit,
  avalaraConfigCreate,
  avalaraConfigEdit,
  productcatalogcreate,
  productcatalogEdit,
  createPosRevampRoutes,
  PosDetailedScreenRoutesRevamp,
  scheduledReportsCreate,
  scheduledReportsEdit,
  scheduledReportsCopy,
  frictionlessPlanogramListStore,
  ModalConfigRoutes,
  alertconfigurationRoutes,
  alertmonitorRoutes,
  InventoryAdjustment,
  AdjustInventoryView,
  productValidationImagesView,
  ModelTrainingCreate,
  ModelTrainingView,
  hiltDetailsRoutes,
  settingPmsConfigCreate,
  settingPmsConfigEdit,
  receiptPrintTemplateEdit,
  translateBundleViewLangSync,
  translateMasterLangSync,
  hitlTenentRoutes, 
  htilProductValidationImagesView
]

const sidebarRoute = [
  dashboardsRoutes,
  contentRoutes,
  masterDataRoutes,
  FrictionlessStoreRevampRoutes,
  transactionRoutes,
  liveMonitoringRoutes,
  alertRoutes,
  reportsRoutes,
  userRoutes,
  hitlRoutes,
  tenantRoutes,
  logViewerRoutes,
  settingsRoutes,
]

export { dashboardLayout, authRoutes, landingRoutes, sidebarRoute }
