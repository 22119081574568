import React from "react"
import { Backdrop, CircularProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Spinner from "../../../../src/components/Spinner/index"
import * as Adm from "@adm"

const BackDropOverLayStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: "white",
  },
}))

function BackdropOverlay({ open = false }) {
  const classes = BackDropOverLayStyles()
  React.useEffect(() => {
    if (open) {
      document.body.classList.add("cls-backdrop-overlay-kit")
    } else {
      document.body.classList.remove("cls-backdrop-overlay-kit")
    }
    return () => {
      document.body.classList.remove("cls-backdrop-overlay-kit")
    }
  }, [open])

  return (
    <Backdrop
      open={open}
      className={`${classes.backdrop} cls-Backdrop-load-kit`}
    >
      <Adm.BrandLoadingBar />
    </Backdrop>
  )
}
export { BackdropOverlay }
export default BackdropOverlay
