import React from "react"
import JSUtils from "../utils/JSUtils"

export function useGlobalStates(props) {
  /**
   * @SOCKET_Connection_INIT
   */
  const getRealmID = localStorage.getItem("realmId")
  const getTenantId = localStorage.getItem("tenantId")
  const getToken = localStorage.getItem("token")
  const onInitSocket = (arg) => {
    const {
      socketUrl = "",
      initData = {},
      setLiveData = () => {},
      liveData = {},
      setWaitModal = () => false,
      setPreUploadFlag = () => false,
      setAlert = () => false,
      setAlertMessage = () => {},
      setAlertType = () => "",
      t = () => {},
    } = arg || {}
    setAlert(false)
    let { wsTelemtry = {} } = arg || {}
    if (!socketUrl) {
      setWaitModal(false)
      setPreUploadFlag(false)
      return
    }
    /**
     * @INFO_wsTelemtry_is_Ref
     *   */

    if (wsTelemtry?.current) {
      wsTelemtry.current.close()
    }
    try {
      wsTelemtry.current = new WebSocket(socketUrl)
      wsTelemtry.current.onopen = function (e) {
        let initAuth = {
          tenantId: getTenantId || initData?.tenantId || "",
          Authorization: `Bearer ${getToken}`,
        }

        wsTelemtry.current.send(JSON.stringify(initAuth))

        onHandleLiveDataSocket({
          liveData,
          setLiveData,
          wsTelemtry,
          setWaitModal,
          setPreUploadFlag,
          setAlert,
          setAlertMessage,
          setAlertType,
          socketUrl,
          initData,
        })
      }

  
    wsTelemtry.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
    wsTelemtry.current.onclose = (event) => {
      setAlert(false)
      const msg = JSON.parse(event?.reason || `{}`) || {}
      if(event?.code === 1008){
      setLiveData({...msg,state:"Error"} || {})
      }
      setAlertType("error")
      setAlert(true)
      setAlertMessage(
        JSUtils.formatMessageCode(msg, t)
      )
      
    }
   
    } catch (e) {
      setWaitModal(false)
      setPreUploadFlag(false)
      setAlertType("error")
      setAlertMessage("Error in socket connection")
      setAlert(true)
    }
  }
  
  /**
   * @SOCKET_LIVE_TELEMETRY
   */
  const onHandleLiveDataSocket = (arg) => {
    const {
      liveData = {},
      setLiveData = () => {},
      setWaitModal = () => false,
      setPreUploadFlag = () => false,
      setAlert = () => false,
      setAlertMessage = () => "",
      setAlertType = () => "",
      socketUrl = "",
      initData = {},
    } = arg || {}
    let { wsTelemtry = {} } = arg || {}
    if (!wsTelemtry?.current) {
      setWaitModal(false)
      setPreUploadFlag(false)
      return () => {}
    }
    try {
      wsTelemtry.current.onmessage = (event) => {
        const msg = JSON.parse(event?.data || `{}`) || {}
        const { status = "", message = "", stack = "", type = ""} = msg || {}
        if (status === "failed") {
          setAlertType("error")
          setAlertMessage(`${message}.${stack}`)
          setAlert(true)
        } else if (type === "authSuccess" || status === "Success") {
          wsTelemtry.current.send(
            JSON.stringify({
              ...initData,
              x_kc_realm_id: getRealmID,
            })
          )
        } else {
          setWaitModal(false)
          setPreUploadFlag(false)
          setLiveData({ ...liveData, ...msg } || {})
        }
      }
      return () => {}
    } catch (e) {
      console.log("<< socket live telemtry error: ", e)
      setWaitModal(false)
      setPreUploadFlag(false)
    }
  }

  return {
    onInitSocket,
    onHandleLiveDataSocket,
  }
}
