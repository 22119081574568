import React, { useState, useContext } from "react"
import { NavLink } from "react-router-dom"
import {
  SidebarCategory,
  SidebarSubCategory,
  SidebarLink,
  SidebarSubLink,
} from "."
import _ from "lodash"
import { JSUtils } from "@utils"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { useStyles } from "./style"
import { Collapse } from "@material-ui/core"
import { DashboardContext } from "../../Dashboardcontext/DashboardContext"

const getReadWritePermissions = (x, userPermAttr = {}) =>
  JSUtils.getReadWritePermissions(x, userPermAttr)
const TooltipSideBar = (props) => {
  const { routeName, formatRoutes } = props
  const history = useHistory()
  const { showMore, setShowMore } = useContext(DashboardContext)
  const classesOne = useStyles()
  const { t } = useTranslation()
  const [routesWithActiveStatus, setRoutesWithActiveStatus] = useState(
    formatRoutes(_.cloneDeep(routeName), "", t)
  )
  const userPermAttr = useSelector(
    (state) => state.adminReducer.userKeycloakAttributes
  )

  const handleToggle = (indicesArr, route = {}) => {
    const { redirect = true } = route
    // console.log(route, "redirect")
    const tempRoutes = _.cloneDeep(routesWithActiveStatus)
    if (_.isArray(indicesArr)) {
      let isOnly = indicesArr.length === 1
      let path = indicesArr.reduce((acc, x, i, arr) => {
        let isFirst = i === 0
        let isLast = arr.length - 1 === i
        acc = `${isFirst ? "" : `${acc}.`}${x}${isLast ? "" : ".children"}`
        return acc
      }, "")
      _.set(tempRoutes, path, {
        ..._.get(tempRoutes, path),
        isOpen: !_.get(tempRoutes, `${path}.isOpen`),
      })
      console.log(path, _.get(routesWithActiveStatus, path), tempRoutes)
    }
    setRoutesWithActiveStatus(tempRoutes)
    if (redirect) {
      if (_.has(route, "path")) history.push(t(_.get(route, "path")))
    }
  }
  // return <div style={{ color: "white" }}>BK</div>
  return (
    <>
      {routeName?.map((category, index) => {
        let { isReadAllowed: isCategoryAllowed } = getReadWritePermissions(
          category,
          userPermAttr
        )
        let allowedHeadModule = []
        let allowedSubHeadModule = []
        _.map(category?.children, (ro, i) => {
          allowedHeadModule.push(
            getReadWritePermissions(ro, userPermAttr).isReadAllowed
          )
          _.map(ro?.children, (rou, i) => {
            allowedSubHeadModule.push(
              getReadWritePermissions(rou, userPermAttr).isReadAllowed
            )
          })
        })

        return (
          <div>
            {isCategoryAllowed &&
            category.children &&
            category.icon &&
            !allowedHeadModule.every((x) => x === false) ? (
              <React.Fragment key={index}>
                <SidebarCategory
                  isOpen={_.get(
                    routesWithActiveStatus,
                    `${index}.isOpen`,
                    false
                  )}
                  isCollapsable={false}
                  name={t(category.id)}
                  button={true}
                  onClick={() => handleToggle([index])}
                />
                {category?.children?.map((route, ind) => {
                  let {
                    isReadAllowed: isRouteAllowed,
                  } = getReadWritePermissions(route, userPermAttr)
                  if (route.children && isRouteAllowed) {
                    return (
                      <>
                        {route.children &&
                        !allowedSubHeadModule.every((x) => x === false) ? (
                          <React.Fragment key={`sidebarsubcategory_${ind}`}>
                            <SidebarSubCategory
                              isOpen={_.get(
                                routesWithActiveStatus,
                                `${index}.children.${ind}.isOpen`,
                                false
                              )}
                              isCollapsable={true}
                              name={t(route.id)} // master auto frition;
                              button={true}
                              onClick={() => handleToggle([index, ind], route)}
                            />
                            <Collapse
                              in={_.get(
                                routesWithActiveStatus,
                                `${index}.children.${ind}.isOpen`,
                                false
                              )}
                              timeout="auto"
                              unmountOnExit
                              className={classesOne.collapse}
                            >
                              {route.children.map((r, i) => {
                                let {
                                  isReadAllowed: isChildRouteAllowed,
                                } = getReadWritePermissions(r, userPermAttr)
                                if (isChildRouteAllowed) {
                                  return (
                                    <React.Fragment key={`sidebarsublink_${i}`}>
                                      <SidebarSubLink
                                        key={`sidebarsublink_${i}`}
                                        name={t(r.name)}
                                        to={t(r.path)} //group ,product ,group
                                        badge={r.badge}
                                        onClick={() => {
                                          history.push(t(route.path))
                                        }}
                                      />
                                    </React.Fragment>
                                  )
                                }
                                return null
                              })}
                            </Collapse>
                          </React.Fragment>
                        ) : null}
                      </>
                    )
                  } else {
                    return (
                      isRouteAllowed && (
                        <React.Fragment key={`sidebarsubcategory_${ind}`}>
                          <SidebarLink // plonogram,inventory, userMangement,permission,role permission,country,tax masterData
                            isRouteAllowed={getReadWritePermissions(
                              route,
                              userPermAttr
                            )}
                            route={route}
                            routePerms={getReadWritePermissions(
                              route,
                              userPermAttr
                            )}
                            key={`sidebarsubcategory_${ind}`}
                            name={t(route.name)}
                            to={t(route.path)}
                            icon={route.icon}
                            badge={route.badge}
                          />
                        </React.Fragment>
                      )
                    )
                  }
                })}
              </React.Fragment>
            ) : (
              <>
                {isCategoryAllowed &&
                category.icon &&
                allowedHeadModule.every((x) => x !== false) ? (
                  <React.Fragment key={`sidebarCategory_${index}`}>
                    <SidebarCategory
                      isCollapsable={false}
                      name={t(category.id)}
                      to={t(category.path)}
                      activeClassName="active"
                      component={NavLink}
                      exact={false}
                      button
                      badge={category.badge}
                      key={index}
                    />
                  </React.Fragment>
                ) : null}
              </>
            )}
          </div>
        )
      })}
    </>
  )
}
export default TooltipSideBar
