import _ from 'lodash';
import * as constantsAPI from "@constants"
const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}

const types = REDUX_CONSTANTS

const initialState = {
  locationInfo: null,
  openDate: null,
  operatingHoursAlchol: null,
  operatingHoursBusiness: null,
  locationHierarchy: null,
  locationServices: null,
  locationAttributes: [],
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.ADD_LOCATION_INFO:
      return {
        ...state,
        locationInfo: actions.locationInfo,
      }
    case types.ADD_LOCATION_OPEN_DATE:
      return {
        ...state,
        openDate: actions.openDate,
      }
    case types.ADD_LOCATION_OPERATING_HOURS_ALCHOL:
      return {
        ...state,
        operatingHoursAlchol: actions.operatingHoursAlchol,
      }
    case types.ADD_LOCATION_OPERATING_HOURS_BUSINESS:
      return {
        ...state,
        operatingHoursBusiness: actions.operatingHoursBusiness,
      }
    case types.ADD_LOCATION_TYPE_HIERARCHY:
      return {
        ...state,
        locationHierarchy: actions.locationHierarchy,
      }
    case types.ADD_LOCATION_SERVICES:
      return {
        ...state,
        locationServices: actions.locationServices,
      }
    case types.ADD_LOCATION_ATTRIBUTES:
      return {
        ...state,
        locationAttributes: actions.locationAttributes,
      }
    case types.ADD_LOCATION_RESET:
      return {
        locationInfo: null,
        openDate: null,
        operatingHoursAlchol: null,
        operatingHoursBusiness: null,
        locationHierarchy: null,
        locationServices: null,
        locationAttributes: [],
      }
    default:
      return state
  }
}
