import React from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"

function CustomRedirect({ history = {}, lang }) {
  const { location = {} } = history
  console.log("lang>>", lang)
  const { i18n, t } = useTranslation()

  const onTriggerRerouting = () => {
    if (location?.pathname === "/") {
      console.log("Redirecting to dashboard>>", t("/dashboard"))
      history.push(t("/dashboard"))
    }
  }

  React.useEffect(() => {
    if (location.pathname === "/") {
      if(lang !== "") {
        console.log("lang>>", lang)
        i18n.changeLanguage(lang)
        onTriggerRerouting()
      }
    }
  }, [lang])

  return <></>
}

export default CustomRedirect
export { CustomRedirect }
