import * as constantsAPI from "@constants"
const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}

const types = REDUX_CONSTANTS

const initialState = {
  invLocItems: {},
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.INV_LOC_SELECTED_ITEMS:
      return {
        ...state,
        invLocItems: actions.obj,
      }
    default:
      return state
  }
}
