const TableColumns = [
  {
    field: "locationId",
    title: "Location Number",
    border: true,
    filter: false,
    show: true,
    min_width: 20,
  },
  {
    field: "productCount",
    title: "No. of Products",
    border: true,
    filter: false,
    show: true,
    min_width: 20,
  },
  {
    field: "locationType",
    title: "Type",
    border: true,
    filter: false,
    show: true,
    min_width: 20,
  },
  {
    field: "locationName",
    title: "Name",
    border: true,
    filter: false,
    show: true,
    min_width: 20,
  },
  {
    field: "locationCity",
    title: "City",
    border: true,
    filter: false,
    show: true,
    min_width: 20,
  },
  {
    field: "locationState",
    title: "State",
    border: true,
    filter: false,
    show: true,
    min_width: 15,
  },
  {
    field: "locationManager",
    title: "Manager",
    border: true,
    filter: false,
    show: true,
    min_width: 15,
  },
  {
    field: "locationPhone",
    title: "Phone",
    filter: false,
    show: true,
    min_width: 15,
  },
  {
    field: "locationEmail",
    title: "Email",
    border: true,
    filter: false,
    show: true,
    min_width: 15,
  },
]
export default TableColumns
