import React, { useState, useEffect } from "react"
import { withTheme } from "styled-components/macro"
import {
  Divider,
  Grid,
  Typography,
  Collapse,
  Card,
  IconButton,
  Tooltip,
} from "@material-ui/core"
import {
  CheckCircleIcon,
  ChevronUp,
  ChevronDown,
  Slash,
  Edit,
} from "@iconsGallery"
import { useTranslation } from "react-i18next"
import LocationTreeComponent from "./LocationTree"
import { useDispatch, useSelector } from "react-redux"
import * as ServiceAPI from "@services"
import _ from "lodash"
import * as Features from "@features"
import * as Adm from "@adm"
import * as constantsAPI from "@constants"
import { JSUtils } from "@utils"
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

function EditLocationHierarchyKit(props) {
  const {
    setAllowPrompt = () => { },
    isWriteAllowed = false,
    locationType = {},
    setLocationType = () => { },
    locationSize = {},
    setLocationSize = () => { },
    oldState = {},
    storeList = [],
    setStoreType = () => { },
    storeType = {},
  } = props
  const [collapseOpen, setCollapseOpen] = useState(false)
  const { t } = useTranslation()
  const [edit, setEdit] = useState(false)
  const [reset, setReset] = useState(false)
  const [locationTypeError, setLocationTypeError] = useState(false)
  const [storeTypeError, setStoreTypeError] = useState(false)
  const [locationSizeError, setLocationSizeError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")
  const [isAnyChanges, setAnyChanges] = useState(false)
  const [defaultData, setDefaultData] = useState({})

  useEffect(() => {
    setLocationSize(props?.locationSize)
    setLocationType(props?.locationType)
    setDefaultData((e) => {
      return {
        locationSize: props.locationSize,
        locationType: props?.locationType,
      }
    })
  }, [props?.locationSize, props?.locationType])

  const resetData = () => {
    setEdit(false)
    setReset(true)
    setLocationType(oldState?.locationType)
    setLocationSize(oldState?.locationSize)
  }

  const updateData = async () => {
    if (locationType === {}) {
      setLocationTypeError(true)
      return
    } else if (
      !_.every(
        locationSize,
        (x, i, k) =>
          k.lengthValue !== "" && k.heightValue !== "" && k.widthValue !== ""
      )
    ) {
      setLocationSizeError(true)
      return
    } else if (
      _.every(
        locationSize,
        (x, i, k) =>
          k.lengthValue === "0" || k.heightValue === "0" || k.widthValue === "0"
      )
    ) {
      setLocationSizeError(true)
      return
    } else {
      setEdit(false)
      setLoading(true)
      setAlert(false)
      setLocationSizeError(false)
      setLocationTypeError(false)
      let body = {
        ...props.sendBackData,
        locationType: locationType,
        locationSize: locationSize,
      }

      let response = await ServiceAPI.fetchStoreCommonData(
        API_END_POINTS.updateLocation,
        body
      )
      await setDefaultData({
        locationSize: _.get(response, "data.data.locationSize", {}),
        locationType: _.get(response, "data.data.locationType", {}),
        storeType: _.get(response, "data.data.storeType", {}),
      })
      await setLocationSize(_.get(response, "data.data.locationSize", {}))
      await setLocationType(_.get(response, "data.data.locationType", {}))
      await setAlertType(_.get(response, "data.status", "error"))
      await setAlertMessage(JSUtils.formatMessageCode(response?.data, t, "locationSize must be a safe number"))
      await setAlert(true)
      await setLoading(false)
      setAnyChanges(false)
    }
    props.fetchData()
  }

  return (
    <Grid lg={12} md={12} sm={12} xs={12} className="cls-locationtype">
      <Adm.BackdropOverlay open={loading} />
      <Card
        style={{
          width: "100%",
          boxShadow:
            " 0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)",
          borderRadius: "8px",
        }}
      >
        <Grid
          container
          row="true"
          justify="space-between"
          alignContent="center"
          alignItems="center"
          style={{
            paddingLeft: "16px",
            height: "60px",
            cursor: "pointer"
          }}
          onClick={() => {
            setCollapseOpen(!collapseOpen)
            setAllowPrompt(false)
            resetData()
            setAnyChanges(false)
          }}
        >
          <Grid item>
            <Typography
              variant="h6"
              align="center"
              style={{ fontWeight: "bold" }}
            >
              {t("Location Type")}
            </Typography>
          </Grid>
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <div>
              {collapseOpen ? (
                <div>
                  {edit ? (
                    <>
                      <Tooltip title={t("Cancel")} placement="top">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation()
                            resetData()
                            setEdit(false)
                            setAnyChanges(false)
                            setAllowPrompt(false)
                            setLocationType(oldState?.locationType)
                            setLocationSize(oldState?.locationSize)
                          }}
                        >
                          <Slash
                            size={20}
                            style={{
                              marginRight: "1px",
                              color: "red",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("Save")} placement="top">
                        <IconButton
                          type="submit"
                          style={{
                            marginRight: "2px",
                            pointerEvents: `${isAnyChanges ? "unset" : "none"}`,
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            updateData()
                          }}
                        >
                          <CheckCircleIcon
                            fontSize="medium"
                            style={{
                              color: `${isAnyChanges ? "#4CAF50" : "#CCC"}`,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title={t("Edit")} placement="top">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation()
                            setEdit(true)
                          }}
                          disabled={isWriteAllowed ? false : true}
                        >
                          <Edit
                            size={20}
                            style={{
                              color: "rgba(158, 158, 158, 1)",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </div>
              ) : (
                ""
              )}{" "}
            </div>
            {collapseOpen ? (
              <IconButton>
                <ChevronUp style={{ color: "rgba(0, 0, 0, 1)" }} />
              </IconButton>
            ) : (
              <IconButton>
                <ChevronDown style={{ color: "rgba(0, 0, 0, 1)" }} />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Divider />
          <Collapse in={collapseOpen} timeout="auto">
            {locationType === "" && locationType === "" ? (
              <Features.NoRecordFound />
            ) : (
              <LocationTreeComponent
                edit={edit}
                locationSize={locationSize}
                locationType={locationType}
                locations={props.locationTypeList}
                setLocationSize={setLocationSize}
                setLocationType={setLocationType}
                locationTypeError={locationTypeError}
                locationSizeError={locationSizeError}
                setAllowPrompt={setAllowPrompt}
                setAnyChanges={setAnyChanges}
                isDetailView={true}
                isAnyChanges={isAnyChanges}
                storeList={storeList}
                setStoreType={setStoreType}
                storeType={storeType}
                storeTypeError={storeTypeError}
                SDCConfigured={oldState?.SDCConfigured}
                lg={2}
                lg1={4}
                lg2={6}
              />
            )}
          </Collapse>
        </Grid>
      </Card>
      <>
        {alert ? (
          <Adm.SnackBar open={true} message={alertMessage} type={alertType} />
        ) : null}
      </>
    </Grid>
  )
}
const EditLocationHierarchy = withTheme(EditLocationHierarchyKit)
export { EditLocationHierarchy }
export default EditLocationHierarchy
