import React from "react"
import { useTranslation } from "react-i18next"

function useConfigUI(props) {
  const {
    defaultList = {},
    onUpdate = () => "",
    getIsErrorIntiated = false,
    setIsErrorIntiated = () => "",
    getDefaultSampleCode,
  } = props || {}
  let getDefaultERPConfig =
    defaultList !== {}
      ? defaultList
      : {
          erpconfigName: "API",
          apiUrl: "apiUrl",
          connectionMethod: "Basic Authentication",
          userName: "userName",
          password: "password",
          token: "token",
          responseType: "JSON",
          statusCode: [{ code: 200, selected: true }],
          fileLocation: "fileLocation",
          statusErrorCode: [{ code: 500, selected: true }],
          fileType: "CSV",
          delimter: "%",
          sampleTestCode: ``,
        }
  const { t } = useTranslation()
  const [getERPConfig, setERPConfig] = React.useState()
  const [getMainDropdownList, setMainDropdownList] = React.useState([
    "API",
    "File",
  ])
  const [getCMDropdownList, setCMDropdownList] = React.useState([
    "Token Authentication",
    "Basic Authentication",
  ])
  const [getRTDropdownList, setRTDropdownList] = React.useState([
    "JSON",
    "XML",
    "Test",
    "CSV",
    "EDI",
  ])
  const [getFTDropdownList, setFTDropdownList] = React.useState(["CSV", "XML"])
  const [getDLDropdownList, setDLDropdownList] = React.useState([
    "%",
    "|",
    "||",
    "&",
    "#",
  ])
  const [getFTDropdownValue, setFTDropdownValue] = React.useState()
  const [getDLDropdownValue, setDLDropdownValue] = React.useState()
  const [getRTDropdownValue, setRTDropdownValue] = React.useState()
  const [getCMDropdownValue, setCMDropdownValue] = React.useState()
  const [getMainDropdownValue, setMainDropdownValue] = React.useState()

  React.useEffect(() => {
    setERPConfig(defaultList)
    setMainDropdownValue(getDefaultERPConfig?.erpconfigName)
    setCMDropdownValue(getDefaultERPConfig?.connectionMethod)
    setRTDropdownValue(getDefaultERPConfig?.responseType)
    setDLDropdownValue(getDefaultERPConfig?.delimter)
    setFTDropdownValue(getDefaultERPConfig?.fileType)
  }, [defaultList])

  const onChangeValueTextField = (eventProps) => {
    const { value = "", fieldName = "" } = eventProps
    let urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    if (!(value.length > 0)) {
      setIsErrorIntiated(true)
    }
    if (
      fieldName === "apiUrl" &&
      value?.length > 4 &&
      value?.match(urlRegex) === null
    ) {
      setIsErrorIntiated(true)
    }

    if (fieldName === "token") {
      var letters = /^[A-Za-z0-9 ]+$/
      if (value !== "" && value?.match(letters) === null) {
        eventProps.defaultPrevented = true
        return false
      } else if (value !== "" && value?.length < 4) {
        setIsErrorIntiated(true)
      }
    }
    if (fieldName === "password" && value?.length < 2) {
      setIsErrorIntiated(true)
    }
    let dataUpdate = getERPConfig
    dataUpdate[fieldName] = value
    setERPConfig({ ...dataUpdate })
    onUpdate({ updatedList: dataUpdate })
  }
  return {
    getMainDropdownList,
    setMainDropdownList,
    getCMDropdownList,
    setCMDropdownList,
    getCMDropdownValue,
    setCMDropdownValue,
    getMainDropdownValue,
    setMainDropdownValue,
    getRTDropdownList,
    setRTDropdownList,
    getRTDropdownValue,
    setRTDropdownValue,
    getDLDropdownList,
    setDLDropdownList,
    getDLDropdownValue,
    setDLDropdownValue,
    getFTDropdownList,
    setFTDropdownList,
    getFTDropdownValue,
    setFTDropdownValue,
    getERPConfig,
    setERPConfig,
    onUpdate,
    onChangeValueTextField,
    getIsErrorIntiated,
    setIsErrorIntiated,
    getDefaultSampleCode,
    t,
  }
}

export { useConfigUI }
