import { Formik } from "formik"
import {
  Card,
  Divider,
  List,
  ListItem,
  IconButton,
  CardHeader,
  Collapse,
  TextField,
  Button,
  ListItemText,
  Grid,
} from "@material-ui/core"
import { AddCircleOutlineIcon } from "@iconsGallery"
import _ from "lodash"
import { facetUIYupSchema } from "./yupSchema"
import { useFacetUIContentStyles } from "./styles"
import { borderTop } from "@material-ui/system"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

const FormUI = (formUIProps) => {
  const { t } = useTranslation()
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    touched,
    keyPath = "id",
    valuePath = "value",
    values,
  } = formUIProps
  const classes = useFacetUIContentStyles()
  const location = useLocation()

  const handleKeyPress = (e, type) => {
    if (type === "number") {
      if (e.which === 32) {
        e.preventDefault()
      }
    } else {
      if (e.which === 32 && e.target.value === "") {
        e.preventDefault()
      }
    }
  }

  return (
    <form
      noValidate
      onSubmit={handleSubmit}
      style={{
        padding: "20px",
        paddingTop: "0px",
        paddingBottom: "0px",
        paddingLeft:
          location.pathname === "/master-data/customers/create"
            ? "0px"
            : "16px",
        paddingRight: "16px",
      }}
    >
      {errors.submit && " "}
      <Grid
        container
        direction="row"
        xs={12}
        sm={12}
        lg={12}
        md={12}
        xl={10}
        spacing={4}
      >
        <Grid item direction="row" xs={4} sm={4} lg={4} md={4}>
          <TextField
            type="text"
            name={keyPath}
            placeholder={t("Name")}
            value={values[keyPath]}
            autoComplete="off"
            onKeyPress={(e) => handleKeyPress(e, "text")}
            error={Boolean(touched[keyPath] && errors[keyPath])}
            helperText={touched[keyPath] && t(errors[keyPath])}
            onBlur={handleBlur}
            onChange={handleChange}
            variant="outlined"
            size="small"
            className={`${classes.textFiled1} makeStyles-textFiled-support`}
          />
        </Grid>
        <Grid item direction="row" xs={4} sm={4} lg={4} md={4}>
          <TextField
            type="text"
            name={valuePath}
            placeholder={t("Value")}
            variant="outlined"
            autoComplete="off"
            onKeyPress={(e) => handleKeyPress(e, "text")}
            value={values[valuePath]}
            error={Boolean(touched[valuePath] && errors[valuePath])}
            helperText={touched[valuePath] && t(errors[valuePath])}
            onBlur={handleBlur}
            onChange={handleChange}
            size="small"
            className={`${classes.textFiled2} makeStyles-textFiled-support`}
          />
        </Grid>
        <Grid item direction="row" xs={3} sm={4} lg={4} md={4}>
          <Button
            variant="contained"
            type="submit"
            className={classes.addButton}
            startIcon={<AddCircleOutlineIcon />}
          >
            {t("Add")}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

function FormfacetUI(props) {
  const {
    setData,
    data,
    keyPath,
    valuePath,
    formValues = {
      [keyPath]: "",
      [valuePath]: "",
    },
    validationSchema,
    onSubmit,
  } = props || {}
  const formikAttr = {
    initialValues: { ...formValues },
    validationSchema: facetUIYupSchema(validationSchema),
    onSubmit: onSubmit,
  }
  const form = (formProps) => (
    <FormUI {...formProps} keyPath={keyPath} valuePath={valuePath} />
  )
  return <Formik {...formikAttr}>{form}</Formik>
}

export { FormfacetUI }
