import * as StyledDOM from "./styles"
import * as RenderProps from "./renderProps"
import { useTranslation } from "react-i18next"

function FrictionlessDashboard(props) {
  const { t } = useTranslation()
  const { mainStateDashboard = {} } = props
  return (
    <StyledDOM.StyledContainer>
      {RenderProps.renderRetailerUI({ mainStateDashboard, t })}
    </StyledDOM.StyledContainer>
  )
}

export { FrictionlessDashboard }
export default FrictionlessDashboard
