import Switch from "@material-ui/core/Switch"
import React, { useEffect } from "react"
import { FormControlLabel } from "@material-ui/core"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import clsx from "clsx"

const StyledSwitch = withStyles({

  switchBase: {
    color: "rgba(158, 158, 158, 1)",
  },
  track: {
    backgroundColor: "rgba(158, 158, 158, 1)",
    width: "34px",
    height: "14px",
  },
  thumb: {
    width: "20px",
    height: "20px",
  },
})(Switch)

const commonStyles = makeStyles((theme) => ({
  clsLocationSwitch: {
    '& .MuiSwitch-switchBase': {
      color: 'rgba(158, 158, 158, 1) !important',
    },
    '& .MuiSwitch-track': {
      backgroundColor: 'rgba(158, 158, 158, 1) !important',
    },
    '& .Mui-checked.MuiSwitch-switchBase': {
      color: 'var(--primaryColor) !important',
    },
    '& .Mui-checked.MuiSwitch-switchBase + .MuiSwitch-track': {
      backgroundColor: 'var(--primaryColor) !important',
    },
  },
}))

function ToggleButton(props) {
  const classes = commonStyles()
  const { handleChange, checked, value, name, labelPlacement, disabled = false, className = "", isNoLabel = false, style = {} } =
    props || {}

  return (
    <FormControlLabel
      style={style}
      className={className}
      control={
        <StyledSwitch
          className={clsx(className, [classes.clsLocationSwitch])}
          onClick={handleChange}
          checked={checked}
          defaultValue={value}
          name={name}
          disabled={disabled}
        />
      }
      label={isNoLabel ? undefined : name}
      labelPlacement={labelPlacement}
    />
  )
}

export { ToggleButton }
export default ToggleButton
