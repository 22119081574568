import * as React from "react"
import { IconButton, CardHeader, Tooltip } from "@material-ui/core"
import {
  Edit,
  Slash,
  CheckCircleIcon,
  ExpandMoreIcon,
  ExpandLessIcon,
} from "@iconsGallery"
import _ from "lodash"
import { View } from "./View"
import * as Features from "@features"
import * as Utils from "@utils"

export const customRenderContainer = (props) => {
  const { facetData, isEditMode, edit, setAllowPrompt = () => {} } = props
  return Utils.JSUtils.isEmpty(facetData) ? (
    isEditMode && !edit ? (
      <View {...props} setAllowPrompt={setAllowPrompt} />
    ) : (
      <Features.NoRecordFound />
    )
  ) : (
    <View {...props} setAllowPrompt={setAllowPrompt} />
  )
}

export const customRenderHeader = (props) => {
  const {
    classes,
    setEdit,
    expanded,
    handleExpandClick,
    updateData,
    setAllowPrompt = () => "",
    resetData = () => "",
    isEditMode = true,
    label = "",
    isAnyChange,
    setAnyChange,
    isWriteAllowed,
    t,
  } = props || {}
  return (
    <CardHeader
      style={{ borderBottom: "1px solid #EEEEEE", cursor: "pointer" }}
      className={expanded === false ? classes.dividerBottom : ""}
      title={<div style={{ fontWeight: "bold" }}>{t(label)}</div>}
      onClick={() => {
        setEdit(true)
        resetData()
        handleExpandClick()
        setAllowPrompt(false)
      }}
      action={
        <>
          {props.edit ? (
            <>
              {expanded === true && isEditMode === true && (
                <Tooltip title={t("Edit")} placement="top">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation()
                      setEdit(false)
                      setAllowPrompt(true)
                    }}
                    style={{ marginRight: "13px" }}
                    disabled={isWriteAllowed ? false : true}
                  >
                    <Edit className={classes.basicIconProperty} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          ) : (
            <>
              {expanded === true ? (
                <>
                  <Tooltip title={t("Close")} placement="top">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation()
                        resetData()
                        setAllowPrompt(false)
                        setAnyChange(false)
                        setEdit(true)
                      }}
                      style={{ marginRight: "20px" }}
                    >
                      <Slash
                        style={{ color: "red" }}
                        className={classes.basicIconProperty}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("Save")} placement="top">
                    <IconButton
                      style={{
                        marginRight: "18px",
                        pointerEvents: `${isAnyChange ? "unset" : "none"}`,
                      }}
                      onClick={(e) => {
                        e.stopPropagation()
                        updateData(props)
                        setEdit(true)
                        setAnyChange(false)
                      }}
                    >
                      <CheckCircleIcon
                        style={{ color: `${isAnyChange ? "#4CAF50" : "#CCC"}` }}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                ""
              )}
            </>
          )}
          {expanded === true ? (
            <IconButton>
              <ExpandLessIcon className={classes.iconUpDown} />
            </IconButton>
          ) : (
            <IconButton>
              <ExpandMoreIcon className={classes.iconUpDown} />
            </IconButton>
          )}
        </>
      }
    ></CardHeader>
  )
}
