import React from "react"
import {
  Avatar,
  Grid,
  TextField,
  Typography,
  Card,
  Divider,
  List,
  ListItem,
  IconButton,
  CardHeader,
  Collapse,
  Button,
  ListItemText,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import * as Adm from "@adm"
import {
  AddCircleOutlineIcon,
  CheckCircleIcon,
  Edit,
  Slash,
} from "@iconsGallery"
import _ from "lodash"
import styled from "styled-components"
import { renderSetDefaultValue } from "../CustomTableUI/renderProps"
import * as StyledDOM from "./styles"

const renderTextField = (props) => {
  const {
    setData,
    data,
    formValues,
    formErrors,
    formTouched,
    handleBlur,
    setFieldValue,
    updateFormData,
    setAllowPrompt = () => {},
  } = props || {}

  const { firstName = "", middleInitial = "", lastName = "", associate_flag } =
    formValues || {}
  const handleKeyPress = (e, type) => {
    if (type === "number") {
      if (e.which === 32) {
        e.preventDefault()
      }
    } else {
      if (e.which === 32 && e.target.value === "") {
        e.preventDefault()
      }
    }
  }
  return (
    // <asside>
    <Grid item direction="column">
      <Grid style={{ display: "flex" }}>
        <StyledDOM.TextFiledWrapper
          type="text"
          name="firstName"
          placeholder="FirstName"
          onKeyPress={(e) => handleKeyPress(e, "text")}
          defaultValue={firstName}
          error={Boolean(formTouched.firstName && formErrors.firstName)}
          helperText={formTouched.firstName && formErrors.firstName}
          onBlur={handleBlur}
          onChange={(event) => {
            if (firstName !== null) {
              setAllowPrompt(true)
            }
            setFieldValue("firstName", event.target.value)
            updateFormData("firstName", event.target.value)
          }}
          variant="outlined"
          size="small"
        />
        <StyledDOM.TextFiledWrapper
          type="text"
          name="middleInitial"
          variant="outlined"
          placeholder="Middle Name"
          onKeyPress={(e) => handleKeyPress(e, "text")}
          defaultValue={middleInitial}
          error={Boolean(formTouched.middleInitial && formErrors.middleInitial)}
          helperText={formTouched.middleInitial && formErrors.middleInitial}
          onBlur={handleBlur}
          onChange={(event) => {
            if (middleInitial !== null) {
              setAllowPrompt(true)
            }
            setFieldValue("middleInitial", event.target.value)
            updateFormData("middleInitial", event.target.value)
          }}
          size="small"
        />
        <StyledDOM.TextFiledWrapper
          type="text"
          name="lastName"
          placeholder="LastName"
          onKeyPress={(e) => handleKeyPress(e, "text")}
          variant="outlined"
          defaultValue={lastName}
          error={Boolean(formTouched.lastName && formErrors.lastName)}
          helperText={formTouched.lastName && formErrors.lastName}
          onBlur={handleBlur}
          onChange={(event) => {
            if (lastName !== null) {
              setAllowPrompt(true)
            }
            setFieldValue("lastName", event.target.value)
            updateFormData("lastName", event.target.value)
          }}
          size="small"
        />
      </Grid>
      <Grid>
        <StyledDOM.DropDownGrid>
          <Adm.DropdownKit
            list={["Associate Member", "Non-Associate Member"]}
            defaultValue={
              associate_flag ? "Associate Member" : "Non-Associate Member"
            }
            error={Boolean(
              formTouched.associate_flag && formErrors.associate_flag
            )}
            style={{ paddingTop: "25px", width: "250px" }}
            onUpdate={(value) => {
              const flag =
                value.toLocaleLowerCase() === "associate member"
                  ? true
                  : value.toLocaleLowerCase() === "non-associate member"
                  ? false
                  : false
              if (flag !== null) {
                setAllowPrompt(true)
              }
              setFieldValue("associate_flag", flag)
              updateFormData("associate_flag", flag)
            }}
          />
        </StyledDOM.DropDownGrid>
      </Grid>
    </Grid>
  )
}

const renderLeftPanel = (props) => {
  const { classesUI, ...remainingProps } = props || {}

  const renderInfo = (props) => {
    let { isEdit, data } = props
    var str = _.get(props, "data.firstName", "-")
    var AvatarText = str.charAt(0)

    const flag = _.get(props, "data.associate_flag", false)

    return (
      <StyledDOM.ProfileInfo className={classesUI.address}>
        <Avatar className={classesUI.avatar}>{AvatarText.toUpperCase()}</Avatar>
        {isEdit ? (
          <>{renderTextField({ ...props, ...remainingProps })}</>
        ) : (
          <StyledDOM.ProfileDetail>
            <Typography variant="h3" style={{ fontWeight: "bold" }}>
              {_.get(props, "data.firstName", "")}{" "}
              {_.get(props, "data.middleInitial", "")}{" "}
              {_.get(props, "data.lastName", "")}
            </Typography>
            <Typography
              className={classesUI.heading2}
              style={{
                marginTop: "14px",
                textAlign: "center",
                padding: "0px 5px",
              }}
            >
              {flag ? "Associate Member" : "Non-Associate Member"}
            </Typography>
          </StyledDOM.ProfileDetail>
        )}
      </StyledDOM.ProfileInfo>
    )
  }

  const renderTypes = (props) => {
    const loyaltyTier = _.get(props, "data.loyalty_tier", "Silver Tier-1")
    var tierName = loyaltyTier.split(" Tier-").shift()
    var tierRank = loyaltyTier.split("Tier-").pop()
    const attr = { tierType: tierName, tierLevel: tierRank }

    return <Adm.LoyaltyTier {...attr} />
  }

  return (
    <>
      <Grid
        item
        lg={7}
        md={12}
        sm={12}
        xs={12}
        direction="row"
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
        }}
      >
        {renderInfo(props)}
      </Grid>

      <StyledDOM.ProfileMainWrapper
        style={{
          borderBottom: "1px solid rgba(0, 0, 0, 0)",
          borderTop: "1px solid rgba(10, 10, 10, 0.1)",
        }}
      >
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          direction="row"
          // justify="space-between"
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderRight: "1px solid rgba(47, 72, 88, 0.15)",
            padding: "20px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            paddingLeft: "22px",
          }}
        >
          {/* {renderInfo(props)} */}
          {renderTypes(props)}
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          direction="column"
          style={{ position: "relative" }}
        >
          {RenderCustomerValues(props)}
        </Grid>
      </StyledDOM.ProfileMainWrapper>
    </>
  )
}

const RenderCustomerValues = (props) => {
  const {
    isEdit,
    setIsEdit,
    data,
    updateDataInternally,
    updateFormData,
    resetData,
    isSaveFlag,
    setSaveFlag,
  } = props || {}
  const classes = StyledDOM.useRenderCustomerValuesStyles()

  const renderCustomer = (props) => {
    return (
      <StyledDOM.MainWrapper
        container
        lg={8}
        sm={12}
        md={12}
        xs={12}
        style={{
          paddingTop: "30px",
          borderBottom: "1px solid rgba(10, 10, 10, 0.1)",
        }}
      >
        <Grid
          item
          lg={12}
          direction="row"
          style={{
            borderBottom: "0.1em solid rgba(0, 0, 0, 0.1)",
            //padding: "10px",
            alignItems: "center",
            display: "flex",
            marginBottom: "16px",
          }}
        >
          <Grid
            item
            lg={5}
            style={{ paddingLeft: "10px", paddingBottom: "15px" }}
          >
            <Typography
              variant="h6"
              style={{ fontSize: "14px", fontWeight: "bold" }}
            >
              Customer Number
            </Typography>
          </Grid>
          <Grid item lg={4} style={{ paddingBottom: "15px" }}>
            <Typography
              className={classes.text1}
              style={{ textAlign: "left", fontWeight: "400" }}
            >
              {_.get(props, "data.customer_id", "-")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          lg={12}
          direction="row"
          style={{
            padding: "10px",
            alignItems: "center",
            display: "flex",
            marginBottom: "5px",
          }}
        >
          <Grid item lg={5}>
            <Typography
              variant="h6"
              style={{ fontSize: "14px", fontWeight: "bold" }}
            >
              Customer Number Alias
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography className={classes.text2} style={{ fontWeight: "400" }}>
              {_.get(props, "data.customer_number_alias", "-")}
            </Typography>
          </Grid>
        </Grid>
      </StyledDOM.MainWrapper>
    )
  }
  return renderCustomer(props)
}

export {
  renderLeftPanel,
  renderTextField,
  RenderCustomerValues as renderRightPanel,
}
