import { Switch, Box, Tooltip } from "@material-ui/core"
import React from "react"
import customTableStyles from "../../styles/components/customTable/styles"
import _ from "lodash"
import { useTranslation } from "react-i18next"

function SwitchCell(props) {
  const { t } = useTranslation()
  const { custom = false, tooltipContent = "" } = props
  const data = props.dataItem
  const dataValue = props.value
  const selectedItemIndex =
    props?.filterData?.findIndex(
      (a) => a.value?.toString() === dataValue?.toString()
    ) || 0
  const classes = customTableStyles()
  let success =
    _.find(props?.filterData, { value: "true" }) ||
    _.find(props?.filterData, { value: true })
  let error =
    _.find(props?.filterData, { value: "false" }) ||
    _.find(props?.filterData, { value: false })
  return (
    <td>
      {data.inEdit ? (
        <Switch
          className={classes.clsLocationSwitch}
          checked={dataValue}
          onChange={props.onChange}
          value={dataValue}
        />
      ) : custom ? (
        <>
          {props.renderBox && selectedItemIndex === 0 ? (
            <Box
              className={`${classes.enabledBox} makeStyles-enabledBox-support`}
            >
              {success?.tab}
            </Box>
          ) : tooltipContent ? (
            <Tooltip
              title={<p>{tooltipContent}</p>}
              placement="bottom-center"
              classes={{ tooltip: classes.customTooltip }}
              arrow
            >
              <Box
                className={
                  error?.color === "error"
                    ? `${classes.errorBox} makeStyles-disabledBox-support`
                    : `${classes.disabledBox} makeStyles-disabledBox-support`
                }
              >
                {error?.tab}
              </Box>
            </Tooltip>
          ) : (
            <Box
              className={
                error?.color === "error"
                  ? classes.errorBox
                  : `${classes.disabledBox} makeStyles-disabledBox-support`
              }
            >
              {error?.tab}
            </Box>
          )}
        </>
      ) : (
        <>
          {props.renderBox && selectedItemIndex === 0 ? (
            <Box
              className={`${classes.enabledBox} makeStyles-enabledBox-support`}
            >
              {t("Enabled")}
            </Box>
          ) : (
            <Box
              className={`${classes.disabledBox} makeStyles-disabledBox-support`}
            >
              {t("Disabled")}
            </Box>
          )}
        </>
      )}
    </td>
  )
}

export default SwitchCell
