import { TextField } from "@material-ui/core"
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import DateFnsUtils from "@date-io/date-fns"
import "date-fns"
import frLocale from "date-fns/locale/fr"
import esLocale from "date-fns/locale/es"
import { useTranslation } from "react-i18next"

let local = { es: esLocale, fr: frLocale }
let lang = localStorage.getItem("i18nextLng")

const CustomDateTimePicker = (props) => {
  const {
    field = "",
    value = "",
    handleDateChange = () => {},
    onClearButtonClick = () => {},
    selectedDate = "",
    selectedTime = "",
  } = props
  const { t } = useTranslation()
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={local[lang]}>
          <KeyboardDatePicker
            className={"deviceDatepicker"}
            style={{ width: "100%", marginRight: "25px" }}
            disableToolbar
            variant="inline"
            format="MMM dd yyyy"
            placeholder={t("MMM dd yyyy")}
            id="date-picker-inline"
            value={selectedDate}
            onChange={(e) => handleDateChange(e, field, "date")}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
        <button
          className="k-button k-button-icon k-clear-button-visible"
          title="Clear"
          style={{ visibility: value.value !== "" }}
          onClick={() => {
            onClearButtonClick(field)
          }}
        >
          <span className="k-icon k-i-filter-clear" />
        </button>
      </div>
    </>
  )
}
export default CustomDateTimePicker
