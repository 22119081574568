import React, { useState, useEffect } from "react"
import _ from "lodash"
import useStyles from "./styles"
import * as Adm from "@adm"
import MaterialDataGrid from "../../../../components/MaterialDataGrid"
import TableFilter from "../../../../components/tableFilter"
import api from "../../../../services/masterData/locations/api"
import { CloseRounded } from "@material-ui/icons"
import {
  Button,
  Divider,
  AppBar,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
  Card,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core"
import update from "immutability-helper"
import classnames from "classnames"
import { Trash2, SearchIcon, CloseIcon, SearchOutlined } from "@iconsGallery"
import { ConfirmationDialog } from "@features"
import styled from "styled-components"
import AttributeLookUpTable from "../../products/ProductDetailedPage/productAttribute/AttributeLookUpTable"
import AttributeTypeCheck from "../../products/ProductDetailedPage/productAttribute/AttributeTypeCheck"
import { EditAttributes } from "@material-ui/icons"
import { productDataApiMethods } from "../../../../services/masterData/products/api"
import moment from "moment"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { decryptData } from "../../../../components/AdmKit/Decoding/Decryption"

export const renderOnEdit = (
  props,
  {
    history,
    reDirectPath = "",
    editVariable = "",
    tableGlobalState = {},
    isType = "",
    isCustomFunc = false,
  },
  gridState
) => {
  const { rowData = {}, setRowData = () => { } } = tableGlobalState || {}
  const newRowDataState = {
    ...rowData,
  }

  newRowDataState["editID"] = props.dataItem._id
  setRowData(newRowDataState)
  let urlPush = ""
  if (isType === "singel") {
    const preDefinedId = props.dataItem[editVariable] || ""
    urlPush = reDirectPath + encodeURIComponent(preDefinedId)
  }
  if (isType === "group") {
    let addUrl = ""
    const splitReDirectPath = reDirectPath.split("/")
    splitReDirectPath.forEach((item) => {
      if (item !== "") {
        let setItem = ""
        if (item.includes("=")) {
          const splitEqual = item.split("=")
          setItem = `${splitEqual[0]}=${props.dataItem[splitEqual[1]]}`
        } else {
          setItem = item
        }
        addUrl = addUrl + "/" + setItem
      }
    })
    urlPush = addUrl
  }
  urlPush !== "" &&
    history.push(urlPush, {
      state: { urlGridState: gridState },
    })
}
const TemplateTableHeaderGrid = styled(Grid)`
  .ecom-card-table-header {
    border-bottom: none;
    border-radius: 5px 5px 0 0 !important;
  }
`
const PageHeaderBreadcrumbKit = styled(Grid)`
  button {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 18px;
    padding-right: 18px;
    max-width: unset;
  }
  margin-bottom: 24px;
  border-bottom: 1px #e0e0e0 solid;
  padding-bottom: 15px;
`
const SearchBox = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: "4px 0px 0px 4px",
  },
}))
const columnData = [
  {
    field: "locationName",
    title: "Location Name",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
  },
  {
    field: "locationAddress.line1",
    title: "Location Address",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
  },
  {
    field: "locationType.name",
    title: "Location Type",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
  },
]

const defaultDataState = {
  filter: {
    logic: "and",
    filters: [],
  },
  sort: [],
  take: 10,
  skip: 0,
}

const defaultBodyData = {
  page: 1,
  limit: 10,
  sort: {},
  filter: {},
}

const renderUpdateTableFilterData = (item, stateProps) => {
  const { columnData, setColumnData } = stateProps || {}
  const newColumnData = [...columnData]
  newColumnData.map((val) => {
    if (val.field === item.field) {
      val.show = !val.show
    }
    return {}
  })
  setColumnData(newColumnData)
}

const Table = ({
  isWriteAllowed,
  errorMessage = {},
  dataFetchMethod = () => { },
  deleteAnId = () => { },
  deleteMultipleId = () => { },
  isPageView = false,
  lookupType = "",
  noShowPrimaryWithoutSelect = false,
  columns = columnData,
  additionalButtons = [],
  customEditFunc = false,
  handleCustomFunc = () => { },
  onDiscard = () => { },
  onSave = () => { },
  onDelete = () => { },
  onView = () => { },
  onEdit = () => { },
  onCopy = () => { },
  onItemChange = () => { },
  onDropDownCellClick = () => { },
  responseDataPath = "data.data",
  isLookUp = false,
  onPrimaryActionClick = () => { },
  handleClose = () => { },
  preSelectedItems = [],
  triggerDataFetch = { uncategorized: false, categorized: false },
  setAnyChanges = () => { },
  setTriggerDataFetch = () => { },
  dataFetchTriggerKey = "uncategorized",
  title = "UnCategorized Locations",
  selectedHierarchyIds = [],
  tableLocationAttributes = {},
  fetchOnlyOnSelectedIdUpdate = false,
  dataUniqueIdPath = "_id",
  primaryActionLabel = "Associate",
  showSearchBar,
  setExportGridData = () => { },
  isTablet,
  isMobile,
  additionalFilters = [
    { field: "locationHierarchy", operator: "eq", value: "" },
  ],
  tableFilter = true,
  ShowCheckBox = true,
  isStatic = false,
  reRender = false,
  menutype = "master",
  pageable = true,
  isCreating = false,
  actionMenu = true,
  switchHandler = () => { },
  isDisableViewIcon = false,
  isDisableDeleteButton = false,
  isDisableEditIcon = true,
  customSearchComponent = null,
  dateFilter = false,
  showDeleteIcon = false,
  methodType = "",
  urlGridState = {},
  redirectPage = false,
  set_reRender = () => { },
  showCopyIcon = false,
  handleSingleDropdownChange = () => { },
  fileId = "",
  isBulkUploadLog = false,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const fetchMethod = _.isFunction(dataFetchMethod)
    ? dataFetchMethod
    : api.getAllLocations
  const [columnData, setColumnData] = useState(columns)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [reDirect, setReDirect] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isdelete, setIsdelete] = useState(false)
  const [gridState, setGridState] = useState({ dataState: defaultDataState })
  const [selectedItems, setSelectedItems] = useState(
    () => preSelectedItems || []
  )
  const [rawData, setRawData] = useState({})
  const [gridData, setGridData] = useState({ data: [] })
  const [attSearchVal, setAttSearchVal] = useState("")

  useEffect(() => {
    async function handlesetProductGrid() {
      if (window?.redirectPage && !_.isEmpty(urlGridState)) {
        await setGridState(urlGridState)
        window["redirectPage"] = false
        await set_reRender(true)
      }
    }
    handlesetProductGrid()
  }, [urlGridState])

  const adopterFunction = (dataState, page, limit, additionalFilters = []) => {
    let sort = {}

    if (isLookUp && lookupType === "elasticSearch") {
      sort = dataState.sort.reduce((acc, o) => {
        acc[o.field] = o.dir
        return acc
      }, {})
      return {
        page,
        limit,
        filter: [
          ...(dataState?.filter?.filters || []),
          ...(additionalFilters || []),
        ],
        sort,
        searchTerm: attSearchVal,
      }
    } else if (isBulkUploadLog) {
      let returnObj = {
        page,
        limit,
        filter: [],
        sort: {},
        tenant_id: localStorage.getItem("tenantId") || "",
        file_Id: fileId,
      }
      console.log("<< return obj: ", returnObj)
      return returnObj
    } else {
      sort = dataState.sort.reduce((acc, o) => {
        acc[o.field] = o.dir === "asc" ? 1 : -1
        return acc
      }, {})
      return {
        page,
        limit,
        filter: [
          ...(dataState?.filter?.filters || []),
          ...(additionalFilters || []),
        ],
        sort,
      }
    }
  }

  useEffect(() => {
    if (isCreating) {
      setGridData({
        ...gridData,
        data: [
          {
            inEdit: true,
            isActive: true,
            isRowEditable: true,
            createdAt: moment().format("YYYY-MM-DDTHH:mm:ss"),
            _id: "new",
            accessType: "terminal",
            updatedBy: "-",
            setFlag: "-",
            createdBy: "-",
            saveBTNdisabled: true,
          },
          ...gridData.data,
        ],
      })
    }
  }, [isCreating])

  const fetchDataObject = async (dateFilter = false) => {
    setLoading(true)

    fetchMethod(
      adopterFunction(
        gridState.dataState,
        Math.floor(gridState.dataState.skip / gridState.dataState.take) + 1,
        gridState.dataState.take,
        additionalFilters
      )
    )
      .then(async (resp) => {
        let bothApiCalls
        const hashedMessage = _.get(resp, responseDataPath)
        let responseData = decryptData(hashedMessage);
        if (lookupType === "elasticSearch" && isLookUp) {
          let tempDocs = []
          responseData?.results?.map((o) =>
            tempDocs.push({ ...o._source, _id: o._id })
          )
          bothApiCalls = {
            docs: tempDocs,
            totalDocs: _.get(responseData, "total.value"),
          }
        } else {
          bothApiCalls = responseData?.data ? responseData?.data : responseData
        }
        setLoading(false)

        setExportGridData({
          ...gridState.dataState,
          totalDocs: responseData?.totalDocs,
          page:
            Math.floor(gridState.dataState.skip / gridState.dataState.take) + 1,
          limit: gridState.dataState.take,
          tableRowData: responseData?.docs || [],
        })

        await setRawData(bothApiCalls)
      })
      .catch((err) => {
        console.log("<< return err: ", err)
        setLoading(false)
      })
  }

  useEffect(() => {
    const asyncFetchDatareRender = async () => {
      if (dateFilter && reRender && !isStatic) {
        fetchDataObject(dateFilter)
      } else if (reRender && !isStatic) {
        fetchDataObject()
      } else if (reRender && isStatic) {
        setLoading(true)
        const tempData = await fetchMethod(tableFilter)
        await setRawData(_.get(tempData, responseDataPath))
        await setLoading(false)
      }
    }
    asyncFetchDatareRender()
  }, [reRender])

  useEffect(() => {
    if (dateFilter) {
      setGridState({
        dataState: {
          ...gridState.dataState,
          skip: 0,
        },
      })
    }
  }, [dateFilter])

  // this is changed only to access static data page for userManagement
  useEffect(() => {
    async function asyncFetchData() {
      if (dateFilter && !isStatic) {
        return null
      } else if (isStatic && methodType === "post") {
        await fetchDataObject()
      } else if (!isStatic) {
        await fetchDataObject()
      } else if (isStatic) {
        setLoading(true)
        const tempData = await fetchMethod(tableFilter)
        await setLoading(false)
        await setRawData(_.get(tempData, responseDataPath))
      }
    }
    if (!window?.redirectPage) {
      asyncFetchData()
    }
  }, [gridState.dataState])

  useEffect(() => {
    setAttSearchVal(attSearchVal)
  }, [attSearchVal])

  useEffect(() => { }, [attSearchVal])
  // this is changed only to access static data page for userManagement
  useEffect(() => {
    if (!isStatic) {
      if (
        triggerDataFetch[dataFetchTriggerKey] ||
        fetchOnlyOnSelectedIdUpdate
      ) {
        fetchMethod(
          adopterFunction(
            gridState.dataState,
            Math.floor(gridState.dataState.skip / gridState.dataState.take) + 1,
            gridState.dataState.take,
            additionalFilters
          )
        )
          .then((resp) => {
            setRawData(_.get(resp, responseDataPath))
            setTriggerDataFetch((c) => ({ ...c, [dataFetchTriggerKey]: false }))
          })
          .catch((err) => { })
      }
    }
  }, [triggerDataFetch[dataFetchTriggerKey], selectedHierarchyIds])

  useEffect(() => {
    const handleGridData = async () => {
      if (!_.isEmpty(rawData)) {
        await setGridData(() => {
          let x = _.cloneDeep(_.get(rawData, "docs", [])) //Object.assign([], _.get(rawData, 'docs', []));
          let data = x.reduce((acc, o, i) => {
            if (
              _.findIndex(
                selectedItems,
                (c) =>
                  _.get(o, dataUniqueIdPath, "*_*") ===
                  _.get(c, dataUniqueIdPath, "-_-")
              ) !== -1
            ) {
              o.selected = true
            }
            acc.push(o)
            return acc
          }, [])
          return { data }
        })
      }
    }
    handleGridData()
  }, [rawData])

  const handleSelection = (e) => {
    let temp = []
    let tempGridData = {}
    let dataItem = e.dataItem
    let existingIdx = _.findIndex(
      selectedItems,
      (o) =>
        _.get(o, dataUniqueIdPath, "*_*") ===
        _.get(dataItem, dataUniqueIdPath, "-_-")
    )
    let idxInDataState = _.findIndex(
      gridData.data,
      (o) =>
        _.get(o, dataUniqueIdPath, "*_*") ===
        _.get(dataItem, dataUniqueIdPath, "-_-")
    )
    if (existingIdx === -1) {
      temp = update(selectedItems, { $push: [dataItem] })
      tempGridData = update(gridData, {
        data: { [idxInDataState]: { $merge: { selected: true } } },
        allSelected: { $set: false },
      })
    } else {
      temp = update(selectedItems, { $splice: [[existingIdx, 1]] })
      tempGridData = update(gridData, {
        data: { [idxInDataState]: { $merge: { selected: false } } },
        allSelected: { $set: false },
      })
    }
    setSelectedItems(temp)
    setGridData(tempGridData)
  }

  const deleteItems = async (selectedItems) => {
    await deleteMultipleId(selectedItems)
    await setSelectedItems([])
    await fetchDataObject()
  }

  const handleClearSelection = () => {
    setSelectedItems([])
    setGridData((c) => {
      return {
        data: c.data.map((x) => {
          x.selected = false
          return x
        }),
      }
    })
  }

  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target
    const checked = checkboxElement.checked
    if (checked) {
      setGridData((c) => {
        let data = _.map(c?.data || [], (o, i) => {
          o.selected = true
          return o
        })
        setSelectedItems((s) => {
          let temp = _.isEmpty(s) ? [] : _.cloneDeep(s)
          // temp.concat(data);
          return _.uniqBy([...temp, ...data], dataUniqueIdPath)
        })
        return {
          allSelected: true,
          data,
        }
      })
    } else {
      setGridData((c) => {
        let data = c.data.map((x) => {
          x.selected = false
          return x
        })

        setSelectedItems((s) => {
          let filteredSelection = _.differenceBy(s, data, dataUniqueIdPath)
          return filteredSelection
        })
        return {
          data,
        }
      })
    }
  }, [])

  const handleCustomCloseRedirect = () => {
    setOpenConfirmationDialog(false)
    deleteItems(selectedItems)
  }

  const handleCloseButtonTrigger = () => {
    handleClearSelection()
  }
  return (
    <>
      <Adm.BackdropOverlay open={loading} />

      <ConfirmationDialog
        openConfirmationDialog={openConfirmationDialog}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        // isdelete={isdelete}
        setReDirect={setReDirect}
        handleCloseButtonTrigger={handleCloseButtonTrigger}
        handleCustomCloseRedirect={handleCustomCloseRedirect}
        deleteContent={`${t("Selected")} ${title.slice(0, -1)}(s) ${t(
          "would be deleted, do you really want to continue"
        )} `}
      />
      <div className={classes.container}>
        <div
          className={classnames(classes.header, {
            [classes.noPadding]: isPageView,
            [classes.height60]: isPageView,
          })}
        >
          <TemplateTableHeaderGrid lg={12} md={12} sm={12} xs={12}>
            <Card className="ecom-card-table-header">
              <Grid
                container
                row="true"
                justify="space-between"
                alignContent="center"
                alignItems="center"
                style={{
                  paddingLeft: "16px",
                  height: "60px",
                  flexWrap: "nowrap",
                }}
              >
                <Typography
                  variant="h4"
                  className={`${classnames(classes.title, {
                    [classes.headerTitle]: isPageView,
                  })} makeStyles-headerTitle-support makeStyles-title-support`}
                >
                  <span
                    className={
                      isBulkUploadLog ? "cls-unset" : "cls-plain-text-ui-kit"
                    }
                  >
                    {t(title)}
                  </span>
                </Typography>

                <>
                  {showSearchBar && (
                    <span style={{ display: "flex", width: "35vh" }}>
                      <SearchBox
                        size="small"
                        fullWidth
                        value={attSearchVal}
                        placeholder={t("Search")}
                        variant="outlined"
                        onChange={(e) => {
                          setAttSearchVal(
                            e.target.value?.replace(/(\s{2,})/g, " ")
                          )
                        }}
                      />
                      {/* <IconButton style={{ backgroundColor: "#1565C0", borderRadius: "0px 4px 4px 0px", width: "36px", height: "37.5px" }} size="small" variant="outlined" ></IconButton> */}
                      <IconButton
                        style={{
                          backgroundColor: "var(--primaryColor)",
                          borderRadius: "0px 4px 4px 0px",
                          width: "36px",
                          height: "37.5px",
                        }}
                        size="small"
                        variant="outlined"
                        onClick={() => fetchDataObject()}
                      >
                        <SearchIcon style={{ color: "white" }} />
                      </IconButton>
                    </span>
                  )}
                  {(selectedItems.length > 0 ||
                    gridData?.allSelected ||
                    isLookUp ||
                    (_.isArray(additionalButtons) &&
                      !_.isEmpty(additionalButtons))) && (
                      <HeaderActionsContainer
                        t={t}
                        columnData={columnData}
                        setColumnData={setColumnData}
                        noShowPrimaryWithoutSelect={noShowPrimaryWithoutSelect}
                        additionalButtons={additionalButtons}
                        isLookUp={isLookUp}
                        onPrimaryActionClick={() =>
                          onPrimaryActionClick(
                            selectedItems,
                            handleClearSelection
                          )
                        }
                        count={
                          selectedItems.length > 0
                            ? selectedItems.length
                            : gridData?.allSelected
                              ? gridData?.data?.length
                              : selectedItems.length
                        }
                        classes={classes}
                        label={primaryActionLabel}
                        handleClearSelection={handleClearSelection}
                        showSearchBar={showSearchBar}
                        setAnyChanges={setAnyChanges}
                        deleteItems={() => deleteItems(selectedItems)}
                        // handleAttribute={handleAttribute}
                        setOpenConfirmationDialog={setOpenConfirmationDialog}
                        lookupType={lookupType}
                      />
                    )}
                  <span>
                    {isLookUp && lookupType === "elasticSearch" ? (
                      <Tooltip title={t("Close")} placement="top">
                        <CloseRounded
                          style={{
                            width: "25px",
                            margin: "30px",
                            height: "30px",
                            marginTop: "30px",
                            color: "gray",
                          }}
                          onClick={handleClose}
                        />
                      </Tooltip>
                    ) : null}
                    {/* <Button onClick={handleClose}>Close</Button> */}
                  </span>
                  {tableFilter && (
                    <div style={{ padding: "20px" }}>
                      <TableFilter
                        tableFilterData={columnData}
                        updateTableFilterData={(item) => {
                          renderUpdateTableFilterData(item, {
                            columnData,
                            setColumnData,
                          })
                        }}
                      />
                      {_.isArray(additionalButtons) &&
                        !_.isEmpty(additionalButtons)
                        ? additionalButtons
                        : null}
                    </div>
                  )}
                </>
              </Grid>
            </Card>
          </TemplateTableHeaderGrid>
        </div>
        {_.isFunction(customSearchComponent) && (
          <Grid style={{ paddingBottom: "10px", backgroundColor: "#FFF" }}>
            {customSearchComponent()}
          </Grid>
        )}

        <div className={classes.content}>
          <MaterialDataGrid
            handleSingleDropdownChange={(e, props) =>
              handleSingleDropdownChange(e, { gridData, setGridData, ...props })
            }
            errorMessage={errorMessage}
            isDisableViewIcon={isDisableViewIcon}
            isInlineEditAllowed={isWriteAllowed}
            isDisableDeleteButton={isDisableDeleteButton}
            isDisableEditIcon={isDisableEditIcon}
            columnData={columnData}
            showDeleteIcon={showDeleteIcon}
            gridState={gridState}
            setGridState={setGridState}
            rowData={gridData}
            setRowData={setGridData}
            isLookup={isLookUp || !isPageView}
            number_of_items={_.toFinite(rawData?.totalDocs || 0)}
            selectionChange={(event) => handleSelection(event)}
            headerSelectionChange={(e) => onHeaderSelectionChange(e)}
            actionMenu={actionMenu}
            showCopyIcon={showCopyIcon}
            onEdit={(props) => {
              onEdit(props)
              customEditFunc
                ? handleCustomFunc({ ...props, gridData, setGridData })
                : renderOnEdit(props, tableLocationAttributes, gridState)
            }}
            onDiscard={(props) => {
              onDiscard({ ...props, gridData, setGridData })
            }}
            switchHandler={(event, props) => {
              switchHandler({ ...props, gridData, setGridData })
            }}
            onSave={(props) => {
              onSave({ ...props, gridData, setGridData })
            }}
            onDelete={(props) => {
              onDelete({ ...props, gridData, setGridData })
            }}
            onView={(props) => {
              onView({ ...props, gridData, setGridData })
            }}
            onCopy={(props) => {
              onCopy({ ...props, gridData, setGridData })
            }}
            menutype={menutype}
            tableFilter={tableFilter} // tableFilter is for remove filter option
            ShowCheckBox={ShowCheckBox} // checkbox in table
            pageable={pageable}
            itemChange={(e) => onItemChange(e, { gridData, setGridData })}
            onDropDownCellClick={(props) =>
              onDropDownCellClick({ ...props, gridData, setGridData })
            }
            isBulkUploadLog={isBulkUploadLog}
          />
          {/* } */}
        </div>
      </div>
    </>
  )
}

const HeaderActionsContainer = ({
  showSearchBar,
  onPrimaryActionClick = () => { },
  // handleAttribute = () => {},
  setAnyChanges = () => { },
  setOpenConfirmationDialog = () => { },
  columnData = [],
  setColumnData = () => { },
  noShowPrimaryWithoutSelect = false,
  additionalButtons = [],
  classes = {},
  count = 0,
  label = "Primary Action",
  isLookUp = false,
  handleClearSelection = () => { },
  deleteItems = () => { },
  lookupType = "",
  isMobile,
  t,
}) => {
  return (
    <>
      <div
        className={`${classes.headerActionsContainer} makeStyles-headerActionsContainer-support`}
      >
        {count > 0 ? (
          <Typography variant="h5" className={classes.subTitle}>
            {count} {count > 1 ? "" : " "}
            {t("Selected")}
          </Typography>
        ) : null}
        {isLookUp && lookupType === "elasticSearch" ? (
          <>
            <Button
              onClick={() => {
                if (count > 0) {
                  setAnyChanges(true)
                }
                onPrimaryActionClick()
              }}
              variant="contained"
              color="primary"
              style={{ backgroundColor: "var(--primaryColor)" }}
            >
              {t("ASSOCIATE")}
            </Button>
            <Button
              color="primary"
              style={{ fontWeight: "bold", color: "var(--primaryColor)" }}
              onClick={handleClearSelection}
            >
              {t("Clear")}
            </Button>
          </>
        ) : count > 0 ? (
          <>
            <Tooltip title={t("Delete")} placement="top">
              <IconButton onClick={() => setOpenConfirmationDialog(true)}>
                <Trash2 style={{ color: "var(--primaryColor)" }} />
              </IconButton>
            </Tooltip>
          </>
        ) : null}
      </div>
    </>
  )
}

export default Table
