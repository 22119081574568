import * as Utils from "@utils"
import { forEach, map } from "lodash"
import _ from "lodash"
import { useTranslation } from "react-i18next"

export const retailerList = (data) => {
  // const { t } = useTranslation()
  return data.map((itm) => {
    const {
      address = {},
      isActive = "",
      logo = "",
      storeType = "",
      tenantName = "",
      tenantId,
      total = 0,
      isPipelineConfigured = false,
      isConnectorConfigured = false,
    } = itm || {}

    const { line1 = "", region = {}, postalcode = "", country = {} } = address
    const { code: regionCode = "" } = region
    const { name: countryName = "" } = country

    const mainAddress = []

    if (!Utils.JSUtils.isEmpty(line1)) {
      mainAddress.push(line1)
    }
    if (!Utils.JSUtils.isEmpty(regionCode)) {
      mainAddress.push(regionCode)
    }
    if (!Utils.JSUtils.isEmpty(postalcode)) {
      mainAddress.push(postalcode)
    }
    if (!Utils.JSUtils.isEmpty(countryName)) {
      mainAddress.push(countryName)
    }

    let updateAddressString = Utils.JSUtils.isEmpty(mainAddress)
      ? ""
      : mainAddress.join(", ")

    let isConnector = false
    if (window !== "undefined") {
      isConnector = window.location?.pathname?.includes("connectorConfig")
    }

    let flagLock = isConnector ? isConnectorConfigured : isPipelineConfigured

    return {
      id: tenantId,
      title: Utils.JSUtils.isEmpty(tenantName) ? "" : tenantName,
      address: updateAddressString,
      status: isActive ? "Active" : "Inactive",
      image: Utils.JSUtils.isEmpty(logo)
        ? "https://via.placeholder.com/68.png?text=placeholder"
        : logo,
      count: total,
      flagLock,
    }
  })
}
// Tenant Autonomous Store
const dashboard_bstPerforming_read = "Top 10 Region By Sales - Read"
const dashboard_emp_read = "Employees - Read"
const dashboard_infras_read = "Infrastructure - Read"
const dashboard_lct_read = "Locations - Read"
const dashboard_suptInc_read = "Support Incidents - Read"
const dashboard_tenant_read = "Tenants - Read"
const dashboard_top10_prod_read = "Top 10 Product by Sale - Read"
const dashboard_top10_tenant_read = "Top 10 Tenants by Sale - Read"
const dashboard_totDev_read = "Total Devices - Read"
const dashboard_totSale_read = "Total Sales - Read"
const dashboard_location_based_alerts = "Location Based Alerts - Read"
const dashboard_location_based_severity = "Location Based Severity - Read"

const dashboardData_read = "Read"

const dashboardAS = "Dashboard Autonomous Retailers"
const dashboardFS = "Dashboard Frictionless Retailers"
const dashboardAlertsMonitor = "Alerts"

//Alert Monitor
const alert_monitor_read = "Monitor - Read"

const getDashboardAlertsMonitor = (res) => {
  let myObj = {}
  let myRes = res
  myRes &&
    Object.entries(myRes).forEach(([key, value]) => {
      if (key === alert_monitor_read) {
        myObj = { ...myObj, alert_monitor_read: value }
      }
    })
  return myObj
}

// Tenant User
const getAsDashboardData = (res) => {
  let myObj = {}
  let myRes = res
  myRes &&
    Object.entries(myRes).forEach(([key, value]) => {
      if (key === dashboard_bstPerforming_read) {
        myObj = { ...myObj, dashboard_bstPerforming_read: value }
      }
      if (key === dashboard_emp_read) {
        myObj = { ...myObj, dashboard_emp_read: value }
      }
      if (key === dashboard_infras_read) {
        myObj = { ...myObj, dashboard_infras_read: value }
      }
      if (key === dashboard_lct_read) {
        myObj = { ...myObj, dashboard_lct_read: value }
      }
      if (key === dashboard_suptInc_read) {
        myObj = { ...myObj, dashboard_suptInc_read: value }
      }
      if (key === dashboard_tenant_read) {
        myObj = { ...myObj, dashboard_tenant_read: value }
      }
      if (key === dashboard_top10_prod_read) {
        myObj = { ...myObj, dashboard_top10_prod_read: value }
      }
      if (key === dashboard_top10_tenant_read) {
        myObj = { ...myObj, dashboard_top10_tenant_read: value }
      }
      if (key === dashboard_totDev_read) {
        myObj = { ...myObj, dashboard_totDev_read: value }
      }
      if (key === dashboard_totSale_read) {
        myObj = { ...myObj, dashboard_totSale_read: value }
      }
      if (key === dashboard_location_based_alerts) {
        myObj = { ...myObj, dashboard_location_based_alerts: value }
      }
      if (key === dashboard_location_based_severity) {
        myObj = { ...myObj, dashboard_location_based_severity: value }
      }
    })

  return myObj
}

const getFsDashboardData = (res) => {
  let myObj = {}
  let myRes = res
  myRes &&
    Object.entries(myRes).forEach(([key, value]) => {
      if (key === dashboard_bstPerforming_read) {
        myObj = { ...myObj, dashboard_bstPerforming_read: value }
      }
      if (key === dashboard_emp_read) {
        myObj = { ...myObj, dashboard_emp_read: value }
      }
      if (key === dashboard_infras_read) {
        myObj = { ...myObj, dashboard_infras_read: value }
      }
      if (key === dashboard_lct_read) {
        myObj = { ...myObj, dashboard_lct_read: value }
      }
      if (key === dashboard_suptInc_read) {
        myObj = { ...myObj, dashboard_suptInc_read: value }
      }
      if (key === dashboard_tenant_read) {
        myObj = { ...myObj, dashboard_tenant_read: value }
      }
      if (key === dashboard_top10_prod_read) {
        myObj = { ...myObj, dashboard_top10_prod_read: value }
      }
      if (key === dashboard_top10_tenant_read) {
        myObj = { ...myObj, dashboard_top10_tenant_read: value }
      }
      if (key === dashboard_totDev_read) {
        myObj = { ...myObj, dashboard_totDev_read: value }
      }
      if (key === dashboard_totSale_read) {
        myObj = { ...myObj, dashboard_totSale_read: value }
      }
      if (key === dashboard_location_based_alerts) {
        myObj = { ...myObj, dashboard_location_based_alerts: value }
      }
      if (key === dashboard_location_based_severity) {
        myObj = { ...myObj, dashboard_location_based_severity: value }
      }
    })
  return myObj
}

export const tenantTransferModal = (res) => {
  const myAsData = getAsDashboardData(res[dashboardAS])
  const myFsData = getFsDashboardData(res[dashboardFS])
  const myDashboardAlertsMonitor = getDashboardAlertsMonitor(
    res[dashboardAlertsMonitor]
  )
  return {
    as: myAsData,
    fs: myFsData,
    homeAlertsMonitor: myDashboardAlertsMonitor,
  }
}

// For Sass Admin
const sassDashboard = "Dashboard"
const sassDashboardAS = "Autonomous Store"
const sassDashboardFS = "Frictionless Store"
const sassDashboardAutoRetailer = "Dashboard Autonomous Retailers"
const sassDashboardFriRetailer = "Dashboard Frictionless Retailers"

// SassAdmin Dashboard
const autonomousTab_read = "AutonomousTab - Read"
const dashboardTab_read = "DashboardTab - Read"
const frictionlessTab_read = "FrictionlessTab - Read"

// SassAdmin Dashboard Screen Data
const dashboard_sass_bstPerforming_read =
  "Best Performing Region By Sales - Read"
const dashboard_sass_emp_read = "Employees - Read"
const dashboard_sass_infras_read = "Infrastructure - Read"
const dashboard_sass_lct_read = "Locations - Read"
const dashboard_sass_suptInc_read = "Support Incidents - Read"
const dashboard_sass_tenant_read = "Tenants - Read"
const dashboard_sass_top10_prod_read = "Top 10 Product by Sale - Read"
const dashboard_sass_top10_tenant_read = "Top 10 Tenants by Sale - Read"
const dashboard_sass_totDev_read = "Total Devices - Read"
const dashboard_sass_totSale_read = "Total Sales - Read"

// SassAdmin Autonomous Store
const sassAs_planoMainAndMgt_read =
  "Planogram - Maintenance and Management - Read"
const sassAs_sdcCamera_read = "Smart Device Configuration - Camera - Read"
const sassAs_sdcCamera_write = "Smart Device Configuration - Camera - Write"
const sassAs_sdcDS_read = "Smart Device Configuration - Digit Shelves - Read"
const sassAs_sdcDS_write = "Smart Device Configuration - Digit Shelves - Write"
const sassAs_sdcGon_read = "Smart Device Configuration - Gondola - Read"
const sassAs_sdcGon_write = "Smart Device Configuration - Gondola - Write"
const sassAs_sdcSC_read = "Smart Device Configuration - Store Config - Read"
const sassAs_sdcSC_write = "Smart Device Configuration - Store Config - Write"
const sassAs_sdcTU_read = "Smart Device Configuration - Turnstiles - Read"
const sassAs_sdcTU_write = "Smart Device Configuration - Turnstiles - Write"

// SassAdmin Frictionless Store
const sassFs_planoMainAndMgt_read =
  "Planogram - Maintenance and Management - Read"
const sassFs_sdcCamera_read = "Smart Device Configuration - Camera - Read"
const sassFs_sdcCamera_write = "Smart Device Configuration - Camera - Write"
const sassFs_sdcED_read = "Smart Device Configuration - Edge Devices - Read"
const sassFs_sdcED_write = "Smart Device Configuration - Edge Devices - Write"

// SassAdmin Dashboard Autonomous Retailers

// SassAdmin Dashboard
const getSassDashboard = (res) => {
  let myObj = {}
  let myRes = res
  myRes &&
    Object.entries(myRes).forEach(([key, value]) => {
      if (key === autonomousTab_read) {
        myObj = { ...myObj, autonomousTab_read: value }
      }
      if (key === dashboardTab_read) {
        myObj = { ...myObj, dashboardTab_read: value }
      }
      if (key === frictionlessTab_read) {
        myObj = { ...myObj, frictionlessTab_read: value }
      }
    })
  return myObj
}

// SassAdmin Autonomous Store
const getSassAutonomous = (res) => {
  let myObj = {}
  let myRes = res
  myRes &&
    Object.entries(myRes).forEach(([key, value]) => {
      if (key === sassAs_planoMainAndMgt_read) {
        myObj = { ...myObj, sassAs_planoMainAndMgt_read: value }
      }
      if (key === sassAs_sdcCamera_read) {
        myObj = { ...myObj, sassAs_sdcCamera_read: value }
      }
      if (key === sassAs_sdcCamera_write) {
        myObj = { ...myObj, sassAs_sdcCamera_write: value }
      }
      if (key === sassAs_sdcDS_read) {
        myObj = { ...myObj, sassAs_sdcDS_read: value }
      }
      if (key === sassAs_sdcDS_write) {
        myObj = { ...myObj, sassAs_sdcDS_write: value }
      }
      if (key === sassAs_sdcGon_read) {
        myObj = { ...myObj, sassAs_sdcGon_read: value }
      }
      if (key === sassAs_sdcGon_write) {
        myObj = { ...myObj, sassAs_sdcGon_write: value }
      }
      if (key === sassAs_sdcSC_read) {
        myObj = { ...myObj, sassAs_sdcSC_read: value }
      }
      if (key === sassAs_sdcSC_write) {
        myObj = { ...myObj, sassAs_sdcSC_write: value }
      }
      if (key === sassAs_sdcTU_read) {
        myObj = { ...myObj, sassAs_sdcTU_read: value }
      }
      if (key === sassAs_sdcTU_write) {
        myObj = { ...myObj, sassAs_sdcTU_write: value }
      }
    })
  return myObj
}

// SassAdmin Frictionless Store
const getSassFrictionless = (res) => {
  let myObj = {}
  let myRes = res
  myRes &&
    Object.entries(myRes).forEach(([key, value]) => {
      if (key === sassFs_planoMainAndMgt_read) {
        myObj = { ...myObj, sassFs_planoMainAndMgt_read: value }
      }
      if (key === sassFs_sdcCamera_read) {
        myObj = { ...myObj, sassFs_sdcCamera_read: value }
      }
      if (key === sassFs_sdcCamera_write) {
        myObj = { ...myObj, sassFs_sdcCamera_write: value }
      }
      if (key === sassFs_sdcED_read) {
        myObj = { ...myObj, sassFs_sdcED_read: value }
      }
      if (key === sassFs_sdcED_write) {
        myObj = { ...myObj, sassFs_sdcED_write: value }
      }
    })
  return myObj
}

const getSassAsDashboardData = (res) => {
  let myObj = {}
  let myRes = res
  myRes &&
    Object.entries(myRes).forEach(([key, value]) => {
      if (key === dashboard_sass_bstPerforming_read) {
        myObj = { ...myObj, dashboard_sass_bstPerforming_read: value }
      }
      if (key === dashboard_sass_emp_read) {
        myObj = { ...myObj, dashboard_sass_emp_read: value }
      }
      if (key === dashboard_sass_infras_read) {
        myObj = { ...myObj, dashboard_sass_infras_read: value }
      }
      if (key === dashboard_sass_lct_read) {
        myObj = { ...myObj, dashboard_sass_lct_read: value }
      }
      if (key === dashboard_sass_suptInc_read) {
        myObj = { ...myObj, dashboard_sass_suptInc_read: value }
      }
      if (key === dashboard_sass_tenant_read) {
        myObj = { ...myObj, dashboard_sass_tenant_read: value }
      }
      if (key === dashboard_sass_top10_prod_read) {
        myObj = { ...myObj, dashboard_sass_top10_prod_read: value }
      }
      if (key === dashboard_sass_top10_tenant_read) {
        myObj = { ...myObj, dashboard_sass_top10_tenant_read: value }
      }
      if (key === dashboard_sass_totDev_read) {
        myObj = { ...myObj, dashboard_sass_totDev_read: value }
      }
      if (key === dashboard_sass_totSale_read) {
        myObj = { ...myObj, dashboard_sass_totSale_read: value }
      }
    })

  return myObj
}

const getSassFsDashboardData = (res) => {
  let myObj = {}
  let myRes = res
  myRes &&
    Object.entries(myRes).forEach(([key, value]) => {
      if (key === dashboard_sass_bstPerforming_read) {
        myObj = { ...myObj, dashboard_sass_bstPerforming_read: value }
      }
      if (key === dashboard_sass_emp_read) {
        myObj = { ...myObj, dashboard_sass_emp_read: value }
      }
      if (key === dashboard_sass_infras_read) {
        myObj = { ...myObj, dashboard_sass_infras_read: value }
      }
      if (key === dashboard_sass_lct_read) {
        myObj = { ...myObj, dashboard_sass_lct_read: value }
      }
      if (key === dashboard_sass_suptInc_read) {
        myObj = { ...myObj, dashboard_sass_suptInc_read: value }
      }
      if (key === dashboard_sass_tenant_read) {
        myObj = { ...myObj, dashboard_sass_tenant_read: value }
      }
      if (key === dashboard_sass_top10_prod_read) {
        myObj = { ...myObj, dashboard_sass_top10_prod_read: value }
      }
      if (key === dashboard_sass_top10_tenant_read) {
        myObj = { ...myObj, dashboard_sass_top10_tenant_read: value }
      }
      if (key === dashboard_sass_totDev_read) {
        myObj = { ...myObj, dashboard_sass_totDev_read: value }
      }
      if (key === dashboard_sass_totSale_read) {
        myObj = { ...myObj, dashboard_sass_totSale_read: value }
      }
    })
  return myObj
}

export const sassAdminTranferModal = (res) => {
  const myAsData = getSassAutonomous(res[sassDashboardAS])
  const myFsData = getSassFrictionless(res[sassDashboardFS])
  const myDashboardData = getSassDashboard(res[sassDashboard])
  const myDashBoardAS = getSassAsDashboardData(res[sassDashboardAutoRetailer])
  const myDashBoardFS = getSassFsDashboardData(res[sassDashboardFriRetailer])
  const myDashboardAlertsMonitor = getDashboardAlertsMonitor(
    res[dashboardAlertsMonitor]
  )
  return {
    as: myAsData,
    fs: myFsData,
    home: myDashboardData,
    homeAS: myDashBoardAS,
    homeFS: myDashBoardFS,
    homeAlertsMonitor: myDashboardAlertsMonitor,
  }
}
