import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components/macro"
import CircularProgress from "@material-ui/core/CircularProgress"
import { Helmet } from "react-helmet"
import { useHistory, Prompt } from "react-router-dom"
import { useTheme } from "@material-ui/core/styles"
import {
  Grid,
  Typography,
  FormHelperText,
  Button,
  IconButton,
  useMediaQuery,
  Card,
  Tooltip,
  Collapse,
  TextField,
  Card as MuiCard,
  CardMedia,
  Box,
} from "@material-ui/core"
import { Field, Form, Formik, useFormik } from "formik"
import { DropzoneArea, DropzoneDialog } from "material-ui-dropzone"
import Paper from "@material-ui/core/Paper"
import Divider from "@material-ui/core/Divider"
import { Add } from "@material-ui/icons"
import {
  CheckCircleIcon,
  ChevronUp,
  Trash2,
  ChevronDown,
  Save,
  Slash,
  Edit,
  CloseIcon,
  PlusCircle,
} from "@iconsGallery"
import { useTranslation } from "react-i18next"
import { useWindowSize } from "@utils"
import * as ServiceAPI from "@services"
import { useDispatch, useSelector } from "react-redux"
import * as constantsAPI from "@constants"
import _, { isEmpty, set } from "lodash"
import * as Adm from "@adm"
import * as Yup from "yup"
import * as Features from "@features"
import { APIUrl } from "@utils"
import useStyles from "./style"
import { ConfirmationDialog } from "@features"
import { productDataApiMethods as productsAPI } from "../../../services/masterData/products"
import productCategoryApiMethods from "../../../services/masterData/products"
import MediaUpload from "./MediaUpload"
import { v4 as uuidv4 } from "uuid"
import ProductAttribute from "../../../pages/masterData/products/ProductDetailedPage/productAttribute/ProductAttribute"
import { JSUtils } from "@utils"
import * as Utils from "@utils"

const initSnackbarProps = {
  isOpen: false,
  message: "",
  type: "info",
  autoHideDuration: 2500,
}
const validationSchema = Yup.object().shape({
  categoryCode: Yup.string()
    .required("Enter Category Code")
    .matches(
      Utils?.JSUtils.getYupValidationCheck("^[a-zA-Z0-9]+$"),
      "Only Alphanumeric are allowed for Category Code"
    )
    .min(3, "Category Code can be of min-3 max-6 characters")
    .max(6, "Category Code can be of min-3 max-6 characters"),
  category_name: Yup.string()
    .trim()
    .required("Enter Category Name")
    .min(3, "Category Name can be of min-3 max-255 characters")
    .max(255, "Category Name can be of min-3 max-255 characters"),
  short_description: Yup.string()
    .trim()
    .required("Enter Description")
    .min(3, "Description can be of min-3 max-255 characters")
    .max(255, "Description can be of min-3 max-255 characters"),
})

function CategoryForm(props) {
  const {
    isWriteAllowed = false,
    onClose = () => {},
    formProps = {},
    setAnyChanges = () => {},
  } = props
  const formRef = useRef()
  const history = useHistory()
  const [initialValues, setInitialValues] = useState({})
  const [height, width] = useWindowSize()
  const { t } = useTranslation()
  const classes = useStyles(width)
  const [editMode, setEditMode] = React.useState(false)
  const [data, setData] = useState([])
  const formData = useSelector((state) => state.addLocationReducer)
  const [loading, setLoading] = useState(false)
  const [allowUpdate, setAllowUpdate] = useState(false)
  const [snackBarProps, setSnackBarProps] = useState(initSnackbarProps)
  const [selectedImages, setSelectedImages] = useState([])
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [reDirect, setReDirect] = useState(false)
  const [open, setOpen] = useState(false)
  const [pathName, setPathName] = useState("/")
  const [allowPrompt, setAllowPrompt] = useState(true)
  const [cancel, setCancel] = useState("")
  const [openModal, setopenModal] = useState(false)
  const theme = useTheme()
  const isTablet = useMediaQuery("(max-width: 959px)")
  const isMobile = useMediaQuery("(max-width: 600px)")

  useEffect(() => {
    setInitialValues({
      categoryId: props?.data?.categoryId || "",
      category_name: props?.data?.category_name || "",
      categoryCode: props?.data?.categoryCode || "",
      short_description: props?.data?.short_description || "",
      isActive: props?.data?.isActive === "" ? false : true,
      similarProducts: props?.data?.similarProducts || false,
    })
  }, [props.data])

  const handleOpen = () => {
    setopenModal(true)
  }

  const handleClose = () => {
    setopenModal(false)
  }

  const slashHandler = () => {
    setEditMode(false)
    props.setFormProps((c) => ({
      ...c,
      mode: "view",
      data: props?.data || {},
      isOpen: c.mode === "create" ? false : true,
    }))
  }

  const handleCustomCloseRedirect = () => {
    setAnyChanges(false)
    if (props?.formProps?.mode === "view") {
      props?.onClose()
      setOpenConfirmationDialog(false)
    } else {
      setOpenConfirmationDialog(false)
      setOpen(false)
      slashHandler()
      backToExistedData()
    }
  }

  const adopterFunction = (data, images) => {
    let formData = new FormData()
    formData.append("payload", JSON.stringify(data))
    return formData
  }

  const handleAddCategory = async (data, actions) => {
    setLoading(true)
    setSnackBarProps({
      ...initSnackbarProps,
      isOpen: false,
    })
    const body = {
      category_name: data["category_name"],
      categoryCode: data["categoryCode"],
      short_description: data["short_description"],
      similarProducts: data?.similarProducts,
    }
    const temp = adopterFunction(body)
    productsAPI
      .createCategory(temp || {})
      .then((resp) => {
        let formatResp = {
          ...resp?.data?.data,
          categoryId: resp?.data?.data?._id,
        }
        setSnackBarProps({
          ...initSnackbarProps,
          isOpen: true,
          message: JSUtils.formatMessageCode(resp?.data, t, "Success"),
          type: "success",
        })
        props.setFormProps((c) => ({
          ...c,
          mode: "view",
          data: { ...formatResp, parent_name: c.data.parent_name } || {},
        }))
        props?.fetchNewFlatTreeData()
        setTimeout(() => {
          props?.handleAssociatedNode({ node: formatResp })
        }, 1000)
        setLoading(false)
        setAnyChanges(false)
      })
      .catch((err) => {
        setSnackBarProps({
          ...initSnackbarProps,
          isOpen: true,
          message: JSUtils.formatMessageCode(
            err?.response?.data,
            t,
            "Product Category already exists"
          ),
          type: "error",
        })
        setLoading(false)
      })
  }

  const handleUpdateCategory = async (formData, actions) => {
    setLoading(true)
    const categoryId = formData.categoryId
    const body = {
      category_name: formData["category_name"],
      categoryCode: formData["categoryCode"],
      short_description: formData["short_description"],
      similarProducts: formData?.similarProducts,
      isActive: formData["isActive"],
    }
    const temp = adopterFunction(body)
    productsAPI
      .updateCategory(categoryId, temp || {})
      .then((resp) => {
        let formatResp = {
          ...resp?.data?.data,
          categoryId: resp?.data?.data?._id,
        }
        props?.handleViewOpen(formatResp, formData?.parent_name)
        setSnackBarProps({
          ...initSnackbarProps,
          isOpen: true,
          message: JSUtils.formatMessageCode(resp?.data, t, "Success"),
          type: "success",
        })
        props.setFormProps((c) => ({
          ...c,
          mode: "view",
          data:
            { ...resp?.data?.data, parent_name: formData?.parent_name } || {},
        }))
        props?.fetchNewFlatTreeData()
        setLoading(false)
        setAnyChanges(false)
      })
      .catch((err) => {
        setSnackBarProps({
          ...initSnackbarProps,
          isOpen: true,
          message: JSUtils.formatMessageCode(
            err?.response?.data,
            t,
            "Sorry! Update operation failed"
          ),
          type: "error",
        })
        setLoading(false)
      })
  }

  const getErrText = (name) => {
    return formikProps.touched?.[name] && t(formikProps.errors?.[name])
  }
  const formikProps = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit:
      props.formProps?.mode === "edit"
        ? (val) => handleUpdateCategory(val)
        : (val) => handleAddCategory(val),
  })

  const inputFields = [
    {
      label: "Category Code",
      name: "categoryCode",
      required: true,
      component: (inputProps) => {
        return (
          <>
            <TextField
              disabled={inputProps.disabled}
              placeholder={t("Enter Category Code")}
              variant={inputProps.disabled ? "standard" : "outlined"}
              name={inputProps?.name}
              autoComplete="off"
              onChange={inputProps.handleChange}
              onBlur={inputProps.handleBlur}
              {...inputProps}
              size="small"
              className={
                inputProps.disabled
                  ? classes.textInputDisabled
                  : classes.textInput
              }
              fullWidth
              onKeyPress={(e) => {
                if (e.which === 32 && e.target.value === "") {
                  e.preventDefault()
                }
              }}
            />
            {props.formProps?.mode !== "view" &&
              formikProps.errors[inputProps.name] && (
                <FormHelperText style={{ color: "red" }}>
                  {getErrText(inputProps.name)}
                </FormHelperText>
              )}
          </>
        )
      },
    },
    {
      label: "Category Name",
      name: "category_name",
      required: true,
      component: (inputProps) => {
        return (
          <>
            <TextField
              disabled={inputProps.disabled}
              placeholder={t("Enter Category Name")}
              variant={inputProps.disabled ? "standard" : "outlined"}
              name={inputProps?.name}
              autoComplete="off"
              onChange={inputProps.handleChange}
              onBlur={inputProps.handleBlur}
              {...inputProps}
              size="small"
              className={
                inputProps.disabled
                  ? classes.textInputDisabled
                  : classes.textInput
              }
              fullWidth
              onKeyPress={(e) => {
                if (e.which === 32 && e.target.value === "") {
                  e.preventDefault()
                }
              }}
            />
            {props.formProps?.mode !== "view" &&
              formikProps.errors[inputProps.name] && (
                <FormHelperText style={{ color: "red" }}>
                  {getErrText(inputProps.name)}
                </FormHelperText>
              )}
          </>
        )
      },
    },
    {
      label: "Category Description",
      name: "short_description",
      required: true,
      component: (inputProps) => {
        return (
          <>
            <TextField
              disabled={inputProps.disabled}
              rows={4}
              multiline
              placeholder={t("Enter Description")}
              autoComplete="off"
              name={inputProps?.name}
              variant={inputProps.disabled ? "standard" : "outlined"}
              onChange={inputProps.handleChange}
              onBlur={inputProps.handleBlur}
              {...inputProps}
              size="small"
              className={
                inputProps.disabled
                  ? classes.textInputDisabled
                  : classes.textInput
              }
              fullWidth
              onKeyPress={(e) => {
                if (e.which === 32 && e.target.value === "") {
                  e.preventDefault()
                }
              }}
            />
            <FormHelperText style={{ color: "red" }}>
              {getErrText(inputProps.name)}
            </FormHelperText>
          </>
        )
      },
    },
    {
      label: "Similar Products",
      name: "similarProducts",
      isMini: false,
      component: (inputProps) => {
        return (
          <>
            {inputProps.disabled ? (
              <Typography
                style={{ marginLeft: "15px" }}
                className={
                  inputProps.value === true
                    ? classes.toggleYes
                    : classes.toggleNo
                }
              >
                {inputProps.value === true ? t("Yes") : t("No")}
              </Typography>
            ) : (
              <Adm.ToggleButton
                isNoLabel
                name={inputProps?.name}
                disabled={inputProps.disabled}
                handleChange={(event) => {
                  formikProps.setFieldValue(
                    inputProps?.name,
                    event.target.checked
                  )
                }}
                onBlur={inputProps.handleBlur}
                checked={formikProps.values[inputProps?.name]}
                className={classes.toggleBar}
              />
            )}
          </>
        )
      },
    },
  ]

  const backToExistedData = () => {
    formikProps.setFieldValue("category_name", initialValues.category_name)
    formikProps.setFieldValue("categoryCode", initialValues.categoryCode)
    formikProps.setFieldValue(
      "short_description",
      initialValues.short_description
    )
    formikProps.setFieldValue("isActive", initialValues.isActive)
    formikProps.setFieldValue("similarProducts", initialValues.similarProducts)
  }
  return (
    <Grid container item lg={12}>
      <ConfirmationDialog
        openConfirmationDialog={openConfirmationDialog}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        setReDirect={setReDirect}
        setCancel={setCancel}
        handleCustomCloseRedirect={handleCustomCloseRedirect}
      />

      <Adm.BackdropOverlay open={loading} />
      <Grid container>
        <Grid
          className="cls-productcategorydetail"
          container
          direction="row"
          item
          xs={12}
          lg={12}
          md={12}
          xl={12}
          sm={12}
        >
          <Grid
            container
            xs={12}
            md={12}
            lg={12}
            xl={12}
            sm={12}
            justify="space-between"
            alignContent="center"
            alignItems="center"
            style={{
              paddingLeft: isMobile ? "" : "16px",
              height: isMobile ? "" : "60px",
            }}
          >
            <Typography variant="h4" align="center">
              {t("Product Category Details")}
            </Typography>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
              {props?.formProps?.mode === "view" ? (
                <>
                  <Tooltip
                    title={<p className="test-tooltip">{t("Edit")}</p>}
                    placement="top"
                    id="id-kit-test-tooltip"
                    classes={{
                      tooltip: `${classes.customTooltip} makeStyles-customTooltip-support-kit`,
                    }}
                  >
                    <IconButton
                      onClick={() =>
                        props?.setFormProps((c) => ({ ...c, mode: "edit" }))
                      }
                      disabled={isWriteAllowed === true ? false : true}
                    >
                      <Edit
                        size={20}
                        style={{ color: "rgba(158, 158, 158, 1)" }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={<p className="test-tooltip">{t("Close")}</p>}
                    placement="top"
                    id="id-kit-test-tooltip"
                    classes={{
                      tooltip: `${classes.customTooltip} makeStyles-customTooltip-support-kit`,
                    }}
                  >
                    <IconButton onClick={() => props?.onClose()}>
                      <CloseIcon
                        size={20}
                        style={{ color: "rgba(158, 158, 158, 1)" }}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip
                    classes={{
                      tooltip: `${classes.customTooltip} makeStyles-customTooltip-support-kit`,
                    }}
                    title={<p className="test-tooltip">{t("Cancel")}</p>}
                    placement="top"
                    id="id-kit-test-tooltip"
                  >
                    <IconButton onClick={() => setOpenConfirmationDialog(true)}>
                      <Slash size={20} style={{ color: "red" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    classes={{
                      tooltip: `${classes.customTooltip} makeStyles-customTooltip-support-kit`,
                    }}
                    title={<p className="test-tooltip">{t("Save")}</p>}
                    placement="top"
                    id="id-kit-test-tooltip"
                    className="test-tooltip"
                  >
                    <IconButton
                      onClick={() => formikProps.handleSubmit()}
                      style={{ marginRight: "18px" }}
                    >
                      <CheckCircleIcon
                        fontSize="medium"
                        style={{ color: allowUpdate ? "#4CAF50" : "#4CAF50" }}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ padding: `8px 20px 8px 20px` }}>
            {inputFields.map((o) => {
              if (o?.isMini) {
                return (
                  <Grid key={o?.label} container item lg={6}>
                    <InputField
                      required={o.required}
                      key={o?.label}
                      name={o?.name}
                      disabled={props?.formProps?.mode === "view"}
                      value={formikProps?.values?.[o?.name]}
                      handleChange={(event) =>
                        formikProps?.setFieldValue(
                          event.target.name,
                          event.target.value?.replace(/(\s{2,})/g, " ")
                        )
                      }
                      handleBlur={formikProps?.handleBlur}
                      classes={classes}
                      label={t(o?.label)}
                      inputComponent={o?.component}
                    />
                  </Grid>
                )
              }
              return (
                <InputField
                  required={o.required}
                  key={o?.label}
                  name={o?.name}
                  disabled={props?.formProps?.mode === "view"}
                  value={formikProps?.values?.[o?.name]}
                  handleChange={(event) => {
                    setAnyChanges(true)
                    formikProps?.setFieldValue(
                      event.target.name,
                      event.target.value?.replace(/(\s{2,})/g, " ")
                    )
                  }}
                  handleBlur={formikProps?.handleBlur}
                  classes={classes}
                  label={t(o?.label)}
                  inputComponent={o?.component}
                  isColumn={o?.isColumn}
                />
              )
            })}
          </Grid>
        </Grid>
      </Grid>
      {snackBarProps?.isOpen && (
        <Adm.SnackBar
          open
          message={snackBarProps?.message || "Uh! May be wait"}
          type={snackBarProps?.type || "info"}
          icon={snackBarProps?.icon || undefined}
          handleClose={() => setSnackBarProps(initSnackbarProps)}
        />
      )}
      <Grid item xs={12} style={{ padding: `8px 20px 8px 20px` }}>
        {props?.formProps?.mode === "view" ? (
          <Features.LastUpdateMessage
            date={_.get(props.formProps.data, "updatedAt", "")}
            createdDate={_.get(props.formProps.data, "createdAt", "")}
          />
        ) : null}
      </Grid>
    </Grid>
  )
}

const InputField = ({
  classes = {},
  label = "Label",
  required = false,
  isColumn = "",
  name = "",
  handleChange,
  handleBlur,
  value,
  inputComponent = () => null,
  disabled = false,
  setFieldValue,
}) => {
  return (
    <>
      <Grid
        container
        style={{
          borderBottom: label === "" ? "" : "1px solid #EEEEEE",
          padding: "15px 0px",
        }}
      >
        <Grid item xs={isColumn ? 12 : 5} container>
          <Typography
            variant="h5"
            className={`${classes.inputFieldText} makeStyles-inputFieldText-support`}
          >
            {label} {required && <span style={{ color: "red" }}>*</span>}
          </Typography>
        </Grid>
        <Grid container xs={isColumn ? 12 : 7}>
          {inputComponent({
            handleChange,
            handleBlur,
            name,
            disabled,
            value,
            setFieldValue,
          })}
        </Grid>
      </Grid>
      <Divider className={classes.Divider} />
    </>
  )
}

export { CategoryForm }
export default CategoryForm
