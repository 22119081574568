import _ from "lodash"
import React from "react"

function useStageUnstageUI(props) {
    const {
        defaultList = {},
        onUpdate = () => "",
    } = props || {}

    const [checked, setChecked] = React.useState()
    const [getFieldStage, setFieldStage] = React.useState()



    let getDefaultFieldStage = defaultList !== {} ? defaultList
        : {
            unstage: [],
            stage: [],
        }

    React.useEffect(() => {
        setFieldStage(defaultList)
    }, [defaultList])


    const onChangeFieldStage = (eventProps) => {
        const { value = "", fieldName = "" } = eventProps
        setChecked(value)
        let dataUpdate = getFieldStage
        if (fieldName === "unstage") {
            dataUpdate.stage = [...dataUpdate.stage, value]
            let filtered = dataUpdate?.unstage?.filter(filt => filt !== value)
            dataUpdate.unstage = filtered
        } else if (fieldName === "stage") {
            dataUpdate.unstage = [...dataUpdate.unstage, value]
            let filtered = dataUpdate?.stage?.filter(filt => filt !== value)
            dataUpdate.stage = filtered
        }
        setFieldStage({ ...dataUpdate })
        onUpdate({ updatedList: dataUpdate })

    }

    const onChangeAllFieldList = (name) => {
        let dataUpdate = getFieldStage
        if (name === "unstage") {
            dataUpdate.unstage?.forEach((x, i) => {
                if (_.find(dataUpdate.stage, x)) {
                } else {
                    dataUpdate.stage.push(x)
                }
            })
            dataUpdate.unstage = []
        } else if (name === "stage") {
            dataUpdate.stage?.forEach((x, i) => {
                if (_.find(dataUpdate.unstage, x)) {
                } else {
                    dataUpdate.unstage.push(x)
                }

            })
            dataUpdate.stage = []
        }
        setFieldStage({ ...dataUpdate })
        onUpdate({ updatedList: dataUpdate })
    }

    return {
        setChecked,
        checked,
        onChangeFieldStage,
        onChangeAllFieldList,
        getFieldStage,
        setFieldStage
    }
}

export { useStageUnstageUI }
