import React, { useState, useRef } from "react"
import {
  makeStyles,
  TextField,
  MenuItem,
  withStyles,
  Dialog,
  IconButton,
  Icon,
  Grid,
  Typography,
  InputLabel,
} from "@material-ui/core"
import _ from "lodash"
import HierarchyMapping from "../../../pages/masterData/HierarchyMapping"

const StyledDialog = withStyles((theme) => ({
  paper: {
    minHeight: "90vh !important",
    maxHeight: "120vh !important",
    minWidth: "170vh !important",
    maxWidth: "190vh !important",
    padding: "0px",
  },
}))(Dialog)

const TextSearch = (props) => {
  const {
    searchObjKey = "",
    searchObj = {},
    setSearchObj = () => {},
    label = "",
    select = false,
    SelectProps = {},
    InputLabelProps = {},
    searchByOptions = [],
    isLocationLookup = false,
  } = props
  const value = _.get(searchObj, searchObjKey, "")
  const handleChange = (val) =>
    setSearchObj((c) => {
      let temp = _.cloneDeep(c)
      temp[searchObjKey] = val
      return temp
    })
  const selectRef = useRef()

  const [isOpen, setIsOpen] = useState(false)
  const onSubmit = (x, y, z) => {
    let tempStr = _.reduce(
      x,
      (acc, obj) =>
        (acc += `${_.get(obj, "_id", "")}__||__${_.get(
          obj,
          "locationId",
          _.get(obj, "labelForSelectOption", "")
        )}__||__${_.get(
          obj,
          "locationId",
          _.get(obj, "idForFilter", "")
        )}-next-option-`),
      ""
    )
    console.log("tempStr --- ", tempStr)
    handleChange(tempStr)
    setIsOpen(false)
  }

  return (
    <>
      <Grid item xs={12}>
        <TextField
          select={select}
          ref={selectRef}
          id={searchObjKey}
          InputLabelProps={InputLabelProps}
          SelectProps={{ displayEmpty: true }}
          label={label}
          variant="outlined"
          margin="dense"
          fullWidth
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          {...(isLocationLookup
            ? {
                value: getSelectedItemsFromIdString(value),
                SelectProps: {
                  open: false,
                  multiple: true,
                  renderValue: (v) => <RenderSelectValue value={v} />,
                  onOpen: (e) => {
                    setIsOpen(true)
                    selectRef.current = e.target
                  },
                  onClose: (e) => {
                    setIsOpen(false)
                    console.log("from onclose")
                  },
                },
              }
            : {})}
        >
          {isLocationLookup
            ? getSelectedItemsFromIdString(value).map((x, i) => (
                <MenuItem
                  key={`option_item_for_dynamic_filter_select_no_${i + 1}`}
                  value={_.get(x, "_id", "")}
                >
                  {_.get(x, "labelForSelectOption", "")}
                </MenuItem>
              ))
            : _.map(
                _.get(
                  _.find(searchByOptions, { id: searchObjKey }),
                  "options",
                  []
                ),
                (x, i) => (
                  <MenuItem
                    key={`option_item_for_dynamic_filter_select_no_${i + 1}`}
                    value={_.get(x, "id", "")}
                  >
                    {_.get(x, "label", "")}
                  </MenuItem>
                )
              )}
        </TextField>
      </Grid>
      <StyledDialog
        open={isOpen}
        onClose={(e) => {
          setIsOpen(false)
          console.dir(selectRef.current)
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="xl"
      >
        <HierarchyMapping
          isWriteAllowed
          lookupType="location"
          primaryActionLabel="Select"
          isLookUp
          handleCloseLookUp={() => setIsOpen(false)}
          handleLookUpPrimaryAction={onSubmit}
          selectedItems={getSelectedItemsFromIdString(value)}
        />
      </StyledDialog>
    </>
  )
}

export default TextSearch

const RenderSelectValue = ({ value }) => {
  if (_.isArray(value) && !_.isEmpty(value)) {
    if (value.length === 1) {
      return (
        <Typography>
          {_.get(value, `[0].labelForSelectOption`, `1 item selected`)}
        </Typography>
      )
    } else {
      return (
        <Typography>
          {_.get(
            value,
            `[0].labelForSelectOption`,
            `${value.length} items selected`
          )}{" "}
          + {value.length - 1}
        </Typography>
      )
    }
  }
  return ""
}

export const getSelectedItemsFromIdString = (idString) =>
  idString
    .split("-next-option-")
    .filter((c) => !_.isEmpty(c))
    .map((id) => {
      let strToArr = id.split("__||__")
      return {
        _id: strToArr[0],
        labelForSelectOption: strToArr[1],
        idForFilter: strToArr[2],
      }
    })
