import React from "react"
import { Button, Grid, Typography, Dialog } from "@material-ui/core"
import { AddCircleOutlineIcon } from "@iconsGallery"
import * as Adm from "@adm"
import * as Features from "@features"
import { RenderTableEditableContainer } from "./RenderTableEditableContainer"
import { defaultList } from "./fixture"
import {
  StyledDialog,
  tableWrapper,
  buttonWrapper,
  wrapperButton,
} from "./styles"
import Styled from "styled-components"
import _ from "lodash"
import * as ServiceAPI from "@services"

export const EmptyWrapper = Styled(Grid)`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70%;
    justify-content: center;
`
export const EmptyTypography = Styled(Typography)`
  font-size: 18px;
  margin-top: 20px;
`

const { getElasticSearch } = ServiceAPI || {}
const MultiSearchListForm = (props) => {
  const defaultDisplayTabs = ["item_id", "commodity"]
  const defaultTabs = [
    { name: "Commodity", attr: "commodity" },
    { name: "Product", attr: "product" },
  ]
  const defaultHeadings = ["Product ID", "Item Name"]

  const {
    edit = true,
    list = defaultList,
    buttonLabel = "ADD FRUITS",
    displayTabs = defaultDisplayTabs,
    elasticSearchEndpoint = "",
    idName = "item_id",
    tabs = defaultTabs,
    tableDataUpdate = () => "",
    tableHeadings = defaultHeadings,
  } = props || {}

  const [fetchData, setFetchData] = React.useState({ data: list })
  const [treeData, setTreeData] = React.useState([])
  const [stores, setStores] = React.useState([])
  const [open, setOpen] = React.useState(false)
  let temp = []

  React.useEffect(() => {
    setFetchData({ data: list })
  }, [list])

  React.useEffect(() => {
    props.fetchTreeData(setTreeData, setStores)
  }, [fetchData])

  React.useEffect(() => {
    tableDataUpdate(fetchData.data)
  }, [fetchData])

  const onClose = (value: Boolean) => {
    setOpen(!value)
  }

  const onDialogSubmit = (updatedData) => {
    setFetchData(updatedData)
    onClose(true)
  }

  const onRemoveItem = (id) => {
    const newFetchData = { ...fetchData }
    const idx = newFetchData.data.findIndex((item) => item[idName] === id)
    newFetchData.data.splice(idx, 1)
    setFetchData(newFetchData)
  }
  const customOnSearchUpdate = async (
    textboxValue,
    setTableDataState,
    elasticSearchEndpoint
  ) => {
    let response = await getElasticSearch(elasticSearchEndpoint + textboxValue)
    let results = _.get(response, "data.searchResults", {})
    setTableDataState({ data: results })
    return results
  }

  const multiSearchUIAttr = {
    tabs: tabs,
    tableData: fetchData.data,
    idName: idName,
    onUpdate: onDialogSubmit,
    onClose: onClose,
    elasticSearchEndpoint: elasticSearchEndpoint,
    fetchSearchData: (textboxValue, setTableDataState, endpoint) =>
      customOnSearchUpdate(textboxValue, setTableDataState, endpoint),
  }
  const Empty = () => {
    return (
      <EmptyWrapper style={{ padding: "40px" }}>
        <svg
          version="1.1"
          x="0px"
          y="0px"
          width={90}
          viewBox="0 0 512.01 512.01"
        >
          <g transform="translate(0 -1)">
            <g>
              <g>
                <path
                  fill="#a3a3a3"
                  d="M388.89,314.055c-11.435-2.773-22.955,4.373-25.664,15.829c-2.731,11.456,4.373,22.955,15.829,25.664
				c66.261,15.723,90.283,38.976,90.283,50.795c0,25.493-85.077,64-213.333,64c-128.235,0-213.333-38.507-213.333-64
				c0-11.819,24.043-35.072,90.261-50.795c11.477-2.709,18.56-14.208,15.829-25.664c-2.709-11.456-14.229-18.603-25.664-15.829
				C43.717,332.871,0.005,365.639,0.005,406.343c0,70.016,128.811,106.667,256,106.667c127.211,0,256-36.651,256-106.667
				C512.005,365.639,468.293,332.871,388.89,314.055z"
                />
                <path
                  fill="#a3a3a3"
                  d="M256.015,171.681c11.776,0,21.333-9.557,21.333-21.333s-9.557-21.333-21.333-21.333s-21.333,9.557-21.333,21.333
				S244.239,171.681,256.015,171.681z"
                />
                <path
                  fill="#a3a3a3"
                  d="M228.239,398.518l8.683,17.365c3.627,7.232,11.008,11.797,19.093,11.797s15.467-4.565,19.093-11.797l18.389-36.779
				c22.379-44.779,49.984-88.149,76.672-130.091l12.16-19.136c15.061-23.808,23.019-51.307,23.019-79.531
				c0-42.496-18.197-83.115-49.92-111.445C323.727,10.592,281.082-2.975,238.565,2.017C172.175,9.569,117.349,63.03,108.154,129.121
				c-5.44,39.168,4.352,78.059,27.541,109.547C171.877,287.691,200.122,342.241,228.239,398.518z M256.015,86.347
				c35.285,0,64,28.715,64,64c0,35.285-28.715,64-64,64s-64-28.715-64-64C192.015,115.062,220.73,86.347,256.015,86.347z"
                />
              </g>
            </g>
          </g>
        </svg>

        <EmptyTypography>{`No Locations, Yet!`}</EmptyTypography>
      </EmptyWrapper>
    )
  }

  if (edit) {
    return (
      <div>
        <div style={wrapperButton}>
          <Button
            startIcon={<AddCircleOutlineIcon />}
            variant="contained"
            color="primary"
            onClick={() => onClose(false)}
            style={buttonWrapper}
          >
            {buttonLabel}
          </Button>
        </div>
        {fetchData.data.length > 0 ? (
          <div style={tableWrapper}>
            <RenderTableEditableContainer
              tableData={fetchData}
              columns={displayTabs}
              removeItem={onRemoveItem}
              editMode={edit}
              tableHeadings={tableHeadings}
              rowId={idName}
            />
          </div>
        ) : (
          <Typography style={{ paddingLeft: "20px" }}>
            <Empty />
          </Typography>
        )}
      </div>
    )
  } else {
    return fetchData.data.length > 0 ? (
      <div style={tableWrapper}>
        <RenderTableEditableContainer
          tableData={fetchData}
          columns={displayTabs}
          removeItem={onRemoveItem}
          editMode={edit}
          tableHeadings={tableHeadings}
          rowId={idName}
        />
      </div>
    ) : (
      <Empty />
    )
  }
}

export { MultiSearchListForm }
export default MultiSearchListForm
