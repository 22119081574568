import { makeStyles, useTheme } from "@material-ui/core/styles"
import { maxHeight } from "@material-ui/system"
export const useFacetUIStyles = makeStyles({
  cardSadhow: {
    boxShadow: "0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset",
  },
})

export const useFacetUIContentStyles = makeStyles(theme => ({
  dividerBottom: {
    borderBottom: "0.1em solid rgba(0, 0, 0, 0.1)",
  },

  listProperty: {
    display: "flex",
    maxHeight: 400,
    // overflowY: "scroll",
    // marginBottom: "20px",
  },
  listWrapper: {
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "0px",
    width: "100%",
    margin: "auto",
    // border: "1px solid #e5e5e5",
    height: "auto",
    borderTop: "0",
    minHeight: "auto",
    maxHeight: "400px",
    overflowY: "scroll",
    '&::-webkit-scrollbar,::-webkit-scrollbar-track': {
      width: '3px',
      height: '8px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      '-webkit-appearance': 'none',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.almostBlack[500],
      height: '5px',
      width: '3px',
      borderRadius: '8px',
    },
  },
  listItem: {
    border: "0.1em solid rgba(0, 0, 0, 0.1) !important",
    height: "50px",
    alignItems: "center",
    padding: "20px",
  },
  verticalDivider: {
    // borderRight: "0.1em solid rgba(0, 0, 0, 0.1)",
    flex: 1,
  },

  textFiled1: {
    width: "100%",
    marginRight: "32px",
    marginTop: "12px",
    marginBottom: "12px",
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      "borderColor": "var(--primaryColor)",
    },
  },
  textFiled2: {
    width: "100%",
    marginRight: "20px",
    marginTop: "12px",
    marginBottom: "12px",
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      "borderColor": "var(--primaryColor)",
    },
  },
  addButton: {
    backgroundColor: "var(--primaryColor)",
    color: "white",
    maxWidth: "100%",
    maxHeight: "36px",
    marginTop: "12px",
    '&:hover': {
      "backgroundColor": "var(--primaryColor)",
    },
  },
  smallAccordianHeader: {
    '& .MuiCardHeader-title': {
      fontSize: '14px'
    }
  }
})
)
