import React, { useState, useEffect, useCallback } from "react"
import CommonComponent, { validateTime } from "./CommonComponent"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import { addOperatingHoursBusiness, addOperatingHoursAlchol } from "@reduxHelper"
import * as Adm from "@adm"
import { useTranslation } from "react-i18next"

function BusinessHours({ editMode, setUpdate, isCreateMode = false, editingTheBusinnessHours, data, clearVal, setClearVal = () => { },
  setAllowUpdate = () => { }, isAllOkayRef = { current: true }, isTimeArrOkayRef = { current: true }, setAllowPrompt = () => { },
  fetchData = () => { }, preventRerender = false, setPreventRerender = () => { }, isError = [], checkError = () => { },
}) {
  const { t } = useTranslation()
  const customDaysArr = [
    {
      day: "sunday",
      label: t("Sunday").split('')[0],
      selected: false,
      isDisabled: false,
    },
    {
      day: "monday",
      label: t("Monday").split('')[0],
      selected: false,
      isDisabled: false,
    },
    {
      day: "tuesday",
      label: t("Tuesday").split('')[0],
      selected: false,
      isDisabled: false,
    },
    {
      day: "wednesday",
      label: t("Wednesday").split('')[0],
      selected: false,
      isDisabled: false,
    },
    {
      day: "thursday",
      label: t("Thursday").split('')[0],
      selected: false,
      isDisabled: false,
    },
    {
      day: "friday",
      label: t("Friday").split('')[0],
      selected: false,
      isDisabled: false,
    },
    {
      day: "saturday",
      label: t("Saturday").split('')[0],
      selected: false,
      isDisabled: false,
    },
  ]
  const dispatch = useDispatch()
  const activeStep = useSelector((state) => state.stepperReducer.step)
  const timeObj = { time_from: "10:00", time_to: "23:00" }
  const [daysArrState, setDaysArrState] = useState([])
  const [timeArrState, setTimeArrState] = useState([])
  const reduxData = useSelector((state) => state.addLocationReducer)
  const [timeError, setTimeError] = useState([])
  const [daysError, setDaysError] = useState([])
  const [alertMessage, setAlertMessage] = useState("")
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")

  useEffect(() => {
    let tempBusinessDays = _.get(data, "businessHrs.days", []).map((item) => {
      item.map((subItem) => {
        subItem.isDisabled = false
      })
      return item
    })
    setDaysArrState(tempBusinessDays)
    setTimeArrState(_.get(data, "businessHrs.time", []))
    let errArr = _.get(data, "businessHrs.time", _.get(reduxData, "operatingHoursBusiness.time", []))
      .map((x, i) => _.get(x, `[${i}].time_from`, "") > _.get(x, `[${i}].time_to`, ""))
    setTimeError(errArr)
  }, [data])

  useEffect(() => {
    if (clearVal === true) {
      fetchData()
      setDaysArrState(_.get(data, "businessHrs.days", []))
      setTimeArrState(_.get(data, "businessHrs.time", []))
    }
    setClearVal(false)

  }, [clearVal, data])

  useEffect(() => {
    if (_.get(reduxData, "operatingHoursBusiness") !== null && editingTheBusinnessHours
    ) {
      setDaysArrState(reduxData.operatingHoursBusiness.days)
      setTimeArrState(reduxData.operatingHoursBusiness.time)
    } else {
      const newDaysArrState = [...daysArrState]
      const newTimeArrState = [...timeArrState]
      newDaysArrState.push(customDaysArr)
      newTimeArrState.push(timeObj)
      setDaysArrState(_.get(data, "businessHrs.days", newDaysArrState))
      setTimeArrState(_.get(data, "businessHrs.time", newTimeArrState))
    }
  }, [editingTheBusinnessHours, data])

  const clickHandler = (rowIdx, dayIdx, dayDetails) => {
    const newDaysArrState = [...daysArrState]
    newDaysArrState.map((item, index) => {
      item.map((subItem) => {
        if (subItem.day === dayDetails.day && index !== rowIdx) {
          subItem.isDisabled = !subItem.isDisabled
        }
      })
      return item
    })
    newDaysArrState[rowIdx][dayIdx].selected = !newDaysArrState[rowIdx][dayIdx].selected
    if (!newDaysArrState[rowIdx].some((x) => x.selected === false)) {
      newDaysArrState[rowIdx].error = "Please select a day"
      isAllOkayRef.current = false
    } else {
      isAllOkayRef.current = true
    }
    setDaysArrState(newDaysArrState)
    setAllowUpdate(true)
    setAllowPrompt(true)
    validateTime({
      daysArrState: newDaysArrState,
      timeArrState,
      rowIdx,
      timeCheckKey: "between",
      setDaysError,
      t
    })
  }

  const textChangeHandler = (val, type, i) => {
    const newTimeArrState = [...timeArrState]
    newTimeArrState[i][type] = val
    let tempAlcoholHoursTimeArr = _.cloneDeep(_.get(reduxData, "operatingHoursAlchol.time", []))
    tempAlcoholHoursTimeArr[i] = { time_from: "", time_to: "" }
    dispatch(addOperatingHoursAlchol({ days: daysArrState, time: tempAlcoholHoursTimeArr }))
    setTimeArrState(newTimeArrState)
    setAllowPrompt(true)
    setAllowUpdate(true)
  }
  const addHandler = (idx) => {
    setAlert(false)
    if ((daysArrState.length > 0 && daysArrState[idx].some((x) => x.selected === true)) || daysArrState.length === 0) {
      daysArrState[0].map((item) => {
        customDaysArr.map((subItem) => {
          if (item.day === subItem.day) {
            subItem.isDisabled = item.selected || item.isDisabled
          }
          return subItem
        })
      })
      const newDaysArrState = [...daysArrState]
      const newTimeArrState = [...timeArrState]
      newTimeArrState.push(timeObj)
      newDaysArrState.push(customDaysArr)
      let tempAlcoholHoursTimeArr = _.cloneDeep(_.get(reduxData, "operatingHoursAlchol.time", []))
      tempAlcoholHoursTimeArr.push({ time_from: "", time_to: "" })
      dispatch(addOperatingHoursAlchol({ days: newDaysArrState, time: tempAlcoholHoursTimeArr }))
      setDaysArrState(newDaysArrState)
      setTimeArrState(newTimeArrState)
    } else {
      const newDaysArrState = [...daysArrState]
      newDaysArrState[idx].error = t("Kindly choose any of the days")
      setDaysError(newDaysArrState)
      setAlertType("error")
      setAlertMessage(t("Select atleast a day"))
      setAlert(true)
    }
  }
  const closeHandler = (ind) => {
    checkError(ind)
    const newDaysArrState = [...daysArrState]
    newDaysArrState[ind].error = ""
    const newTimeArrState = [...timeArrState]
    newDaysArrState.splice(ind, 1)
    newTimeArrState.splice(ind, 1)
    let tempAlcoholHoursTimeArr = _.cloneDeep(_.get(reduxData, "operatingHoursAlchol.time", []))
    tempAlcoholHoursTimeArr.splice(ind, 1)
    dispatch(addOperatingHoursAlchol({ days: newDaysArrState, time: tempAlcoholHoursTimeArr }))
    setAllowUpdate(true)
    setDaysArrState(newDaysArrState)
    setTimeArrState(newTimeArrState)
  }
  useEffect(() => {
    if (preventRerender === false) {
      dispatch(addOperatingHoursBusiness({ days: daysArrState, time: timeArrState }))
    }
    setPreventRerender(false)
  }, [preventRerender, daysArrState, timeArrState])

  useEffect(() => {
    isTimeArrOkayRef.current = timeError.filter((x) => x).length === 0

    return () => {
      let clonedTimeArr = _.cloneDeep(timeArrState)
      let indicesOfErr = timeError.reduce((acc, x, i) => {
        if (x === true) {
          acc.push(i)
          clonedTimeArr[i] = { time_from: "", time_to: "" }
        }
        return acc
      }, [])
      if (!_.isEmpty(indicesOfErr) && isCreateMode) {
        dispatch(addOperatingHoursBusiness({ days: [], time: [], dummy: true, }))
        dispatch(addOperatingHoursAlchol({ days: [], time: [], dummy: true }))
      }
    }
  }, [timeError])

  return (
    <>
      <CommonComponent
        daysArrState={daysArrState}
        timeArrErr={timeError}
        daysArrErr={daysError}
        setTimeArrErr={setTimeError}
        setDaysArrErr={setDaysError}
        timeArrState={timeArrState}
        clickHandler={clickHandler}
        textChangeHandler={textChangeHandler}
        addHandler={addHandler}
        closeHandler={closeHandler}
        setUpdate={setUpdate}
        isAllOkayRef={isAllOkayRef}
        isTimeMandatory
        markAsRequired
        displayText={"Business Hours"}
        editMode={editMode}
      />
      {alert ? (
        <Adm.SnackBar
          open={true}
          message={alertMessage}
          type={alertType}
          handleClose={() => setAlert(false)}
        />
      ) : null}
    </>
  )
}
export { BusinessHours }
export default BusinessHours
