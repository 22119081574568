import React, { useMemo, useRef, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import PolygonAnnotation from "./PolygonAnnotation"
import { Stage, Layer, Image } from "react-konva"
import {
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Grid,
} from "@material-ui/core"
import PanToolIcon from "@material-ui/icons/PanTool"
import GestureIcon from "@material-ui/icons/Gesture"
import { DeleteIcon } from "@iconsGallery"
import { cloneDeep } from "lodash"
import PolygonAPI from "../../services/trainingProductsHitl/api"

const Button = ({ name, onClick }) => {
  return (
    <button
      style={{
        margin: 8,
        padding: "10px 16px",
        color: "white",
        backgroundColor: "#FF019A",
        border: "none",
        borderRadius: ".4rem",
      }}
      onClick={onClick}
    >
      {name}
    </button>
  )
}

const wrapperStyle = {
  display: "flex",
  justifyContent: "center",
  marginTop: 20,
  backgroundColor: "aliceblue",
}
const columnStyle = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  marginTop: 20,
  backgroundColor: "aliceblue",
}

function usePolygonalEditorStates(props) {
  const {
    defaultPoints = [],
    onUpdate = () => "",
    imageUrl = "",
    showSnackBar,
    setShowSnackBar,
    snackBarMessage,
    setSnackBarMessage,
    snackBarMessageType,
    setSnackBarMessageType,
    isEnableCloseButton,
    setEnableCloseButton,
  } = props || {}

  const { t } = useTranslation()
  const [image, setImage] = useState()
  const imageRef = useRef(null)
  const dataRef = useRef(null)
  const [points, setPoints] = useState(defaultPoints)
  const [originalSize, setOriginalSize] = useState({})
  const [size, setSize] = useState({})
  const [flattenedPoints, setFlattenedPoints] = useState()
  const [position, setPosition] = useState([0, 0])
  const [isMouseOverPoint, setMouseOverPoint] = useState(false)
  const [isPolyComplete, setPolyComplete] = useState(true)
  const [layerList, setLayerList] = useState([])
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [dragPolygon, setDragPolygon] = useState(false)
  const [addClicked, setAddClicked] = useState(false)

  useEffect(() => {
    setShowSnackBar(false)
    setSnackBarMessage("")
    /**
     * Building Image as per the stage & Aspect Ratio
     */
    const img = new window.Image()
    img.src = imageUrl
    img.onload = () => {
      const { naturalWidth, naturalHeight } = img
      const maxDimension = 1080
      const scale = Math.min(
        maxDimension / naturalWidth,
        maxDimension / naturalHeight
      )
      setOriginalSize({ width: naturalWidth, height: naturalHeight })
      setSize({ width: naturalWidth * scale, height: naturalHeight * scale })
      setImage(img)
    }
  }, [imageUrl])

  useEffect(() => {
    let tempLayerList = cloneDeep(layerList)
    if (layerList.length === 0 && defaultPoints.length > 0) {
      setLayerList(defaultPoints)
      setPoints(defaultPoints?.find((x) => x.isSelected)?.points)
      setEnableCloseButton(false)
    }
  }, [defaultPoints])

  const getMousePos = (stage) => {
    return [stage.getPointerPosition().x, stage.getPointerPosition().y]
  }

  /**
   *  Generating the points as per Image Size on the stage
   */
  const genPoints = (arr, width, height) => {
    const result = arr.map((value, index) => {
      if (index === 0) {
        return value / width
      } else if (index === 1) {
        return value / height
      } else {
        return value
      }
    })
    return result
  }

  /**
   *  drawing begins when mousedown event fires.
   */
  const handleMouseDown = (e) => {
    if (isPolyComplete) return
    const stage = e.target.getStage()
    const mousePos = getMousePos(stage)
    const generatePoints = genPoints(
      mousePos,
      size.width / originalSize.width,
      size.height / originalSize.height
    )
    if (isMouseOverPoint && points.length >= 3) {
      setPolyComplete(true)
      let isNameExists = false
      const tempLayerList = cloneDeep(layerList)
      tempLayerList.map((item) => {
        if (item.layerName === `Polygon${tempLayerList.length}`) {
          isNameExists = true
        }
      })
      tempLayerList.map((item) => {
        if (item.isSelected) {
          item.layerName = `Polygon${
            isNameExists ? tempLayerList.length + 1 : tempLayerList.length
          }`
          item.points = points
        }
      })
      setLayerList(tempLayerList)
      onUpdate(tempLayerList)
      setAddClicked(false)
      setEnableCloseButton(false)
    } else {
      setPoints([...points, generatePoints])
      const tempLayerList = cloneDeep(layerList)
      tempLayerList.map((item) => {
        if (item.isSelected) {
          item.points = [...points, mousePos]
        }
      })
    }
  }

  const handleMouseMove = (e) => {
    const stage = e.target.getStage()
    const mousePos = getMousePos(stage)
    setPosition(mousePos)
  }

  const handleMouseOverStartPoint = (e) => {
    if (isPolyComplete || points.length < 3) return
    e.target.scale({ x: 3, y: 3 })
    setMouseOverPoint(true)
  }
  const handleMouseOutStartPoint = (e) => {
    e.target.scale({ x: 1, y: 1 })
    setMouseOverPoint(false)
  }
  const handlePointDragMove = (e) => {
    const stage = e.target.getStage()
    const index = e.target.index - 1
    const pos = [
      e.target._lastPos.x / (size.width / originalSize.width),
      e.target._lastPos.y / (size.height / originalSize.height),
    ]
    if (pos[0] < 0) pos[0] = 0
    if (pos[1] < 0) pos[1] = 0
    setPoints([...points.slice(0, index), pos, ...points.slice(index + 1)])
    const tempLayerList = cloneDeep(layerList)
    tempLayerList.map((item) => {
      if (item.isSelected) {
        item.points = [
          ...points.slice(0, index),
          pos,
          ...points.slice(index + 1),
        ]
      }
    })
    onUpdate(tempLayerList)
  }

  /**
   *  Generating Lines between the Annotation Points based on Image Size
   */
  useEffect(() => {
    if (size.width !== undefined) {
      const transformedArray = points
        .concat(isPolyComplete ? [] : position)
        .flatMap((subArray) => {
          if (subArray.length >= 2) {
            return [
              subArray[0] * (size.width / 1920),
              subArray[1] * (size.height / 1080),
            ]
          }
          return subArray
        })
      setFlattenedPoints(transformedArray)
    }
  }, [points, isPolyComplete, position, size])

  const undo = () => {
    setPoints(points.slice(0, -1))
    setPolyComplete(false)
    setPosition(points[points.length - 1])
  }

  const reset = () => {
    setPoints([])
    setPolyComplete(true)
  }

  const handleGroupDragEnd = (e) => {
    //drag end listens other children circles' drag end event
    //...that's, why 'name' attr is added, see in polygon annotation part
    if (e.target.name() === "polygon") {
      let result = []
      let copyPoints = [...points]
      copyPoints.map((point) =>
        result.push([point[0] + e.target.x(), point[1] + e.target.y()])
      )
      e.target.position({ x: 0, y: 0 }) //needs for mouse position otherwise when click undo you will see that mouse click position is not normal:)
      setPoints(result)
      const tempLayerList = cloneDeep(layerList)
      tempLayerList.map((item) => {
        if (item.isSelected) {
          item.points = result
        }
      })
      onUpdate(tempLayerList)
    }
  }

  const handleLayerSelection = (data) => {
    layerList.map((item) => {
      if (item.layerName === data.layerName) {
        item.isSelected = true
      } else {
        item.isSelected = false
      }
    })
    setPoints(data.points)
  }

  const handleDeleteLayer = (data) => {
    setOpenConfirmationDialog(true)
    setSelectedRow(data)
  }

  const handleCustomCloseRedirect = () => {
    if (selectedRow.layerName === "Untitled") {
      setEnableCloseButton(false)
    }
    setPolyComplete(true)
    let tempLayerList = cloneDeep(
      layerList.filter((x) => x.id !== selectedRow.id)
    )
    tempLayerList.map((item, index) => {
      if (index === tempLayerList.length - 1) {
        item.isSelected = true
      } else {
        item.isSelected = false
      }
    })
    setPoints(tempLayerList?.find((x) => x.isSelected)?.points || [])
    if (tempLayerList.length === 0) {
      setEnableCloseButton(true)
    }
    setLayerList(tempLayerList)
    onUpdate(tempLayerList)
  }

  const handleAddLayer = () => {
    setPoints([])
    setShowSnackBar(false)
    setAddClicked(true)
    let tempLayerList = cloneDeep(layerList)
    tempLayerList.map((item) => {
      item.isSelected = false
    })
    tempLayerList.push({
      layerName: `Untitled`,
      points: [],
      isSelected: true,
      isEdit: false,
      isDuplicate: false,
      id: tempLayerList.length + 1,
    })
    setLayerList(tempLayerList)
  }

  const handleLayerNameChange = (value, id) => {
    const tempLayerList = cloneDeep(layerList)
    let checkDuplicate = false
    tempLayerList.map((item) => {
      if (item.id === id) {
        item.layerName = value
      }
      if (item.layerName === value && item.id !== id) {
        checkDuplicate = true
      }
    })
    tempLayerList.map((item) => {
      if (item.id === id) {
        item.isDuplicate = checkDuplicate
      }
    })

    setLayerList(tempLayerList)
  }

  const handleLayerNameEdit = (layerId) => {
    const tempLayerList = cloneDeep(layerList)
    tempLayerList.map((item) => {
      if (layerId !== "") {
        if (item.id === layerId) {
          item.isEdit = true
        } else {
          item.isEdit = false
        }
      } else {
        item.isEdit = false
      }
    })
    setLayerList(tempLayerList)
    if (layerId === "") {
      onUpdate(tempLayerList)
    }
  }

  return {
    image,
    setImage,
    imageRef,
    dataRef,
    points,
    setPoints,
    originalSize,
    size,
    setSize,
    flattenedPoints,
    setFlattenedPoints,
    position,
    setPosition,
    isMouseOverPoint,
    setMouseOverPoint,
    isPolyComplete,
    setPolyComplete,
    layerList,
    setLayerList,
    getMousePos,
    handleMouseDown,
    handleMouseMove,
    handleMouseOverStartPoint,
    handleMouseOutStartPoint,
    handlePointDragMove,
    undo,
    reset,
    handleGroupDragEnd,
    handleLayerSelection,
    handleDeleteLayer,
    handleAddLayer,
    openConfirmationDialog,
    setOpenConfirmationDialog,
    handleCustomCloseRedirect,
    dragPolygon,
    setDragPolygon,
    handleLayerNameChange,
    handleLayerNameEdit,
    addClicked,
    setAddClicked,
    t,
    selectedRow,
  }
}

export default usePolygonalEditorStates
export { usePolygonalEditorStates }
