import React from "react"
import SassDashboard from "./SassDashboard"
import { Typography, Grid } from "@material-ui/core"

function TenantList(props) {
  const { onUpdate = () => "", defaultList } = props
  return <SassDashboard {...props} />
}

export { TenantList }
export default TenantList
