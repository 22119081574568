import { TextField } from "@material-ui/core"
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import DateFnsUtils from "@date-io/date-fns"
import "date-fns"
import { es, fr } from "date-fns/locale"
import { useTranslation } from "react-i18next"
import { format } from "date-fns"

let local = { es: es, fr: fr }
let lang = localStorage.getItem("i18nextLng")

export const useStyles = makeStyles((theme) => ({
  deviceDatepicker: {
    "& .MuiOutlinedInput-root": {
      height: "40px",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& .MuiSvgIcon-root": {
      color: "var(--primaryColor)",
    },
  },
}))

const CustomDateTimePicker = (props) => {
  const {
    handleDateChange = () => {},
    selectedDate = "",
    selectedTime = "",
    format = "MMM dd yyyy",
    maxDate = "",
  } = props
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        className={classes.deviceDatepicker}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={local[lang]}>
          <KeyboardDatePicker
            className={"deviceDatepicker"}
            style={{ width: "100%" }}
            disableToolbar
            okLabel=""
            cancelLabel=""
            autoOk={true}
            inputVariant="outlined"
            format={format}
            placeholder={t("Select Business Date")}
            id="date-picker-inline"
            value={selectedDate}
            onChange={(e) => handleDateChange(e, "date")}
            maxDate={maxDate}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
    </>
  )
}
export default CustomDateTimePicker
export { CustomDateTimePicker }
