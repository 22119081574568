import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  textHeader: {
    marginTop: "10px",
  },
  bottomDivider: {
    borderBottom: "0.1em solid rgba(0, 0, 0, 0.1)",
  },
  card: {
    width: "100%",
    boxShadow:
      " 0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)",
    borderRadius: "8px",
  },
  attributeList: {
    padding: "15px",
    borderBottom: `1px solid #e0e0e0`,
  },
  evenClsx: {
    padding: "10px",
    borderBottom: `1px solid #e0e0e0`,
    borderRight: "1px solid #e0e0e0",
  },
  accordianCss: {
    borderBottom: "1px solid #EEEEEE",
    padding: "15px",
  },
  textAreaWrap: {
    margin: "-12px 10px",
  },
  textValues: {
    fontSize: "14px",
    width: "100%",
    marginLeft: "10px",
    fontWeight: 400,
    maxWidth: "300px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}))
export default useStyles
