import * as constantsAPI from "@constants"
const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}

const types = REDUX_CONSTANTS

const initialState = {
    createDeviceSuccess: false,
    detailsForDeviceId: [],
    errorGetDetails: {},
    deviceSelectedItems: [],
}

export default function reducer(state = initialState, actions) {
    switch (actions.type) {
      case types.ADD_DEVICE_MGMT_SUCCESS:
        return {
          ...state,
          createDeviceSuccess: actions.success,
        }
      case types.GET_DETAILS_FOR_DEVICE_MGMT:
        return {
          ...state,
          detailsForDeviceId: actions.obj,
        }
      case types.ERROR_DETAILS_FOR_POS:
        return {
          ...state,
          errorGetDetails: actions.obj,
        }
      case types.DEVICE_SELECTED_ITEMS:
        return {
          ...state,
          deviceSelectedItems: actions.obj,
        }
      default:
        return state
    }
}