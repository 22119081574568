import { Button, Menu, MenuItem } from "@material-ui/core";
import { useState } from "react";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from "@material-ui/styles";
import { DownloadIcon } from "@iconsGallery"
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";

const options = ['PDF', 'EXCEL', 'CSV'];
const useStyles = makeStyles((theme) => ({
    paper: {
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    menu: {
        '& .MuiPaper-root': {
            width: "150px"
        },
        '& .MuiList-padding': {
            padding: "0px"
        }
    }

}));

const ButtonGroup = (props) => {
    const { CSVData = [],
        anchorEl = null, setAnchorEl = () => { },
        CSVHeaders = [], CSVFilename = "", handleExportPDF = () => { }, handleExportExcel = () => { }, handleExportCSV = () => { } } = props
    const { t } = useTranslation()
    const classes = useStyles()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button startIcon={<DownloadIcon style={{ width: "25px", marginBottom: "2px", marginRigt: "10px" }} />} aria-controls="simple-menu" aria-haspopup="true" variant="contained"
                endIcon={<ArrowDropDownIcon style={{ width: "25px", marginBottom: "2px" }} />} style={{ background: "var(--primaryColor)", color: "#FFFFFF", fontSize: "14px" }} onClick={handleClick}>
                {t('EXPORT')}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={{ padding: "0px" }}
                className={classes.menu}
            >
                <MenuItem className="cls-btnGroup-export" onClick={() => { handleClose(); handleExportPDF() }} style={{ fontSize: "14px", fontWeight: "600", borderBottom: "1px solid #e0e0e0" }}>PDF</MenuItem>
                <MenuItem className="cls-btnGroup-export" onClick={() => { handleClose(); handleExportExcel() }} style={{ fontSize: "14px", fontWeight: "600", borderBottom: "1px solid #e0e0e0" }}>EXCEL(XLSX)</MenuItem>
                <CSVLink
                    filename={`${CSVFilename}.csv`}
                    data={CSVData}
                    headers={CSVHeaders}
                    style={{ textDecoration: "unset", color: "black" }}
                >
                    <MenuItem className="cls-btnGroup-export" onClick={() => { handleClose() }} style={{ fontSize: "14px", fontWeight: "600" }}>
                        CSV
                    </MenuItem>

                </CSVLink>
            </Menu>
        </div >
    );
}
export { ButtonGroup }
export default ButtonGroup