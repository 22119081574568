import { process } from "@progress/kendo-data-query"
import {
  Grid,
  GridCell,
  GridColumn as Column,
  GridNoRecords,
} from "@progress/kendo-react-grid"
import "@progress/kendo-theme-bootstrap/dist/all.css"
import { useHistory } from "react-router-dom"
import commonStyles from "../../styles/Pages/common/styles"
import moment from "moment"
import React from "react"
import { Button } from "@material-ui/core"
import { Map } from "@iconsGallery"
import styled from "styled-components"
import _ from "lodash"

const TemplateTableUIGrid = styled(Grid)`
  &.k-grid {
    background-color: #ffffff;
    box-shadow: 1px 2px 3px #0000002b;
  }

  .k-header {
    background: #f4f7fa;

    .k-link {
      color: #000000;
      font-weight: 500;
      padding: 15px 12px !important;
    }
  }
  .k-filter-row {
    background: #ffffff;

    .k-dropdown .k-dropdown-wrap {
      background: white;
      border: none;
    }
    .k-button {
      background: white;
      border: none;
    }

    .k-i-filter::before {
      color: #353535;
    }
    .k-textbox {
      border-top: none;
      border-left: none;
      border-right: none;
      background: transparent;
    }
  }

  &.k-grid td {
    border-bottom: 1px solid #e0e0e0;
  }
  .k-filter-row th {
    padding: 16px 8px;
  }
  .k-master-row,
  .k-pager-wrap {
    background: transparent !important;
  }

  .k-pager-numbers .k-link {
    color: #1565c0;
  }
  .k-pager-numbers .k-link.k-state-selected {
    color: #1565c0;
    background-color: #f3f7fc;
  }
  .k-checkbox {
    border: 2px solid #8c8c8c;
  }
  .bRDGfF {
    border-bottom: 1px solid #fefefe52;
  }

  .k-grid tbody td {
    padding: 0;
  }

  &.k-grid td {
    padding: 0.4rem 10px;
    border-style: solid;
    outline: 0;
    font-weight: inherit;
    text-align: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.k-grid th {
    padding: 0.4rem 0.4rem;
    border-style: solid;
    outline: 0;
    font-weight: inherit;
    text-align: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.k-grid .k-filter-row th:empty {
    padding: 0 !important;
  }
  &.k-grid td,
  .k-grid .k-grid-content,
  .k-grid .k-grid-header-locked,
  .k-grid .k-grid-content-locked {
    border-color: transparent;
    border-bottom: 1px solid #e8e8e8;
  }
  .selected {
    margin-bottom: 10px,
    margin-left: 5px,
  }
.k-dropdown-operator {
    width: auto;
    display: none;
}
.k-icon-button, .k-button.k-button-icon {
    width: calc(0.2em + 0.2rem );
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.1rem;
}
.k-checkbox{
  margin-bottom: 6px;
  margin-left: 6px;
}
`

const LookupGrid = (props) => {
  const { items = [], columnData = [] } = props || {}
  const initialDataState = {
    sort: [],
    take: 10,
    skip: 0,
  }
  const [dataState, setDataState] = React.useState(initialDataState)
  const [tableData, setTableData] = React.useState([])
  const [rawData, setRawData] = React.useState({})
  React.useEffect(() => {
    setTableData(items)
  }, [items])

  React.useEffect(() => {
    setRawData({ docs: items, totalDocs: items.length })
  }, [items])

  return (
    <>
      <TemplateTableUIGrid
        pageable={{ buttonCount: 4, pageSizes: true }}
        filterable
        sortable
        data={process([...tableData], dataState)}
        {...dataState}
        selectedField="selected"
        onDataStateChange={(e: GridDataStateChangeEvent) => {
          setDataState(e.dataState)
        }}
        total={_.toFinite(rawData?.totalDocs || 0)}
        className="ecom-table-ui"
        GridLinesVisibility
      >
        <GridNoRecords>No records found</GridNoRecords>

        <Column
          field="selected"
          width="50px"
          className="selected"
          filterable={false}
        />
        {columnData &&
          columnData.map((val, index) => {
            return (
              val.show && (
                <Column
                  field={val.field && val.field}
                  title={val.title && val.title}
                  filterable={val.filter && val.filter}
                  filter={val.filterType && val.filterType}
                  width={val.min_width && val.min_width}
                />
              )
            )
          })}
      </TemplateTableUIGrid>
    </>
  )
}

export default LookupGrid
export { LookupGrid }
