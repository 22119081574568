import React, { useState, useContext } from "react"
import {
  CheckCircleIcon,
  Edit,
  Slash,
  PlusCircle,
  ExpandLessIcon,
  Trash2,
  ExpandMoreIcon,
} from "@iconsGallery"
import { Add } from "@material-ui/icons"
import {
  CardHeader,
  IconButton,
  Tooltip,
  Button,
  Modal,
  Grid,
} from "@material-ui/core"
import * as Utils from "@utils"
import { useTranslation } from "react-i18next"
import SharedTable from "../../sharedComponents/Table"
import columns from "../ProductAttribute/column"
import dummyData from "../ProductAttribute/attributeData.json"
const CustomHeaderContainer = (props) => {
  const [flag, setFlag] = useState(false)
  const {
    resetForm = () => {},
    showDeleteIcon = false,
    isAnyChanges = false,
    setFieldValue = () => {},
    locationIds,
    updateData = () => {},
    setAllowPrompt = () => {},
    containerProps,
    values,
    setAnyChanges = () => {},
    edit = false,
    setEdit = () => {},
    handleSpecificationDelete = () => {},
    openModal,
    setopenModal = () => {},
    handleOpen = () => {},
    handleClose = () => {},
    dataFetchMethod = () => {},
    setOpenConfirmationDialog = () => {},
    handledeleteAttributeItems = () => {},
    data,
    attributes,
    pageMode = "edit",
    clearVal,
    setClearVal = () => {},
  } = props

  const {
    expanded,
    isEditMode = true,
    classes,
    fetchData,
    label,
    setCollapseOpen,
    handleExpandClick,
    resetData = () => {},
    isWriteAllowed = false,
    iconDisplay = true,
  } = containerProps

  const isSelected = isAnyChanges
  const { t } = useTranslation()
  return (
    <CardHeader
      style={{ borderBottom: "1px solid #EEEEEE", cursor: "pointer" }}
      title={<>{t(label)}</>}
      onClick={() => {
        setEdit(true)
        handleExpandClick()
        setAllowPrompt(false)
        resetData()
      }}
      action={
        <>
          {props.edit ? (
            <>
              {expanded === true && isEditMode === true && iconDisplay && (
                <Tooltip title={t("Edit")} placement="top">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation()
                      if (isWriteAllowed) {
                        setEdit(false)
                      }
                    }}
                    style={{ marginRight: "13px" }}
                  >
                    <Edit className={classes.basicIconProperty} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          ) : (
            <>
              {expanded === true ? (
                <>
                  {containerProps.label === "Attributes" &&
                  pageMode !== "create" ? (
                    <>
                      <Button
                        variant="contained"
                        className="cls-attribute-btn"
                        color="primary"
                        style={{
                          marginRight: "25px",
                          color: "#fff",
                          backgroundColor: "var(--primaryColor)",
                        }}
                        startIcon={
                          <PlusCircle
                            style={{
                              color: "#fff",
                              backgroundColor: "var(--primaryColor)",
                              width: "16px",
                            }}
                          />
                        }
                        onClick={(e) => {
                          e.stopPropagation()
                          handleOpen()
                        }}
                        disabled={pageMode === "create" ? true : false}
                      >
                        {t("Attribute List")}
                      </Button>

                      {showDeleteIcon ? (
                        ""
                      ) : (
                        <>
                          <Tooltip title={t("Archive")} placement="top">
                            <IconButton
                              style={{ marginRight: "20px" }}
                              disabled={props.attributes?.length === 0}
                              onClick={(e) => {
                                e.stopPropagation()
                                setOpenConfirmationDialog(true)
                                setAnyChanges(true)
                              }}
                            >
                              <Trash2 />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </>
                  ) : null}

                  {iconDisplay && (
                    <>
                      <Tooltip title={t("Cancel")} placement="top">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation()
                            setClearVal(true)
                            resetData()
                            setEdit(true)
                            setAnyChanges(false)
                            setAllowPrompt(false)
                          }}
                          style={{ marginRight: "20px" }}
                        >
                          <Slash
                            style={{ color: "red" }}
                            className={classes.basicIconProperty}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("Save")} placement="top">
                        <IconButton
                          style={{
                            marginRight: "18px",
                            pointerEvents: `${isSelected ? "unset" : "none"}`,
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            if (isSelected) {
                              updateData(fetchData)
                              setEdit(true)
                              setAnyChanges(false)
                              setAllowPrompt(true)
                            }
                          }}
                        >
                          <CheckCircleIcon
                            style={{
                              color: `${isSelected ? "#4CAF50" : "#CCC"}`,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </>
              ) : (
                ""
              )}
            </>
          )}
          {expanded === true ? (
            <IconButton>
              <ExpandLessIcon className={classes.iconUpDown} />
            </IconButton>
          ) : (
            <IconButton>
              <ExpandMoreIcon className={classes.iconUpDown} />
            </IconButton>
          )}
        </>
      }
    ></CardHeader>
  )
}
export default CustomHeaderContainer
