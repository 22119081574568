import React from "react"
import { useParams, useLocation, useHistory } from "react-router-dom"
import moment from "moment"
import * as API from "./api"
import * as Deps from "./deps"
import _ from "lodash"
import {
  sassAdminTranferModal,
  tenantTransferModal,
} from "../SassDashboard/transformModal"
import { useTranslation } from "react-i18next"

function useDashboardState(props) {
  const { t } = useTranslation()

  const { userPermAttr = {} } = props
  let getRoleName = localStorage.getItem("role")
  let getTenantId = localStorage.getItem("tenantId")
  getRoleName = getRoleName.toLocaleLowerCase()
  const isSassRole = localStorage.getItem("role")?.includes("SaaS-Site-Admin")
  const rolePermAttr = isSassRole
    ? sassAdminTranferModal(userPermAttr)
    : tenantTransferModal(userPermAttr)
  const pageHistory = useHistory()
  const pageParams = useParams()
  const pageLocation = useLocation()
  let defaultRetailerList = {
    docs: [],
    total: "",
  }
  const [isSyncLoad, setIsSyncLoad] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [currentMainTabIndex, setCurrentMainTabIndex] = React.useState(null)
  const [pageNumber, setPageNumber] = React.useState(
    props?.defaultList?.pageNumber || 0
  )
  const [rowsPerPage, setRowsPerPage] = React.useState(
    props?.defaultList?.rowsPerPage || 15
  )
  const [searchValue, setSearchValue] = React.useState(
    props?.defaultList?.searchTenantValue || ""
  )
  const [fetchAPIRetailerList, setFetchAPIRetailerList] = React.useState(
    props?.defaultList?.tenantList || defaultRetailerList
  )
  const refFieldValue = React.createRef()
  const [retailerList, setRetailerList] = React.useState([])
  const [selectedTenantId, setSelectedTenantId] = React.useState(
    props?.defaultList?.selectedTenantId || ""
  )

  const getTabIdValue = () => {
    const defaultList = Deps.getDefaultList({}, "", t)
    const { search = "" } = pageLocation
    let queryObj = Object.fromEntries(new URLSearchParams(search))
    let searchQry = queryObj?.[t("page")] || ""
    let retailer = queryObj?.[t("retailer")] || ""
    let subpage = queryObj?.[t("subpage")] || ""
    const getTabName = defaultList.map((i) => i[t("qureyName")])
    const getTabIndex = getTabName.indexOf(searchQry)

    return getTabIndex
  }

  const handleSaveTenantId = ({ value = {} }) => {
    const { id = "" } = value
    setSelectedTenantId(id)
  }

  const stateProps = {
    fetchAPIRetailerList,
    setFetchAPIRetailerList,
    pageHistory,
    pageParams,
    pageLocation,
    currentMainTabIndex,
    setCurrentMainTabIndex,
    isSyncLoad,
    setIsSyncLoad,
    handleSaveTenantId,
    setSelectedTenantId,
    selectedTenantId,
    pageNumber,
    setPageNumber,
    rowsPerPage,
    setRowsPerPage,
    isLoading,
    setIsLoading,
    refFieldValue,
    retailerList,
    setRetailerList,
    searchValue,
    setSearchValue,
    getRoleName,
    isSassRole,
    getTenantId,
    rolePermAttr,
    defaultRetailerList,
  }

  const getAPIRetailerList = (innerProps) =>
    API.getAPIRetailerList({ ...stateProps, ...innerProps })

  return {
    ...stateProps,
    getTabIdValue,
    getAPIRetailerList,
    rolePermAttr,
    t,
  }
}

export default useDashboardState
