import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"

import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import App from "./App"
import "./mocks"
import { Provider } from "react-redux"
import store from "./redux/store/index"
import HttpService from "../src/services/HttpService"
import UserService from "../src/services/UserService"
function Preload() {
  return (
    <Provider store={store}>
      <Suspense fallback={<div>Loading</div>}>
        <App />
      </Suspense>
    </Provider>
  )
}

export default Preload
