import React, { useMemo, useRef, useState, useEffect } from "react"
import PolygonAnnotation from "./PolygonAnnotation"
import { Stage, Layer, Image } from "react-konva"
import {
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Grid,
  colors,
  CardActions,
  TextField,
} from "@material-ui/core"
import { SaveIcon, AddIcon } from "@iconsGallery"
import PanToolIcon from "@material-ui/icons/PanTool"
import GestureIcon from "@material-ui/icons/Gesture"
import { DeleteIcon } from "@iconsGallery"
import { cloneDeep } from "lodash"
import usePolygonalEditorState from "./usePolygonalEditorStates"

const Button = ({ name, onClick, t }) => {
  return (
    <button
      style={{
        margin: 8,
        padding: "10px 16px",
        color: "white",
        backgroundColor: "#FF019A",
        border: "none",
        borderRadius: ".4rem",
      }}
      onClick={onClick}
    >
      {name}
    </button>
  )
}

const wrapperStyle = {
  display: "flex",
  justifyContent: "center",
  margin: 30,
  backgroundColor: "aliceblue",
  borderRadius: "50px",
  height: "78vh",
}
const columnStyle = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  marginTop: 20,
  backgroundColor: "aliceblue",
}

export const renderButtons = (props) => {
  const {
    setPolyComplete,
    isPolyComplete,
    layerList,
    dragPolygon,
    setDragPolygon,
    addClicked,
    setShowSnackBar,
    setSnackBarMessage,
    setSnackBarMessageType,
    t,
  } = props
  return (
    <Grid item xs={0.5} style={{ marginTop: "30px" }}>
      <Card style={{ borderRadius: "15px", width: "75px", height: "150px" }}>
        <Grid container style={{ padding: "10px" }}>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <IconButton
              aria-label="drag"
              size="large"
              onClick={() => {
                setPolyComplete(true)
                setDragPolygon(true)
              }}
              style={{
                backgroundColor: dragPolygon ? "var(--primaryColor)" : "",
                paddingLeft: "5px",
                pointerEvents: `${isPolyComplete ? "unset" : "none"}`,
              }}
            >
              <PanToolIcon
                fontSize="inherit"
                style={{
                  width: "40px",
                  height: "40px",
                  color: isPolyComplete ? "#000000" : "gray",
                }}
              />
            </IconButton>
          </Grid>
          <div style={{ border: "1px solid #b8b8b8", width: "100%" }}></div>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <IconButton
              aria-label="create"
              size="large"
              onClick={() => {
                if (addClicked) {
                  setDragPolygon(false)
                  if (layerList?.length <= 50) {
                    setPolyComplete(false)
                  }
                } else {
                  setShowSnackBar(true)
                  setSnackBarMessage(
                    t("Please click the add(+) button on the layers.")
                  )
                  setSnackBarMessageType("warning")
                }
              }}
              style={{
                backgroundColor: !isPolyComplete ? "var(--primaryColor)" : "",
                paddingLeft: "10px",
              }}
            >
              <GestureIcon
                fontSize="inherit"
                style={{ width: "40px", height: "40px", color: "#000000" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  )
}

export const renderPolygonEditor = (props) => {
  const {
    points,
    flattenedPoints,
    handlePointDragMove,
    handleGroupDragEnd,
    handleMouseOverStartPoint,
    handleMouseOutStartPoint,
    isPolyComplete,
    handleMouseMove,
    handleMouseDown,
    size,
    originalSize,
    imageRef,
    image,
    undo,
    reset,
    dataRef,
    dragPolygon,
    t,
  } = props
  return (
    <Grid item xs={9}>
      <div style={wrapperStyle}>
        <div style={columnStyle}>
          <Stage
            width={size.width || 650}
            height={size.height || 302}
            onMouseMove={handleMouseMove}
            onMouseDown={handleMouseDown}
          >
            <Layer>
              <Image
                ref={imageRef}
                image={image}
                x={0}
                y={0}
                width={size.width}
                height={size.height}
              />
              <PolygonAnnotation
                points={points}
                flattenedPoints={flattenedPoints}
                handlePointDragMove={handlePointDragMove}
                handleGroupDragEnd={handleGroupDragEnd}
                handleMouseOverStartPoint={handleMouseOverStartPoint}
                handleMouseOutStartPoint={handleMouseOutStartPoint}
                isFinished={isPolyComplete}
                size={size}
                originalSize={originalSize}
                dragPolygon={dragPolygon}
              />
            </Layer>
          </Stage>
        </div>
      </div>
    </Grid>
  )
}

export const renderPolygonLayers = (props) => {
  const {
    handleLayerSelection,
    handleDeleteLayer,
    layerList,
    savePolygonAnnotation,
    setPolyComplete,
    setPoints,
    handleAddLayer,
    handleLayerNameChange,
    handleLayerNameEdit,
    isPolyComplete,
    setEnableCloseButton,
    t,
  } = props
  return (
    <Grid item xs={2} style={{ marginTop: "30px" }}>
      {layerList.length === 0 && (
        <p style={{ color: "white" }}>
          {`${t(" Please draw atleast one Polygonal layer")}`}
        </p>
      )}
      <Card
        style={{
          maxHeight: "736px",
          overflow: "auto",
          backgroundColor: "#bebebe",
        }}
      >
        <CardHeader
          style={{
            borderBottom: "1px solid #b8b8b8",
            backgroundColor: "#f2f2f2",
          }}
          title="Layer"
          action={
            <IconButton
              style={{ pointerEvents: `${isPolyComplete ? "unset" : "none"}` }}
              aria-label="settings"
              onClick={() => {
                if (layerList?.length <= 50) {
                  setPolyComplete(false)
                  setEnableCloseButton(true)
                  setPoints([])
                  handleAddLayer()
                }
              }}
            >
              <AddIcon
                style={{
                  color: isPolyComplete ? "#000000" : "gray",
                }}
              />
            </IconButton>
          }
        />
        <CardContent style={{ padding: "0px" }}>
          {layerList.map((item) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "50px",
                  padding: "8px",
                  borderBottom: "0.5px solid lightgrey",
                  backgroundColor: item.isSelected
                    ? "var(--primaryColor)"
                    : "#dadbdc",
                  pointerEvents: `${
                    isPolyComplete || item.layerName === "Untitled"
                      ? "unset"
                      : "none"
                  }`,
                }}
                onClick={() => handleLayerSelection(item)}
              >
                {!item.isEdit ? (
                  <span
                    style={{
                      marginTop: "6px",
                      fontWeight: "bold",
                      fontSize: "medium",
                      cursor: "pointer",
                      pointerEvents: `${
                        isPolyComplete || item.layerName === "Untitled"
                          ? "unset"
                          : "none"
                      }`,
                    }}
                    onClick={() => {
                      handleLayerNameEdit(item.id)
                    }}
                  >
                    {item.layerName}
                  </span>
                ) : (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={item.layerName}
                    onChange={(event) => {
                      handleLayerNameChange(event?.target?.value, item.id)
                    }}
                    onBlur={() => {
                      if (!item.isDuplicate) {
                        handleLayerNameEdit("")
                      }
                    }}
                    error={item.isDuplicate}
                    helperText={
                      item.isDuplicate && `${t("Please enter unique name")}`
                    }
                  />
                )}
                <IconButton
                  aria-label="delete"
                  size="small"
                  style={{
                    textAlign: "right",
                    pointerEvents: `${
                      isPolyComplete || item.layerName === "Untitled"
                        ? "unset"
                        : "none"
                    }`,
                  }}
                  onClick={() => {
                    handleDeleteLayer(item)
                  }}
                >
                  <DeleteIcon
                    style={{
                      color:
                        isPolyComplete || item.layerName === "Untitled"
                          ? "#000000"
                          : "gray",
                    }}
                  />
                </IconButton>
              </div>
            )
          })}
        </CardContent>
      </Card>
    </Grid>
  )
}
