import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles({
  sliderCSS: {
    color: "var(--primaryColor)",
    padding: "8px 0",
    "& .MuiSlider-thumb": {
      width: "15px",
      height: "15px",
    },
    "& .MuiSlider-rail": {
      height: "5px",
    },
    "& .MuiSlider-track": {
      height: "5px",
    },
  },
  cls_img_item_grid: {
    width: "38.5vw",
    height: "66vh",
  },
  cls_img_aug: {
    padding: "5px",
    width: "33vw",
    height: "66vh",
    border: "1px solid #c7cdd5",
  },
  cls_main_aug_grid: {
    maxHeight: "80vh",
    overflowY: "scroll",
  },
  cls_aug_grid: { width: "100%", padding: "25px" },
})
