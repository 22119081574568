import * as React from "react"
import { Card } from "@material-ui/core"
import _ from "lodash"
import { useFacetUIState } from "./state"
import { FormfacetUI } from "./form"
import { View } from "./View"
import * as Adm from "@adm"
import styled from "styled-components"
import { customRenderContainer, customRenderHeader } from "./renderProps"
import { useTranslation } from "react-i18next"
const CardWrapper = styled(Card)`
  width: 100%;
  box-shadow: 0px 0px 0px rgba(63, 63, 68, 0.05),
    0px 1px 2px rgba(63, 63, 68, 0.15);
  border-radius: 8px;
`

function FacetUI(props) {
  const {
    title = "facets",
    list = [],
    onUpdate = () => "",
    resetData = () => "",
    setOpenConfirmationDialog = () => "",
    isEditMode = true,
    isOpenMode = false,
    listColumnSplit = false,
    inputField = false,
    setAllowPrompt = () => { },
    isDetailedAttribute = false,
    keyPath = 'id',
    valuePath = "value",
    isWriteAllowed = false,
  } = props || {}
  const facetState = useFacetUIState(props)
  const { data, setData, isAnyChange, setAnyChange } = facetState
  const { t } = useTranslation()
  React.useEffect(() => setData(list || []), [list])

  const onUpdateData = async () => {
    onUpdate({ ...facetState })
  }
  const stateProps = { isAnyChange, setAnyChange, setOpenConfirmationDialog, setAllowPrompt, isWriteAllowed, t }

  return (
    <CardWrapper>
      <Adm.AccordianPanel
        isOpen={isOpenMode}
        isEditMode={isEditMode}
        renderHeader={(headrProps) =>
          customRenderHeader({ ...headrProps, ...stateProps, setAllowPrompt })
        }
        renderContainer={(containerProps) =>
          customRenderContainer({
            ...containerProps,
            facetData: data,
            facetSetData: setData,
            listColumnSplit,
            inputField,
            isDetailedAttribute,
            keyPath,
            setAllowPrompt,
            valuePath,
            ...stateProps,
          })
        }
        label={title}
        updateData={onUpdateData}
        resetData={resetData}
      />
    </CardWrapper>
  )
}
export { FacetUI, View as FacetUIView, FormfacetUI as FacetUIForm }
export default FacetUI
