import { makeStyles } from "@material-ui/styles"
import { Grid } from "@progress/kendo-react-grid"
import styled from "styled-components"
import { createMuiTheme } from "@material-ui/core"
export const MainWrapperTableUIGrid = styled("div")`
  .cls-image-k-url {
    min-width: 70px;
    min-height: 70px;
    max-width: 70px;
    max-height: 70px;
    padding: 5px;
    border-radius: 6px;
    border: 1px solid #000;
    cursor: pointer;
  }

  &.cls-grid-ui-card.cls-card-ui-kit {
    margin: 10px;
    td[role="gridcell"]:has(> .k-checkbox-label) {
      position: absolute;
      right: 6px;
      top: 6px;
    }

    tr.k-state-selected > td {
      background: transparent !important;
    }
    .k-master-row {
      cursor: pointer;
      td[aria-selected="false"] {
        position: absolute;
        left: 95px;
        top: 6px;
      }
      td[aria-colindex="3"] {
        top: 10px;
        font-weight: bold !important;
        display: inline-block;
        text-overflow: ellipsis;
        width: calc(100% - 150px);
      }
      td[aria-colindex="4"] {
        top: 35px;
      }
      .k-checkbox {
        transform: scale(1.5);
        border-radius: 100%;
      }
      position: relative !important;
      box-shadow: 2px 2px 1px #0000005e;
      border: 1px solid #c7c7c7;
      &.k-state-selected {
        box-shadow: 2px 2px 1px var(--primaryColor);
        border: 1px solid var(--primaryColor);
      }
    }
    .k-grid-table {
      tbody[role="presentation"] {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 10px;
        row-gap: 10px;
        column-gap: 10px;
        padding: 15px;

        tr.k-master-row {
          display: flex !important;
          background: #fff !important;
          padding: 10px;
          border-radius: 10px;
          min-height: 126px;
          max-height: 126px;
          height: 126px;
          td {
            display: flex !important;
            border: none;
            font-weight: bold !important;
            &.cls-td-kit-ele.cls-td-kit-25 .cls-td-kit-action-button {
              display: none !important;
            }
          }
          td[aria-colindex="3"] {
            top: 30px;
            font-weight: 400 !important;
            display: inline-block !important;
            text-overflow: ellipsis !important;
            width: calc(100% - 160px);
            margin-left: 15px;
          }
          td[aria-colindex="4"] {
            top: 50px;
            font-weight: 400 !important;
            display: inline-block !important;
            text-overflow: ellipsis !important;
            width: calc(100% - 150px);
            margin-left: 15px;
          }
          td[aria-colindex="5"] {
            top: 70px;
            font-weight: 400 !important;
            display: inline-block !important;
            text-overflow: ellipsis !important;
            width: calc(100% - 120px);
            margin-left: 15px;
          }
          td[aria-colindex="6"] {
            top: 95px;
            font-weight: 400 !important;
            display: inline-block !important;
            text-overflow: ellipsis !important;
            width: calc(100% - 120px);
            margin-left: 15px;
          }
          td[aria-colindex="7"] {
            top: unset;
            bottom: 4px;
            display: inline-block !important;
            text-overflow: ellipsis !important;
            width: calc(100% - 150px);
            left: 7px;
            width: calc(100% - 180px) !important;
            font-size: 13px;
            font-style: italic;
            margin-left: 15px;
          }
        }
      }
    }
    background: red;
    .k-grid-header,
    colgroup[role="presentation"] {
      display: none;
    }
  }
  &.cls-grid-ui-card.cls-card-image-gallery {
    margin: 10px;
    .k-checkbox:checked {
      background-color: #009900;
      border-color: #009900;
    }
    .cls-image-k-url {
      min-width: 100%;
      min-height: 100%;
      max-width: 100%;
      max-height: 100%;
      padding: 0;
      border-radius: 0;
      border: 0;
      cursor: pointer;
    }
    td[role="gridcell"]:has(> .k-checkbox-label) {
      position: absolute;
      right: 0;
      top: 40px;
      bottom: 0;
      left: 0;
      margin: auto;
      width: fit-content;
      height: fit-content;
    }

    tr.k-state-selected > td {
      background: transparent !important;
    }
    .k-master-row {
      &:hover {
        box-shadow: 2px 2px 1px var(--primaryColor);
      }
      td[aria-selected="false"] {
        position: absolute;
        left: 95px;
        top: 6px;
      }
      td[aria-colindex="3"] {
        top: 10px;
        font-weight: bold !important;
        display: inline-block;
        text-overflow: ellipsis;
        width: calc(100% - 150px);
      }
      td[aria-colindex="4"] {
        top: 35px;
      }
      .k-checkbox {
        transform: scale(2);
        border-radius: 100px;
        margin-top: 10px;
      }
      position: relative !important;
      box-shadow: 2px 2px 1px #0000005e;
      border: 1px solid #c7c7c7;
      &.k-state-selected {
        box-shadow: 2px 2px 1px var(--primaryColor);
        border: 1px solid var(--primaryColor);
      }
    }
    .k-grid-table {
      tbody[role="presentation"] {
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        grid-row-gap: 10px;
        row-gap: 10px;
        column-gap: 10px;
        padding: 15px;

        .cls-td-kit-ele.cls-td-kit-03 {
          width: 100%;
          height: 100%;
        }
        tr.k-master-row {
          display: flex !important;
          background: #fff !important;
          padding: 10px;
          border-radius: 10px;
          min-height: 110px;
          max-height: 110px;
          height: 110px;
          td {
            display: flex !important;
            border: none !important;
            &.cls-td-kit-ele.cls-td-kit-25 {
              display: none !important;
            }
          }
          td[aria-colindex="3"] {
            top: 10px;
            font-weight: bold !important;
            display: inline-block !important;
            text-overflow: ellipsis !important;
            width: calc(100% - 150px);
          }
          td[aria-colindex="4"] {
            top: 35px;
            display: inline-block !important;
            text-overflow: ellipsis !important;
            width: calc(100% - 150px);
          }
          td[aria-colindex="5"] {
            top: 60px;
            display: inline-block !important;
            text-overflow: ellipsis !important;
            width: calc(100% - 150px);
          }
          td[aria-colindex="6"] {
            top: 85px;
            display: inline-block !important;
            text-overflow: ellipsis !important;
            width: calc(100% - 150px);
          }
          td[aria-colindex="7"] {
            top: unset;
            bottom: 4px;
            display: inline-block !important;
            text-overflow: ellipsis !important;
            width: calc(100% - 150px);
            left: 7px;
            border-top: 1px dashed #c7c7c7 !important;
            width: calc(100% - 34px) !important;
            font-size: 13px;
            font-style: italic;
          }
        }
      }
    }
    background: red;
    .k-grid-header,
    colgroup[role="presentation"] {
      display: none;
    }
  }

  .cls-td-kit-ele.cls-td-kit-03 {
    padding: 0 !important;
  }

  &.cls-grid-ui-card.cls-card-ui-kit.cls-card-ui-progress-kit
    .k-grid-table
    tbody[role="presentation"]
    tr.k-master-row {
    min-height: 135px;
    max-height: 180px;
    height: 135px;
  }
  &.cls-grid-ui-card.cls-card-ui-kit.cls-card-ui-progress-kit.cls-card-ui-checkbox-hide
    .k-checkbox {
    display: none !important;
  }
  &.cls-card-ui-kit.cls-card-showFour-dataset {
    td[role="gridcell"]:has(> .k-checkbox-label) {
      top: 6px;
      right: 0px;
    }
    .cls-image-k-url {
      max-height: 70px;
      margin-left: 15px;
      margin-right: 15px;
    }
    margin-top: 0px;
    .k-grid {
      border: 0px;
      box-shadow: unset;
      padding: 10px;
    }
    .k-grid-table {
      tbody[role="presentation"] {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 10px;
        row-gap: 10px;
        column-gap: 10px;
        padding: 15px;
      }
    }
    tr.k-master-row {
      box-shadow: 0px 1px 1px #0000005e;
      td[aria-colindex="6"] {
        top: unset !important;
        border-top: 1px dashed #c7c7c7 !important;
        bottom: 4px;
        display: inline-block !important;
        text-overflow: ellipsis !important;
        width: calc(100% - 150px);
        left: 7px;
        width: calc(100% - 34px) !important;
        font-size: 13px;
        font-style: italic;
      }
      .k-checkbox {
        transform: scale(1.1);
        border-radius: 0px;
      }
      &.k-state-selected {
        box-shadow: unset !important;
        border: 1px solid #c7c7c7;
      }
    }
  }
  &.cls-card-showFour-dataset-hide-checkbox {
    .k-checkbox {
      display: none;
    }
  }
  .cls-progress-bar-aug-wrapper {
    position: absolute;
    bottom: 40px;
    left: 10px;
    width: calc(100% - 20px);
    height: 40px;
  }
  .cls-progress-augument-processing {
    position: absolute;
    bottom: 7px;
    right: 0px;
    color: #cf4b4b;
    font-size: 14px;
    font-weight: 700;
    padding: 3px;
    text-overflow: ellipsis;
    width: calc(100% - 160px);
    overflow: hidden;
    text-align: right;
  }
  .cls-progress-augument-completed {
    position: absolute;
    bottom: 7px;
    right: 3px;
    color: #4cb64c;
    font-size: 14px;
    font-weight: 700;
    padding: 3px;
    text-overflow: ellipsis;
    width: calc(100% - 120px);
    overflow: hidden;
    text-align: right;
  }
  .cls-dotted-line {
    position: absolute;
    bottom: 35px;
    right: 0px;
    width: 100%;
    border-top: 1px dashed #c7c7c7 !important;
  }
  .cls-progress-bar-aug {
    width: 100%;
    height: 7px;
    border: 1px solid var(--primaryColor);
    border-radius: 10px;
  }
  .cls-progress-bar-kit {
    height: 100%;
    background: var(--primaryColor);
    border-radius: 10px;
  }
`
export const TemplateTableUIGrid = styled(Grid)`
  &.k-grid {
    background-color: #ffffff;
    box-shadow: 1px 2px 3px #0000002b;
  }
   &.k-grid tr.k-state-selected > td{
    background-color: #F9F9FC;
  }
 .k-checkbox:checked{
    background-color: var(--primaryColor);
    border-Color: var(--primaryColor);
  }
  &.k-animation-container{
    z-index: unset
  }
 .k-grid-table {
    width: 100% !important
  }
  .k-grid-header table {
     width: 100% !important
  }
  .k-header {
    background: #f4f7fa;

    .k-link {
      color: #000000;
      font-weight: 500;
      padding: 15px 12px !important;
    }
  }
  .k-filter-row {
    background: #ffffff;

    .k-dropdown .k-dropdown-wrap {
      background: white;
      border: none;
    }
    .k-button {
      background: white;
      border: none;
    }

    .k-i-filter::before {
      color: #353535;
    }
    .k-textbox {
      border-top: none;
      border-left: none;
      border-right: none;
      background: transparent;
    }
  }

  &.k-grid td {
    border-bottom: 1px solid #e0e0e0;
  }
  .k-filter-row th {
    padding: 16px 8px;
  }
  .k-master-row,
  .k-pager-wrap {
    background: transparent !important;
    cursor: ${(props) => (props.cursor ? "pointer" : "")};
  }
  .k-pager-numbers .k-link {
    color:  #000;
  }
  .k-pager-numbers .k-link.k-state-selected {
     color: var(--primaryColor);
    background-color: #f9f9fc;
    border-color: var(--primaryColor) !important;
    box-shadow: none !important; 
  }
   .k-pager-numbers .k-link:active {
    border-right: 1px solid var(--primaryColor) !important;
    border-color: var(--primaryColor) !important;
    color: var(--primaryColor) !important ;
    background-color: #f9f9fc !important;
  }
   .k-pager-numbers .k-link:focus, .k-pager-numbers .k-link.k-state-focus {
    box-shadow: none !important;
  }
  .k-checkbox {
    border: 2px solid #8c8c8c;
  }
  .bRDGfF {
    border-bottom: 1px solid #fefefe52;
  }

  .k-grid tbody td {
    padding: 0;
  }
  &.k-grid td {
    padding: 0.4rem 10px;
    border-style: solid;
    outline: 0;
    font-weight: inherit;
    text-align: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.k-grid th {
    padding: 0.4rem 0.4rem;
    border-style: solid;
    outline: 0;
    font-weight: inherit;
    text-align: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &.k-grid .k-filter-row th:empty {
    padding: 0 !important;
  }
  &.k-grid td,
  .k-grid .k-grid-content,
  .k-grid .k-grid-header-locked,
  .k-grid .k-grid-content-locked {
    border-color: transparent;
    border-bottom: 1px solid #e8e8e8;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .selected {
    margin-bottom: 10px,
    margin-left: 5px,
  }
.k-dropdown-operator {
    width: auto;
    display: none;
}
.k-icon-button, .k-button.k-button-icon {
    width: calc(0.2em + 0.2rem );
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.1rem;
}
.k-pager-info.k-label{
  display: block
}
.k-checkbox{
  margin-bottom: 6px;
  margin-left: 6px;
}
.k-pager-nav.k-link{
  color: #000;
}
.k-dropdown {
  background-color: #f9f9fc;
}
.k-grid-header .k-i-sort-asc-sm, .k-grid-header .k-i-sort-desc-sm{
   color:  #000;
   place-self: flex-start;
   position: absolute;
   top: 40%;
   right: 5px;
}
.k-grid th.k-state-selected, .k-grid td.k-state-selected, .k-grid tr.k-state-selected > td{
      background-color: var(--primaryColor);
}
.k-grid-header{
  padding: 0px
}
`

export const useStyles = makeStyles((theme) => ({
  headerActionsContainer: {
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #FFFFFF",
    backgroundColor: "#FFFFFF",
    display: "flex",
    justifyContent: "space-between",
  },
  enableBgColor: {
    backgroundColor: "#FFFF",
  },
}))

export const theme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "var(--primaryColor)",
      },
    },
    MuiPickersClockNumber: {
      clockNumberSelected: {
        backgroundColor: "var(--primaryColor)",
        color: "var(--white)",
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: "var(--primaryColor)",
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: "var(--primaryColor)",
      },
      thumb: {
        backgroundColor: "var(--primaryColor)",
        border: "14px solid var(--primaryColor)",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "var(--primaryColor)",
      },
    },
    MuiOutlinedInput: {
      root: {
        height: "36px",
        "&$focused $notchedOutline": {
          borderColor: "var(--primaryColor) ",
          borderWidth: 1,
        },
      },
      adornedEnd: {
        paddingRight: "0px",
      },
    },

    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "var(--primaryColor)",
        },
      },
    },
  },
})
