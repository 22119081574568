import * as languagePage from "./rtl_style_Global"

let kendorlt = `

body#id-rlt .cls-main-global-table-kit div[role="tooltip"].MuiTooltip-popper {
  left: -76vw !important;
}

body#id-rlt
  .cls-main-global-table-kit
  .cls-td-kit-ele.cls-td-kit-4
  div[class*="makeStyles-tootlipEllipse-support"],
body#id-rlt
  .cls-main-global-table-kit
  .cls-td-kit-ele.cls-td-kit-20
  .MuiTypography-body1,
body#id-rlt
.cls-main-global-table-kit
.cls-td-kit-ele.cls-td-kit-24
.MuiTypography-body2,
body#id-rlt
  .cls-main-global-table-kit
  .cls-td-kit-ele.cls-td-kit-18
  .MuiTypography-body1,
body#id-rlt .cls-main-global-table-kit .cls-td-kit-ele.cls-td-kit-15 p,
body#id-rlt .cls-main-global-table-kit .k-grid-norecords td,
body#id-rlt .cls-main-global-table-kit .cls-RoleDropdown-kit p,
body#id-rlt .cls-main-global-table-kit .cls-gridNoRecordsText-kit,
body#id-rlt .cls-main-global-table-kit td.cls-td-kit-2 .MuiTypography-body2,
body#id-rlt .cls-main-global-table-kit td div[class*="makeStyles-enabledBox-support"],
body#id-rlt .cls-main-global-table-kit td div[class*="makeStyles-disabledBox-support"],
body#id-rlt .cls-main-global-table-kit table thead tr th .k-column-title,
body#id-rlt
  .cls-main-global-table-kit
  .k-grid-content.k-virtual-content
  td[role="gridcell"],
body#id-rlt .cls-main-global-table-kit .k-filtercell-wrapper input {
  transform: rotate(0deg) scaleX(-1) !important;
}

body#id-rlt .cls-main-global-table-kit .cls-td-kit-ele.cls-td-kit-23 span {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block;
}

body#id-rlt .cls-main-global-table-kit .k-pager-numbers.k-reset li a,
body#id-rlt .cls-main-global-table-kit .k-pager-info.k-label,
body#id-rlt .cls-main-global-table-kit .k-pager-sizes {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: left !important;
}

body#id-rlt .cls-main-global-table-kit td .MuiSwitch-root {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-main-global-table-kit .k-pager-sizes {
  display: flex;
  flex-direction: row-reverse;
}

body#id-rlt .cls-main-global-table-kit .k-widget.k-dropdown {
  margin-left: 10px !important;
}

body#id-rlt .cls-main-global-table-kit .cls-td-kit-ele input[type="text"],
body#id-rlt
  .cls-main-global-table-kit
  .k-grid-content.k-virtual-content
  td[role="gridcell"],
body#id-rlt
  .cls-main-global-table-kit
  .cls-td-kit-ele.cls-td-kit-4
  div[class*="makeStyles-tootlipEllipse-support"],
body#id-rlt
  .cls-main-global-table-kit
  .cls-td-kit-ele.cls-td-kit-20
  .MuiTypography-body1,
body#id-rlt
  .cls-main-global-table-kit
  .cls-td-kit-ele.cls-td-kit-18
  .MuiTypography-body1,
body#id-rlt .cls-main-global-table-kit .cls-td-kit-ele.cls-td-kit-15 p, body#id-rlt
.cls-main-global-table-kit
.cls-td-kit-ele.cls-td-kit-24
.MuiTypography-body2 {
  text-align: right !important;
}

body#id-rlt
  .cls-main-global-table-kit
  td.cls-td-kit-ele.cls-td-kit-checkbox
  .cls-checkbox-kit
  span.cls-CheckboxCell-kit {
  width: 100%;
  color: rgb(175, 175, 175);
  transform: rotate(0deg) scaleX(-1) !important;
  display: block;
  text-align: right;
}

body#id-rlt .cls-main-global-table-kit .k-filtercell-wrapper input,
body#id-rlt .cls-main-global-table-kit td.cls-td-kit-2 .MuiTypography-body2 {
  text-align: right !important;
}
body#id-rlt .cls-main-global-table-kit .k-filtercell .k-input {
  display: block;
  transform: rotate(0deg) scaleX(-1) !important;
  width: 100%;
  text-align: right;
}

body#id-rlt span.MuiButton-label {
  display: flex;
  transform: rotate(0deg) scaleX(-1) !important;
  flex-direction: row-reverse;
}

body#id-rlt span.MuiButton-label .MuiButton-startIcon.MuiButton-iconSizeMedium {
  margin-left: 5px;
}

body#id-rlt h4[class*="makeStyles-headerTitle-support"],
body#id-rlt .cls-main-global-table-kit thead tr th input[type="checkbox"] {
  transform: rotate(0deg) scaleX(-1) !important;
}

body#id-rlt .cls-main-global-table-kit
  .k-master-row.k-grid-edit-row
  td[role="gridcell"]
  input[type="checkbox"] {
  transform: unset !important;
}

body#id-rlt span.MuiButton-label .MuiButton-startIcon.MuiButton-iconSizeMedium {
  margin-left: 5px;
}

body#id-rlt
  .cls-main-global-table-kit
  .cls-td-kit-ele.cls-td-kit-10
  .cls-ussrname-icon-kit,
body#id-rlt
  .cls-main-global-table-kit
  .cls-td-kit-ele.cls-td-kit-10
  .cls-ussrname-kit {
  transform: rotate(0deg) scaleX(-1) !important;
  margin-right: 10px;
}

body#id-rlt
  div[role="presentation"].MuiDrawer-modal
  p[class*="makeStyles-headerText-support"],
body#id-rlt
  div[role="presentation"].MuiDrawer-modal
  p[class*="makeStyles-listText-support"],
body#id-rlt
  div[role="presentation"].MuiDrawer-modal
  p[class*="makeStyles-subHeaderText-support"] {
  transform: rotate(0deg) scaleX(-1) !important;
}

body#id-rlt .k-animation-container {
  direction: rtl !important;
}

body#id-rlt .k-animation-container div div {
  width: 100%;
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .k-animation-container {
  transform: unset !important;
  margin: auto !important;
  position: fixed !important;
  right: 0 !important;
  bottom: 0 !important;
  top: 0px !important;
  left: 0px !important;
  height: fit-content !important;
  box-shadow: 0px 0px 8px 1110px #00000050 !important;
}

body#id-rlt
 .cls-enabledToggle
  span:last-child {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-main-global-table-kit td.cls-td-kit-ele.cls-td-kit-11
{
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
/**
 * MasterData Products Imp/Exp
 */
 
body#id-rlt .cls-dk-link-btn-skip {
  display: flex;
  transform: rotate(0deg) scaleX(-1) !important;
  flex-direction: row-reverse;
  left:495px;
}
body#id-rlt .cls-dk-error-wrapper {
  transform: rotate(0deg) scaleX(-1) !important;
}
   
`

let sideBar = `
    body#id-rlt div[role="tooltip"].MuiTooltip-popper {
            left: -76vw !important;
            
    }
    body#id-rlt div[role="tooltip"].MuiTooltip-popper#id-kit-test-tooltip {
      right: 50% !important;
      left: unset !important;
            
    }
    body#id-rlt
  div[role="tooltip"].MuiTooltip-popper#id-kit-test-tooltip
  .makeStyles-customTooltip-support-kit
  p {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right;
}
    
    body#id-rlt .cls-CategoryIconLess-icon-kit,
    body#id-rlt .cls-CategoryIconMore-icon-kit,
    body#id-rlt .cls-icon-sidebar-right-kit
     {
        transform: unset !important;
    }

    body#id-rlt .cls-header-category-link-kit,
    body#id-rlt .cls-header-category-kit,
    body#id-rlt .cls-header-category-pop-link-kit,
    body#id-rlt .cls-sub-header-category-kit {
        transform: rotate(0deg) scaleX(-1) !important;
        display: flex !important;
        width: fit-content !important;
        text-align: right !important;
    }
    
`
let header = `

body#id-rlt .cls-list-menu-user-drop .cls-common-text-head,
body#id-rlt .cls-list-menu-user-drop .cls-common-usrdd-kit {
  transform: rotate(0deg) scaleX(-1) !important;
}

body#id-rlt .cls-list-menu-user-drop .cls-common-usrdd-kit,
body#id-rlt .cls-dialog-kit .cls-dialog-header-kit,
body#id-rlt .cls-list-menu-user-drop .cls-profile-emialUpdate-kit span {
  display: inline-block !important;
}

body#id-rlt .cls-dialog-kit .cls-common-text-head,body#id-rlt input[type="password"] {
  text-align: right !important;
}
body#id-rlt .cls-list-menu-user-drop .cls-profile-emialUpdate-kit p {
  display: table;
}
body#id-rlt .cls-list-menu-user-drop .cls-profile-emialUpdate-kit span,
body#id-rlt .cls-dialog-kit .cls-dialog-header-kit,
body#id-rlt .cls-dialog-kit .cls-common-text-head,
body#id-rlt .cls-list-menu-user-drop .cls-profile-emialUpdate-kit p {
  transform: rotate(0deg) scaleX(-1) !important;
}

body#id-rlt .cls-uddr-menu-kit .MuiPopover-paper {
  right: 20px !important;
  left: unset !important;
}

body#id-rlt .cls-demo-simple-select-standard-kit .MuiSelect-select {
  transform: rotate(0deg) scaleX(-1) !important;
  padding-left: 24px !important;
  padding-right: 0px !important;
}

body#id-rlt .cls-header-language-kit.MuiPopover-paper .cls-common-text-lan {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-header-language-kit.MuiPopover-paper {
  right: 70px !important;
  left: unset !important;
}
body#id-rlt .cls-avathar-text-icon {
  transform: rotate(0deg) scaleX(-1) !important;
}
`

let breadcrumbs = `
body#id-rlt .cls-breadcrumbs .MuiBreadcrumbs-li,
body#id-rlt .cls-breadcrumbs .MuiBreadcrumbs-separator,
body#id-rlt .cls-breadcrumbs button[class*="btntxt"] {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block;
}
body#id-rlt .cls-breadcrumbs .MuiBreadcrumbs-separator {
  transform: rotate(0deg) scaleX(-1) !important;
  display: flex;
  margin-top: 2px;
}
body#id-rlt .cls-menuchevron {
  transform: rotate(0deg) scaleX(-1) !important;
  padding-top: 5px !important;
}
`
let toaster = `
body#id-rlt div.MuiAlert-message {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
`

let toaster_fileupload = `
body#id-rlt .MuiSnackbarContent-message {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt.cls-dropzone-upload-kit div.MuiDialog-root p,
body#id-rlt.cls-dropzone-upload-kit div.MuiDialog-root div.MuiDialogTitle-root h2 {
    transform: rotate(0deg) scaleX(-1) !important;
    text-align: right !important;
}
body#id-rlt.cls-dropzone-upload-kit div.MuiDialogContent-root span.MuiTypography-root.MuiTypography-subtitle1 {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  display: inline-block !important;
}

`

let global = `

    body#id-rlt {
        transform: rotate(0deg) scaleX(-1) !important;  
    }

    body#id-rlt input.search-box {
      transform: rotate(0deg) scaleX(-1) !important;
      text-align: right;
    }

    body#id-rlt label[data-shrink="false"] {
      font-size: 12px !important;
      background-color: white !important;
    }

    body#id-rlt .react-tel-input .country-list .flag {
      transform: rotate(0deg) scaleX(-1) !important;
    }
    body#id-rlt .react-tel-input .country-list .country-name {
      transform: rotate(0deg) scaleX(-1) !important;
      display: inline-block;
    }
    body#id-rlt .react-tel-input .country-list .dial-code {
      transform: rotate(0deg) scaleX(-1) !important;
      display: inline-block;
    }

    body#id-rlt .react-tel-input .country-list .search {
      z-index: 9;
    }

    body#id-rlt input[type="text"].cls-custom-time-input {
      transform: unset !important;
    }
    body#id-rlt img#unique-image {
        transform: none !important;
    }

    body#id-rlt img:not(body#id-rlt img#augmentimage, body#id-rlt img#unique-image),
    body#id-rlt svg:not(body#id-rlt [class*="MuiPickersCalendarHeader-switchHeader"] .MuiIconButton-label svg,
    body#id-rlt .cls-inventoryAdjustment-page .cls-search-grid-list-kit svg,
    body#id-rlt .cls-inventory-land-page .cls-search-grid-list-kit svg,
    body#id-rlt .cls-inventoryCounts-page .cls-search-grid-list-kit svg,
    body#id-rlt .cls-modeltraining-page .cls-search-grid-list-kit svg,
    body#id-rlt #simple-menu1-revenufilter  div[role="radiogroup"] svg,
    body#id-rlt div[class*="MuiDropzoneSnackbar"] svg,
    body#id-rlt .cls-common-cf-glb-kit .MuiIconButton-label svg.MuiSvgIcon-root,
    body#id-rlt .cls-checkbox-svg-kit .MuiIconButton-label svg.MuiSvgIcon-root),
    body#id-rlt canvas,
    body#id-rlt input[type="text"],
    body#id-rlt input[type="password"],
    body#id-rlt input[type="number"],
    body#id-rlt img {
        transform: rotate(0deg) scaleX(-1) !important;  
    }
    body#id-rlt div[role="presentation"].MuiAutocomplete-popper,
    body#id-rlt div[role="presentation"].cls-uddr-menu-kit .MuiPopover-paper,
    body#id-rlt div[role="presentation"] .cls-header-dropdownkit-kit,
    body#id-rlt div[role="presentation"] .cls-header-language-kit {
      box-shadow: 0px 0px 8px 300vw #00000050 !important;
    }

body#id-rlt
  .cls-main-global-table-kit
  div[class*="makeStyles-headerActionsContainer-support"]
  b {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}



    
    ${sideBar}

    ${header}

    ${breadcrumbs}

    ${toaster}

    ${toaster_fileupload}

    ${languagePage?.auth_SignIn}

    ${languagePage?.auth_SignUp}

    ${languagePage?.auth_ResetPassword}

    ${languagePage?.auth_Page404}

    ${languagePage?.auth_Page500}

    ${languagePage?.auth_Page403}

    ${languagePage?.spacePlanning_FrictionlessDetailedView}

    ${languagePage?.auth_ForgetPassword}

    ${languagePage?.auth_ProfileUpdate}

    ${languagePage?.SassDashboard}

    ${languagePage?.dashboard}
    body#id-rlt .cls-dashboard button span.cls-StyledSyncedButton-text {
      transform: unset !important;
      display: inline-block;
    }
    body#id-rlt .cls-admin-access {
              transform: rotate(0deg) scaleX(-1) !important; 
              text-align: end; 
    }

    ${languagePage?.content_countries}

    ${languagePage?.content_usStates}

    ${languagePage?.content_currencies}

    ${languagePage?.content_gender}

    ${languagePage?.content_canadianProvincesList}

    ${languagePage?.content_languages}

    ${languagePage?.content_unitsOfMeasure}

    ${languagePage?.content_barcodeTypes}

    ${languagePage?.content_tax}

    ${languagePage?.masterData_locations}

    ${languagePage?.masterData_locations_GroupLocation_GroupLocationsList}

    ${languagePage?.masterData_Hierarchy}

    ${languagePage?.masterData_HierarchyMapping_index}

    ${languagePage?.frictionlessStore_deviceConfig_ConfigurePos_index}

    ${languagePage?.frictionlessStore_deviceConfig_ConfigurePos_Pos}

    ${languagePage?.frictionlessStore_deviceConfig_ConfigurePos_CreatePos_index}

    ${languagePage?.frictionlessStore_deviceConfig_ConfigurePos_PosDetailed_index}

    ${languagePage?.masterData_products_ProductAttribute_index}

    ${languagePage?.masterData_products_ProductsList}

    ${languagePage?.masterData_products_ProductGroupPage}

    ${languagePage?.masterData_products_ProductHierarchyPage}

    ${languagePage?.masterData_products_ProductsMapping_ProductMapping}

    ${languagePage?.masterData_customers}

    ${languagePage?.masterData_price}

    ${languagePage?.transactions}

    ${languagePage?.trainingProducts}

    ${languagePage?.trainingProducts_create}

    ${languagePage?.userManagement_users}

    ${languagePage?.userManagement_rolePermission_rolePermissionList_index}

    ${languagePage?.userManagement_permission_index}

    ${languagePage?.settings_productcatalog_index}

    ${languagePage?.settings_ProductCatalogAdd_index}

    ${languagePage?.settings_productCatalogEdit_ProductCatalogEdit}

    ${languagePage?.settings_taxconfig_taxConfiglist}

    ${languagePage?.settings_avalaraconfig_AvalaraConfigList}

    ${languagePage?.settings_avalaraconfig_AvalaraCommon}


    ${languagePage?.settings_taxconfig_taxConfigAdd_index}

    ${languagePage?.settings_taxconfig_taxConfigEdit_index}

    ${languagePage?.userManagement_rolePermission_index}

    ${languagePage?.userManagement_rolePermissionTenant_index}

    ${languagePage?.system}

    ${languagePage?.editProfile}

    ${languagePage?.settings}

    ${languagePage?.help}

    ${languagePage?.support}

    ${languagePage?.privacy}

    ${languagePage?.settings_PaymentGatewayTerminal}

    ${languagePage?.settings_PaymentGatewayMobile}

    ${languagePage?.liveMonitoring_Devices}

    ${languagePage?.termsOfService}

    ${languagePage?.masterData_locations_CreateLocations}

    ${languagePage?.masterData_locations_GroupLocation_LocationGroupCreatePage}

    ${languagePage?.masterData_products_createGroupModel_ProductGroupCreatePage}

    ${languagePage?.masterData_locations_LocationDetailedPage_index}

    ${languagePage?.masterData_customers_CustomerDetailedPage}

    ${languagePage?.masterData_price_priceDetailedPage}

    ${languagePage?.masterData_products_GroupDetail_ProductGroupDetailedPage}

    ${languagePage?.masterData_customers_AddNewAddress}

    ${languagePage?.masterData_customers_CreateCustomers}

    ${languagePage?.masterData_price_CreatePrice}

    ${languagePage?.masterData_products_ProductCreatePage}

    ${languagePage?.masterData_products_ProductDetailedPage_ProductDetailedPage}

    ${languagePage?.masterData_products_GroupDetail}
    ${languagePage?.masterData_inventory_module}

    ${languagePage?.masterData_inventory_Report}

    ${languagePage?.status}

    ${languagePage?.transactions_EditOrder_EditOrder}

    ${languagePage?.tenant_index}

    ${languagePage?.userManagement_users_userCreateEdit_index}

    ${languagePage?.tenant_tenantCreateEdit_TenantCreateEdit}

    ${languagePage?.liveMonitoring_products_ProductsList}

    ${languagePage?.liveMonitoring_products_ProductDetailedPage}

    ${languagePage?.liveMonitoring_devices_DevicesList}

    ${languagePage?.liveMonitoring_devices_Stores_Devices}

    ${languagePage?.liveMonitoring_devices_Stores}

    ${languagePage?.liveMonitoring_devices_DeviceDetailedPage}

    ${languagePage?.spacePlanning_PlanogramDetailedView}

    ${languagePage?.spacePlanning_spacePlanningList_index}

    ${languagePage?.spacePlanning_spacePlanningList_FrictionlessStore_spacePlanningList}

    ${languagePage?.frictionlessStore_deviceConfig_ModalConfig_index}

    ${languagePage?.frictionlessStore_deviceConfig_ModalConfig_ModalConfig}

    ${languagePage?.logviewer_auditLogs_AuditLog}

    ${languagePage?.reports_detailedTransactionReport}

    ${languagePage?.reports_salesSummaryReport}

    ${languagePage?.reports_scheduledReport}

    ${languagePage?.reports_scheduledReport_ScheduledReportsCreateEdit}

    ${languagePage?.alerts_Alerts}

    ${languagePage?.alerts_NewAlert}

    ${languagePage?.alerts_configuration_index}

    ${languagePage?.alerts_monitor_index}

   body#id-rlt .cls-common-usr-ddp-kit {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-common-plain-txt-img-gif {
  transform: rotate(0deg) scaleX(-1) !important;
  display: block;
}
body#id-rlt .cls-common-plain-txt-img-select {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-flex;
}

body#id-rlt .cls-ledgent-input-label label {
  transform: translate(70px, -10px) rotate(0deg) scaleX(-1) !important;
  font-size: 12px;
  background: white;
}
body#id-rlt .cls-ledgent-input-label legend {
  font-size: 0px;
  height: 0;
  width: 0;
}

body#id-rlt .cls-ledgent-input-label.newkit label {
  transform: translate(70px, -10px) rotate(0deg) scaleX(-1) !important;
  font-size: 12px;
  background: white;
  position: absolute;
  left: -54px;
}

body#id-rlt .cls-common-dd-plain-txt-kit {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block !important;
}
body#id-rlt.cls-location-tree-comp
  #id-dropdown-common-postion-setup-kit
  .cls-common-dd-plain-txt-kit {
  transform: unset !important;
}



body#id-rlt.cls-location-tree-comp div[role="presentation"] {
  /* margin: auto !important;
  right: 0 !important;
  bottom: 0 !important;
  top: 0px !important;
  left: 0px !important;
  height: fit-content !important;
  position: fixed !important;
  box-shadow: 0px 0px 8px 1110px #00000050 !important; */
}
body#id-rlt.cls-location-tree-comp
  div[role="presentation"]
  .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
  margin: auto !important;
  right: 0 !important;
  bottom: 0 !important;
  top: 0px !important;
  left: 0px !important;
  height: fit-content !important;
  position: fixed !important;
  box-shadow: 0px 0px 8px 1110px #00000050 !important;
  max-width: 300px;
}

body#id-rlt .cls-common-dd-plain-txt-kit {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block !important;
}
body#id-rlt.cls-location-tree-comp
  #id-dropdown-common-postion-setup-kit
  .cls-common-dd-plain-txt-kit {
  transform: unset !important;
}

body#id-rlt.cls-location-service-comp
  div[role="presentation"]
  .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
  margin: auto !important;
  right: 0 !important;
  bottom: 0 !important;
  top: 0px !important;
  left: 0px !important;
  height: fit-content !important;
  position: fixed !important;
  box-shadow: 0px 0px 8px 1110px #00000050 !important;
  max-width: 300px;
}

body#id-rlt .cls-plain-lable-end-time,
body#id-rlt .cls-plain-lable-start-time {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block !important;
}

body#id-rlt header.MuiAppBar-root {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt
  .MuiListItem-root[role="option"]
  > .MuiListItemText-root
  > .MuiTypography-root {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}

body#id-rlt [class*="MuiPickersCalendarHeader-switchHeader"] svg {
  transform: unset !important;
}

body#id-rlt
  .cls-sidebar-Scrollbar-kit
  .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
  height: 100%;
  // height: 100vw;
}
body#id-rlt .cls-Backdrop-load-kit {
  height: 100vh;
}
.dk-loadBar {
  z-index: 99999 !important;
  display: flex !important;
  background: #fff !important;
  width: 200px !important;
  padding: 20px !important;
  border-radius: 10px !important;
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  top: 0 !important;
  margin: auto !important;
  box-shadow: 2px 4px 10px #0000006b !important;
  flex-direction: row !important;
  justify-content: center !important;
}
body#id-rlt .cls-loading-LoadingPanel-kit .dk-loadBar {
  transform: rotate(0deg) scaleX(-1) !important;
}

body#id-rlt .cls-Backdrop-load-kit {
  height: 100vh;
  width: 100vw;
}

body#id-rlt .cls-Backdrop-load-kit .dk-loadBar-brand-logo-kit {
  left: unset !important;
  margin: unset !important;
  bottom: unset !important;
  right: calc(100vw / 2 - 200px) !important;
  top: calc(100vh / 2 - 124px) !important;
}
body#id-rlt.cls-backdrop-overlay-kit {
  // overflow: hidden !important;
}
body#id-rlt .cls-ui-AppContent-kit .cls-Backdrop-load-kit {
  display: none !important;
}

body#id-rlt .cls-ui-AppContent-kit .cls-Backdrop-load-kit:nth-child(2) {
  display: block !important;
}


`

let rlt = `
    ${global}
    ${kendorlt}
    body#id-rlt div[role="dialog"].MuiDialog-root
    .MuiPickersBasePicker-pickerView
    .MuiPickersCalendarHeader-iconButton
    svg {
    transform: unset !important;
  }  
  body#id-rlt .cls-PaymentGatewayTerminal-page  .cls-paymentGatewayTerminal-norecord-icon {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-PaymentGatewayTerminal-page h3.MuiTypography-h3.cls-paymentGatewayTerminal-norecord-txt {
  text-align: center !important;
}
body#id-rlt .cls-sidebar-main-wrapper {
  background-color: #140316;
}

body#id-rlt .cls-access-umr-kit {
  transform: rotate(0deg) scaleX(-1) !important;
}

.cls-login-banner-text-page.cls-login-banner-text-page1 {
  position: absolute;
  left: 6%;
  top: 8vh;
  font-size: 40px;
  font-weight: bold;
  color: #fff;
}
.cls-login-banner-text-page.cls-login-banner-text-page2 {
  position: absolute;
  left: 6%;
  top: 15vh;
  font-size: 48px;
  font-weight: bold;
  color: #fff;
}

.direction_rtl.on .cls-login-banner-text-page {
  width: 50% !important;
  text-align: right !important;
  padding-right: 20px;
}

.direction_rtl.on input[type="text"] {
  text-align: right !important;
}

.direction_rtl.on .cls-textInput-support-kit .MuiInputBase-formControl {
  flex-direction: row-reverse;
}
.direction_rtl.on .cls-textInput-support-kit p.Mui-error {
  text-align: right !important;
}

.cls-textInput-support-kit {
  width: 100%;
  max-width: 400px;
}

body#id-rlt .cls-oph-itm-day-kit>div,
body#id-rlt .selectedall,
body#id-rlt .cls-oph-itm-time,
body#id-rlt .cls-oph-itm-allday-kit {
    transform: rotate(0deg) scaleX(-1) !important;
}

`
export default rlt
