import { makeStyles } from "@material-ui/core/styles"
export const useCalenderStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "fit-content",
    paddingLeft: "6px",
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: "var(--primaryColor)",
    },
  },
  time: {
    marginTop: "16px",
  },
  date: {
    width: 160,
  },
  dateNative: {
    marginTop: "16px",
    width: 200,
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: "var(--primaryColor)",
    },
  },
  locationDateNative: {
    marginTop: "0px",
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: "var(--primaryColor)",
    },
  }
}))
