import React, { useState, useEffect } from 'react'
import {
  IconButton,
  TextField,
  Grid,
  Card,
  Typography,
  Divider,
  Switch,
  InputAdornment,
  Radio,
  Checkbox
} from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { grey, blue } from "@material-ui/core/colors"
import _ from 'lodash'
import * as Adm from "@adm"

const PurpleSwitch = withStyles({
  switchBase: {
    color: grey[400],
    "&$checked": {
      color: blue[500],
    },
    "&$checked + $track": {
      backgroundColor: blue[500],
    },
  },
  checked: {},
  track: {},
})(Switch)

const AttributeTypeCheck = (props) => {
  const {
    value: { attribute_name, attribute_field_type, attribute_values, values },
    edit,
    setAnyChanges = () => { },
    updateData = () => { },
    index = -1,
    isAccordian = false,
    setAllowPrompt, } = props || {}

  const renderProps = (attribute_field_type, values = "") => {
    console.log("renderProps", values)
    const listDropDown = {
      list: attribute_values,
      defaultValue: values,
      placeholder: "Select",
      style: { width: '100%' },
      label: 'name',
      keyForValueReference: '_id',
      onUpdate: (value, event) => {
        if (value !== null) {
          setAllowPrompt(true)
          setAnyChanges(true)
        }
        updateData(value, index)
      }
    }

    console.log("attribute_field_typeattribute_values", attribute_field_type, attribute_values, values)
    switch (attribute_field_type) {
      case "List":
        return (
          <>
            <Adm.DropdownKit
              {...listDropDown}
            />
          </>
        )
      case "Text Box":
        return (
          <TextField
            variant="outlined"
            type="text"
            placeholder='Enter value'
            name={attribute_name}
            size="small"
            value={values}
            fullWidth
            onChange={(e) => {
              if (values !== null) {
                setAnyChanges(true)
                setAllowPrompt(true)
              }
              updateData(e.target.value, index)
            }}
          />
        )
      case "Boolean":
        return (
          <div className='cls-attribute-switch'>
            <PurpleSwitch
              checked={values}
              value={values}
              name={attribute_name}
              onChange={() => {
                if (values !== null) {
                  setAnyChanges(true)
                  setAllowPrompt(true)
                }
                updateData(!values, index)
              }}
            />
          </div>
        )
      case "Text Area":
        return (
          <Adm.RichTextWYSIWYG
            content={Array.isArray(values) ? values[0] : values}
            style={{ height: "300px" }}
            onUpdate={(e) => {
              if (values !== null) {
                setAnyChanges(true)
                setAllowPrompt(true)
              }
              updateData(e.html, index)
            }}
          />
        )
      case "Date":
        return (
          <div className='cls-attribute-calender'>
            <Adm.Calender
              isTimeEnable={false}
              fullWidth
              style={{ width: '100%', marginTop: '0px' }}
              date={values}
              allowFutureDate={true}
              onUpdateCalender={({ date }) => {
                let updateDate = new Date(date)
                if (values !== null) {
                  setAnyChanges(true)
                  setAllowPrompt(true)
                }
                updateData(date, index)
              }}

            />
          </div>
        )
      case "Single Choice":
        return (
          _.map(attribute_values, (o, idx) => {
            console.log("attribute_values", o, values)
            console.log("condition", o?._id === values._id, "/", typeof o?._id, typeof values._id, "//", values)

            return (
              <div className='cls-att-radio-btn'>
                <Radio

                  checked={values?._id === o?._id}
                  onClick={(e) => {
                    if (o?.values !== null) {
                      console.log("RADIO", o?.name)
                      setAnyChanges(true)
                      setAllowPrompt(true)
                    }
                    updateData(o, index, idx)
                  }}
                  value={values?._id === o?._id}
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'A' }}
                />
                <span className='cls-multi-checklist-kit'>{o?.name}</span>
              </div>
            )
          })
        )
      case "Multi Choice":
        return (
          <div style={{ height: '200px', overflowY: 'scroll' }}>
            {
              _.map(attribute_values, (o, idx) => {
                return (
                  <>
                    <div className="checkList">
                      <div className="list-container">
                        <div key={idx}>
                          <Checkbox
                            checked={values?.includes(o?._id)}
                            onClick={(e) => {
                              if (o?.values !== null) {
                                console.log("CHECKBOX", o?.name)
                                setAnyChanges(true)
                                setAllowPrompt(true)
                              }
                              updateData(!o?.name, index, idx)
                            }}
                            value={values?.includes(o?._id)}
                            name="checkbox-button-demo"
                            inputProps={{ 'aria-label': 'A' }}
                          />
                          <span className='cls-multi-checklist-kit'>{o?.name}</span>
                        </div>
                      </div>
                    </div>
                  </>
                )
              })
            }
          </div>
        )
      default:
        return (
          <Typography variant="h4">No Records Found</Typography>
        )
    }
  }
  return (
    <>
      <div>
        {renderProps(attribute_field_type, values)}
      </div>
    </>
  )
}
export default AttributeTypeCheck