import React, { useState, useEffect } from "react"
import {
  Button,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  Typography,
  Checkbox,
  Collapse,
} from "@material-ui/core"
import { Filter, XCircle, ChevronUp, ChevronDown, MapPin } from "react-feather"
import TableFilterStyles from "../../styles/components/tableColumnFilter/styles.js"
import styled from "styled-components"
import * as Adm from "@adm"
import { useTranslation } from "react-i18next"

function TableFilter({
  tableFilterData,
  updateTableFilterData,
  showLocationicon,
  handleDedupeModal,
  locationId,
  dropDownList,
}) {
  const { t } = useTranslation()
  const classes = TableFilterStyles()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [collapseOpen, setCollapseOpen] = useState(true)

  const toggleDrawer = (drawerOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setDrawerOpen(!drawerOpen)
  }

  const FilterIcon = () => (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.32943 10H9.66276V8.33333H6.32943V10ZM0.496094 0V1.66667H15.4961V0H0.496094ZM2.99609 5.83333H12.9961V4.16667H2.99609V5.83333Z"
        fill="currentColor"
      />
    </svg>
  )

  return (
    <React.Fragment>
      {showLocationicon && (
        <span style={{ marginRight: 20 }}>
          {locationId && "Location: " + locationId}
        </span>
      )}
      {showLocationicon && (
        <Adm.DropdownKit
          className={classes.dropDownStyles}
          defaultValue={"Select"}
          list={dropDownList}
          onUpdate={(val) => {
            handleDedupeModal(val)
          }}
        />
      )}
      <Button
        variant="text"
        style={{
          border: "1px solid #EEEEEE",
          padding: "0px 20px 0px 20px",
          fontWeight: "bold",
          height: "35px",
        }}
        onClick={toggleDrawer(drawerOpen)}
        startIcon={<FilterIcon />}
      >
        {t("FILTERS")}
      </Button>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(drawerOpen)}
        className={classes.drawer}
        style={{
          paper: {
            width: "19%",
          },
        }}
      >
        <Grid
          container
          row
          justify="space-between"
          style={{ padding: "16px", width: "100%" }}
        >
          <Typography
            className={`${classes.headerText} makeStyles-headerText-support`}
          >
            {t("Filters")}
          </Typography>
          <XCircle
            size={20}
            onClick={() => {
              setDrawerOpen(false)
            }}
          />
        </Grid>
        <Divider />
        <List>
          <ListItem onClick={() => setCollapseOpen(!collapseOpen)}>
            <Grid
              container
              row
              justify="space-between"
              style={{ minWidth: "200px" }}
            >
              <Typography
                className={`${classes.subHeaderText} makeStyles-subHeaderText-support`}
              >
                {t("Columns")}
              </Typography>
              {collapseOpen ? <ChevronUp /> : <ChevronDown />}
            </Grid>
          </ListItem>
          <Collapse
            component="li"
            in={collapseOpen}
            timeout="auto"
            unmountOnExit
            style={{ marginLeft: "-10px" }}
          >
            {tableFilterData &&
              tableFilterData.filter((x) => !x.isShowCheckbox).map((item, index) => (
                <ListItem button key={index}>
                  <Checkbox
                    style={{ color: "var(--primaryColor)" }}
                    checked={item.show}
                    onChange={() => {
                      updateTableFilterData(item)
                    }}
                  />
                  <Typography
                    className={`${classes.listText} makeStyles-listText-support`}
                  >
                    {t(item.title)}
                  </Typography>
                </ListItem>
              ))}
          </Collapse>
        </List>
      </Drawer>
    </React.Fragment>
  )
}

export default TableFilter
