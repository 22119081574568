import React, { useState } from "react"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  TextField,
  Typography,
  Paper,
} from "@material-ui/core"
import * as Utils from "@utils"
import styled from "styled-components"
import * as IconsGallery from "@iconsGallery"
import { makeStyles } from "@material-ui/core/styles"
import { useStyles } from "@material-ui/pickers/views/Calendar/Day"
import Autocomplete from "@material-ui/lab/Autocomplete"
import _ from "lodash"
import { render } from "@testing-library/react"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import * as Adm from "@adm"

const usestyles = makeStyles({
  paper: {
    border: "1px solid grey",
  },
  autoComplete: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primaryColor)",
      },
    },
  },
})

function GridDropDownFilter(props) {
  const data = props?.dataItem
  const { t } = useTranslation()
  let {
    suffixClassname = "1-common-text",
    list = props?.list || [],
    defaultValue = "",
    selectedValue = "",
    onChangeEvent = () => {},
    popupOpen = false,
    onInputChangeEvent = () => {},
    setPopupOpen = () => {},
    disableClearable = true,
    classNameTextField = "",
    touchedControl = "",
    errorControl = "",
    placeholder = "",
    style = {},
    disabled = false,
    isIndival = false,
    //optionDisplay = true,
    isPaperMenuParentWrapper = false,
    selectedIndex = 0,
    handleChangeEvent = () => {},
  } = props

  const StyledPaperKit = styled(Paper)`
    .MuiAutocomplete-listbox li[data-option-index="${selectedIndex}"] {
      background: pink;
      pointer-events: none;
    }
  `

  let objProps = {}

  if (isPaperMenuParentWrapper) {
    objProps = {
      PaperComponent: (props) => {
        return <StyledPaperKit elevation={8} {...props} id={"id-text-bk"} />
      },
    }
  }
  const classes = usestyles()
  const [popupOpenKit, setPopupOpenKit] = React.useState(false)

  return (
    <td className="cls-td-kit-ele cls-td-kit-20">
      {data?.inEdit ? (
        <Adm.DropdownFilter
          list={list}
          defaultValue={data?.name}
          selectedValue={data?.name}
          onChangeEvent={(e, val) => {
            handleChangeEvent(e, val)
          }}
          popupOpen={popupOpenKit}
          setPopupOpen={setPopupOpenKit}
          disableClearable={true}
          classNameTextField={`${classes.autoCompleteHeight} makeStyles-autoCompleteHeight-support`}
          placeholder={t("Select Language")}
          style={{
            width: "90%",
            minHeight: "10px",
          }}
        />
      ) : (
        <Typography>{data?.name || "-"}</Typography>
      )}
    </td>
  )
}
export { GridDropDownFilter }
export default GridDropDownFilter
