import { Typography } from "@material-ui/core"
import _ from "lodash"

const StatusColorBased = (props) => {
  const {
    dataItem = {},
    colorsArr = [],
    fieldMap = "",
    subfieldMap = "",
  } = props
  let title = subfieldMap
    ? dataItem?.[fieldMap]?.[subfieldMap]
    : dataItem?.[fieldMap] || ""
  return (
    <td>
      <Typography
        className="cls-status-color-based"
        style={{
          backgroundColor: _.find(colorsArr, { text: title })?.colorCode,
          minWidth: "fit-content",
          maxWidth: "100px",
          color: "#FFF",
          borderRadius: "4px",
          textAlign: "center",
          padding: "3px",
        }}
      >
        {title?.toUpperCase()}
      </Typography>
    </td>
  )
}
export default StatusColorBased
