import * as ServiceAPI from "@services"
import * as constantsAPI from "@constants"
import axios from "axios"
import { cloneDeep, get, isEmpty } from "lodash"
import { API_KC_URL, API_PG_URL } from "../../utils/url"
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}
const { headerAxios, API_BASE_URL, KC_API_BASE_URL } = ServiceAPI
const authHeaderAxios = axios.create()

export const createPaymentConfig = (body) => {
  return headerAxios.post(API_PG_URL + API_END_POINTS.createPaymentConfig, body)
}

export const updatePaymentKey = (body, id) => {
  return headerAxios.put(
    API_PG_URL + API_END_POINTS.updatePaymentKey + id,
    body
  )
}

export const deletePaymentConfig = (id) => {
  return headerAxios.put(API_PG_URL + API_END_POINTS.deletePaymentConfig + id, {
    updatedBy: localStorage.getItem("userName"),
  })
}

export const postRefundPayment = (id, data) => {
  return headerAxios.post(API_PG_URL + API_END_POINTS.refundPayment + id, data)
}

export const getPaymentConfig = (data) => {
  return headerAxios.post(API_PG_URL + API_END_POINTS.getPaymentConfig + data)
}
export const getPaymentKey = (data) => {
  return headerAxios.get(API_PG_URL + API_END_POINTS.getPaymentKey + "/" + data)
}
export const getCloverRegionsList = (data) => {
  return headerAxios.get(API_PG_URL + API_END_POINTS.getCloverRegions)
}
export const checkRefund = () => {
  return headerAxios.get(API_PG_URL + API_END_POINTS.checkRefund)
}

