import moment from "moment"
import { useEffect } from "react"
import UserService from "../services/UserService"

const AppLogout = (props) => {
  let timer = undefined
  let timeoutSplit = props?.appLogoutTime?.split("*")
  let timeOut =
    parseInt(timeoutSplit[0]) *
    parseInt(timeoutSplit[1]) *
    parseInt(timeoutSplit[2])
  let alterTimeOut = parseInt(60000)
  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keydown",
    "touchstart",
    "beforeunload",
    "DOMContentLoaded",
  ]
  const eventHandler = (eventType) => {
    localStorage.setItem("lastInteractionTime", moment(new Date()))
    if (timer) {
      props.onActive()
      startTimer()
    }
  }

  useEffect(() => {
    addEvents()
    return () => {
      removeEvents()
    }
  }, [])

  const startTimer = () => {
    timer = setTimeout(
      () => {
        let lastInteractionTime = localStorage.getItem("lastInteractionTime")
        const diff = moment.duration(moment().diff(moment(lastInteractionTime)))
        let timeOutInterval = timeOut ? timeOut : alterTimeOut
        if (diff._milliseconds < timeOutInterval && timeOutInterval !== null) {
          startTimer()
          props.onActive()
        } else {
          localStorage.clear()
          UserService.doLogout()
        }
      },
      timeOut ? timeOut : alterTimeOut
    )
  }
  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler)
    })
    startTimer()
  }

  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler)
    })
  }
  return <div></div>
}

export default AppLogout
