import Tooltip from "@material-ui/core/Tooltip"
import React, { useEffect, useState, useContext } from "react"
import { sidebarRoute } from "../../routes/RouterConfig"
import TooltipSideBar from "./TooltipSideBar"
import { useStyles } from "./style"
import { DashboardContext } from "../../Dashboardcontext/DashboardContext"
import { useTranslation } from "react-i18next"

const Generictooltip = ({ icon, isCollapsable, formatRoutes, ...rest }) => {
  const { t } = useTranslation()
  const [color, setColor] = useState(false)
  const [routeName, setRouteName] = useState()
  const { showMore, setShowMore } = useContext(DashboardContext)
  const classes = useStyles()
  return (
    <>
      {!showMore ? (
        <div>{icon}</div>
      ) : (
        <Tooltip
          className="cls-sidebar-kit"
          classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
          interactive
          // showMore={true}
          // open={true}
          showMore={showMore}
          open={color}
          onOpen={() => setColor(true)}
          onClose={() => setColor(false)}
          PopperProps={{
            popperOptions: {
              modifiers: {
                offset: {
                  enabled: true,
                  offset: "40px, 40px",
                },
              },
            },
          }}
          componentsProps={{
            tooltip: {
              sx: {
                width: "200px",
                height: "auto",
                bgcolor: "#140316",
                margin: "0 0 0 15em",
                ".MuiTooltip-popper": {
                  marginLeft: "10px",
                },
                "& .MuiTooltip-arrow": {
                  color: "#fff",
                },
              },
            },
          }}
          placement={window?.languagedmrtl ? "right" : "left"}
          title={
            <TooltipSideBar
              showMore={showMore}
              setShowMore={setShowMore}
              formatRoutes={formatRoutes}
              routeName={routeName}
              isCollapsable={isCollapsable}
            />
          }
        >
          <div
            style={{ color: color ? "#0066CC" : "#FFFFFF" }}
            onMouseOver={(event) => {
              let expandDetailsObj = JSON.parse(
                localStorage.getItem("expandDetails")
              )
              let formExpandDetailsObj = {
                indicesArr: expandDetailsObj?.indicesArr,
                route: expandDetailsObj?.route,
                isOpen: expandDetailsObj?.isOpen,
                tooltipOpen: true,
                iconOnlyDisplay: true,
              }
              localStorage.setItem(
                "expandDetails",
                JSON.stringify(formExpandDetailsObj)
              )
              let routeIcon = sidebarRoute.filter(
                (routename) => t(routename.id) === rest.name
              )
              setRouteName(routeIcon)
            }}
          >
            {icon}
          </div>
        </Tooltip>
      )}
    </>
  )
}

export default Generictooltip
