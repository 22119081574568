const columns = [
  {
    field: "barcode",
    title: "Product Barcode",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
    min_width: 25,
  },
  {
    field: "productName",
    title: "Product Name",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
    min_width: 20,
  },
  {
    field: "imageCount",
    title: "Images Count",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
    min_width: 25,
  },
  {
    field: "trainedStatusFlag",
    title: "Trained Status",
    editable: false,
    border: true,
    filter: true,
    min_width: 15,
    verified: true,
    show: true,
    filterType: "boolean",
    customFilter: true,
    filterData: [
      { tab: "Trained", value: "true" },
      { tab: "Untrained", value: "false" },
    ],
  },
]

export default columns
