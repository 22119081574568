import * as React from "react"
import { Editor, EditorTools } from "@progress/kendo-react-editor"
const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  FontSize,
  FontName,
  Link,
  Unlink,
  ViewHtml,
} = EditorTools

function RichTextWYSIWYG(props) {
  const {
    content = "",
    onUpdate = () => { },
    style = {},
  } = props || {}
  return (
    <div className="cls-editor-kit">
      <Editor
        tools={[
          [Bold, Italic, Underline, Strikethrough],
          [AlignLeft, AlignCenter, AlignRight, AlignJustify],
          [Indent, Outdent],
          [OrderedList, UnorderedList],
          [ViewHtml],]}
        defaultContent={content}
        onChange={onUpdate}
        style={style}
      />
    </div>
  )
}

export { RichTextWYSIWYG }
export default RichTextWYSIWYG
