const ProductLookupFixture = [
    {
        field: "item_id",
        title: "Item ID",
        filter: false,
        border: true,
        show: true,
        min_width: 20,
    },
    {
        field: "title",
        title: "Title",
        border: true,
        filter: false,
        show: true,
        min_width: 20,
    },
    {
        field: "barcodeValue",
        title: "Barcode Value",
        border: true,
        filter: false,
        show: true,
        min_width: 20,
    },

    {
        field: "brand",
        title: "Brand",
        border: true,
        filter: false,
        show: true,
        min_width: 20,
    },
    {
        field: "manufacturer",
        title: "Manufacturer",
        border: true,
        filter: false,
        show: true,
        min_width: 20,
    },
]
export default ProductLookupFixture