import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import { fetchTranslationData } from "../services/common"
import translationEN from "../locales/en/translation.json"
import translationFR from "../locales/fr/translation.json"
import translationES from "../locales/es/translation.json"
async function initializeI18n() {
  try {
    await i18n
      .use(Backend)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        fallbackLng: "en",
        debug: false,
        interpolation: {
          escapeValue: false,
        },
      })

    let lcLang =
      localStorage.getItem("languageskit") !== "undefined" &&
      localStorage.getItem("languageskit") !== null
        ? JSON.parse(localStorage.getItem("languageskit"))
        : []

    const languages = lcLang && lcLang.length > 0 ? lcLang : ["en"]
    const resources = {}
    await i18n.init({
      resources: JSON.parse(localStorage.getItem("translationData")),
    })
  } catch (error) {
    console.error("Error initializing i18n:", error)
  }
}

initializeI18n()

export default i18n
export { initializeI18n }
