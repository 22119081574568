import * as React from "react"
import { Card } from "@material-ui/core"
import { Formik } from "formik"
import _ from "lodash"
import * as Features from "@features"
import * as Adm from "@adm"
import * as Utils from "@utils"
import { defaultList } from "./fixture"
import { SearchFacetUIView } from "./SearchFacetUIView"

const customRenderContainer = (props) => {
  return <SearchFacetUIView {...props} />
}

function SearchFacetUI(props) {
  const {
    title = "facets",
    list = defaultList || [],
    onUpdate = () => "",
    isEditMode = true,
    isOpenMode = false,
    listColumnSplit = false,
    inputField = false,
    variable1 = "haii",
    data = {},
  } = props || {}

  let newdata = _.get(data, "buy_product", 0)
  const [fetchData, setFetchData] = React.useState(list)

  const stateProps = { fetchData, setFetchData }
  // "Fetch Data have value pass the value outside to the parent "
  const onUpdateData = async () => {
    onUpdate(fetchData)
  }
  const attrAccordion = {
    isOpen: isOpenMode,
    isEditMode,
    label: title,
    variable1: variable1,
    updateData: onUpdateData,
    renderContainer: (accorProps) =>
      customRenderContainer({ ...accorProps, ...stateProps }),
  }

  return <Adm.AccordianPanel {...attrAccordion} />
}
export { SearchFacetUI }
export default SearchFacetUI
