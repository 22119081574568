import React from "react"

function useConfigUI(props) {
  const {
    defaultList = {},
    onUpdate = () => "",
    getIsErrorIntiated = false,
    setIsErrorIntiated = () => "",
    getAvalaraDetails = () => { },
    getDefaultSampleCode,
  } = props || {}
  let getDefaultTaxConfig =
    defaultList !== {}
      ? defaultList
      : {
        taxconfigName: "",
        accountNumber: "",
        licenseKey: "",
        environment: process.env.REACT_APP_API_ENDPOINT.includes('dev') ? "sandbox" : "production",
        companyCodeLookup: "",
        currentTab: "tax",
        taxConfig: {
          name: "Tax Config",
          apiUrl: "",
          connectionMethod: "Token",
          token: "",
          responseType: "JSON",
          userName: "",
          password: "",
          statusCode: "",
          statusErrorCode: "401",
          sampleTestCode: "[{}]",
        },
        priceConfig: {
          name: "Price Config",
          apiUrl: "",
          connectionMethod: "Token",
          token: "",
          responseType: "JSON",
          userName: "",
          password: "",
          statusCode: "",
          statusErrorCode: "401",
          sampleTestCode: "[{}]",
        },
        promotionConfig: {
          name: "Promotion Config",
          apiUrl: "",
          connectionMethod: "Token",
          token: "",
          responseType: "JSON",
          userName: "",
          password: "",
          statusCode: "",
          statusErrorCode: "401",
          sampleTestCode: "[{}]",
        },
        pmsConfig: {
          name: "Pms Config",
          apiUrl: "",
          connectionMethod: "Token",
          token: "",
          responseType: "JSON",
          userName: "",
          password: "",
          statusCode: "",
          statusErrorCode: "401",
          sampleTestCode: "[{}]",
        }
      }

  const [getTaxConfig, setTaxConfig] = React.useState(getDefaultTaxConfig)
  const [currentTab, setCurrentTab] = React.useState("tax")
  const [getCMDropdownList, setCMDropdownList] = React.useState([
    "Token Authentication",
    "Basic Authentication",
  ])
  const [getRTDropdownList, setRTDropdownList] = React.useState([
    "JSON",
    "XML",
    "Test",
    "CSV",
    "EDI",
  ])
  const [getRTDropdownValue, setRTDropdownValue] = React.useState()
  const [getCMDropdownValue, setCMDropdownValue] = React.useState()

  React.useEffect(() => {
    setTaxConfig(defaultList)
  }, [defaultList])

  const onChangeValueTextField = (eventProps) => {
    const { value = "", fieldName = "" } = eventProps
    if (!(value.length > 0)) {
      setIsErrorIntiated(true)
    }

    let dataUpdate = getTaxConfig
    dataUpdate[fieldName] = value
    setTaxConfig({ ...dataUpdate })
    onUpdate({ updatedList: dataUpdate })
  }
  const onChangeValueInObject = (eventProps) => {
    const { value = "", fieldName1 = "", fieldName = "" } = eventProps
    let urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g

    console.log(eventProps, "eventProps")
    if (!(value.length > 0)) {
      setIsErrorIntiated(true)
    }
    if (fieldName === "apiUrl" && value?.length > 4 && value?.match(urlRegex) === null) {
      setIsErrorIntiated(true)
    }
    if (fieldName === "token") {
      var letters = /^[A-Za-z0-9 ]+$/;
      if (value !== "" && value?.match(letters) === null) {
        eventProps.defaultPrevented = true;
        return false
      }
      else if (value !== "" && value?.length < 4) {
        setIsErrorIntiated(true)
      }
    }
    if (fieldName === "password" && value?.length < 2) {
      setIsErrorIntiated(true)
    }
    let dataUpdate = getTaxConfig
    dataUpdate[fieldName1][fieldName] = value
    setTaxConfig({ ...dataUpdate })
    onUpdate({ updatedList: dataUpdate })
  }

  return {
    getCMDropdownList,
    setCMDropdownList,
    getCMDropdownValue,
    setCMDropdownValue,
    getRTDropdownList,
    setRTDropdownList,
    getRTDropdownValue,
    setRTDropdownValue,
    getTaxConfig,
    setTaxConfig,
    onUpdate,
    onChangeValueTextField,
    onChangeValueInObject,
    getIsErrorIntiated,
    setIsErrorIntiated,
    setCurrentTab,
    currentTab,
    getDefaultSampleCode,
  }
}

export { useConfigUI }
