import * as React from "react"
import {
  Button,
  Typography,
  Dialog,
  Grid,
  IconButton,
  useMediaQuery,
} from "@material-ui/core"
import { useDropzone } from "react-dropzone"
import { useTheme } from "@material-ui/core/styles"
import { DragDropwrapper, DialogContentWrapper, ButtonGroup } from "./styles"
import * as Adm from "@adm"
import * as Utils from "@utils"
import * as XLSX from "xlsx"
import _ from "lodash"
import { Close } from "@material-ui/icons"
import lodashcontrib from "lodash-contrib"

const DropZone = (props) => {
  const {
    fileData,
    setFileData,
    setImportData,
    setWorkbookDetails,
    setLoading,
    fileTypeList,
    setAlertType,
    setAlertMessage,
    setAlert,
    setFileSize,
    downloadTemplateClick,
    t,
  } = props || {}
  const theme = useTheme()
  console.log("DropZone", props)
  const isMobile = useMediaQuery("(max-width: 600px)")
  const onDrop = React.useCallback(async (acceptedFiles, rejectedFiles) => {
    setAlert(false)
    setLoading(true)
    if (acceptedFiles.length > 1) {
      await setAlertType("error")
      await setAlertMessage("Only one file allowed to upload")
      await setAlert(true)
      await setLoading(false)
      return false
    }
    let validFileType = _.includes(fileTypeList, acceptedFiles[0].type)
    if (validFileType) {
      if (_.toInteger(acceptedFiles[0].size) < 5242880) {
        setFileData({
          files: acceptedFiles,
        })
        acceptedFiles.forEach(async (file) => {
          const reader = new FileReader()
          reader.onload = async (evt) => {
            var data = evt.target.result
            var workbook = XLSX.read(data, {
              type: "binary",
            })
            setWorkbookDetails(workbook)
            setFileSize(acceptedFiles[0].size)
            await setLoading(false)
          }

          reader.onabort = (abortProps) => {
            console.log("file reading was aborted")
            setImportData({ status: "abort", ...abortProps })
          }
          reader.onerror = (errorProps) => {
            console.log("file reading has failed")
            setImportData({ status: "error", ...errorProps })
          }

          reader.readAsBinaryString(file)
        })
      } else {
        await setAlertType("error")
        await setAlertMessage("File size should be less than 5 MB")
        await setAlert(true)
        await setLoading(false)
      }
    } else {
      await setAlertType("error")
      await setAlertMessage("Invalid File Type")
      await setAlert(true)
      await setLoading(false)
    }
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  })

  return (
    <>
      <div style={{ padding: "10px" }}>
        <DragDropwrapper {...getRootProps()}>
          <Grid container>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              xl={12}
              style={{ paddingTop: isMobile ? "10px" : "10px" }}
            >
              <Grid lg={12} md={12} sm={12} xs={12} xl={12}>
                <img
                  src={"/static/img/downloadIcon.svg"}
                  alt={"downloadIcon"}
                  width="20px"
                />
              </Grid>
              <Typography
                style={{
                  fontWeight: 800,
                  color: "#000",
                  fontSize: "14px",
                  padding: "10px",
                  textTransform: "capitalize",
                }}
              >
                {t("Choose the file to be imported")}
              </Typography>
              <Typography
                variant="body1"
                style={{ color: "#9E9E9E", textTransform: "capitalize" }}
              >
                {t("only xls,xlsx and csv formats are supported")}
              </Typography>
              <Typography
                variant="body1"
                style={{ textTransform: "capitalize" }}
              >
                {t("Maximum upload file size is 5 MB")}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                disabled={fileData.files.length === 1 && true}
                style={{
                  pointerEvents:
                    fileData.files.length === 1 ? "none" : "pointer",
                  margin: "10px",
                  color: "#fff",
                  fontWeight: "bold",
                  backgroundColor: "var(--primaryColor)",
                }}
              >
                {t("UPLOAD FILE")}
                <input
                  {...getInputProps()}
                  disabled={fileData.files.length === 1 && true}
                />
              </Button>
              <Typography variant="body1" style={{ padding: "20px" }}>
                <a
                  style={{
                    cursor: "pointer",
                    color: "var(--primaryColor)",
                    textTransform: "capitalize",
                    fontWeight: "bold",
                  }}
                  onClick={downloadTemplateClick}
                >
                  {t("Download sample template for download")}
                </a>
              </Typography>
            </Grid>
          </Grid>
        </DragDropwrapper>
      </div>
    </>
  )
}

export const renderOverlay = (props) => {
  const {
    isDialogOpen,
    setDialogOpen,
    fileData,
    setFileData,
    importData,
    setImportData,
    fileSize,
    isMobile,
    setFileSize,
    onSubmit,
    t,
  } = props || {}

  const closeButtonAttrs = {
    variant: "outlined",
    type: "submit",
    color: "default",
    style: { fontWeight: 1000 },
    onClick: () => {
      setFileData({
        files: [],
      })
      setDialogOpen(false)
    },
  }
  const submitButtonAttrs = {
    variant: "contained",
    type: "submit",
    color: "primary",
    style: {
      fontWeight: 1000,
      backgroundColor: "var(--primaryColor)",
      color: "#FFF",
    },
    className: `${
      Utils.JSUtils.isEmpty(fileData.files) ? "btn-disable" : "btn-enable"
    }`,
    onClick: () => {
      console.log("test")
      onSubmit(importData)
    },
  }

  const bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"]
    if (bytes === 0) return "0 Byte"
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i]
  }

  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => {
        setDialogOpen(false)
        setFileData({
          files: [],
        })
      }}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      lg={2}
      md={2}
      sm={12}
      xs={12}
    >
      <Grid
        container
        row="true"
        justify="space-between"
        style={{ borderBottom: "1px solid #eee", paddingBottom: "5px" }}
      >
        <Typography
          style={{
            fontWeight: "bold",
            paddingLeft: "20px",
            paddingTop: "15px",
          }}
        >
          {t(`Import`)}
        </Typography>

        <IconButton
          style={{}}
          onClick={(event) => {
            setFileData({
              files: [],
            })
            setDialogOpen(false)
          }}
        >
          <Close />
        </IconButton>
      </Grid>
      <DialogContentWrapper>
        <DropZone {...props} />
        {fileData?.files && (
          <div style={{ width: "100%" }}>
            {fileData &&
              fileData?.files?.map((f) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                    gap: "20px",
                    marginLeft: "20px",
                    paddingRight: isMobile ? "30px" : "",
                  }}
                  key={f.name}
                >
                  <div>
                    <img
                      src={"/static/img/excel.svg"}
                      alt={"excel"}
                      width="40"
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "20px",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {f.name}{" "}
                      {fileSize !== "" ? bytesToSize(fileSize) : "0 KB"}
                      <IconButton
                        onClick={(event) => {
                          setFileData({
                            files: [],
                          })
                        }}
                      >
                        <img
                          src={"/static/img/closeIcon.svg"}
                          alt={"closeIcon"}
                        />
                      </IconButton>
                    </div>

                    <div style={{ paddingLeft: "20px" }}>
                      <img
                        src={"/static/img/progressbar.svg"}
                        alt={"progressbar"}
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}

        {fileData?.files?.length > 0 ? (
          <ButtonGroup>
            <Adm.Button {...closeButtonAttrs}>
              {_.toUpper(t("Close"))}{" "}
            </Adm.Button>
            <Adm.Button {...submitButtonAttrs}>
              {_.toUpper(t("Import"))}
            </Adm.Button>
          </ButtonGroup>
        ) : (
          <></>
        )}
      </DialogContentWrapper>
    </Dialog>
  )
}

export const excelSheetValidation = (
  jsonExcelSheet = [],
  listData = [],
  rowLabel = ""
) => {
  let formatList = []
  let excelErrorProduct = []
  _.isArray(listData) &&
    _.map(listData, (value, index) => {
      var obj = lodashcontrib.renameKeys(
        value,
        jsonExcelSheet?.productListColumns
      )
      formatList.push(obj)
    })
  let excelValidatonJson = jsonExcelSheet?.productListDataFormat
  _.map(formatList, (value, index) => {
    let excelPrefixText = rowLabel + parseInt(index + 2)
    _.map(excelValidatonJson, (objValue, objIndex) => {
      if (objValue?.splitValue) {
        let splitValue = _.split(
          value[objValue?.columnName],
          objValue?.splitValue
        )
        ;(_.isEmpty(_.trim(splitValue && splitValue[0] ? splitValue[0] : "")) ||
          _.isEmpty(_.trim(splitValue && splitValue[1] ? splitValue[1] : "")) ||
          _.isEmpty(
            _.trim(splitValue && splitValue[2] ? splitValue[2] : "")
          )) &&
          _.isObject(value) &&
          objValue?.isRequired &&
          excelErrorProduct.push(
            excelPrefixText +
              " " +
              objValue?.columnTitle +
              " is Empty/Invalid Format"
          )
      } else {
        _.isObject(value) &&
          objValue?.isRequired &&
          _.isEmpty(_.trim(value[objValue?.columnName])) &&
          excelErrorProduct.push(
            excelPrefixText + " " + objValue?.columnTitle + " is Empty"
          )
      }
      if (value["min_threshold_value"] === 0) {
        excelErrorProduct.push(
          excelPrefixText +
            " Minimum threshold should be greater than or equal to 1"
        )
      }
      if (objValue?.duplicateValidate) {
        let columnName = objValue?.columnName
        let excelPrefixText =
          rowLabel + parseInt(index + 2) + " " + objValue?.columnTitle
        if (
          _.filter(
            objValue?.listArray,
            _.matches({
              columnName: _.lowerCase(value[columnName]),
            })
          ).length === 0
        ) {
          let objTemp = {
            columnName: _.lowerCase(value[columnName]),
          }
          objValue?.listArray.push(objTemp)
        } else {
          excelErrorProduct.push(excelPrefixText + " already Exist")
        }
      }
      if (objValue?.priceValidate) {
        if (value["offerPrice"] !== "" && value["msrp"] < value["offerPrice"]) {
          excelErrorProduct.push(
            excelPrefixText + " List Price should be greater than offer price"
          )
        }
      }
    })
  })
  return {
    formatList: formatList,
    excelErrorProduct: excelErrorProduct,
  }
}
