import React from "react"
import {
  Card,
  Grid,
  Button,
  Typography,
  TextField,
  makeStyles,
} from "@material-ui/core"
import * as Adm from "@adm"
import * as IconGallery from "@iconsGallery"
import moment from "moment"

const CustomTextField = (props) => {
  const { isError = false, errorMessage = "is Required", ...restProps } =
    props || {}
  return (
    <Grid style={{ minHeight: 60 }}>
      <TextField {...restProps} />
      {isError && (
        <Typography style={{ color: "red", fontSize: 12 }}>
          {errorMessage}
        </Typography>
      )}
    </Grid>
  )
}

export const renderStartDateDatePicker = (props, classes) => {
  const { t } = props
  return (
    <Grid>
      <Typography variant="h5" style={{ marginBottom: 1 }}>
        <span
          style={{ color: "black", fontWeight: "bold" }}
        >{t(`Start Date`)}</span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      <Adm.Calender
        isISOFormatCheck={false}
        className="locationDateNative"
        innerDatestyle={{ width: 200, marginTop: 10 }}
        isTimeEnable={false}
        date={props?.getRecurrence?.startDate}
        onUpdateCalender={({ date }) => {
          props?.onChangeValueTextField({
            value: date,
            fieldName: "startDate",
          })
        }}
      />
    </Grid>
  )
}
export const renderEndDateDatePicker = (props, classes) => {
  const { t } = props
  return (
    <Grid>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" style={{ marginBottom: 1 }}>
          <span
            style={{ color: "black", fontWeight: "bold" }}
          >{t(`End Date`)}</span>
          <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
          <span
            style={{ marginLeft: 4, color: "grey", fontSize: 12 }}
          >({t('Optional')})</span>
        </Typography>
        {props?.getRecurrence?.endDate !== undefined &&
          props?.getRecurrence?.endDate !== "Invalid date" &&
          props?.getRecurrence?.endDate.length > 0 && (
            <Button
              style={{
                background: "white",
                color: "#df86bb",
                fontWeight: "bold",
                height: 10,
              }}
              variant="contained"
              onClick={() => {
                props?.onChangeValueTextField({
                  value: "",
                  fieldName: "endDate",
                })
              }}
            >
              {t(`Remove`)}
            </Button>
          )}
      </Grid>

      <Grid style={{ display: "flex" }}>
        <Adm.Calender
          isISOFormatCheck={false}
          className="locationDateNative"
          innerDatestyle={{ width: 200, marginTop: 10 }}
          isTimeEnable={false}
          error={
            props?.getRecurrence?.endDate <= props?.getRecurrence?.startDate &&
              props?.getRecurrence?.endDate !== ""
              ? "Select Valid End Date"
              : ""
          }
          date={props?.getRecurrence?.endDate}
          onUpdateCalender={({ date }) => {
            props?.onChangeValueTextField({
              value: date,
              fieldName: "endDate",
            })
          }}
        />
      </Grid>
    </Grid>
  )
}

export const renderRepeatEveryCount = (props) => {
  const { t } = props
  return (
    <Grid>
      <CustomTextField
        type="number"
        autoFocus={true}
        inputProps={{
          min: "1",
        }}
        errorMessage={t(`count Required`)}
        key={props?.getRecurrence?.repeatevery_count}
        defaultValue={props?.getRecurrence?.repeatevery_count}
        style={{ width: 80 }}
        variant="outlined"
        size="small"
        placeholder={t("Enter the User Name")}
        onChange={(event) => {
          props?.onChangeValueTextField({
            value: event?.target?.value,
            fieldName: "repeatevery_count",
          })
        }}
      />
    </Grid>
  )
}

export const renderRepeatEveryDP = (props) => {
  return (
    <Grid>
      <Adm.DropdownKit
        style={{ width: 140 }}
        defaultValue={props?.getRecurrence?.repeatevery_day}
        list={props?.getRepeateveryDayList}
        onUpdate={(e) => {
          if (e === "Immediate") {
            props?.onChangeValueTextField({
              value: moment(new Date()).format("YYYY-MM-DD"),
              fieldName: "startDate",
            })
            props?.onChangeValueTextField({
              value: "",
              fieldName: "endDate",
            })
          }
          props?.onChangeValueTextField({
            value: e,
            fieldName: "repeatevery_day",
          })
        }}
      />
    </Grid>
  )
}

export const renderRepeatEveryWeek = (props) => {
  const { t } = props
  const isFirstOnlyAvailable = props?.getDaysList.filter((itm) => itm.selected)
  let getValueSingleName = ""
  if (isFirstOnlyAvailable.length === 1) {
    const [x_getValue = {}] = isFirstOnlyAvailable
    const { name = "" } = x_getValue
    getValueSingleName = name
  }
  return (
    <Grid style={{ marginBottom: 30, marginTop: 30 }}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span
          style={{ color: "black", fontWeight: "bold" }}
        >{t(`Scheduled Weekdays`)}</span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      {props?.getDaysList.map((itm) => {
        return (
          <Button
            disabled={getValueSingleName === itm?.name}
            onClick={() => {
              let miniList = props?.getDaysList?.map((innerItm) => {
                let obj = { selected: innerItm?.selected }
                if (itm?.name === innerItm?.name) {
                  obj = { selected: !itm?.selected }
                }
                return { ...innerItm, ...obj }
              })
              props?.setDaysList(miniList)

              let filterminiList = miniList.filter((itms) => itms?.selected)
              props?.onChangeValueTextField({
                value: filterminiList.map((innnrItm) => innnrItm.name),
                fieldName: "repeatevery_days",
              })
            }}
            style={{
              width: 40,
              height: 40,
              maxWidth: 40,
              minWidth: 40,
              borderRadius: "100px",
              fontSize: 16,
              fontWeight: itm?.selected ? "bold" : "normal",
              color: itm?.selected ? "#fff" : "#000",
              background: itm?.selected ? "#df86bb" : "#94949457",
              padding: 10,
              marginRight: 10,
            }}
          >
            {itm?.letter}
          </Button>
        )
      })}
    </Grid>
  )
}
export const renderRepeatEveryMonth = (props) => {
  const { t } = props
  return (
    <Grid
      style={{ marginTop: 20, marginBottom: 20, width: 530 }}
      key={props?.getMonthsList}
    >
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5">
          <span
            style={{ color: "black", fontWeight: "bold" }}
          >{t(`Scheduled Weekdays`)}</span>
          <span style={{ color: "red" }}>{`*`}</span>
          <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
        </Typography>
        {props?.getRecurrence?.repeatevery_month_value.length <= 4 && (
          <Button
            style={{
              background: "white",
              color: "#df86bb",
            }}
            variant="contained"
            startIcon={<IconGallery.Plus />}
            onClick={() => {
              let totalList = props?.getRecurrence?.repeatevery_month_value
              totalList = [
                ...totalList,
                {
                  days: [
                    {
                      name: "Sunday",
                      letter: "S",
                      selected: false,
                    },
                    {
                      name: "Monday",
                      letter: "M",
                      selected: true,
                    },
                    {
                      name: "Tuesday",
                      letter: "T",
                      selected: false,
                    },
                    {
                      name: "Wednesday",
                      letter: "W",
                      selected: false,
                    },
                    {
                      name: "Thursday",
                      letter: "T",
                      selected: false,
                    },
                    {
                      name: "Friday",
                      letter: "F",
                      selected: false,
                    },
                    {
                      name: "Saturday",
                      letter: "S",
                      selected: false,
                    },
                  ],
                },
              ]

              props?.onChangeValueTextField({
                value: totalList,
                fieldName: "repeatevery_month_value",
              })
            }}
          >
            {t(`Add Week`)}
          </Button>
        )}
      </Grid>
      <Grid style={{ marginTop: 20 }}>
        {props?.getRecurrence?.repeatevery_month_value.map((itm, index) => {
          return (
            <Grid
              style={{
                padding: 10,
                margin: 5,
                borderRadius: 2,
                width: "100%",
                marginLeft: 0,
                paddingLeft: 0,
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" style={{ width: 100 }}>
                  <span
                    style={{
                      color: "#df86bb",
                      fontWeight: "800",
                      fontSize: 18,
                    }}
                  >{`${t('WEEK')} ${index + 1}:`}</span>
                </Typography>
                <Grid>
                  {itm?.days?.map((innerItem) => {
                    const isFirstOnlyAvailable = itm?.days?.filter(
                      (itm) => itm.selected
                    )
                    let getValueSingleName = ""
                    if (isFirstOnlyAvailable.length === 1) {
                      const [x_getValue = {}] = isFirstOnlyAvailable
                      const { name = "" } = x_getValue
                      getValueSingleName = name
                    }
                    return (
                      <Button
                        disabled={getValueSingleName === innerItem?.name}
                        onClick={() => {
                          let totalList =
                            props?.getRecurrence?.repeatevery_month_value
                          let miniList = itm?.days?.map((innerItmKit) => {
                            let obj = { selected: innerItmKit?.selected }
                            if (innerItem?.name === innerItmKit?.name) {
                              obj = { selected: !innerItem?.selected }
                            }
                            return { ...innerItmKit, ...obj }
                          })

                          totalList[index]["days"] = miniList

                          props?.onChangeValueTextField({
                            value: totalList,
                            fieldName: "repeatevery_month_value",
                          })
                        }}
                        style={{
                          width: 40,
                          height: 40,
                          maxWidth: 40,
                          minWidth: 40,
                          borderRadius: "100px",
                          fontSize: 16,
                          fontWeight: innerItem?.selected ? "bold" : "normal",
                          color: innerItem?.selected ? "#fff" : "#000",
                          background: innerItem?.selected
                            ? "#df86bb"
                            : "#94949457",
                          padding: 10,
                          marginRight: 10,
                        }}
                      >
                        {innerItem?.letter}
                      </Button>
                    )
                  })}
                </Grid>

                {index !== 0 && (
                  <Button
                    style={{
                      background: "white",
                      color: "#df86bb",
                    }}
                    variant="contained"
                    startIcon={<IconGallery.DeleteIcon />}
                    onClick={() => {
                      let totalList =
                        props?.getRecurrence?.repeatevery_month_value
                      totalList.splice(index, 1)

                      props?.onChangeValueTextField({
                        value: totalList,
                        fieldName: "repeatevery_month_value",
                      })
                    }}
                  />
                )}
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}

export const renderTimer = (props, classes) => {
  console.log(classes, "renderTimer")
  const { t } = props
  return (
    <Grid style={{ marginLeft: 20 }}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span
          style={{ color: "black", fontWeight: "bold" }}
        >{t(`Scheduled Time`)}</span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      <TextField
        className={classes.textInput}
        ampm={false}
        size="small"
        variant="outlined"
        id="time"
        type="time"
        value={props?.getRecurrence?.repeatevery_timer_value}
        onChange={(e) => {
          props?.onChangeValueTextField({
            value: e.target.value,
            fieldName: "repeatevery_timer_value",
          })
        }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300,
        }}
      />
    </Grid>
  )
}
export const renderRepeatEvery = (props, classes) => {
  const { t } = props
  return (
    <Grid>
      <Grid style={{ display: "flex" }}>
        <Grid>
          <Typography variant="h5" style={{ marginBottom: 10 }}>
            <span
              style={{ color: "black", fontWeight: "bold" }}
            >{t(`Repeat Type`)}</span>
            <span style={{ color: "red" }}>{`*`}</span>
            <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
          </Typography>
          <Grid style={{ display: "flex" }}>
            {renderRepeatEveryDP(props)}
            {props?.getRecurrence?.repeatevery_day === "Immediate" && (
              <Grid style={{ display: "flex", alignItems: "center" }}>
                <IconGallery.QueryBuilderIcon
                  style={{ marginLeft: 10, width: 30, height: 30 }}
                />
                <Typography
                  style={{ marginLeft: 5, fontWeight: 700 }}
                >{t(`Now`)}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        {!(props?.getRecurrence?.repeatevery_day === "Immediate") &&
          renderTimer(props, classes)}
      </Grid>
      <Grid>
        {props?.getRecurrence?.repeatevery_day === "Week" &&
          renderRepeatEveryWeek(props)}
        {props?.getRecurrence?.repeatevery_day === "Month" &&
          renderRepeatEveryMonth(props)}
      </Grid>
    </Grid>
  )
}
