import styled from "styled-components"

import {
  Avatar as MuiAvatar,
  ListItem as MuiListItem,
  Popover as MuiPopover,
} from "@material-ui/core"

export const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    min-width: 275px;
    max-width: 350px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid #8584841f;
    padding: 2px 16px;
    border-radius: 4px;
    max-height: 500px !important;
  }
`

export const Avatar = styled(MuiAvatar)`
  background: white;
`

export const ListItem = styled(MuiListItem)`
  padding-top: 0px;
  padding-bottom: 0px;
`
