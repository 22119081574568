import React, { useState } from "react"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  TextField,
  Typography,
  Paper,
} from "@material-ui/core"
import * as Utils from "@utils"
import styled from "styled-components"
import * as IconsGallery from "@iconsGallery"
import { makeStyles } from "@material-ui/core/styles"
import { useStyles } from "@material-ui/pickers/views/Calendar/Day"
import Autocomplete from "@material-ui/lab/Autocomplete"
import _ from "lodash"
import { render } from "@testing-library/react"
import clsx from "clsx"
import { useTranslation } from "react-i18next"

const usestyles = makeStyles({
  paper: {
    border: "1px solid grey",
  },
  autoComplete: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primaryColor)",
      },
    },
    "& .MuiInputBase-root.Mui-disabled":{
      backgroundColor:"#f6f6f6"
    }
  },
})

function DropdownFilter(props) {
  const { t } = useTranslation()
  let {
    suffixClassname = "1-common-text",
    list = [],
    defaultValue = "",
    selectedValue = "",
    onChangeEvent = () => {},
    popupOpen = false,
    onInputChangeEvent = () => {},
    setPopupOpen = () => {},
    disableClearable = true,
    classNameTextField = "",
    touchedControl = "",
    errorControl = "",
    placeholder = "",
    style = {},
    disabled = false,
    isIndival = false,
    //optionDisplay = true,
    isPaperMenuParentWrapper = false,
    selectedIndex = 0,
  } = props
  const StyledPaperKit = styled(Paper)`
    .MuiAutocomplete-listbox li[data-option-index="${selectedIndex}"] {
      background: pink;
      pointer-events: none;
    }
  `

  let objProps = {}

  if (isPaperMenuParentWrapper) {
    objProps = {
      PaperComponent: (props) => {
        return <StyledPaperKit elevation={8} {...props} id={"id-text-bk"} />
      },
    }
  }
  const classes = usestyles()
  const [popupOpenKit, setPopupOpenKit] = React.useState(false)
  return (
    <>
      <Autocomplete
        className="cls-adm-dropdownfilter-1-common-inner-text"
        options={list}
        value={
          list?.length > 0
            ? _.isEmpty(selectedValue)
              ? defaultValue
              : selectedValue
            : ""
        }
        classes={{
          paper: `${classes.paper} cls-adm-dropdownfilter-1-common-text`,
        }}
        onChange={onChangeEvent}
        disabled={disabled}
        getOptionLabel={(option) =>
          _.isObject(option) ? option?.name : option
        }
        // open={true}
        open={isIndival ? popupOpenKit : popupOpen}
        onInputChange={onInputChangeEvent}
        onOpen={() => {
          isIndival ? setPopupOpenKit(true) : setPopupOpen(true)
        }}
        onClose={() => {
          isIndival ? setPopupOpenKit(false) : setPopupOpen(false)
        }}
        disableClearable={disableClearable}
        style={style}
        noOptionsText={t("No options")}
        openText={t("Open")}
        closeText={"Close"}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            className={clsx(classes.autoComplete, classNameTextField)}
            placeholder={t(placeholder)}
            error={Boolean(touchedControl && errorControl)}
            helperText={touchedControl && t(errorControl)}
          />
        )}
        {...objProps}
      />
    </>
  )
}
export { DropdownFilter }
export default DropdownFilter
