import Axios from "axios"
import {
  PRODUCT_CATEGORY_URLS as URLS,
  PRODUCT_DATA_URLS,
  MASTER_DATA_URLS,
} from "../../../utils/url"
import UserService from "../../UserService"
import * as ServiceAPI from "@services"
import * as constantsAPI from "@constants"
const CancelToken = Axios.CancelToken
const { headerAxios } = ServiceAPI
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

//PATH VARIABLE REPLACER
function bindPath(url, pathVal) {
  var newUrl = url
  var pathExpression = /:[a-z0-9]+/gi
  var pathVar
  while (((pathVar = pathExpression.exec(url)), pathVar != null)) {
    let pathVarName = pathVar[0]
    newUrl = newUrl.replace(
      pathVarName,
      pathVal[pathVarName.substring(1, pathVarName.length)]
    )
  }
  return newUrl
}

const productAxios = Axios.create()

productAxios.interceptors.request.use(
  async function (config) {
    config.baseURL = config?.baseURL || URLS.baseURL

    if (UserService.isLoggedIn()) {
      if (UserService.isAuthExpired() === true) {
        const myData = await UserService.updateRefreshToken()
        if (myData === true) {
          let mydataToken = UserService.getToken((res) => {
            return res
          })
          localStorage.setItem("token", mydataToken)
          config.headers["Authorization"] = "Bearer " + mydataToken
          let tenantId =
            localStorage.getItem("tenantId") === null
              ? ""
              : localStorage.getItem("tenantId")
          let tempTenantId = localStorage.getItem("tempTenantId")
          config.headers["X-Tenant-ID"] = tenantId || tempTenantId
        } else {
          localStorage.clear()
          UserService.doLogout()
        }
      } else {
        let tenantId =
          localStorage.getItem("tenantId") === null
            ? ""
            : localStorage.getItem("tenantId")
        let tempTenantId = localStorage.getItem("tempTenantId")
        config.headers["X-Tenant-ID"] = tenantId || tempTenantId
        config.headers["Authorization"] =
          localStorage.getItem("token") === null
            ? ""
            : "Bearer " + localStorage.getItem("token")
      }
    } else {
      localStorage.clear()
      UserService.doLogout()
    }

    //PATH VARIABLES IS AVAILABLE
    // config.headers['Authorization'] = localStorage.getItem('token') === null ? "" : "Bearer " + localStorage.getItem('token')

    if (config.headers.path) {
      try {
        config.url = bindPath(config.url, config.headers.path)
      } catch (e) {
        console.error("ERROR OCCURED WHEN REPLACING PATH VARIABLES", e)
      }
    }
    delete config.headers.path
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

const productCategoryApiMethods = {
  getProductHierarchy: (cancelSource = { current: undefined }) => {
    if (cancelSource?.current) {
      cancelSource.current()
    }
    return productAxios.get(URLS.endpoints.getLocationHierarchy, {
      cancelToken: new CancelToken(function executor(c) {
        cancelSource.current = c
      }),
    })
  },
  getAllProductCategories: (cancelSource = { current: undefined }) => {
    if (cancelSource?.current) {
      cancelSource.current()
    }
    return productAxios.get(URLS.endpoints.getAll, {
      cancelToken: new CancelToken(function executor(c) {
        cancelSource.current = c
      }),
    })
  },
  createCategory: (bodyData) => {
    return productAxios.post(URLS.endpoints.create, bodyData)
  },
  fetchCategory: (categoryID) => {
    return productAxios.get(URLS.endpoints.fetchCategory)
  },
  updateCategory: (categoryID, bodyData) => {
    return productAxios.patch(URLS.endpoints.updateCategory, bodyData, {
      headers: { path: { categoryID } },
    })
  },
  uploadImage: () => {
    return productAxios.post(URLS.endpoints.uploadImage)
  },
  deleteCategory: (categoryID) => {
    return productAxios.delete(URLS.endpoints.deleteCategory, {
      headers: { path: { categoryID } },
    })
  },
}

export const productDataApiMethods = {
  getProducts: (bodyData) => {
    return headerAxios.post(PRODUCT_DATA_URLS.endpoints.getProducts, bodyData, {
      baseURL: PRODUCT_DATA_URLS.baseURL,
    })
  },
  getProductMapping: async (body) => {
    let response = await ServiceAPI.fetchStoreCommonData(
      API_END_POINTS.getProductMappingDetails,
      body
    )
    return response
  },
  exportAllProduct: () => {
    return headerAxios.get(PRODUCT_DATA_URLS.endpoints.exportAllProduct, {
      baseURL: PRODUCT_DATA_URLS.baseURL,
    })
  },
  getAllProductsWithPagination: (page = 1, limit = 10) => {
    return productAxios.get(PRODUCT_DATA_URLS.endpoints.getAllWithPagination, {
      baseURL: PRODUCT_DATA_URLS.baseURL,
      params: { page, limit },
    })
  },
  getProductGroups: (bodyData) => {
    return productAxios.post(
      PRODUCT_DATA_URLS.endpoints.getAllProductGroupsWithPagination,
      bodyData,
      { baseURL: PRODUCT_DATA_URLS.baseURL }
    )
  },
  getProductCategories: (cancelSource = { current: undefined }) => {
    if (cancelSource?.current) {
      cancelSource.current()
    }
    return productAxios.get(PRODUCT_DATA_URLS.endpoints.getProductCategories, {
      baseURL: PRODUCT_DATA_URLS.baseURL,
      cancelToken: new CancelToken(function executor(c) {
        cancelSource.current = c
      }),
    })
  },
  getProductById: (item_id, isLoc) => {
    return productAxios.get(
      PRODUCT_DATA_URLS.endpoints.getProductById + "/" + item_id + "/" + isLoc,
      { baseURL: PRODUCT_DATA_URLS.baseURL }
    )
  },
  updateProduct: (bodyData, item_id) => {
    return productAxios.put(
      PRODUCT_DATA_URLS.endpoints.updateProduct + "/" + item_id,
      bodyData,
      { baseURL: PRODUCT_DATA_URLS.baseURL }
    )
  },
  updateCategory: (id, bodyData) => {
    return productAxios.put(
      PRODUCT_DATA_URLS.endpoints.updateCategory + "/" + id,
      bodyData,
      { baseURL: PRODUCT_DATA_URLS.baseURL }
    )
  },
  deleteProductCategory: (categoryId) => {
    return productAxios.delete(
      PRODUCT_DATA_URLS.endpoints.deleteCategory + "/" + categoryId,
      { baseURL: PRODUCT_DATA_URLS.baseURL }
    )
  },
  updateProductCategoryMappings: (bodyData) => {
    return productAxios.put(
      PRODUCT_DATA_URLS.endpoints.updateProductCategoryMappings,
      bodyData,
      { baseURL: PRODUCT_DATA_URLS.baseURL }
    )
  },
  getProductCategoryById: (categoryId) => {
    return productAxios.get(
      PRODUCT_DATA_URLS.endpoints.getProductCategoryById + "/" + categoryId,
      { baseURL: PRODUCT_DATA_URLS.baseURL }
    )
  },
  elasticSearchLocation: (bodyData) => {
    return productAxios.post(
      PRODUCT_DATA_URLS.endpoints.searchProducts,
      bodyData,
      { baseURL: PRODUCT_DATA_URLS.baseURL }
    )
  },
  getProductsAttribute: (bodyData) => {
    return headerAxios.post(
      PRODUCT_DATA_URLS.endpoints.getProductsAttribute,
      bodyData,
      { baseURL: PRODUCT_DATA_URLS.baseURL }
    )
  },
  searchAttributes: (bodyData) => {
    return productAxios.post(
      PRODUCT_DATA_URLS.endpoints.searchAttributes,
      bodyData,
      { baseURL: PRODUCT_DATA_URLS.baseURL }
    )
  },
  createProductAttribute: (bodyData) => {
    return headerAxios.post(
      PRODUCT_DATA_URLS.endpoints.createProductAttribute,
      bodyData,
      { baseURL: PRODUCT_DATA_URLS.baseURL }
    )
  },
  getProductAttrById: (_id) => {
    return productAxios.get(
      PRODUCT_DATA_URLS.endpoints.getProductAttrById + "/" + _id,
      { baseURL: PRODUCT_DATA_URLS.baseURL }
    )
  },
  updateProductAttribute: (bodyData, _id) => {
    return productAxios.put(
      PRODUCT_DATA_URLS.endpoints.updateProductAttribute + "/" + _id,
      bodyData,
      {
        baseURL: PRODUCT_DATA_URLS.baseURL,
        headers: {
          ...ServiceAPI.axiosConfig,
        },
      }
    )
  },
  getProductImportStatus: () => {
    return productAxios.get(
      MASTER_DATA_URLS.endpoints.bulkUploadProductStatus,
      {
        baseURL: MASTER_DATA_URLS.baseURL,
        headers: {
          ...ServiceAPI.axiosConfig.headers,
        },
      }
    )
  },
  uploadBulkProductData: (apiProps) => {
    const { formData } = apiProps
    return productAxios.post(
      MASTER_DATA_URLS.endpoints.bulkUploadProducts,
      formData,
      {
        baseURL: MASTER_DATA_URLS.baseURL,
        headers: {
          ...ServiceAPI.axiosConfig.headers,
          "Content-Type": "multipart/form-data",
          "language-code": localStorage.getItem("i18nextLng"),
        },
      }
    )
  },
  getProductBulkUploadLogs: (apiProps) => {
    const { bodyData = {} } = apiProps
    return productAxios.post(
      MASTER_DATA_URLS.endpoints.bulkUploadLogs,
      apiProps,
      {
        baseURL: MASTER_DATA_URLS.baseURL,
        headers: {
          ...ServiceAPI.axiosConfig.headers,
        },
      }
    )
  },
  exportAllProducts: (apiProps) => {
    const { fileType = "" } = apiProps
    return productAxios.get(MASTER_DATA_URLS.endpoints.exportAllProducts, {
      baseURL: MASTER_DATA_URLS.baseURL,
      headers: {
        ...ServiceAPI.axiosConfig.headers,
        fileType: fileType,
        "language-code": localStorage.getItem("i18nextLng"),
      },
    })
  },
  doArchieveProductsStatus: (apiProps) => {
    const { isExport = false, bodyData = {} } = apiProps
    return productAxios.post(
      MASTER_DATA_URLS.endpoints.archieveProductsStatus,
      bodyData,
      {
        baseURL: MASTER_DATA_URLS.baseURL,
        headers: {
          ...ServiceAPI.axiosConfig.headers,
          isExport: isExport,
        },
      }
    )
  },
  getProductExportStatus: () => {
    return productAxios.get(
      MASTER_DATA_URLS.endpoints.bulkDownloadProductStatus,
      {
        baseURL: MASTER_DATA_URLS.baseURL,
        headers: {
          ...ServiceAPI.axiosConfig.headers,
        },
      }
    )
  },
}

export default productCategoryApiMethods
