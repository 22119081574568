import React from "react"
import * as Adm from "@adm"
import {
  Grid,
  Typography,
  TextField,
  Tooltip,
  TextareaAutosize,
} from "@material-ui/core"
import { InfoOutlined } from "@material-ui/icons"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import _ from "lodash"

const StyledTooltip = withStyles({
  arrow: {
    color: "#d38bb9",
  },
  tooltip: {
    color: "#fff",
    backgroundColor: "#d38bb9",
    fontSize: "14px",
    width: "auto",
  },
})(Tooltip)
const appThemeColor = "#d38bb9"

const styles = {
  delimiterText: {
    color: "#fff",
    backgroundColor: appThemeColor,
    width: "200px",
    height: "auto",
    fontWeight: "normal",
    fontSize: "12px",
  },
  delimiterInfoGrid: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    right: "10px",
    bottom: "10px",
    paddingLeft: "10px",
  },
  delimiterContainer: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
  },
}

const useStyles = makeStyles((theme) => ({
  textInput: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
  },
}))

const CustomTextField = (props) => {
  const classes = useStyles()
  const { isError = false, errorMessage = "is Required", ...restProps } =
    props || {}
  return (
    <Grid style={{ minHeight: 60 }}>
      <TextField {...restProps} className={classes.textInput} />
      {isError && (
        <Typography style={{ color: "red", fontSize: 12 }}>
          {errorMessage}
        </Typography>
      )}
    </Grid>
  )
}

const regex = (value) =>
  value.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  ) === null

export const renderERPConfigDP = (props) => {
  const { getMainDropdownValue, getMainDropdownList, setMainDropdownValue, t } =
    props || {}
  return (
    <Grid item xs={12} sm={6} md={6} lg={5}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span style={{ color: "black", fontWeight: "bold" }}>
          {t(`Connection Method`)}
        </span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      <Adm.DropdownKit
        style={{ width: "100%" }}
        defaultValue={getMainDropdownValue}
        list={getMainDropdownList}
        onUpdate={(e) => {
          setMainDropdownValue(e)
          props?.onChangeValueTextField({
            value: e,
            fieldName: "erpconfigName",
          })
        }}
      />
    </Grid>
  )
}
export const renderAPIEndpointTF = (props) => {
  const { t } = props
  return (
    <Grid item xs={12} sm={6} md={6} lg={5}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span style={{ color: "black", fontWeight: "bold" }}>
          {t(`API End Point URL`)}
        </span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      <CustomTextField
        name="apiUrl"
        inputProps={{
          maxLength: 255,
        }}
        errorMessage={
          props?.getIsErrorIntiated &&
          (props?.getERPConfig?.apiUrl === ""
            ? t(`API Url Required`)
            : regex(props?.getERPConfig?.apiUrl)
            ? t(`Enter Valid URL`)
            : "")
        }
        isError={
          props?.getIsErrorIntiated &&
          (props?.getERPConfig?.apiUrl === "" ||
            regex(props?.getERPConfig?.apiUrl))
        }
        value={props?.getERPConfig?.apiUrl}
        style={{ width: "100%" }}
        variant="outlined"
        size="small"
        placeholder={t("Enter the url")}
        onChange={(event) => {
          props?.onChangeValueTextField({
            value: event?.target?.value,
            fieldName: "apiUrl",
          })
        }}
      />
    </Grid>
  )
}
export const renderConnectionMethodDP = (props) => {
  const { getCMDropdownValue, getCMDropdownList, setCMDropdownValue, t } =
    props || {}
  return (
    <Grid item xs={12} sm={6} md={6} lg={5}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span style={{ color: "black", fontWeight: "bold" }}>
          {t(`Authentication`)}
        </span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      <Adm.DropdownKit
        style={{ width: "100%" }}
        defaultValue={getCMDropdownValue}
        list={getCMDropdownList}
        onUpdate={(e) => {
          setCMDropdownValue(e)
          props?.onChangeValueTextField({
            value: e,
            fieldName: "connectionMethod",
          })
        }}
      />
    </Grid>
  )
}
export const renderUserNameTF = (props) => {
  const { t } = props
  return (
    <Grid item xs={12} sm={6} md={6} lg={5}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span style={{ color: "black", fontWeight: "bold" }}>
          {t(`UserName`)}
        </span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      <CustomTextField
        inputProps={{
          maxLength: 64,
        }}
        errorMessage={t(`User Name Required`)}
        isError={
          props?.getIsErrorIntiated && props?.getERPConfig?.userName === ""
        }
        name="userName"
        value={props?.getERPConfig?.userName}
        style={{ width: "100%" }}
        variant="outlined"
        size="small"
        placeholder={t("Enter the User Name")}
        onChange={(event) => {
          props?.onChangeValueTextField({
            value: event?.target?.value,
            fieldName: "userName",
          })
        }}
      />
    </Grid>
  )
}
export const renderPasswordTF = (props) => {
  const { t } = props
  return (
    <Grid item xs={12} sm={6} md={6} lg={5}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span style={{ color: "black", fontWeight: "bold" }}>
          {t(`Password`)}
        </span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      <CustomTextField
        inputProps={{
          maxLength: 32,
        }}
        errorMessage={
          props?.getIsErrorIntiated &&
          (props?.getERPConfig?.password === ""
            ? t(`Password Required`)
            : props?.getERPConfig?.password?.length < 2
            ? t(`Min-2 characters Required`)
            : "")
        }
        isError={
          (props?.getIsErrorIntiated && props?.getERPConfig?.password === "") ||
          props?.getERPConfig?.password?.length < 2
        }
        name="password"
        value={props?.getERPConfig?.password}
        style={{ width: "100%" }}
        variant="outlined"
        size="small"
        placeholder={t("Enter the password")}
        onKeyPress={(e) => {
          if (e.which === 32) e.preventDefault()
        }}
        onPaste={(e) => e.preventDefault()}
        onChange={(event) => {
          props?.onChangeValueTextField({
            value: event?.target?.value,
            fieldName: "password",
          })
        }}
      />
    </Grid>
  )
}
export const renderResponseTypeDP = (props) => {
  const { getRTDropdownValue, getRTDropdownList, setRTDropdownValue, t } =
    props || {}
  return (
    <Grid item xs={12} sm={6} md={6} lg={5}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span style={{ color: "black", fontWeight: "bold" }}>
          {t(`Response Type`)}
        </span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      <Adm.DropdownKit
        style={{ width: "100%" }}
        defaultValue={getRTDropdownValue}
        list={getRTDropdownList}
        onUpdate={(e) => {
          setRTDropdownValue(e)
          props?.onChangeValueTextField({
            value: props?.getDefaultSampleCode[e],
            fieldName: "sampleTestCode",
          })
          props?.onChangeValueTextField({
            value: e,
            fieldName: "responseType",
          })
        }}
      />
    </Grid>
  )
}
export const renderStatusCodeTF = (props) => {
  return (
    <Grid item xs={12} sm={6} md={6} lg={5}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span
          style={{ color: "black", fontWeight: "bold" }}
        >{`Status Code`}</span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      <CustomTextField
        type="number"
        autoFocus={true}
        inputProps={{
          maxLength: 16,
        }}
        className="cls-statuscode-ui-txt"
        errorMessage={`Status Code Required`}
        key={props?.getERPConfig?.statusCode}
        defaultValue={props?.getERPConfig?.statusCode}
        style={{ width: "100%" }}
        variant="outlined"
        size="small"
        placeholder="Enter the Status Code"
        isError={
          props?.getIsErrorIntiated && props?.getERPConfig?.statusCode === ""
        }
        onChange={(event) => {
          const onlyNums = event.target.value.replace(/[^0-9]/g, "")
          if (onlyNums.length < 10) {
            props?.onChangeValueTextField({
              value: event?.target?.value,
              fieldName: "statusCode",
            })
          } else if (onlyNums.length === 10) {
            const number = onlyNums.replace(
              /(\d{3})(\d{3})(\d{4})/,
              "($1) $2-$3"
            )
            props?.onChangeValueTextField({
              value: event?.target?.value,
              fieldName: "statusCode",
            })
          }
        }}
      />
    </Grid>
  )
}

export const renderSuccessStatusCodeTF = (props) => {
  const { t } = props
  return (
    <Adm.ChipKit
      defaultCodeList={props?.getERPConfig?.statusCode}
      bgColor="#9ED38B"
      title={t("Success Status Code(s)")}
      onUpdate={(e) => {
        props?.onChangeValueTextField({
          value: e?.list,
          fieldName: "statusCode",
        })
        console.log("renderSuccessStatusCodeTF", e)
      }}
    />
  )
}
export const renderErrorStatusCodeTF = (props) => {
  const { t } = props
  return (
    <Adm.ChipKit
      defaultCodeList={props?.getERPConfig?.statusErrorCode}
      bgColor="#EF9E9E"
      title={t("Error Status Code(s)")}
      onUpdate={(e) => {
        props?.onChangeValueTextField({
          value: e?.list,
          fieldName: "statusErrorCode",
        })
      }}
    />
  )
}

export const renderSampleTestCode = (props) => {
  const { t } = props
  let value = props?.getERPConfig?.sampleTestCode

  return (
    <Grid item xs={12} sm={12} md={12} lg={10}>
      <Grid
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" style={{ marginBottom: 10 }}>
          <span style={{ color: "black", fontWeight: "bold" }}>
            {t(`Sample Response`)}
          </span>
          <span style={{ color: "red" }}>{`*`}</span>
          <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
        </Typography>
        <Typography style={{ fontWeight: "bold", color: "#CE5E5E" }}>
          {props?.getRTDropdownValue}
        </Typography>
      </Grid>
      <TextareaAutosize
        style={{
          resize: "none",
          border: "1px solid #C7C7C7",
          padding: 10,
          borderRadius: 8,
          width: "100%",
          height: 200,
          minHeight: 200,
          maxHeight: 200,
        }}
        defaultValue={value}
        onChange={(event) => {
          props?.onChangeValueTextField({
            value: event?.target?.value,
            fieldName: "sampleTestCode",
          })
        }}
        onBlur={(event) => {
          props?.onChangeValueTextField({
            value: event?.target?.value,
            fieldName: "sampleTestCode",
          })
        }}
      />
    </Grid>
  )
}
export const renderTokenTF = (props) => {
  const { t } = props
  return (
    <Grid item xs={12} sm={6} md={6} lg={5}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span style={{ color: "black", fontWeight: "bold" }}>{t(`Token`)}</span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>

      <CustomTextField
        name="token"
        errorMessage={
          props?.getIsErrorIntiated &&
          (props?.getERPConfig?.token === ""
            ? t("Token Required")
            : props?.getERPConfig?.token?.length < 4
            ? t(`Min-4 characters Required`)
            : "")
        }
        isError={
          (props?.getIsErrorIntiated && props?.getERPConfig?.token === "") ||
          props?.getERPConfig?.token?.length < 4
        }
        value={props?.getERPConfig?.token}
        inputProps={{
          maxLength: 128,
        }}
        style={{ width: "100%" }}
        variant="outlined"
        size="small"
        placeholder={t("Enter the Token")}
        onKeyPress={(e) => {
          if (e.which === 32) e.preventDefault()
        }}
        onChange={(event) => {
          props?.onChangeValueTextField({
            ...event,
            value: event?.target?.value,
            fieldName: "token",
          })
        }}
      />
    </Grid>
  )
}
export const renderFTPTF = (props) => {
  const { t } = props
  return (
    <Grid item xs={12} sm={6} md={6} lg={5}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span style={{ color: "black", fontWeight: "bold" }}>
          {t(`File Location (FTP/SFTP)`)}
        </span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      <CustomTextField
        name="fileLocation"
        inputProps={{
          maxLength: 64,
        }}
        errorMessage={t(`File Location Required`)}
        isError={
          props?.getIsErrorIntiated && props?.getERPConfig?.fileLocation === ""
        }
        value={props?.getERPConfig?.fileLocation}
        style={{ width: "100%" }}
        variant="outlined"
        size="small"
        placeholder={t("Enter the URL")}
        onChange={(event) => {
          props?.onChangeValueTextField({
            value: event?.target?.value,
            fieldName: "fileLocation",
          })
        }}
      />
    </Grid>
  )
}
export const renderFileTypeDP = (props) => {
  const { getFTDropdownValue, getFTDropdownList, setFTDropdownValue, t } =
    props || {}
  return (
    <Grid item xs={12} sm={6} md={6} lg={5}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span style={{ color: "black", fontWeight: "bold" }}>
          {t(`File Type`)}
        </span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      <Adm.DropdownKit
        style={{ width: "100%" }}
        defaultValue={getFTDropdownValue}
        list={getFTDropdownList}
        onUpdate={(e) => {
          setFTDropdownValue(e)
          props?.onChangeValueTextField({
            value: e,
            fieldName: "fileType",
          })
        }}
      />
    </Grid>
  )
}
export const renderDelimiterTF = (props) => {
  const {
    getDLDropdownValue,
    getDLDropdownList,
    setDLDropdownValue,
    delimiterToolTip = "The Lorenz attractor is a strange attractor that arises in a system of equations describing the 2-dimensional flow of fluid of uniform depth ",
    tooltipPlacement = "right",
    t,
  } = props || {}
  return (
    <Grid
      style={styles.delimiterContainer}
      container
      item
      xs={12}
      sm={6}
      md={6}
      lg={5}
    >
      <Grid style={{ width: "90%" }} item xs={11} sm={11} md={11} lg={11}>
        <Typography variant="h5" style={{ marginBottom: 10 }}>
          <span style={{ color: "black", fontWeight: "bold" }}>
            {t(`Delimiter`)}
          </span>
          <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
          <span style={{ marginLeft: 10, color: "grey", fontSize: 12 }}>
            ({t("Optional")})
          </span>
        </Typography>
        <Adm.DropdownKit
          style={{ width: "100%" }}
          defaultValue={getDLDropdownValue}
          list={getDLDropdownList}
          onUpdate={(e) => {
            setDLDropdownValue(e)
            props?.onChangeValueTextField({
              value: e,
              fieldName: "delimter",
            })
          }}
        />
      </Grid>
      <Grid style={styles.delimiterInfoGrid} item xs={1} sm={1} md={1} lg={1}>
        <StyledTooltip
          arrow={delimiterToolTip ? true : false}
          placement={tooltipPlacement}
          title={
            delimiterToolTip ? (
              <span style={styles.delimiterText}>
                <span>{`${delimiterToolTip}`}</span>
              </span>
            ) : (
              ""
            )
          }
        >
          <InfoOutlined htmlColor={appThemeColor} />
        </StyledTooltip>
      </Grid>
    </Grid>
  )
}
