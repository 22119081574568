import React from "react"
import {
  Box,
  Card,
  Grid,
  Divider,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
} from "@material-ui/core"
import {
  Typography,
  CardContent,
  GreenText,
  RedText,
  useStyles,
  BlackText,
} from "./styles"
import _ from "lodash"
import * as IconsGallery from "@iconsGallery"
import { useTranslation } from "react-i18next"

const RevenueCard = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    isLoading = false,
    isColor = true,
    style = {},
    isIconEnable = false,
    icon: DynamicIcon = () => "",
    displayCard = true,
  } = props

  const GreenTypo = isColor ? GreenText : BlackText
  const RedTypo = isColor ? RedText : BlackText
  return (
    <>
      {displayCard ? (
        <>
          <Card
            className={`${classes.cardStyles} makeStyles-cardStyles-support`}
            style={style}
          >
            <Grid container>
              <Grid>
                <Typography
                  variant="h4"
                  className="cls-rc-main-heading"
                  style={{
                    paddingLeft: 16,
                    paddingTop: 16,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  {t(props?.title)}
                </Typography>
              </Grid>
            </Grid>
            <CardContent>
              <Grid container>
                {isIconEnable && (
                  <Grid item xs>
                    <div>
                      <Box textAlign="center" justifyContent="space-between">
                        <DynamicIcon />
                      </Box>
                    </div>
                  </Grid>
                )}
                {!_.isEmpty(props?.total) && props?.total.value !== "" && (
                  <Grid item xs>
                    <div>
                      <Box textAlign="center" justifyContent="space-between">
                        <Typography variant="h4" className={classes.values}>
                          {isLoading ? (
                            <IconsGallery.DotLoading />
                          ) : (
                            props?.total.value
                          )}
                        </Typography>
                        <Typography
                          style={{ mt: 1, paddingTop: "1px" }}
                          variant="h6"
                          className={`${classes.headerValue} makeStyles-headerValue-support`}
                        >
                          {t(props?.total.label)}
                        </Typography>
                      </Box>
                    </div>
                  </Grid>
                )}
                {!_.isEmpty(props?.total) &&
                  !_.isEmpty(props?.active) &&
                  props?.total?.value !== "" &&
                  props?.active?.value !== "" && (
                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    />
                  )}
                {!_.isEmpty(props?.active) && props?.active?.value !== "" && (
                  <Grid item xs>
                    <div>
                      <Box textAlign="center" justifyContent="space-between">
                        <Typography variant="h4" className={classes.values}>
                          {isLoading ? (
                            <IconsGallery.DotLoading />
                          ) : (
                            props?.active.value
                          )}
                        </Typography>
                        <GreenTypo
                          className={`${classes.textColorstyle} cls-common-revenue-kit`}
                        >
                          {t(props?.active.label)}
                        </GreenTypo>
                      </Box>
                    </div>
                  </Grid>
                )}
                {!_.isEmpty(props?.inActive) &&
                  !_.isEmpty(props?.active) &&
                  props?.inActive?.value !== "" &&
                  props?.active?.value !== "" && (
                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    />
                  )}
                {!_.isEmpty(props?.inActive) && props?.inActive?.value !== "" && (
                  <Grid item xs>
                    <div>
                      <Box textAlign="center" justifyContent="space-between">
                        <Typography variant="h4" className={classes.values}>
                          {isLoading ? (
                            <IconsGallery.DotLoading />
                          ) : (
                            props?.inActive.value
                          )}
                        </Typography>
                        <RedTypo
                          style={{ paddingLeft: "10px" }}
                          className="cls-common-revenue-kit"
                        >
                          {t(props?.inActive.label)}
                        </RedTypo>
                      </Box>
                    </div>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </>
      ) : (
        <>
          <Card
            className={`${classes.cardStyles} makeStyles-cardStyles-support`}
            style={style}
          ></Card>
        </>
      )}
    </>
  )
}
export default RevenueCard
export { RevenueCard }
