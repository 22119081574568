import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Paper,
  Typography,
} from "@material-ui/core"
import { Formik } from "formik"
import _, { forEach } from "lodash"
import { useAddressCardUIState } from "./state"
import {
  useAddAddressContentStyles,
  ButtonGroup,
  CardWrapper,
  DialogWrapperKit,
  AddressCardListMainWrapper,
  DialogWrapper,
} from "./styles"
import * as Features from "@features"
import * as Adm from "@adm"
import * as Utils from "@utils"
import PropTypes from "prop-types"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { AddIcon, BorderColorOutlinedIcon } from "@iconsGallery"
import { defautFixtureFormList } from "./fixture"
import * as ServiceAPI from "@services"
import * as constantsAPI from "@constants"
import styled from "styled-components"
import { AddressModal } from "./AddressModal"
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

const customAddressCardRenderHeader = (props) => {
  const {
    theme,
    classes,
    history,
    cardData,
    editAddress,
    isEditMode,
    editformList,
    countryList,
    onChangeForm,
    stateList,
    index,
    deleteAddress,
  } = props || {}

  const editCardAttr = {
    dialogTitle: "Edit Address",
    label: "name",
    defautFormList: defautFixtureFormList({
      ...cardData,
      countryList,
      stateList,
    }),
    index,
    addressUpdate: editAddress,
    isEdit: true,
    headingTitle: "Edit Address",
    onDelete: deleteAddress,
    onChangeForm: (data) => {
      onChangeForm(data)
    },
  }
  return cardData ? (
    <Grid container>
      <Grid item xs={6}>
        <Paper className={classes.paper}>
          <Typography
            className={`${classes.title} makeStyles-title-support`}
            color="textSecondary"
            gutterBottom
          >
            {cardData.name}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        {!isEditMode && <AddressModal {...editCardAttr} />}
      </Grid>
    </Grid>
  ) : (
    <></>
  )
}

export const customRenderContainer = (props) => {
  const {
    addressCardData,
    addressList,
    addNewCard,
    classes,
    setAllowPrompt,
    editAddress,
    edit,
    isEditMode,
    updateData,
    changeDefaultStatus,
    countryList,
    stateList,
    onChangeForm,
    deleteAddress,
  } = props
  const onEditaddress = ({
    name,
    line1,
    line2,
    line3,
    line4,
    city,
    state,
    country,
    zip_code_5,
    zip_code_4,
    billing,
    shipping,
    item: { _id } = {},
    ...restProps
  }) => {
    const configEditKit = {
      _id,
      name,
      line1,
      line2,
      line3,
      line4,
      city,
      state,
      country,
      zip_code_5,
      zip_code_4,
      billing,
      shipping,
      default: _.get(restProps, "default", false),
    }
    editAddress(configEditKit)
  }

  return (
    <AddressCardListMainWrapper>
      {Utils.JSUtils.isEmpty(addressCardData) === false
        ? addressCardData.map((item, index) => {
            return (
              <Adm.AddressCard
                cardData={item}
                index={index}
                renderHeader={(props) =>
                  customAddressCardRenderHeader({
                    ...props,
                    countryList,
                    onChangeForm,
                    stateList,
                    index,
                    deleteAddress,
                  })
                }
                editAddress={(props) => onEditaddress({ item, ...props })}
                changeDefaultStatus={changeDefaultStatus}
                isEditMode={edit}
              />
            )
          })
        : edit && <Features.NoRecordFound />}
      {!edit ? (
        <>
          <AddressModal
            isEdit={false}
            defautFormList={defautFixtureFormList({
              countryList,
              stateList,
            })}
            addressUpdate={addNewCard}
            setAllowPrompt={setAllowPrompt}
            onChangeForm={(data) => {
              if (data !== null) {
                setAllowPrompt(true)
              }
              onChangeForm(data)
            }}
          />
        </>
      ) : null}
    </AddressCardListMainWrapper>
  )
}
