import React from "react"
import { withTheme } from "styled-components/macro"
import { makeStyles } from "@material-ui/core/styles"
import {
  Divider,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  TextField,
  InputLabel,
} from "@material-ui/core"
import * as ReduxHelper from "@reduxHelper"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import _ from "lodash"

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    "& .MuiFormHelperText-contained": {
      margin: "0px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
  },
  locSizeWidth: {
    "& .MuiOutlinedInput-root": { width: "100%", height: "36px" },
    "& .MuiFormHelperText-contained": {
      margin: "0px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "var(--primaryColor)",
    },
  },
  mulSymbol: {
    fontSize: "18px",
    fontWeight: 400,
    padding: "5px",
    alignSelf: "flex-start",
  },
  errorMsgDiv: { width: "160px" },
  disabledTextInput: {
    "& input": {
      backgroundColor: "#f6f6f6",
    },
  },
}))

function LocationTreeComponent(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    edit,
    locationSize = {},
    locationType,
    locations,
    locationTypeError,
    locationSizeError,
    setLocationSize,
    setLocationType,
    setAnyChanges,
    isDetailView = false,
    setAllowPrompt = () => {},
    storeList = [],
    setStoreType = () => {},
    storeType = {},
    storeTypeError,
    SDCConfigured,
    isDesktop = false,
    isTablet = false,
  } = props || {}

  const knapcartProject =
    process.env.REACT_APP_PROJECT_NAME === "DGrab" ? false : true

  const getSelectedValue = (list, val, key = "id") =>
    _.find(list, (o) => _.get(o, key) === _.get(val, key, "-"))

  function onlyDotsAndNumbers(txt, event) {
    var charCode = event.which ? event.which : event.keyCode
    if (charCode === 46) {
      if (txt.value.indexOf(".") < 0) return true
      else return false
    }

    if (txt.value.indexOf(".") > 0) {
      var txtlen = txt.value.length
      var dotpos = txt.value.indexOf(".")
      if (txtlen - dotpos > 2) return false
    }

    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false

    return true
  }
  React.useEffect(() => {
    document.body.classList.add("cls-location-tree-comp")
    return () => {
      document.body.classList.remove("cls-location-tree-comp")
    }
  }, [])

  return (
    <Grid container row="true" className="cls-locationtree">
      <Grid
        container
        item
        row="true"
        alignContent="center"
        alignItems="center"
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{
          padding: isDetailView ? "16px" : "8px 0px",
          height: edit ? "" : "60px",
          marginTop: isDetailView ? "" : "20px",
          borderBottom: isDetailView ? "1px solid #e5e5e5" : "",
        }}
      >
        <Grid
          item
          lg={isDetailView ? 2 : 3}
          md={isDetailView ? 3 : 3}
          sm={isDetailView ? 4 : 3}
          xs={isDetailView ? 4 : 12}
        >
          <Typography
            style={{
              fontWeight: "800",
              marginBottom: isDetailView ? "" : "16px",
            }}
            variant="h5"
          >
            {t("Location Type")}
            <span style={{ color: "red" }}>{edit ? "*" : ""} </span>
          </Typography>
        </Grid>
        <Grid
          item
          lg={isDetailView ? 4 : 5}
          md={6}
          sm={6}
          xs={isDetailView ? 8 : 12}
        >
          {edit ? (
            <FormControl
              variant="outlined"
              className={classes.formControl}
              size="small"
            >
              {locationType === "" ? (
                <InputLabel className="ddl-locationtype">
                  {t("Select Location Type")}
                </InputLabel>
              ) : null}
              <Grid container direction="row">
                <TextField
                  id="id-dropdown-common-postion-setup-kit"
                  MenuProps={{
                    classes: { paper: "cls-dropdown-common-postion-setup-kit" },
                  }}
                  select
                  className={classes.formControl}
                  style={SDCConfigured ? { backgroundColor: "#f6f6f6" } : {}}
                  error={
                    locationTypeError && (locationType === "" ? true : false)
                  }
                  helperText={
                    locationTypeError &&
                    (locationType === "" ? t("Select Location Type") : "")
                  }
                  size="small"
                  variant="outlined"
                  defaultValue={locationType}
                  value={
                    _.isPlainObject(locationType)
                      ? getSelectedValue(locations, locationType, "id")
                      : locationType
                  }
                  onChange={(event) => {
                    if (locationType !== null) {
                      setAllowPrompt(true)
                    }
                    if (isDetailView) {
                      setAnyChanges(true)
                    }
                    setLocationType(event.target.value)
                    if (event.target.value?.name === "Store") {
                      setStoreType({ id: "AS", name: "Autonomous Store" })
                    }
                    dispatch(
                      ReduxHelper.addLocationHierarchy({
                        locationType: event.target.value,
                        storeType: storeType,
                        locationSize: locationSize,
                      })
                    )
                  }}
                  disabled={SDCConfigured ? true : false}
                >
                  {locations.map((option) => (
                    <MenuItem key={option.name} value={option}>
                      <span className="cls-common-dd-plain-txt-kit">
                        {option.name}
                      </span>
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </FormControl>
          ) : (
            <Typography
              variant="body1"
              style={{ padding: "19px 0px", fontWeight: 500 }}
            >
              {locationType?.name}
            </Typography>
          )}
        </Grid>
      </Grid>
      {isDetailView ? <Divider /> : ""}
      {isDetailView && locationType && locationType?.name === "Store" && (
        <Divider />
      )}
      <Grid
        container
        item
        row="true"
        alignContent="center"
        alignItems="center"
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{
          padding: isDetailView ? "16px" : "8px 0px",
          height: edit ? "" : "60px",
          marginTop: isDetailView ? "" : "20px",
        }}
      >
        <Grid
          item
          xl={isDetailView ? 2 : 3}
          lg={isDetailView ? 2 : 3}
          md={isDetailView ? 3 : 3}
          sm={isDetailView ? 4 : 3}
          xs={isDetailView ? 4 : 12}
        >
          <Typography
            style={{
              fontWeight: "800",
              marginBottom: isDetailView ? "" : "16px",
            }}
            variant="h5"
          >
            {t("Location Size")}
            <span style={{ color: "#0000008a", marginLeft: "5px" }}>
              ({t("Feet")})
            </span>
            <span style={{ color: "red" }}>{edit ? "*" : ""} </span>
          </Typography>
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={8} xs={isDetailView ? 8 : 12}>
          {edit ? (
            <Grid
              container
              row
              alignContent="center"
              alignItems="center"
              xl={12}
              lg={8}
              sm={12}
              xs={12}
              md={12}
            >
              <Grid
                item
                lg={4}
                sm={3}
                xs={4}
                md={3}
                xl={2}
                style={{ alignSelf: "flex-start" }}
              >
                <TextField
                  type="number"
                  placeholder={t("Length")}
                  variant="outlined"
                  label={t("Length")}
                  inputProps={{
                    minLength: 1,
                  }}
                  className={`${classes.locSizeWidth} makeStyles-locSizeWidth-support`}
                  value={locationSize.lengthValue}
                  error={
                    locationSizeError &&
                    (locationSize.lengthValue === ""
                      ? true
                      : locationSize.lengthValue < 1 ||
                        locationSize.lengthValue === 0
                      ? true
                      : locationSize.lengthValue?.length > 15
                      ? true
                      : false)
                  }
                  helperText={
                    locationSizeError &&
                    (locationSize.lengthValue === ""
                      ? t("Enter Length")
                      : locationSize.lengthValue < 1 ||
                        locationSize.lengthValue === 0
                      ? "Enter valid length"
                      : locationSize.lengthValue?.length > 15
                      ? "Length can be of max-15 characters"
                      : "")
                  }
                  onkeypress="return onlyDotsAndNumbers(this,event);"
                  oncopy="return false"
                  ondrag="return false"
                  ondrop="return false"
                  onpaste="return false"
                  onChange={(event) => {
                    event.target.value =
                      event.target.value === ""
                        ? 0
                        : parseFloat(event.target.value)
                    if (
                      event.target.value.length < 0 ||
                      event.target.value.length > 10
                    ) {
                      return 0
                    }
                    if (
                      _.isEmpty(
                        locationSize.lengthValue["locationSize.lengthValue"]
                      ) &&
                      _.isEmpty(_.trim(event.target.value))
                    ) {
                      return 0
                    }

                    if (
                      event.target.value === "" ||
                      /[0-9]/.test(event.target.value)
                    ) {
                      setLocationSize({
                        ...locationSize,
                        lengthValue:
                          event.target.value < 0 ? 0 : event.target.value,
                      })
                    }
                    if (locationSize.lengthValue !== null) {
                      setAllowPrompt(true)
                    }
                    if (isDetailView) {
                      setAnyChanges(true)
                    }
                    dispatch(
                      ReduxHelper.addLocationHierarchy({
                        locationType: locationType,
                        storeType: storeType,
                        locationSize: {
                          ...locationSize,
                          lengthValue:
                            event.target.value < 0 ? 0 : event.target.value,
                        },
                      })
                    )
                  }}
                  size="small"
                />
              </Grid>
              <Grid
                item
                lg={4}
                sm={3}
                xs={4}
                md={3}
                xl={2}
                style={{ alignSelf: "flex-start", padding: "0px 5px" }}
              >
                <TextField
                  type="number"
                  placeholder={t("Width")}
                  label={t("Width")}
                  variant="outlined"
                  inputProps={{
                    minLength: 1,
                  }}
                  error={
                    locationSizeError &&
                    (locationSize.widthValue === ""
                      ? true
                      : locationSize.widthValue < 1 ||
                        locationSize.widthValue === 0
                      ? true
                      : locationSize.widthValue?.length > 15
                      ? true
                      : false)
                  }
                  helperText={
                    locationSizeError &&
                    (locationSize.widthValue === ""
                      ? t("Enter Width")
                      : locationSize.widthValue < 1 ||
                        locationSize.widthValue === 0
                      ? t("Enter valid Width")
                      : locationSize.widthValue?.length > 15
                      ? t("Width can be of max-15 characters")
                      : "")
                  }
                  className={`${classes.locSizeWidth} makeStyles-locSizeWidth-support`}
                  value={locationSize.widthValue}
                  onkeypress="return onlyDotsAndNumbers(this,event);"
                  oncopy="return false"
                  ondrag="return false"
                  ondrop="return false"
                  onpaste="return false"
                  onChange={(event) => {
                    event.target.value =
                      event.target.value === ""
                        ? 0
                        : parseFloat(event.target.value)
                    if (
                      event.target.value.length < 0 ||
                      event.target.value.length > 10
                    ) {
                      return 0
                    }
                    if (
                      _.isEmpty(
                        locationSize.widthValue["locationSize.widthValue"]
                      ) &&
                      _.isEmpty(_.trim(event.target.value))
                    ) {
                      return 0
                    }
                    if (
                      event.target.value === "" ||
                      /[0-9]/.test(event.target.value)
                    ) {
                      setLocationSize({
                        ...locationSize,
                        widthValue:
                          event.target.value < 0 ? 0 : event.target.value,
                      })
                    }
                    if (locationSize.widthValue !== null) {
                      setAllowPrompt(true)
                    }
                    if (isDetailView) {
                      setAnyChanges(true)
                    }
                    dispatch(
                      ReduxHelper.addLocationHierarchy({
                        locationType: locationType,
                        storeType: storeType,
                        locationSize: {
                          ...locationSize,
                          widthValue:
                            event.target.value < 0 ? 0 : event.target.value,
                        },
                      })
                    )
                  }}
                  size="small"
                />
              </Grid>
              <Grid
                item
                lg={3}
                sm={3}
                xs={4}
                md={3}
                xl={3}
                style={{ alignSelf: "flex-start" }}
              >
                <TextField
                  type="number"
                  placeholder={t("Height")}
                  label={t("Height")}
                  variant="outlined"
                  inputProps={{
                    minLength: 1,
                  }}
                  className={`${classes.locSizeWidth} makeStyles-locSizeWidth-support`}
                  value={locationSize.heightValue}
                  error={
                    locationSizeError &&
                    (locationSize.heightValue === ""
                      ? true
                      : locationSize.heightValue < 1 ||
                        locationSize.heightValue === 0
                      ? true
                      : locationSize.heightValue?.length > 15
                      ? true
                      : false)
                  }
                  helperText={
                    locationSizeError &&
                    (locationSize.heightValue === ""
                      ? t("Enter Height")
                      : locationSize.heightValue < 1 ||
                        locationSize.heightValue === 0
                      ? t("Enter valid Height")
                      : locationSize.heightValue?.length > 15
                      ? t("Height can be of max-15 characters")
                      : "")
                  }
                  onkeypress="return onlyDotsAndNumbers(this,event);"
                  oncopy="return false"
                  ondrag="return false"
                  ondrop="return false"
                  onpaste="return false"
                  onChange={(event) => {
                    event.target.value =
                      event.target.value === ""
                        ? 0
                        : parseFloat(event.target.value)
                    if (
                      event.target.value.length < 0 ||
                      event.target.value.length > 10
                    ) {
                      return 0
                    }
                    if (
                      _.isEmpty(
                        locationSize.heightValue["locationSize.heightValue"]
                      ) &&
                      _.isEmpty(_.trim(event.target.value))
                    ) {
                      return 0
                    }
                    if (
                      event.target.value === "" ||
                      /[0-9]/.test(event.target.value)
                    ) {
                      setLocationSize({
                        ...locationSize,
                        heightValue:
                          event.target.value < 0 ? 0 : event.target.value,
                      })
                    }
                    if (locationSize.heightValue !== null) {
                      setAllowPrompt(true)
                    }
                    if (isDetailView) {
                      setAnyChanges(true)
                    }
                    if (event.target.value === 0) {
                      setTimeout(() => {
                        setLocationSize((e) => {
                          return {
                            ...e,
                            heightValue: 0,
                          }
                        })
                      }, 500)
                    }

                    dispatch(
                      ReduxHelper.addLocationHierarchy({
                        locationType: locationType,
                        storeType: storeType,
                        locationSize: {
                          ...locationSize,
                          heightValue:
                            event.target.value < 0 ? 0 : event.target.value,
                        },
                      })
                    )
                  }}
                  size="small"
                />
              </Grid>
            </Grid>
          ) : (
            <Typography variant="body1" style={{ fontWeight: 500 }}>
              {`${locationSize.lengthValue} x ${locationSize.widthValue} x ${locationSize.heightValue}`}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

const LocationTree = withTheme(LocationTreeComponent)
export { LocationTree }
export default LocationTree
