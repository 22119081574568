import * as Yup from "yup"
import moment from "moment"
import * as Utils from "@utils"
export const getNumberValidation = (props = {}) => {
  const {
    postiveText = "Is positive?",
    textError = "value must be greater than or equal to 0",
    formatValidation = (value) => value > 1,
    number = 0,
  } = props || {}
  if (props.isRequired) {
    return {
      validationSchema: Yup.number()
        .required(`${props.label} is required`)
        .test(
          postiveText,
          `${props.label} value must be greater than 0`,
          (value) => !value || value > -1
        )
        .strict(true),
    }
  } else {
    return {
      validationSchema: Yup.number().test(
        postiveText,
        textError,
        (value) => !value || value > -1
      ),
    }
  }
}
export const getPositiveNumberValidation = (props = {}) => {
  return {
    validationSchema: Yup.number().test(
      "postiveText",
      "value must be greater than or equal to 0",
      (value) => !value || value > -1
    ),
  }
}

export const getStringValidation = (props = {}) => {
  const {
    postiveText = "Is positive?",
    textError = "ERROR: The number must be greater than 0!",
    formatValidation = (value) => value > 1,
    number = 0,
  } = props || {}
  const shapeObj = {}
  if (props.isRequired) {
    return {
      validationSchema: Yup.string()
        //.required(`${props.label} is required`)
        .matches(
          Utils.JSUtils.getYupValidationCheck("^[A-Za-z0-9_.\\s-]*$"),
          `Enter valid ${props.label} `
        )
        .required(
          props.type === undefined
            ? `Enter ${props.label}`
            : `${props.type} ${props.label}`
        )
        .min(
          props.min,
          `${props.label} can be of min-${props.min} and max-${props.max} characters`
        )
        .max(
          props.max,
          `${props.label} can be of min-${props.min} and max-${props.max} characters`
        )
        .strict(true),
    }
  } else {
    return {
      validationSchema: Yup.string()
        .matches(
          Utils.JSUtils.getYupValidationCheck("^[A-Za-z0-9_.\\s-]*$"),
          `Enter valid ${props.label}`
        )
        .min(
          props.min,
          `${props.label} can be of min-${props.min} and max-${props.max} characters`
        )
        .max(
          props.max,
          `${props.label} can be of min-${props.min} and max-${props.max} characters`
        )
        .strict(true),
    }
  }
}
export const getBirthDateValidation = () => {
  return {
    validationSchema: Yup.string().test("DOB", "* is required", (value) => {
      return moment().diff(moment(value), "years") >= 25
    }),
  }
}
export const getEffectiveDateValidation = () => {
  return {
    validationSchema: Yup.object().test(
      "Start Date",
      "Start Date and Time is required",
      (v) => {
        return (
          moment(v.date || "").isValid() &&
          moment(v.time || "", "hh:mm a").isValid()
        )
      }
    ),
  }
}
export const getEndDateValidation = (startDate) => {
  return {
    validationSchema: Yup.object().test(
      "End Date",
      "End date & time should be greater than start date & time",
      (value) => {
        if (moment(startDate.date).diff(moment(value.date), "minute") < 0) {
          return true
        } else if (
          moment(startDate.date).diff(moment(value.date), "minute") === 0
        ) {
          return startDate.time >= value.time ? false : true
        } else {
          return false
        }
      }
    ),
  }
}
