import {
  Grid,
  TextField,
  Typography,
  Switch,
  Checkbox,
  IconButton,
} from "@material-ui/core"
import { CheckCircleIcon, Edit, Slash } from "@iconsGallery"
import React, { useEffect, useRef, useState } from "react"
import { withTheme } from "styled-components/macro"
import _, { matches } from "lodash"
import { useTranslation } from "react-i18next"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import * as Utils from "@utils"
import * as Adm from "@adm"
import moment from "moment"
import styled from "styled-components"
import * as ServiceAPI from "@services"
import * as constantsAPI from "@constants"
import * as StyledDOM from "./styles"
import { ConfirmationDialog } from "@features"
import { renderLeftPanel, renderRightPanel } from "./renderUIProps"
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

const PurpleSwitch = withStyles({
  switchBase: {
    color: "#E4E7EB",
  },
  track: {
    backgroundColor: "rgba(18, 22, 27, 0.2)",
    width: "34px",
    height: "14px",
  },
  thumb: {
    width: "20px",
    height: "20px",
  },
})(Switch)

const defaultRenderHeaderCard = () => <>TEST</>

const CustomerDetailKit = (props) => {
  const {
    isEdit,
    setIsEdit,
    resetData = () => {},
    updateDataInternally,
    renderHeaderCard = defaultRenderHeaderCard,
    updateFormData,
    classesUI,
    data,
    socialProfileList,
    ethnicityList,
    setAllowPrompt = () => {},
    genderList,
    isWriteAllowed = false,
  } = props

  const stateProps = {
    resetData,
    setIsEdit,
    data,
    isSaveFlag,
    setSaveFlag,
    isEdit,
    updateFormData,
    updateDataInternally,
    classesUI,
  }
  const {
    initialValues = {
      firstName: _.get(props, "data.firstName", "-") || "",
      middleInitial: _.get(props, "data.middleInitial", "") || "",
      lastName: _.get(props, "data.lastName", "-") || "",
      associate_flag: _.get(props, "data.associate_flag", false) || false,
      title: _.get(props, "data.title", "-") || "",
      phoneNumber:
        _.get(data, "phoneNumber.countryCode", "") +
        (_.get(data, "phoneNumber.number", "") === 0
          ? ""
          : _.get(data, "phoneNumber.number", "")),
      countryCode: _.get(data, "phoneNumber.countryCode", ""),
      email: _.get(props, "data.email", "-") || "",
      preferred_contact: [
        {
          phone:
            _.get(props, "data.preferred_contact[0].phone", false) || false,
          email:
            _.get(props, "data.preferred_contact[0].email", false) || false,
        },
      ],
      gender: _.get(props, "data.gender", {}) || "",
      ethnicity: _.get(props, "data.ethnicity", "-") || "",
      date_of_birth: _.get(props, "data.date_of_birth", "") || "",
      social_network: _.get(props, "data.social_network", "-") || "",
      profile_name: _.get(props, "data.profile_name", "-") || "",
    },
    isSaveFlag,
    setSaveFlag,
  } = props || {}
  const { t } = useTranslation()
  const classes = StyledDOM.useCustomerDetailStyles()
  const [title, setTitle] = useState("-")
  const [gender, setGender] = useState("")
  const [ethnicity, setEthnicity] = useState("")
  const [socialProfile, setSocialProfile] = useState("")
  const [associateMember, setAssociateMember] = useState(false)
  const [initialValue, setIntialValue] = useState()
  const [open, setOpen] = useState(false)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [reDirect, setReDirect] = useState(false)
  const [pathName, setPathName] = useState("/")
  const phoneNumber = _.get(data, "phoneNumber.number", "")
  const [isPhoneField, setPhoneField] = useState({
    phoneCountryCode: "",
  })
  const titles = ["Mr.", "Mrs.", "Ms."]

  useEffect(() => {
    if (_.get(props, "data") !== undefined) {
      setIntialValue(initialValues)
    }
  }, [props.data])
  const handleCloseButtonTrigger = () => {
    setOpen(true)
  }

  const handleCustomCloseRedirect = () => {
    setOpenConfirmationDialog(false)
    setOpen(false)
  }

  const validationInfoCheck = async (values) => {
    await props.setAlert(false)
    const body = {
      customer_id: _.get(props, "data.customer_id", ""),
      phoneNumber: {
        countryCode: values.countryCode,
        number:
          values.phoneNumber.slice(values.countryCode.length).length === 0
            ? ""
            : values.phoneNumber.slice(values.countryCode.length),
      } || { countryCode: "", number: "" },
      email: values.email,
    }

    let response = await ServiceAPI.fetchCustomerCommonData(
      API_END_POINTS.customerInfoValidationCheck,
      body
    )
    let { data: { status = "", data = {}, message = "" } = {} } = response || {}
    if (status === "success") {
      setIsEdit(false)
      updateDataInternally(values)
    } else {
      await props.setAlertType("error")
      await props.setAlertMessage(
        _.get(
          response,
          "data.message",
          _.get(response, "response.data.message", "Try again later!")
        )
      )
      await props.setAlert(true)
    }
  }

  const setAllowPromptCheck = (value) => {
    if (value !== null) {
      setAllowPrompt(true)
    }
  }

  const phoneRegExp = Utils.JSUtils.getYupPhoneValidationCheck()
  const maxDate = Utils.JSUtils.getYupMaxDateValidationCheck()
  return (
    <>
      <ConfirmationDialog
        openConfirmationDialog={openConfirmationDialog}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        setReDirect={setReDirect}
        handleCloseButtonTrigger={handleCloseButtonTrigger}
        handleCustomCloseRedirect={handleCustomCloseRedirect}
      />
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          title: Yup.string().required("Select Title"),
          firstName: Yup.string()
            .min(1, "First Name can be of min-1 max-255 characters")
            .max(255, "First Name can be of min-1 max-255 characters")
            .strict(true)
            .matches(
              Utils.JSUtils.getYupValidationCheck("^[a-zA-Z_-\\s]+$"),
              "Enter valid First Name"
            )
            .required("Enter First Name"),
          middleInitial: Yup.string()
            .max(255, "Middle Name can be of max-255 characters")
            .matches(
              Utils.JSUtils.getYupValidationCheck("^[a-zA-Z_-\\s]+$"),
              "Enter valid Middle Name"
            )
            .strict(true),
          lastName: Yup.string()
            .strict(true)
            .min(1, "Last Name can be of min-1 max-255 characters")
            .max(255, "Last Name can be of min-1 max-255 characters")
            .matches(
              Utils.JSUtils.getYupValidationCheck("^[a-zA-Z_-\\s]+$"),
              "Enter valid Last Name"
            )
            .required("Enter Last Name"),
          associate_flag: Yup.string().required("* is required"),
          phoneNumber: Yup.string()
            .required(`Enter Phone number`)
            .matches(phoneRegExp, "Enter valid Phone Number"),
          email: Yup.string()
            .required("Enter Valid Email")
            .min(1, "Email ID must be min-1 max-255 characters")
            .max(255, "Email ID must be min-1 max-255 characters")
            .email("Invalid email format"),
          profile_name: Yup.string()
            .min(1, "User Name can be of min-1 max-255 characters")
            .max(255, "User Name can be of min-1 max-255 characters")
            .when("social_network", {
              is: (value: "id") => !!value.id,
              then: Yup.string().required("Enter User name"),
            }),
        })}
        onSubmit={(values) => {
          validationInfoCheck(values)
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => {
          const titleDropdownKitAttr = {
            list: titles,
            defaultValue: values.title,
            placeholder: "Select Title",
            style: { width: "300px" },
            error:
              Boolean(touched.title && errors.title) === true
                ? errors.title
                : "",
            onUpdate: (value) => {
              setAllowPromptCheck(value)
              setFieldValue("title", value)
              setTitle(value)
              updateFormData("title", value)
            },
          }
          const handleKeyPress = (e, type) => {
            if (type === "number") {
              if (e.which === 32) {
                e.preventDefault()
              }
            } else {
              if (e.which === 32 && e.target.value === "") {
                e.preventDefault()
              }
            }
          }
          const genderDropdownKitAttr = {
            list: props.genderList,
            keyForValueReference: "id",
            labelPath: "name",
            defaultValue:
              typeof values.gender === "object" && values.gender.name === ""
                ? ""
                : values.gender,
            placeholder: "Select Gender",
            style: { width: "320px" },
            error:
              Boolean(touched.gender && errors.gender) === true
                ? errors.gender
                : "",
            onUpdate: (value) => {
              setAllowPromptCheck(value)
              setFieldValue("gender", value)
              updateFormData("gender", value)
            },
          }
          const ethnicityDropdownKitAttr = {
            list: props.ethnicityList,
            defaultValue:
              typeof values.ethnicity === "object" &&
              values.ethnicity.name === ""
                ? ""
                : values.ethnicity,
            isPlainObj: true,
            keyForValueReference: "id",
            label: "name",
            placeholder: "Select Ethnicity",
            style: { width: "320px" },
            error:
              Boolean(touched.ethnicity && errors.ethnicity) === true
                ? errors.ethnicity
                : "",
            onUpdate: (value) => {
              setAllowPromptCheck(value)
              setFieldValue("ethnicity", value)
              updateFormData("ethnicity", value)
            },
          }
          const socialProfileDropdownKitAttr = {
            list: props.socialProfileList,
            keyForValueReference: "id",
            defaultValue:
              typeof values.social_network === "object" &&
              values.social_network.name === ""
                ? ""
                : values.social_network,
            placeholder: "Select",
            labelPath: "name",
            style: { marginRight: "10px" },
            error:
              Boolean(touched.name && errors.name) === true ? errors.name : "",
            onUpdate: (value) => {
              setAllowPromptCheck(value)
              setFieldValue("social_network", value)
              updateFormData("social_network", value)
            },
          }
          return (
            <StyledDOM.FormMainWrapper noValidate onSubmit={handleSubmit}>
              <StyledDOM.CardTopWrapper item lg={12} sm={12} md={12} xs={12}>
                {renderLeftPanel({
                  classesUI,
                  handleBlur,
                  formValues: values,
                  formTouched: touched,
                  formErrors: errors,
                  setFieldValue,
                  updateFormData,
                  setAllowPrompt,
                  setAllowPromptCheck,
                  ...stateProps,
                })}
              </StyledDOM.CardTopWrapper>
              <Grid
                container
                lg={12}
                spacing={4}
                style={{
                  borderBottom: "1px solid rgba(47, 72, 88, 0.15)",
                  padding: "15px",
                }}
                alignItems="center"
              >
                <Grid
                  className="cls-title-label"
                  item
                  lg={2}
                  md={4}
                  sm={4}
                  xs={4}
                >
                  <Typography
                    variant="h5"
                    className={`${classes.inputFieldText} makeStyles-inputFieldText-support`}
                  >
                    Title{isEdit ? <span style={{ color: "red" }}>*</span> : ""}
                  </Typography>
                </Grid>
                <Grid
                  className="cls-title-textfield"
                  item
                  lg={4}
                  md={8}
                  sm={8}
                  xs={8}
                >
                  {isEdit ? (
                    <Adm.DropdownKit {...titleDropdownKitAttr} />
                  ) : (
                    <Typography variant="body1" className={classes.nonEditText}>
                      {values?.title}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  lg={2}
                  md={4}
                  sm={4}
                  xs={4}
                  container
                  direction="row"
                >
                  <Typography
                    variant="h5"
                    className={`${classes.inputFieldText} makeStyles-inputFieldText-support`}
                  >
                    Gender
                  </Typography>
                </Grid>
                <Grid item lg={4} md={8} sm={8} xs={8}>
                  {isEdit ? (
                    <Adm.DropdownKit
                      {...genderDropdownKitAttr}
                      label="gender_name"
                    />
                  ) : (
                    <Typography variant="body1" className={classes.nonEditText}>
                      {values.gender.name === "" ? "-" : values.gender.name}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                lg={12}
                spacing={4}
                style={{
                  borderBottom: "1px solid rgba(47, 72, 88, 0.15)",
                  padding: "15px",
                }}
                alignItems="center"
              >
                <Grid
                  className="cls-title-label"
                  item
                  lg={2}
                  md={4}
                  sm={4}
                  xs={4}
                >
                  <Typography
                    variant="h5"
                    className={`${classes.inputFieldText} makeStyles-inputFieldText-support`}
                  >
                    Phone Number
                    {isEdit ? <span style={{ color: "red" }}>*</span> : ""}
                  </Typography>
                </Grid>
                <Grid
                  className="cls-title-textfield"
                  item
                  lg={4}
                  md={8}
                  sm={8}
                  xs={8}
                >
                  {isEdit ? (
                    <Adm.PhoneField
                      name="phoneNumber"
                      entity="Customer"
                      onKeyPress={(e) => handleKeyPress(e, "number")}
                      defaultValue={
                        values.phoneNumber !== "0"
                          ? values.phoneNumber.toString()
                          : ""
                      }
                      className="customer-edit-base-phone-field"
                      onUpdate={({ value, dialCode, format, countryCode }) => {
                        const formatText =
                          format !== undefined &&
                          format
                            .replace("+", "")
                            .replace("-", "")
                            .replaceAll(" ", "")
                        setPhoneField({
                          phoneCountryCode: countryCode,
                          phoneCount: formatText.length,
                          phoneCode: dialCode,
                        })
                        setAllowPromptCheck(value)
                        setFieldValue("phoneNumber", value)
                        setFieldValue("countryCode", dialCode)
                        //onGlobalUpdate()
                      }}
                      defaultErrorValue={Boolean(
                        touched.phoneNumber && errors.phoneNumber
                      )}
                    />
                  ) : (
                    <Typography variant="body1" style={{ fontWeight: "400" }}>
                      {values.phoneNumber === "0" ? "-" : values.phoneNumber}
                      {values.preferred_contact[0].phone ? (
                        <span
                          style={{
                            backgroundColor: "#1565C0",
                            borderRadius: "50px",
                            color: "#fff",
                            marginLeft: "10px",
                            padding: "5px 10px",
                            fontSize: "10px",
                          }}
                        >
                          Preferred Contact
                        </span>
                      ) : null}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  lg={2}
                  md={4}
                  sm={4}
                  xs={4}
                  container
                  direction="row"
                >
                  <Typography
                    variant="h5"
                    className={`${classes.inputFieldText} makeStyles-inputFieldText-support`}
                  >
                    Birth Date
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={8}
                  sm={8}
                  xs={8}
                  alignItems="center"
                  alignContent="center"
                >
                  {isEdit ? (
                    <Adm.Calender
                      isTimeEnable={false}
                      style={{ marginTop: "0px", width: "320px" }}
                      error={
                        Boolean(errors.date_of_birth) === true
                          ? errors.date_of_birth
                          : ""
                      }
                      date={values.date_of_birth}
                      onUpdateCalender={({ date }) => {
                        let updateDate = new Date(date)
                        setAllowPromptCheck(date)
                        setFieldValue("date_of_birth", date)
                        updateFormData("date_of_birth", date)
                      }}
                    />
                  ) : (
                    <Typography variant="body1" className={classes.nonEditText}>
                      {moment(_.get(props, "data.date_of_birth")).format(
                        "MMM DD, YYYY"
                      ) === "Invalid date"
                        ? "-"
                        : moment(_.get(props, "data.date_of_birth")).format(
                            "MMM DD, YYYY"
                          )}{" "}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                lg={12}
                spacing={2}
                style={{
                  borderBottom: "1px solid rgba(47, 72, 88, 0.15)",
                  padding: "15px",
                }}
                alignItems="center"
              >
                <Grid
                  item
                  lg={2}
                  md={4}
                  sm={4}
                  xs={4}
                  container
                  direction="row"
                >
                  <Typography
                    variant="h5"
                    className={`${classes.inputFieldText} makeStyles-inputFieldText-support`}
                  >
                    Social Profile
                  </Typography>
                </Grid>
                {isEdit ? (
                  <Grid
                    className="cls-title-textfield"
                    item
                    lg={4}
                    md={8}
                    sm={8}
                    xs={8}
                  >
                    <div style={{ display: "flex" }}>
                      <Adm.DropdownKit
                        {...socialProfileDropdownKitAttr}
                        style={{ width: "115px" }}
                      />
                      <TextField
                        variant="outlined"
                        size="small"
                        className={`${classes.smallTextInput}`}
                        type="text"
                        name="profile_name"
                        onKeyPress={(e) => handleKeyPress(e, "text")}
                        placeholder="Enter Username"
                        value={values.profile_name}
                        error={Boolean(
                          touched.profile_name && errors.profile_name
                        )}
                        helperText={touched.profile_name && errors.profile_name}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          if (
                            _.isEmpty(values["profile_name"]) &&
                            _.isEmpty(_.trim(event.target.value))
                          ) {
                            return null
                          }
                          setAllowPromptCheck(values.profile_name)
                          setFieldValue("profile_name", event.target.value)
                          updateFormData("profile_name", event.target.value)
                        }}
                      />
                    </div>
                  </Grid>
                ) : (
                  <Grid
                    className="cls-title-textfield"
                    item
                    lg={4}
                    md={8}
                    sm={8}
                    xs={8}
                  >
                    <div style={{ display: "flex" }}>
                      <Typography variant="body1" style={{ fontWeight: "400" }}>
                        {values.social_network.name
                          ? `${values.social_network.name}:`
                          : "-"}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.nonEditText}
                        style={{ marginLeft: "16px" }}
                      >
                        {values.profile_name}
                      </Typography>
                    </div>
                  </Grid>
                )}

                <Grid
                  item
                  lg={2}
                  md={4}
                  sm={4}
                  xs={4}
                  container
                  direction="row"
                >
                  <Typography
                    variant="h5"
                    className={`${classes.inputFieldText} makeStyles-inputFieldText-support`}
                  >
                    Ethnicity
                  </Typography>
                </Grid>
                <Grid item lg={4} md={8} sm={8} xs={8}>
                  {isEdit ? (
                    <Adm.DropdownKit {...ethnicityDropdownKitAttr} />
                  ) : (
                    <Typography variant="body1" className={classes.nonEditText}>
                      {values.ethnicity.name === ""
                        ? "-"
                        : values.ethnicity.name}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid
                container
                lg={12}
                spacing={2}
                style={{
                  borderBottom: "1px solid rgba(47, 72, 88, 0.15)",
                  padding: "15px",
                }}
                alignItems="center"
              >
                <Grid
                  item
                  lg={2}
                  md={4}
                  sm={4}
                  xs={4}
                  container
                  direction="row"
                >
                  <Typography
                    variant="h5"
                    className={`${classes.inputFieldText} makeStyles-inputFieldText-support`}
                  >
                    Email Address
                    {isEdit ? <span style={{ color: "red" }}>*</span> : ""}
                  </Typography>
                </Grid>
                <Grid
                  className="cls-title-textfield"
                  item
                  lg={isEdit ? 4 : 10}
                  md={8}
                  sm={8}
                  xs={8}
                >
                  {isEdit ? (
                    <TextField
                      variant="outlined"
                      size="small"
                      className={classes.textInput}
                      style={{
                        width: "300px",
                        background: "#EEEEEE",
                      }}
                      type="text"
                      disabled
                      name="email"
                      onKeyPress={(e) => handleKeyPress(e, "text")}
                      placeholder="example@domain.com"
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={(event) => {
                        setAllowPromptCheck(values.email)
                        setFieldValue("email", event.target.value)
                        updateFormData("email", event.target.value)
                      }}
                    />
                  ) : (
                    <>
                      <span style={{ display: "flex" }}>
                        <Typography
                          variant="body1"
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: "80%",
                            fontWeight: "400",
                          }}
                        >
                          {values.email}
                        </Typography>
                        {values.preferred_contact[0].email ? (
                          <span
                            style={{
                              backgroundColor: "#1565C0",
                              borderRadius: "50px",
                              color: "#fff",
                              marginLeft: "10px",
                              padding: "5px 10px",
                              fontSize: "10px",
                            }}
                          >
                            Preferred Contact
                          </span>
                        ) : null}
                      </span>
                    </>
                  )}
                </Grid>
                {isEdit ? (
                  <>
                    <Grid
                      item
                      lg={2}
                      md={4}
                      sm={4}
                      xs={4}
                      container
                      direction="row"
                    >
                      <Typography
                        variant="h5"
                        className={`${classes.inputFieldText} makeStyles-inputFieldText-support`}
                      >
                        Preferred Contact
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      md={8}
                      sm={8}
                      xs={8}
                      container
                      direction="row"
                      alignItems="center"
                      alignContent="center"
                    >
                      <Checkbox
                        className={classes.checkBox}
                        checked={
                          _.get(values, "preferred_contact[0].phone", false) ===
                          true
                            ? true
                            : false
                        }
                        onChange={() => {
                          console.log(values, "valuesvalues")
                          setAllowPromptCheck(values.preferred_contact[0].phone)
                          setFieldValue(
                            "preferred_contact[0].phone",
                            !values.preferred_contact[0].phone
                          )
                          updateFormData(
                            "preferred_contact[0].phone",
                            !values.preferred_contact[0].phone
                          )
                        }}
                        name="checkedB"
                        color="primary"
                      />
                      <Typography
                        variant="body1"
                        style={{ marginRight: "30px", marginLeft: "10px" }}
                      >
                        Phone
                      </Typography>
                      <Checkbox
                        className={classes.checkBox}
                        checked={
                          _.get(values, "preferred_contact[0].email", false) ===
                          true
                            ? true
                            : false
                        }
                        onChange={() => {
                          console.log(values, "valuesvalues")
                          setAllowPromptCheck(values.preferred_contact[0].email)
                          setFieldValue(
                            "preferred_contact[0].email",
                            !values.preferred_contact[0].email
                          )
                          updateFormData(
                            "preferred_contact[0].email",
                            !values.preferred_contact[0].email
                          )
                        }}
                        name="checkedB"
                        color="primary"
                      />
                      <Typography
                        variant="body1"
                        style={{ marginLeft: "10px" }}
                      >
                        Email
                      </Typography>
                    </Grid>
                  </>
                ) : null}
              </Grid>

              <StyledDOM.IconWrapper container>
                {isEdit ? (
                  <>
                    <IconButton
                      onClick={() => {
                        // setOpenConfirmationDialog(true)
                        setIsEdit(false)
                        resetData()
                      }}
                    >
                      <Slash style={{ color: "red", height: "20px" }} />
                    </IconButton>
                    <Adm.Button type="submit">
                      <CheckCircleIcon
                        style={{
                          color: `${
                            isSaveFlag === false
                              ? "rgba(76, 175, 80, 1)"
                              : "rgba(76, 175, 80, 0.5)"
                          }`,
                          height: "20px",
                        }}
                      />
                    </Adm.Button>
                  </>
                ) : (
                  <IconButton
                    onClick={() => {
                      setIsEdit(true)
                    }}
                    disabled={isWriteAllowed ? false : true}
                  >
                    <Edit style={{ height: "20px" }} />
                  </IconButton>
                )}
              </StyledDOM.IconWrapper>
            </StyledDOM.FormMainWrapper>
          )
        }}
      </Formik>
    </>
  )
}

const CustomerDetailInfo = withTheme(CustomerDetailKit)

export { CustomerDetailInfo }
export default CustomerDetailInfo
